import { useContext } from "react";
import { useTranslation } from "react-i18next";
import WcOutlinedIcon from "@mui/icons-material/WcOutlined";
import { GenderSelect } from "../../../ui/form/EmotionSelect";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import HumanMatchingContext from "../context/HumanMatchingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import PreferredGender from "../../../../data-model/types/human/PreferredGender";
import useTrackPageView from "../../../providers/matomo/useTrackPageView";
import useSetMatchingStep from "../hooks/useSetMatchingStep";
import { GENDER_STEP } from "../context/humanMatchingSteps";
import { I18Namespaces } from "../../../language/I18Namespaces";

export default function PreferredGenderStep() {
  const { t: translate } = useTranslation<I18Namespaces>(["human", "common"]);

  const {
    next,
    formState,
    totalSteps,
    step,
    previous,
    updateMatchedHumanData,
  } = useContext(HumanMatchingContext);

  useTrackPageView("Human Onboarding > Gender Therapist");
  useSetMatchingStep(GENDER_STEP);

  const goToNextStep = () => {
    updateMatchedHumanData({
      preferredProfessionalGender: formState.getInputProps(
        PreferredGender.getPath(),
      ).value,
    });
    next();
  };

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate(
          "human:onboarding.preferred_professional_gender.description",
        )}
        fullCopyright
        headline={translate("human:onboarding.title")}
        labelBack={translate("common:previous")}
        labelNext={translate("common:next")}
        onClickBack={previous}
        onClickNext={goToNextStep}
        showSpecialConditionsForTherapist={false}
        step={step.step}
        subhead={translate(
          "human:onboarding.preferred_professional_gender.title",
        )}
        subheadicon={<WcOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <GenderSelect
          emoticons={PreferredGender.getSelectOptions(translate)}
          {...formState.getInputProps(PreferredGender.getPath())}
          last
          noWrap
        />
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
