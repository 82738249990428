import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "../../ui/form/TextField";
import Label from "../../ui/form/FormLabel";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../layout/wizard/OnboardingStepContainer";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { graphql } from "../../../api/__generated__";
import * as Yup from "yup";
import { useContext } from "react";
import { Typography, useTheme } from "@mui/material";
import { RouteNames } from "../../routes/routeNames";
import { useNavigate, useSearchParams } from "react-router-dom";
import ToastContext from "../../providers/toast/ToastContext";

const choosePasswordMutation = graphql(`
  mutation ChoosePasswordV2($input: ChoosePasswordV2Input!) {
    choosePasswordV2(input: $input)
  }
`);

const ChoosePasswordPage = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const navigate = useNavigate();
  const { setToast } = useContext(ToastContext);

  const [choosePassword] = useMutation(choosePasswordMutation);

  const [searchParams] = useSearchParams();

  return (
    <Formik
      initialValues={{ password: "", repeatPassword: "" }}
      onSubmit={async ({ password }) => {
        try {
          const email = searchParams.get("email");
          const token = searchParams.get("token");

          if (email && token)
            await choosePassword({
              variables: {
                input: {
                  email: email,
                  newPassword: password,
                  resetToken: token,
                },
              },
            });

          setToast({
            message: t("common:password.reset.success"),
            severity: "success",
          });
          navigate(RouteNames.Login.path);
        } catch (e) {
          setToast({ message: t("errors:error.generic"), severity: "error" });
        }
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(16, t("validation:password.strength"))
          .required(t("validation:password.mandatory")),

        repeatPassword: Yup.string()
          .equals([Yup.ref("password")], t("validation:password.equal"))
          .required(t("validation:password.mandatory")),
      }).required()}
    >
      {(props) => {
        return (
          <OnboardingContainer>
            <OnboardingStepContainer
              disable={!props.isValid && !props.isValidating}
              fullCopyright
              headline={t("common:password")}
              onClickNext={() => props.submitForm()}
              subhead={t("common:password.choose")}
              subheadicon={<LockOutlinedIcon />}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Label
                      label={t("validation:password.strength")}
                      style={{
                        marginBottom: 15,
                      }}
                    />
                    <TextField
                      autoComplete="password"
                      autoFocus
                      id="password"
                      label={t("common:password")}
                      onChange={(e) => {
                        props.setFieldValue("password", e);
                      }}
                      style={{ width: "100%" }}
                      type="password"
                      value={props.values.password}
                    />
                    <Typography
                      sx={{
                        ...theme.controls.validation.error,
                        fontSize: 16,
                        margin: " 3px 14px 0px;",
                      }}
                    >
                      {props.errors.password}
                    </Typography>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      autoComplete="password"
                      id="repeatPassword"
                      label={t("common:password.repeat")}
                      onChange={(e) => props.setFieldValue("repeatPassword", e)}
                      style={{ width: "100%" }}
                      type="password"
                      value={props.values.repeatPassword}
                    />
                    <Typography
                      sx={{
                        ...theme.controls.validation.error,
                        fontSize: 16,
                        margin: " 3px 14px 0px;",
                      }}
                    >
                      {props.errors.repeatPassword}
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
            </OnboardingStepContainer>
          </OnboardingContainer>
        );
      }}
    </Formik>
  );
};

export default ChoosePasswordPage;
