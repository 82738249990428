import { BoxProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Color from "color";
import { ReactComponent as Quotes } from "../../../illustrations/quotes.svg";

const PREFIX = "PartnerReviewBlock";

const classes = {
  avatar: `${PREFIX}-avatar`,
  avatarImg: `${PREFIX}-avatarImg`,
  circle: `${PREFIX}-circle`,
  contentBlock: `${PREFIX}-contentBlock`,
  description: `${PREFIX}-description`,
  innerCircle: `${PREFIX}-innerCircle`,
  name: `${PREFIX}-name`,
  nameText: `${PREFIX}-nameText`,
  quotes: `${PREFIX}-quotes`,
  title: `${PREFIX}-title`,
  wrapperBlock: `${PREFIX}-wrapperBlock`,
};

const StyledBox = styled(Box)<{ color: string }>(({ theme, color }) => ({
  [`&.${classes.wrapperBlock}`]: {
    background: "white",
    borderRadius: 14,
    margin: 30,
    maxWidth: 362,
    minWidth: 320,
  },

  [`& .${classes.contentBlock}`]: {
    background: `${Color(color).alpha(0.01).string()}`,
    border: `solid 2px ${Color(color).alpha(0.2).string()}`,
    borderRadius: 14,
    minheight: "220",
    paddingBottom: 50,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 70,
    position: "relative",
    textalign: "center",
    zindex: -1,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },

  [`& .${classes.title}`]: {
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "35px",
    marginBottom: 20,
  },

  [`& .${classes.avatar}`]: {
    left: "calc(50% - 50px)",
    position: "absolute",
    top: -40,
  },

  [`& .${classes.quotes}`]: {
    position: "absolute",
    right: 70,
    top: -10,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.circle}`]: {
    background: color,
    borderRadius: "50%",
    height: 100,
    position: "relative",
    width: 100,
  },

  [`& .${classes.innerCircle}`]: {
    background: "white",
    borderRadius: "50%",
    bottom: 5,
    left: 5,
    position: "absolute",
    right: 5,
    top: 5,
  },

  [`& .${classes.avatarImg}`]: {
    borderRadius: "50%",
    bottom: 10,
    height: 80,
    left: 10,
    position: "absolute",
    right: 10,
    top: 10,
    width: 80,
  },

  [`& .${classes.name}`]: {
    position: "relative",
  },

  [`& .${classes.nameText}`]: {
    fontFamily: "InterLight",
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: "0.4px",
    marginBottom: 20,
    textAlign: "center",
  },

  [`& .${classes.description}`]: {
    fontSize: 18,
    lineHeight: "27px",
    marginBottom: 20,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      wordWrap: "break-word",
    },
  },
}));

interface IPartnerReviewBlock extends BoxProps {
  avatarSrc: string | undefined;
  color: string;
  key?: string | number;
  name: string | undefined;
}

const PartnerReviewBlock = ({
  color,
  avatarSrc,
  name,
  children,
  key,
  ...other
}: IPartnerReviewBlock) => {
  return (
    <StyledBox
      className={classes.wrapperBlock}
      color={color}
      key={key}
      {...other}
    >
      <Box className={classes.contentBlock}>
        <Quotes className={classes.quotes} fill={color} />
        <Box className={classes.avatar}>
          <Box className={classes.circle}>
            <Box className={classes.innerCircle} />
          </Box>
          <img className={classes.avatarImg} src={avatarSrc} />
        </Box>
        <Box className={classes.name}>
          <Typography className={classes.nameText}>{name}</Typography>
        </Box>
        {children}
      </Box>
    </StyledBox>
  );
};

export default PartnerReviewBlock;
