/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Moment } from "moment";
import useConfirmConsultation from "../../../api/consultations/hooks/useConfirmConsultation";
import useUpdateConsultationMutation from "../../../api/consultations/hooks/useUpdateConsultation";
import LoadingPage from "../../../components/layout/LoadingPage";
import { UserRoles } from "../../../data-model/types/User";
import { canCancel } from "../../../utils/dates";
import useQueryParams, { getParamAsString } from "../../hooks/useQueryParams";
import { IAuthContext } from "../../providers/auth/auth";
import AuthContext from "../../providers/auth/AuthContext";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { IToastContext } from "../../providers/toast/toast";
import ToastContext from "../../providers/toast/ToastContext";
import { RouteNames } from "../../routes/routeNames";
import { IConsultationsOverviewContext } from "../context/consultations";
import ConsultationsOverviewContext from "../context/ConsultationsOverviewContext";
import useUpdateConsultationFormState from "../useUpdateConsultationFormState";
import { QualityQuestions } from "../../quality-questions/QualityQuestions.types";
import { ConsultationStatus as ConsultationStatusFromGraph } from "../../../api/__generated__/graphql";
import { APIConsultation } from "../../../api/consultations/consultations";
import { APIMessage } from "../../../api/messages/messages";
import CashTransaction from "../../../data-model/types/CashTransaction";
import ConsultationStatus from "../../../data-model/types/consultation/ConsultationStatus";
import ConsultationDetail from "./ConsultationDetail";
import { ConsultationTypes } from "../../../data-model/types/consultation/Consultation";
import { graphql } from "../../../api/__generated__";
import { useQuery } from "@apollo/client";
import { OrgProfileDialog } from "../../organizations/OrgProfileDialog";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";

interface ConsultationDetailWithState {
  userRole: UserRoles;
}

const orgProfileQuery = graphql(`
  query OrgProfile($organizationId: Int!) {
    organizationById(id: $organizationId) {
      id
      ...OrgProfileDialogOrganization
      profile {
        ...useSelectTranslationStringInput
      }
    }
  }
`);

export default function ConsultationDetailWithState({
  userRole,
}: ConsultationDetailWithState) {
  const navigate = useNavigate();
  const { setToast } = useContext<IToastContext>(ToastContext);
  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Ui,
  ]);

  const {
    selectedConsultation,
    consultations,
    loading,
    refetchOneConsultation,
  } = useContext<IConsultationsOverviewContext>(ConsultationsOverviewContext);

  const organizationId =
    selectedConsultation?.getClient().getOrganization().getID() ?? 0;

  // Should later be part of consultation data fetched above, but querying here due to context mess
  const { data: orgProfileData } = useQuery(orgProfileQuery, {
    skip: !organizationId,
    variables: {
      organizationId,
    },
  });

  const { updateConsultation } = useUpdateConsultationMutation();
  const {
    confirmConsultation: confirmConsultationCall,
    loading: confirmingConsultationState,
  } = useConfirmConsultation();

  // Use this to toggle the consultation into inline edit mode.
  const [editing, setEditing] = useState(false);
  const [isGoingToCancel, setIsGoingToCancel] = useState<boolean>(false);
  const [cancelling, setCancelling] = useState<boolean>(false);

  const { reimbursementState } = useQueryParams(false);
  const stateOfReimbursement = getParamAsString(reimbursementState);

  //Show toast and refetch consultation when redirected from reimbursement page
  useEffect(() => {
    if (stateOfReimbursement && stateOfReimbursement === "requested") {
      setToast({
        message: t("ui:reimbursement.successfully.requested"),
        severity: "success",
      });

      if (selectedConsultation) {
        refetchOneConsultation(selectedConsultation.getUUID());
      }
    }
  }, [stateOfReimbursement, selectedConsultation]);

  const formState = useUpdateConsultationFormState(
    {
      consultations,
    },
    { validationOptions: { currentConsultation: selectedConsultation } },
  );

  const isHuman = userRole === UserRoles.HUMAN;
  let message: APIMessage.Output;
  let scheduledFrom: Moment | string;

  useEffect(() => {
    if (selectedConsultation) {
      message = selectedConsultation.getMessage().getMessage();
      scheduledFrom = selectedConsultation.getStartDate();

      if (formState) {
        formState.setValue("date", scheduledFrom);
        formState.setValue("timeFrom", scheduledFrom);
        formState.setValue("timeTo", selectedConsultation.getEndDate());
        formState.setValue(
          "duration",
          selectedConsultation.getDurationInMinutes(),
        );
        formState.setValue("message", message);
        formState.setValue(
          ConsultationStatus.getPath(),
          selectedConsultation.getStatus().getAsFormStateValue(t),
        );
      }
    }
  }, [loading, selectedConsultation]);

  const updateFormState = (cashTransaction: CashTransaction) => {
    formState.setValue(
      "pricePerMinute",
      cashTransaction.getAmount() / formState.getValue("duration"),
    );
    formState.setValue("price", cashTransaction.getAmount());
  };

  const redirectToPayment = useCallback(() => {
    if (selectedConsultation) {
      navigate(
        RouteNames.Payment.Consultation.Pay.path.replace(
          ":uuid",
          selectedConsultation.getUUID(),
        ),
      );
    }
  }, [selectedConsultation, navigate]);

  // TODO: add update function to context;
  const handleUpdate = useCallback(
    async (input: APIConsultation.Update.Input) => {
      if (!selectedConsultation) return;

      try {
        if (
          isHuman &&
          selectedConsultation &&
          !canCancel(selectedConsultation.getStartDate())
        ) {
          setToast({
            message: t("common:consultation_detail.reschedule_before_24h"),
            severity: "error",
          });

          return;
        }

        const { data } = await updateConsultation(input);

        selectedConsultation.update(
          data?.updateConsultation as APIConsultation.Result | null,
        );
      } catch {
        setToast({
          message: t("common:somethingwrong"),
          severity: "error",
        });
      }
    },
    [
      isHuman,
      selectedConsultation,
      updateFormState,
      setToast,
      t,
      updateConsultation,
    ],
  );

  const cancelConsultation = () => {
    //TODO: this check should be backend logic
    if (!selectedConsultation) return;

    if (isHuman && !canCancel(selectedConsultation.getStartDate())) {
      setToast({
        message: t("common:consultation_detail.cancel_before_24h"),
        severity: "error",
      });

      return;
    }

    const message = formState.getValue("message");

    if (!message || message.length === 0 || message === " ") {
      setToast({
        message: t("common:consultation_detail.message_cancellation"),
        severity: "warning",
      });

      return;
    }

    setCancelling(true);

    const input: {
      message: APIMessage.Input;
      status: ConsultationStatusFromGraph;
      uuid: string;
    } = {
      message: {
        body: message,
        fromProfessional: true,
      },
      status: isHuman ? "CANCELLED_BY_HUMAN" : "CANCELLED_BY_PROFESSIONAL",
      uuid: selectedConsultation.getUUID(),
    };

    handleUpdate(input as APIConsultation.Update.Input);
    setIsGoingToCancel(false);
    setCancelling(false);
    setToast({
      message: t("common:consultation_detail.cancel_concultation"),
      severity: "success",
    });
  };

  const saveConfirmConsultation = useCallback(async () => {
    if (selectedConsultation && currentUser) {
      const result = await confirmConsultationCall({
        email: currentUser.getEmail().getValue(),
        uuid: selectedConsultation.getUUID(),
      });

      if (selectedConsultation && result)
        selectedConsultation.update(result.data?.confirmConsultation);

      setToast({
        message: t("common:consultation_detail.message_confirmed"),
        severity: "success",
      });
    } else {
      setToast({
        message: t("common:consultation_detail.message_confirmed"),
        severity: "warning",
      });
    }
  }, [selectedConsultation, handleUpdate]);

  const [orgDialogOpen, setOrgDialogOpen] = useState(false);

  const organization = orgProfileData?.organizationById;
  const selectTranslation = useSelectTranslation();

  const confirmConsultation = async () => {
    if (
      selectedConsultation &&
      currentUser &&
      currentUser.getRole() === UserRoles.PROFESSIONAL &&
      selectedConsultation.getConsultationType() === ConsultationTypes.INTRO &&
      selectedConsultation.getClient().getOrganization().getID() !== 0 && // WTF is this? none of these things are nullable 😱😱
      selectTranslation(organization?.profile)
    ) {
      setOrgDialogOpen(true);
    } else {
      await saveConfirmConsultation();
    }
  };

  const handleCloseOrgDialog = (accepted: boolean) => {
    setOrgDialogOpen(false);

    if (accepted) {
      saveConfirmConsultation();
    }
  };

  const updateConsultationStatus = useCallback(
    (uuid: string, status: ConsultationStatus | undefined) => {
      try {
        const input = {
          status,
          uuid,
        };

        handleUpdate(input as APIConsultation.Update.Input);
        setToast({
          message: t("common:consultation_detail.status_changed"),
          severity: "success",
        });
      } catch (error) {
        setToast({
          message: t("common:consultation_detail.message_something_went_wrong"),
          severity: "error",
        });
      }
    },
    [handleUpdate],
  );

  const updateConsult = async () => {
    const isValid = formState.validate();

    if (!isValid) return;

    const startTime = formState.getValue("timeFrom");
    const endTime = formState.getValue("timeTo");
    const uuid = selectedConsultation?.getUUID();

    if (uuid) {
      const consultationUpdateData: APIConsultation.Update.Input = {
        price: Number(formState.getValue("price")),
        scheduledFrom: startTime,
        scheduledTo: endTime,
        status: "REQUESTED",
        uuid,
      };

      const messageBody = formState.getValue("message");
      let messageInput: APIMessage.Input;

      if (messageBody !== null && messageBody.length > 0) {
        messageInput = {
          body: messageBody,
          fromProfessional: !isHuman,
        };
        consultationUpdateData.message = messageInput;
      }

      await handleUpdate(consultationUpdateData);

      setEditing(false);
    }
  };

  const startCall = useCallback(() => {
    if (selectedConsultation) {
      const redirectPath = RouteNames.Call.Enter.path.replace(
        ":uuid",
        selectedConsultation.getUUID(),
      );

      navigate(
        QualityQuestions.getQuestionPath(
          QualityQuestions.Entrypoints.BeforeConsultation,
          redirectPath,
          false,
          selectedConsultation.getID(),
        ),
      );
    }
  }, [selectedConsultation]);

  if (!selectedConsultation) return <LoadingPage full />;

  return (
    <>
      <ConsultationDetail
        cancelConsultation={cancelConsultation}
        cancelling={cancelling}
        confirmConsultation={confirmConsultation}
        confirming={confirmingConsultationState}
        consultation={selectedConsultation}
        formState={formState}
        isEditing={editing}
        isGoingToCancel={isGoingToCancel}
        isProfessional={!isHuman}
        redirectToPayment={redirectToPayment}
        setEditing={setEditing}
        setIsGoingToCancel={setIsGoingToCancel}
        startCall={startCall}
        updateConsultation={updateConsult}
        updateConsultationStatus={updateConsultationStatus}
      />
      {organization && (
        <OrgProfileDialog
          confirmationFooter={true}
          onClose={handleCloseOrgDialog}
          open={orgDialogOpen}
          organization={organization}
        />
      )}
    </>
  );
}
