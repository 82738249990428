import { Box, SxProps, Theme, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
} from "react";

export default function CustomTextField<T>({
  autoComplete,
  autoFocus,
  "data-testid": dataTestId,
  error,
  fullWidth = true,
  id,
  name,
  onBlur,
  onChange,
  onKeyDown,
  placeholder,
  sx,
  type,
  value,
}: {
  autoComplete?: string;
  autoFocus?: boolean;
  "data-testid"?: string;
  error?: string | undefined;
  fullWidth?: boolean;
  id?: string;
  name?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange?:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  placeholder?: string;
  sx?: SxProps<Theme>;
  type?: React.InputHTMLAttributes<unknown>["type"];
  value?: T;
}) {
  return (
    <Box>
      <TextField
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        data-testid={dataTestId}
        fullWidth={fullWidth}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        sx={[
          (theme) => ({
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "& INPUT": {
              border: 0,
            },

            "&:before": {
              border: 0,
            },

            "&:hover": {
              "&:before": { border: 0 },
              border: 3,
              borderColor: error
                ? theme.palette.error.main
                : theme.palette.bloom.main,
              borderRadius: "5px",
            },

            ".Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
            },

            backgroundColor: theme.palette.white.main,

            border: 3,
            borderColor: error
              ? theme.palette.error.main
              : theme.palette.black.light,
            borderRadius: "5px",
            cursor: "pointer",
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        type={type}
        value={value}
      />
      {error && (
        <Typography
          sx={(theme) => ({
            color: theme.palette.error.main,
          })}
          variant={"caption"}
        >
          {/* wrapping is needed for linter not to complain => https://github.com/jaredpalmer/formik/issues/3683 */}
          <>{error}</>
        </Typography>
      )}
    </Box>
  );
}
