/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import LandingPageContext from "../../context/LandingPageContext";
import { ILandingPageContext } from "../../context/organization";

import TextField from "../../../../ui/form/TextField";
import useFormState, { IFormState } from "../../../../hooks/useFormState";
import createFormValidation from "../../../../../utils/forms/createFormValidation";

import EmailDataType from "../../../../../data-model/types/profile/Email";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import Button from "../../../../ui/buttons/Button";
import LoadingPage from "../../../../layout/LoadingPage";
import { ISelectObject } from "../../../../ui/form/select/BaseSimpleSelect";
import SingleSelect from "../../../../ui/form/select/SingleSelect";

const PREFIX = "MatchingBlockBoardOfInnovation";

const classes = {
  center: `${PREFIX}-center`,
  content: `${PREFIX}-content`,
  infoText: `${PREFIX}-infoText`,
  root: `${PREFIX}-root`,
  startButton: `${PREFIX}-startButton`,
  startButtonContainer: `${PREFIX}-startButtonContainer`,
  startMatchingLogo: `${PREFIX}-startMatchingLogo`,
  title: `${PREFIX}-title`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: { position: "relative" },

  [`& .${classes.content}`]: {
    position: "relative",
    top: -130,
  },

  [`& .${classes.startMatchingLogo}`]: {
    marginBottom: 20,
  },

  [`& .${classes.center}`]: {
    textAlign: "center",
  },

  [`& .${classes.title}`]: {
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: "0.4px",
    marginBottom: 40,
  },

  [`& .${classes.infoText}`]: {
    color: "#4D4D4D",
    fontFamily: theme.typography.fontFamily,

    fontSize: 13,
    size: theme.typography.sizes.small,
  },

  [`& .${classes.startButton}`]: {
    minWidth: 120,
  },

  [`& .${classes.startButtonContainer}`]: {
    paddingTop: 50,
    width: "100%",
  },
}));

const MatchingBlockBoardOfInnovation = () => {
  const REGION = "region";

  const { handleSubmit, organization } =
    useContext<ILandingPageContext>(LandingPageContext);

  const { t: translate, ready } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Validation,
    BloomUpNamespacesEnum.Partners,
  ]);

  const emailRegex = new RegExp(
    `^(([^<>()[\\]\\\\.,;é:\\s@"]+(\\.[^<>()[\\]\\\\.,;é:\\s@"]+)*)|(".+"))@(${organization?.getEmailRegex()})$`,
  );

  const validation = useMemo(
    () =>
      createFormValidation([
        ...EmailDataType.getFormValidation(translate),
        {
          message:
            organization?.getTranslation(translate, "validation.wrongemail") ||
            "",
          path: EmailDataType.getPath(),
          validate: (email: string): boolean => {
            return emailRegex.test(email);
          },
        },
        {
          message:
            organization?.getTranslation(
              translate,
              "regions.validation.empty",
            ) || "Gelieve een regio te selecteren.",
          path: REGION,
          validate: (region: ISelectObject<string> | undefined): boolean => {
            return region?.value !== undefined;
          },
        },
      ]),
    [translate],
  );

  const formState: IFormState = useFormState(
    { ...EmailDataType.getInitialValue(), [REGION]: "emea" },
    { validation },
  );

  const onSubmit = () => {
    formState.validate();

    if (!formState.testForErrors()) {
      const values = {
        ...formState.values,
        [REGION]: formState.getValue(REGION).value,
      };

      handleSubmit(values);
    }
  };

  if (!organization || !ready) return <LoadingPage />;

  return (
    <Root>
      <Box mb={4} mt={4}>
        <SingleSelect<string>
          options={[
            {
              label: organization.getTranslation(
                translate,
                `regions.amer`,
                "Noord-Amerika",
              ),
              value: "na",
            },
            {
              label: organization.getTranslation(
                translate,
                `regions.emea`,
                "Europa, het Midden-Oosten en Afrika",
              ),
              value: "emea",
            },
            {
              label: organization.getTranslation(
                translate,
                `regions.apac`,
                "Azië-Pacific",
              ),
              value: "apac",
            },
          ]}
          placeholder={organization.getTranslation(
            translate,
            "regions.question",
            "In welke regio werk je?",
          )}
          {...formState.getInputProps(REGION)}
        />
      </Box>
      <TextField
        label={organization.getTranslation(
          translate,
          "blocks.matching.input.label",
        )}
        {...formState.getInputProps("email")}
      />
      <Box className={classes.infoText} marginTop={3}>
        {organization.getTranslation(translate, "blocks.matching.privacy")}
      </Box>
      <Box
        className={classes.startButtonContainer}
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          className={classes.startButton}
          disabled={formState.testForErrors()}
          id="back-button"
          label={organization.getTranslation(
            translate,
            "blocks.matching.button",
          )}
          onClick={onSubmit}
        />
      </Box>
    </Root>
  );
};

export default MatchingBlockBoardOfInnovation;
