import { Route, Routes } from "react-router-dom";
import HumanOnboardingStart from "../pages/human-matching/steps/HumanOnboardingStart";
import ConsentStep from "../pages/human-matching/steps/ConsentStep";
import AgeStep from "../pages/human-matching/steps/AgeStep";
import PreferredGenderStep from "../pages/human-matching/steps/PreferredGenderStep";
import AccountStep from "../pages/human-matching/steps/AccountStep";
import SearchMatchStep from "../pages/human-matching/steps/SearchMatchStep";
import SelectMatchStep from "../pages/human-matching/steps/SelectMatchStep";
import ConsultationBookedStep from "../pages/human-matching/steps/ConsultationBookedStep";
import WorriesStep from "../pages/human-matching/steps/WorriesStep";
import CrispChat from "../../utils/crisp-chat/CrispChat";
import HumanWaitingRoomPageWithState from "../pages/human-waiting-room/HumanWaitingRoomPageWithState";
import { RouteNames } from "./routeNames";

const HumanMatchingRoutes = () => (
  <>
    <CrispChat />
    <Routes>
      <Route
        element={<ConsentStep />}
        path={RouteNames.Matching.Consent.name}
      />
      <Route element={<AgeStep />} path={RouteNames.Matching.Age.name} />
      <Route
        element={<PreferredGenderStep />}
        path={RouteNames.Matching.Gender.name}
      />
      <Route
        element={<AccountStep />}
        path={RouteNames.Matching.Account.name}
      />
      <Route
        element={<SearchMatchStep />}
        path={RouteNames.Matching.Search.name}
      />
      <Route
        element={<SelectMatchStep />}
        path={RouteNames.Matching.Select.name}
      />
      <Route
        element={<SelectMatchStep />}
        path={RouteNames.Matching.Select.name}
      />
      <Route
        element={<ConsultationBookedStep />}
        path={RouteNames.Matching.Success.name}
      />
      <Route
        element={<WorriesStep />}
        path={RouteNames.Matching.Worries.name}
      />

      <Route
        element={
          <Routes>
            <Route
              element={<HumanWaitingRoomPageWithState />}
              path={RouteNames.Matching.WaitingRoom.Wait.name}
            />
          </Routes>
        }
        path={`${RouteNames.Matching.WaitingRoom.name}/*`}
      />
      <Route
        element={<HumanOnboardingStart />}
        path={RouteNames.Matching.Start.name}
      />
      {/* catch all for matching, put other routes before this one*/}
      <Route
        element={<HumanOnboardingStart />}
        path={RouteNames.Matching.name}
      />
    </Routes>
  </>
);

export default HumanMatchingRoutes;
