import React from "react";
import Box from "@mui/material/Box";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { useTranslation } from "react-i18next";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../layout/wizard/OnboardingStepContainer";
import { RouteNames } from "../../routes/routeNames";
import { I18Namespaces } from "../../language/I18Namespaces";
import LinkButton from "./LinkButton";

export default function WelcomePage() {
  const { t: translate } = useTranslation<I18Namespaces>("common");

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullCopyright
        headline={translate("common:welcome", "Welkom")}
        noFooter
        subhead={translate("common:slogan", "Blij je te zien!")}
        subheadicon={<FavoriteBorderOutlinedIcon />}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mb={6}
          mt={4}
        >
          <LinkButton
            icon={<LockOpenIcon style={{ fontSize: "1.5rem" }} />}
            label={translate("common:signin", "Aanmelden")}
            style={{
              marginBottom: 25,
              width: 500,
            }}
            to={RouteNames.Login.path}
          />
          <LinkButton
            icon={<PersonAddIcon style={{ fontSize: "1.5rem" }} />}
            label={translate(
              "common:register_as_client",
              "Registreer als Cliënt",
            )}
            style={{
              marginBottom: 25,
              width: 500,
            }}
            to={RouteNames.Matching.Start.path}
          />
          <LinkButton
            icon={<PersonAddIcon style={{ fontSize: "1.5rem" }} />}
            label={translate(
              "common:register_as_professional",
              "Registreer als Professional",
            )}
            style={{
              marginBottom: 25,
              width: 500,
            }}
            to={RouteNames.Register.Professional.Start.path}
          />
        </Box>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
