import { useContext, useMemo } from "react";
import { styled, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Alert from "@mui/lab/Alert";
import AlertTitle from "@mui/lab/AlertTitle";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import AgeRangeSlider from "../../../ui/form/AgeRangeSlider";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import HumanMatchingContext from "../context/HumanMatchingContext";
import LoadingPage from "../../../layout/LoadingPage";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import useTrackPageView from "../../../providers/matomo/useTrackPageView";
import { AGE_STEP } from "../context/humanMatchingSteps";
import useSetMatchingStep from "../hooks/useSetMatchingStep";
import Age from "../../../../data-model/types/profile/Age";
import { I18Namespaces } from "../../../language/I18Namespaces";
import { I18NexusLanguages } from "../../../language/languagesUtil";
import SanitizedText from "../../../ui/text/SanitizedText";

const PREFIX = "AgeStep";

const classes = {
  header: `${PREFIX}-header`,
  headerMobile: `${PREFIX}-headerMobile`,
  link: `${PREFIX}-link`,
  resultBox: `${PREFIX}-resultBox`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.header}`]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minHeight: 175,
  },

  [`&.${classes.headerMobile}`]: {
    marginTop: theme.spacing(2),
    padding: 0,
  },

  [`& .${classes.resultBox}`]: {
    background: "#FDECFD",
    maxWidth: "100%",
    textAlign: "left",
  },

  [`& .${classes.link}`]: {
    color: theme.palette.error.main,
  },
}));

export default function AgeStep() {
  const match = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const {
    formState,
    next,
    previous,
    step,
    totalSteps,
    updateMatchedHumanData,
  } = useContext(HumanMatchingContext);

  useTrackPageView("Human Onboarding > Age");
  useSetMatchingStep(AGE_STEP);

  const {
    t: translate,
    i18n,
    ready,
  } = useTranslation<I18Namespaces>(["human", "common"]);

  const createLinkWithSpaces = (link, text) => (
    <>
      {" "}
      <a
        className={classes.link}
        href={link}
        rel="noopener noreferrer"
        target="_blank"
      >
        {text}
      </a>{" "}
    </>
  );

  const goToNextStep = () => {
    updateMatchedHumanData({
      age: formState.getInputProps(Age.getPath()).value,
    });
    next();
  };

  const age: number = useMemo(() => {
    const { age: ageValue } = formState.values;
    let age = 16;

    if (!isNaN(ageValue) && ageValue !== "" && ageValue !== null) {
      age = parseInt(ageValue, 10);
    }

    return age;
  }, [formState.values]);

  if (!formState || !formState.values || !ready) return <LoadingPage full />;

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate("onboarding.age.description")}
        disable={age < 16}
        fullCopyright
        headline={translate("human:onboarding.title")}
        labelBack={translate("common:previous")}
        labelNext={translate("common:next")}
        onClickBack={previous}
        onClickNext={goToNextStep}
        showSpecialConditionsForTherapist={false}
        step={step.step}
        subhead={translate("human:onboarding.age.title")}
        totalSteps={totalSteps}
      >
        <Box mb={match ? 2 : 0} width="100%">
          <Typography color="primary" variant="h2">
            {age}
          </Typography>
          <Typography color="primary" variant="subtitle2">
            {translate("human:onboarding.age.slider")}
          </Typography>
          <AgeRangeSlider
            {...formState.getInputProps("age")}
            defaultValue={16}
            value={age}
          />
        </Box>
        {age < 16 && (
          <StyledContainer
            className={`${classes.header} ${match ? classes.headerMobile : ""}`}
            maxWidth="xs"
          >
            <Alert
              className={classes.resultBox}
              id="age-alert"
              severity="error"
            >
              <AlertTitle>{translate("human:onboarding.age.alert")}</AlertTitle>
              <Typography>
                <SanitizedText
                  input={translate("human:onboarding.age.referral")}
                />

                {i18n.language === I18NexusLanguages.ENGLISH &&
                  createLinkWithSpaces(
                    "https://www.chsbelgium.org/en/",
                    "CHS Belgium",
                  )}
              </Typography>
            </Alert>
          </StyledContainer>
        )}
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
