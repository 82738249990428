import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Quotes } from "../illustrations/quotes.svg";

const PREFIX = "ReviewBox";

const classes = {
  avatar: `${PREFIX}-avatar`,
  avatarImg: `${PREFIX}-avatarImg`,
  circle: `${PREFIX}-circle`,
  description: `${PREFIX}-description`,
  innerCircle: `${PREFIX}-innerCircle`,
  name: `${PREFIX}-name`,
  nameText: `${PREFIX}-nameText`,
  quotes: `${PREFIX}-quotes`,
  reviewBox: `${PREFIX}-reviewBox`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.reviewBox}`]: {
    background: theme.palette.bloom.light,
    borderRadius: 30,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 80,
    minheight: "220",
    paddingBottom: 30,
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 70,
    position: "relative",
    textalign: "center",
    zindex: -1,

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },

  [`& .${classes.avatar}`]: {
    left: "calc(50% - 50px)",
    position: "absolute",
    top: -40,
  },

  [`& .${classes.quotes}`]: {
    position: "absolute",
    right: 70,
    top: -10,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  [`& .${classes.circle}`]: {
    background: theme.palette.bloom.main,
    borderRadius: "50%",
    height: 100,
    position: "relative",
    width: 100,
  },

  [`& .${classes.innerCircle}`]: {
    background: "white",
    borderRadius: "50%",
    bottom: 5,
    left: 5,
    position: "absolute",
    right: 5,
    top: 5,
  },

  [`& .${classes.avatarImg}`]: {
    borderRadius: "50%",
    bottom: 10,
    height: 80,
    left: 10,
    position: "absolute",
    right: 10,
    top: 10,
    width: 80,
  },

  [`& .${classes.name}`]: {
    position: "relative",
  },

  [`& .${classes.nameText}`]: {
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: "0.4px",
    marginBottom: 20,
    textAlign: "center",
  },

  [`& .${classes.description}`]: {
    fontFamily: "Mulish",
    fontSize: 18,
    lineHeight: "27px",
    marginBottom: 20,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      wordWrap: "break-word",
    },
  },
}));

export default function ReviewBox({ name, description, imgSrc }) {
  return (
    <Root className={classes.reviewBox}>
      <Quotes className={classes.quotes}></Quotes>
      <div className={classes.avatar}>
        <div className={classes.circle}>
          <div className={classes.innerCircle} />
        </div>
        <img className={classes.avatarImg} src={imgSrc} />
      </div>
      <div className={classes.name}>
        <Typography className={classes.nameText}>{name}</Typography>
      </div>
      <div>
        <p className={classes.description}>{description}</p>
      </div>
    </Root>
  );
}
