import { Box, Typography } from "@mui/material";
import Button from "../../ui/buttons/Button";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { graphql } from "../../../api/__generated__";
import { useSearchParams } from "react-router-dom";

interface PrivacyStepProps {
  setNextStep: (nextStep: string) => void;
}

const startMatchingMutation = graphql(`
  mutation StartMatchingV2($input: StartMatchingV2Input!) {
    startMatchingV2(input: $input) {
      matchingToken
      nextStep
    }
  }
`);

const PrivacyAgeStep = ({ setNextStep }: PrivacyStepProps) => {
  const [startMatchingV2] = useMutation(startMatchingMutation);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramCode = searchParams.get("code");
  const localCode = localStorage.getItem("orgCode");
  const [age, setAge] = useState<number>(16);

  useEffect(() => {
    if (paramCode) {
      localStorage.setItem("orgCode", paramCode);
      searchParams.delete("code");
      setSearchParams(searchParams);
    }
  }, [paramCode, searchParams, setSearchParams]);

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> PRIVACY </Typography>
      <input
        onChange={(e) => setAge(parseInt(e.target.value))}
        type="number"
        value={age}
      />
      <Button
        label="next"
        onClick={async () => {
          const { data } = await startMatchingV2({
            variables: { input: { age, orgCode: localCode } },
          });

          data?.startMatchingV2.nextStep &&
            setNextStep(data.startMatchingV2.nextStep);

          data?.startMatchingV2.matchingToken &&
            localStorage.setItem(
              "matchingToken",
              data.startMatchingV2.matchingToken,
            );
        }}
        type="submit"
      />
    </Box>
  );
};

export default PrivacyAgeStep;
