import { onError } from "@apollo/client/link/error";
import { ApolloClient, GraphQLRequest } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { NormalizedCacheObject } from "@apollo/client/cache";
import { createUploadLink } from "apollo-upload-client";
import {
  loadAndValidateMatchingToken,
  loadAndValidateMatchingTokenV2,
  loadAndValidateTokens,
} from "../utils/auth";
import { containsUnauthenticatedError } from "../utils/graphql";
import environment from "../environment/environment";
import createApolloCache from "apollo-cache";

export default function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  const setAuthContext = async (request: GraphQLRequest, { headers }: any) => {
    const { accessToken } = await loadAndValidateTokens();
    const matchingToken = await loadAndValidateMatchingToken();
    const matchingTokenV2 = await loadAndValidateMatchingTokenV2();

    if (accessToken === null && matchingToken === null && !matchingTokenV2) {
      return { headers };
    }

    const tokenToSend = accessToken ?? matchingToken ?? matchingTokenV2;

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${tokenToSend}`,
      },
    };
  };

  const removeJwtTokenFromCacheLink = onError((error) => {
    if (containsUnauthenticatedError(error as any)) {
      // TODO we still need to handle an edge case where we have a valid token
      // when we request data, but the token has expired while making the request.
      // The server wil respond with 'Unauthorized'.
    }
  });

  const uploadLink = createUploadLink({
    headers: { "apollo-require-preflight": "true" },
    uri: `${environment.REACT_APP_SERVER_URL}${environment.REACT_APP_GRAPHQL}`,
  });

  const setAuthorizationHeadersLink = setContext(setAuthContext);

  const linkFlow = setAuthorizationHeadersLink.concat(
    removeJwtTokenFromCacheLink.concat(uploadLink as any),
  );

  const cache = createApolloCache();

  return new ApolloClient<NormalizedCacheObject>({
    cache,
    link: linkFlow,
    name: "web-app",
  });
}
