import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IFormState } from "../../../../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import GroupedChipList, {
  ChipSize,
  IChip,
} from "../../../../ui/form/GroupedChipList";
import { ISelectObject } from "../../../../ui/form/select/BaseSimpleSelect";
import Professional from "../../../../../data-model/types/professional/Professional";
import Worries from "../../../../../data-model/types/profile/Worries";

interface MatchedProfessionalSpecialtiesInput {
  formState: IFormState;
  professional: Professional;
}

export default function MatchedProfessionalSpecialties({
  professional,
  formState,
}: MatchedProfessionalSpecialtiesInput) {
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Human,
    BloomUpNamespacesEnum.Glossary,
  ]);

  const breakPoint = 5;

  const chips: IChip[] = useMemo(() => {
    const professionalSpecialities = professional.getSpecialties();

    let worriesSelectedByUser: ISelectObject<string>[] = formState.getValue(
      Worries.getPath(),
    );

    if (!worriesSelectedByUser) worriesSelectedByUser = [];

    const worriesThatAreInProfessionalSpecialties: ISelectObject<string>[] =
      professionalSpecialities
        .getSelectedOptions()
        .filter((s) => worriesSelectedByUser.find((w) => w.value === s.value));

    const matchedChipsToRender = worriesThatAreInProfessionalSpecialties;

    const notMatchedChipsToRender = professionalSpecialities
      .getSelectedOptions()
      .filter(
        (umc) => !matchedChipsToRender.find((mc) => mc.value === umc.value),
      );

    const matchedChipsToReturn: IChip[] = matchedChipsToRender.map(
      (selectedOption) => ({
        checkMark: true,
        color: selectedOption.color ?? "#123456",
        fadeOut: false,
        label: selectedOption.label,
        size: ChipSize.large,
        tooltip: selectedOption.toolTip ? t(selectedOption.toolTip) : "",
      }),
    );

    const notMatchedChipsToReturn: IChip[] = notMatchedChipsToRender.map(
      (selectedOption) => ({
        checkMark: false,
        color: "#808080",
        fadeOut: true,
        label: selectedOption.label,
        size: ChipSize.large,
        tooltip: selectedOption.toolTip ? t(selectedOption.toolTip) : "",
      }),
    );

    if (notMatchedChipsToReturn.length <= breakPoint) {
      return [...matchedChipsToReturn, ...notMatchedChipsToReturn];
    } else {
      const tooltip = notMatchedChipsToReturn
        .slice(breakPoint)
        .map((chip) => " " + t(chip.label));

      return [
        ...matchedChipsToReturn,
        ...notMatchedChipsToReturn.slice(0, breakPoint),
        ...[
          {
            checkMark: false,
            color: "#808080",
            fadeOut: true,
            label: t("human:onboarding.select.more", {
              amount: (notMatchedChipsToReturn.length - breakPoint).toString(),
            }),
            size: ChipSize.large,
            tooltip: tooltip.toString(),
          },
        ],
      ];
    }
  }, [professional, formState, t]);

  return (
    <>
      {/*<Box className={classes.body}>
                {translate(
                    "human:onboarding.select.specializes_in",
                    "Gespecialiseerd in:"
                )}
            </Box>*/}
      <GroupedChipList chips={chips} />
    </>
  );
}
