import { createContext } from "react";
import { IHumanMatchingContext } from "./humanMatching";
import { STEPS } from "./humanMatchingSteps";

export const defaultHumanMatchingValues: IHumanMatchingContext = {
  clearTimeSlot: () => {},
  createFullUser: () => {},
  fetchAndStoreMatchingToken: () => new Promise(() => {}),
  findMatches: () => new Promise(() => {}),
  finishMatchingForExistingUser: () => new Promise(() => {}),
  formIsValid: () => false,
  formState: {},
  fromPartnerPage: false,
  healthInsurance: null,
  isCreatingConsult: false,
  loadStoredWorriesInFormState: () => new Promise(() => {}),
  loading: false,
  matches: null,
  next: () => {},
  onMatchChosen: () => new Promise(() => {}),
  organization: undefined,
  previous: () => {},
  scheduleCall: () => {},
  selectedMatch: undefined,
  selectedTimeSlot: undefined,
  setCurrentStep: () => {},
  setFromPartnerPage: () => {},
  setOrganization: () => {},
  setSelectedTimeSlot: () => {},
  specialties: undefined,
  startCall: () => {},
  step: STEPS[0],
  totalSteps: 0,
  updateMatchedHumanData: () => new Promise(() => {}),
  updateMatchedHumanWorriesData: () => new Promise(() => {}),
};

const HumanMatchingContext = createContext<IHumanMatchingContext>(
  defaultHumanMatchingValues,
);

export default HumanMatchingContext;
