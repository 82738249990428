/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFetchOrganizationBySlug from "../../../../api/organizations/hooks/useFetchOrganizationBySlug";
import { getTokenPayload, hasTokenExpired } from "../../../../utils/auth";
import useStorage from "../../../hooks/useStorage";

import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import { IToastContext } from "../../../providers/toast/toast";
import ToastContext from "../../../providers/toast/ToastContext";
import useTrackLazyPageView from "../../../providers/matomo/useLazyTrackPageView";
import { IHumanMatchingContext } from "../../human-matching/context/humanMatching";
import HumanMatchingContext from "../../human-matching/context/HumanMatchingContext";
import { RouteNames } from "../../../routes/routeNames";
import LandingPageContext from "./LandingPageContext";

const LandingPageContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { trackPageView } = useTrackLazyPageView();

  const { setToast } = useContext<IToastContext>(ToastContext);
  const { fetchAndStoreMatchingToken, setOrganization } =
    useContext<IHumanMatchingContext>(HumanMatchingContext);

  const { getMatchingTokenFromStorage, removeAuthTokensFromStorage } =
    useStorage();

  const { fetchOrganizationBySlug, organization, loading, error } =
    useFetchOrganizationBySlug();

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Partners,
    BloomUpNamespacesEnum.Common,
  ]);

  const [slug, setSlug] = useState<string | undefined>();

  useEffect(() => {
    if (slug) fetchOrganizationBySlug(slug);
  }, [slug]);

  useEffect(() => {
    (async () => {
      const accessToken = await getMatchingTokenFromStorage();

      if (accessToken) {
        const tokenPayload = getTokenPayload(accessToken);

        // If this person is logged in as a professional, remove his / her token and
        // continue as a normal user.
        if (tokenPayload.scope === "PROFESSIONAL") {
          removeAuthTokensFromStorage();
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (slug && !loading && error) {
      setToast({
        // TODO: translate
        message: `Oeps, de partner '${slug} kennen we niet!`,
        severity: "error",
      });
      navigate(RouteNames.B2BLandingPages.Unknown.path);
      //TODO: route somewhere else? TODO: store url in constants;
      //window.location.replace("https://www.bloomup.org");
    } else if (slug && !loading && organization) {
      trackPageView("Organization landing page", [
        {
          orgId: organization.getID(),
          orgName: organization.getName(translate),
          orgSlug: slug,
        },
      ]);
      setOrganization(organization);
    }
  }, [organization, loading]);

  // returns true if a new matching token was created
  const checkOrCreateMatchingToken = async (values: any): Promise<boolean> => {
    const matchingToken = await getMatchingTokenFromStorage();
    let matchingTokenHasExpired = true;

    if (matchingToken) {
      matchingTokenHasExpired = hasTokenExpired(matchingToken);
    }

    if (!matchingToken || matchingTokenHasExpired) {
      await fetchAndStoreMatchingToken(slug, JSON.stringify(values));

      return true;
    }

    return false;
  };

  const createTokenAndPushToMatching = async (values: any) => {
    try {
      await checkOrCreateMatchingToken(values);
      navigate(RouteNames.Matching.Consent.path);
    } catch (error) {
      console.warn(error);
      // TODO:  translate
      setToast({
        message: `Er was een probleem met het starten van de matching.`,
        severity: "warning",
      });
    }
  };

  function handleSubmit(values: any): void {
    createTokenAndPushToMatching(values);
  }

  //if (loading) return <LoadingPage full />;
  return (
    <LandingPageContext.Provider
      value={{
        handleSubmit,
        isSubmitting: false,
        organization,
        setSlug,
        slug,
      }}
    >
      {children}
    </LandingPageContext.Provider>
  );
};

export default LandingPageContextProvider;
