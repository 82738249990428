/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: Date | string; output: string };
  Duration: { input: string; output: string };
  JSON: { input: any; output: any };
  SemVer: { input: string; output: string };
  Upload: { input: unknown; output: unknown };
};

export type AcceptInviteInput = {
  email: Scalars["String"]["input"];
  id: Scalars["Int"]["input"];
  invite: Scalars["String"]["input"];
};

export type AddClientInput = {
  email: Scalars["String"]["input"];
};

export type Address = WithDates &
  WithId & {
    __typename?: "Address";
    country?: Maybe<Scalars["String"]["output"]>;
    createdAt: Scalars["DateTime"]["output"];
    id: Scalars["Int"]["output"];
    location?: Maybe<Scalars["String"]["output"]>;
    postcode?: Maybe<Scalars["String"]["output"]>;
    street?: Maybe<Scalars["String"]["output"]>;
    updatedAt: Scalars["DateTime"]["output"];
  };

export type AddressInput = {
  country?: InputMaybe<Scalars["String"]["input"]>;
  postcode: Scalars["String"]["input"];
  street: Scalars["String"]["input"];
};

export type AnswerInput = {
  answer: Scalars["JSON"]["input"];
  consultationId?: InputMaybe<Scalars["Int"]["input"]>;
  questionId: Scalars["Int"]["input"];
};

export type Avatar = {
  __typename?: "Avatar";
  url?: Maybe<Scalars["String"]["output"]>;
};

/** This is the type for barcharts. */
export type BarchartItem = {
  __typename?: "BarchartItem";
  /** This is the key for the key-value pair. */
  name: Scalars["String"]["output"];
  /** This is the value for the key-value pair. */
  value: Scalars["Int"]["output"];
};

export type BillingInformation = WithDates &
  WithId & {
    __typename?: "BillingInformation";
    accountNumber: Scalars["String"]["output"];
    address: Address;
    createdAt: Scalars["DateTime"]["output"];
    email?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["Int"]["output"];
    taxId?: Maybe<Scalars["String"]["output"]>;
    updatedAt: Scalars["DateTime"]["output"];
  };

export type BillingInformationInput = {
  accountNumber: Scalars["String"]["input"];
  address: AddressInput;
  notificationEmail?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CalendarEvent = {
  __typename?: "CalendarEvent";
  allDay: Scalars["Boolean"]["output"];
  id: Scalars["Int"]["output"];
  scheduledFrom: Scalars["DateTime"]["output"];
  scheduledTo: Scalars["DateTime"]["output"];
  type: CalendarEventTypes;
};

export type CalendarEventInput = {
  allDay: Scalars["Boolean"]["input"];
  returnAsFullCalendar?: InputMaybe<Scalars["Boolean"]["input"]>;
  scheduledFrom: Scalars["DateTime"]["input"];
  scheduledTo: Scalars["DateTime"]["input"];
  type: Scalars["String"]["input"];
};

export type CalendarEventTypes =
  | "availability"
  | "consultation_appointment"
  | "consultation_intro"
  | "online"
  | "unknown_type";

export type CalendarFormats = CalendarEvent | FullCalendarEvent;

export type CancelConsultationInput = {
  /** The id of the consultation to be cancelled */
  id: Scalars["Int"]["input"];
  /** A message for the other party in the consultation */
  message: Scalars["String"]["input"];
};

export type CashTransaction = {
  __typename?: "CashTransaction";
  amount: Scalars["Float"]["output"];
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  mollieTransaction?: Maybe<Scalars["String"]["output"]>;
  status: CashTransactionStatusType;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

/** These are the statuses from Mollie (https://docs.mollie.com/payments/status-changes) with the addition of 'new' which we use for fresh transactions. */
export type CashTransactionStatusType =
  | "authorized"
  | "canceled"
  | "expired"
  | "failed"
  | "new"
  | "open"
  | "paid"
  | "pending"
  | "refunded";

export type ChimeAttendeeInfo = {
  __typename?: "ChimeAttendeeInfo";
  AttendeeId: Scalars["String"]["output"];
  ExternalUserId: Scalars["String"]["output"];
  JoinToken: Scalars["String"]["output"];
};

export type ChimeMediaPlacement = {
  __typename?: "ChimeMediaPlacement";
  AudioFallbackUrl: Scalars["String"]["output"];
  AudioHostUrl: Scalars["String"]["output"];
  ScreenDataUrl: Scalars["String"]["output"];
  ScreenSharingUrl: Scalars["String"]["output"];
  ScreenViewingUrl: Scalars["String"]["output"];
  SignalingUrl: Scalars["String"]["output"];
};

export type ChimeMeetingInfo = {
  __typename?: "ChimeMeetingInfo";
  ExternalMeetingId?: Maybe<Scalars["String"]["output"]>;
  MediaPlacement: ChimeMediaPlacement;
  MediaRegion: Scalars["String"]["output"];
  MeetingId: Scalars["String"]["output"];
};

export type ChoosePasswordV2Input = {
  /** A user's e-mail address */
  email: Scalars["String"]["input"];
  /** The value the password should be set to. */
  newPassword: Scalars["String"]["input"];
  /** A token that was generated by the resetPasswordV2 mutation and email */
  resetToken: Scalars["String"]["input"];
};

export type Client = {
  __typename?: "Client";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  human?: Maybe<Human>;
  humanId?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  inviteToken?: Maybe<Scalars["String"]["output"]>;
  professional?: Maybe<Professional>;
  professionalId?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<ClientStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type ClientEdge = {
  __typename?: "ClientEdge";
  cursor?: Maybe<Scalars["String"]["output"]>;
  node?: Maybe<ClientFormats>;
};

export type ClientFormats = Client | RestrictedClient;

export type ClientStatus =
  | "CURRENT"
  | "DECLINED"
  | "INVITED"
  | "ONLY_INTRO"
  | "PAST"
  | "PENDING";

export type ClientsConnection = {
  __typename?: "ClientsConnection";
  edges?: Maybe<Array<Maybe<ClientEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type ConfirmConsultationInput = {
  email: Scalars["String"]["input"];
  uuid: Scalars["String"]["input"];
};

export type Consultation = {
  __typename?: "Consultation";
  acceptedByHuman?: Maybe<Scalars["Boolean"]["output"]>;
  acceptedByProfessional?: Maybe<Scalars["Boolean"]["output"]>;
  bloomupFee?: Maybe<Scalars["Float"]["output"]>;
  cashTransaction?: Maybe<CashTransaction>;
  createdAt: Scalars["Date"]["output"];
  creditTransactions: Array<CreditTransaction>;
  duration: Scalars["Duration"]["output"];
  expectedReimbursementDate?: Maybe<Scalars["Date"]["output"]>;
  fromMatching: Scalars["Boolean"]["output"];
  human?: Maybe<Human>;
  humanId?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  message?: Maybe<Message>;
  professional?: Maybe<Professional>;
  professionalFee?: Maybe<Scalars["Float"]["output"]>;
  professionalId?: Maybe<Scalars["Int"]["output"]>;
  requestedBy?: Maybe<RequestedByType>;
  scheduledFrom: Scalars["DateTime"]["output"];
  scheduledTo: Scalars["DateTime"]["output"];
  status: ConsultationStatus;
  type?: Maybe<ConsultationType>;
  updatedAt: Scalars["Date"]["output"];
  userRequested: Scalars["Boolean"]["output"];
  uuid: Scalars["String"]["output"];
};

export type ConsultationEdge = {
  __typename?: "ConsultationEdge";
  cursor?: Maybe<Scalars["String"]["output"]>;
  node: Consultation;
};

export type ConsultationFilter = {
  status?: InputMaybe<Array<ConsultationStatus>>;
};

export type ConsultationStatus =
  | "ACCEPTED"
  | "CANCELLED"
  | "CANCELLED_BY_HUMAN"
  | "CANCELLED_BY_PROFESSIONAL"
  | "DONE"
  | "IN_PROGRESS"
  | "NO_SHOW_HUMAN"
  | "NO_SHOW_PROFESSIONAL"
  | "REQUESTED"
  | "WAITING";

export type ConsultationType = "APPOINTMENT" | "CHECKIN" | "INTRO";

export type ConsultationsConnection = {
  __typename?: "ConsultationsConnection";
  edges: Array<ConsultationEdge>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type ContinueTrackInput = {
  id: Scalars["String"]["input"];
  /**
   * When provided will link the current user to the provided professional for the track.
   * Should be part of the Input for the frist checkin node, but for now is here and optional.
   */
  professionalId?: InputMaybe<Scalars["Int"]["input"]>;
  type: Scalars["String"]["input"];
  values?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type CopyCalendarEventsInput = {
  copyTo: Scalars["DateTime"]["input"];
  from: Scalars["DateTime"]["input"];
  onlyType?: InputMaybe<Scalars["String"]["input"]>;
  returnAsFullCalendar?: InputMaybe<Scalars["Boolean"]["input"]>;
  to: Scalars["DateTime"]["input"];
};

export type Country = {
  __typename?: "Country";
  id: Scalars["String"]["output"];
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  regions?: Maybe<Array<Region>>;
  translationKey: Scalars["String"]["output"];
};

export type CreateConsultationInput = {
  fromMatching: Scalars["Boolean"]["input"];
  matchedHumanId?: InputMaybe<Scalars["Int"]["input"]>;
  otherPartyId: Scalars["Int"]["input"];
  price?: InputMaybe<Scalars["Float"]["input"]>;
  scheduledFrom: Scalars["DateTime"]["input"];
  scheduledTo: Scalars["DateTime"]["input"];
  status?: InputMaybe<Scalars["String"]["input"]>;
  type: ConsultationType;
};

export type CreditTransaction = {
  __typename?: "CreditTransaction";
  amount: Scalars["Float"]["output"];
  id: Scalars["Int"]["output"];
};

export type DeleteCalendarEventsInput = {
  ids: Array<InputMaybe<Scalars["Int"]["input"]>>;
};

/** This is the type for well being gauge charts. */
export type DifferenceGaugeResult = {
  __typename?: "DifferenceGaugeResult";
  /** This is the average first score. */
  averageFirstScore: Scalars["Float"]["output"];
  /** This is the average improvement. */
  averageImprovement: Scalars["Float"]["output"];
  /** This is the average latest score. */
  averageLatestScore: Scalars["Float"]["output"];
};

export type DistributeListNodeContent = {
  __typename?: "DistributeListNodeContent";
  amountToDistribute: Scalars["Int"]["output"];
  body: MultiLanguageString;
  buttonType: NodeButtonType;
  headerImage?: Maybe<MultiLanguageString>;
  id: Scalars["String"]["output"];
  options: Array<NodeOption>;
  progress: Scalars["Float"]["output"];
};

export type Enable2FaInput = {
  secret: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type EndOfTrackNodeContent = {
  __typename?: "EndOfTrackNodeContent";
  id: Scalars["String"]["output"];
};

export type EntryPoints =
  | "after_consultation"
  | "after_intro"
  | "after_last_planned_consultation"
  | "before_consultation"
  | "during_self_help"
  | "end_of_treatment"
  | "in_app_notification";

export type EntryPointsInput = {
  entrypoint: EntryPoints;
};

export type ExtendedProps = {
  __typename?: "ExtendedProps";
  status?: Maybe<Scalars["String"]["output"]>;
  type: CalendarEventTypes;
};

export type FullCalendarEvent = {
  __typename?: "FullCalendarEvent";
  allDay: Scalars["Boolean"]["output"];
  end: Scalars["DateTime"]["output"];
  extendedProps: ExtendedProps;
  id: Scalars["String"]["output"];
  start: Scalars["DateTime"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

/** This is the type for funnelcharts. */
export type FunnelItem = {
  __typename?: "FunnelItem";
  /** This is the key for the key-value pair. */
  name: Scalars["String"]["output"];
  /** This is the value for the key-value pair. */
  value: Scalars["Int"]["output"];
};

export type GrowthStep = {
  __typename?: "GrowthStep";
  id: Scalars["String"]["output"];
  title: MultiLanguageString;
};

/** The persona of an individual human/client on the platform. Reference attached user for persona agnostic fields */
export type Human = WithId & {
  __typename?: "Human";
  address?: Maybe<Address>;
  /** @deprecated Prefer to access on user object */
  age?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Prefer to access on user object */
  birthDate?: Maybe<Scalars["DateTime"]["output"]>;
  /**
   * If this is true human can pay for their coaching with credits this is true. e.g. still credits left
   * When false it means the user has to pay for coaching themselves.
   */
  canPayCoachingWithCredits: Scalars["Boolean"]["output"];
  /**
   * If this is true human can pay for their consult with credits this is true. e.g. still credits left
   * When false it means the user has to pay for scheduled consult themselves.
   */
  canPayConsultWithCredits: Scalars["Boolean"]["output"];
  /**
   * If this is true human can pay for their consultations with credits this is true. e.g. still credits left
   * When false it means the user has to pay for scheduled consultations themselves.
   */
  canPayWithCredits: Scalars["Boolean"]["output"];
  contactForFeedback: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  /**
   * If the user is attached to a organization that specifies a maximum credits per human value, this returns the remaining amount for this user in the contract period.
   * Returns null when this value doesn't exist e.g. no org, no current contract, current contract doesn't limit individual usage
   */
  creditsLeft?: Maybe<Scalars["Float"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  /** @deprecated Prefer to access on user object */
  email: Scalars["String"]["output"];
  /** @deprecated Prefer to access on user object */
  firstName?: Maybe<Scalars["String"]["output"]>;
  healthInsurance?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  /** @deprecated Prefer to access on user object */
  lastName?: Maybe<Scalars["String"]["output"]>;
  onboardedAt?: Maybe<Scalars["DateTime"]["output"]>;
  /**
   * The organization this human is a member of.
   * This will return null if the user is not linked to an organization with a current contract.
   */
  organization?: Maybe<Organization>;
  /** @deprecated Prefer to access on the organization object */
  organizationId?: Maybe<Scalars["Int"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Prefer to access on user object */
  phone?: Maybe<Scalars["String"]["output"]>;
  /**
   * The preferred language for this user. He / she will see all text presented in this language.
   * This is also the language that will be the default language used in consultations.
   * @deprecated Prefer to access on user object
   */
  preferredLanguage: Language;
  preferredProfessionalGender?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Prefer to access on user object */
  timezone: Scalars["String"]["output"];
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  /** The user account for this human */
  user: User;
  /** @deprecated Prefer to access on user object */
  uuid: Scalars["String"]["output"];
};

export type HumanInput = {
  address?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  deletedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  healthInsurance?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  onboardedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]["input"]>;
  /** The gender of a professional that a Human prefers to work with. */
  preferredProfessionalGender?: InputMaybe<Scalars["String"]["input"]>;
  /** Professionals that are linked to this Human. */
  professionals?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type HumanWithTokens = {
  __typename?: "HumanWithTokens";
  accessToken: Scalars["String"]["output"];
  human?: Maybe<Human>;
  refreshToken: Scalars["String"]["output"];
};

export type Language = "en" | "frBe" | "nl" | "nlBe";

/** This is the type for line graphs. */
export type LineGraphItem = {
  __typename?: "LineGraphItem";
  /** This is the x value for the line chart. */
  x: Scalars["String"]["output"];
  /** This is the y value for the line chart. */
  y: Scalars["Int"]["output"];
};

export type LoginFailureReason =
  | "INCORRECT_2FA"
  | "INCORRECT_CREDENTIALS"
  | "NEEDS_2FA";

export type LoginInput = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type LoginResponse = {
  __typename?: "LoginResponse";
  /**
   * the accessToken is a JWT that contains the role_type and user_id (UUID) of the user, if login succeeds.
   * The user_id can be used to fetch the associated Professional / Patient.
   */
  accessToken: Scalars["String"]["output"];
  /**
   * the refreshToken is a JWT that can be used to refresh the accessToken if it expires
   * without having to login again.
   */
  refreshToken: Scalars["String"]["output"];
};

export type LoginV2FailureResponse = {
  __typename?: "LoginV2FailureResponse";
  /** The reason here is a value from an enum that indicates the reason why why the login failed. */
  reason: LoginFailureReason;
};

export type LoginV2Input = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  token2FA?: InputMaybe<Scalars["String"]["input"]>;
};

export type LoginV2Response = LoginV2FailureResponse | TokenResponse;

export type LoginWithSsoInput = {
  authorizationCode: Scalars["String"]["input"];
  mindlabBusinessId?: InputMaybe<Scalars["String"]["input"]>;
  redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Match = {
  __typename?: "Match";
  availability: Array<Maybe<Scalars["String"]["output"]>>;
  isOnline: Scalars["Boolean"]["output"];
  professional: Professional;
  score: Scalars["Int"]["output"];
};

export type MatchedHuman = {
  __typename?: "MatchedHuman";
  age?: Maybe<Scalars["Int"]["output"]>;
  chosenProfessionalId?: Maybe<Scalars["Int"]["output"]>;
  createdAt: Scalars["Date"]["output"];
  id: Scalars["Int"]["output"];
  organization?: Maybe<Organization>;
  preferredProfessionalGender?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Date"]["output"];
  userUuid?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated We dont use this anymore, since april 2021 */
  worries?: Maybe<Scalars["String"]["output"]>;
};

export type MatchedHumanInput = {
  age?: InputMaybe<Scalars["Int"]["input"]>;
  chosenProfessionalId?: InputMaybe<Scalars["Int"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  preferredProfessionalGender?: InputMaybe<Scalars["String"]["input"]>;
  userUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type MatchedHumanWorriesInput = {
  /** Specialties should be an array of uuids of SpecialtiesThemeCombo's */
  specialtiesThemeComboIds: Array<Scalars["String"]["input"]>;
};

export type Matches = {
  __typename?: "Matches";
  matches?: Maybe<Array<Match>>;
};

export type Message = {
  __typename?: "Message";
  body?: Maybe<Scalars["String"]["output"]>;
  consultationId?: Maybe<Scalars["Int"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  fromProfessional?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

export type MessageInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  fromProfessional?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MobileStatus = "FORCE_UPDATE" | "SUPPORTED" | "WARN_UPDATE";

export type MultiLanguageBoolean = {
  __typename?: "MultiLanguageBoolean";
  en: Scalars["Boolean"]["output"];
  fr: Scalars["Boolean"]["output"];
  nl: Scalars["Boolean"]["output"];
};

export type MultiLanguageString = {
  __typename?: "MultiLanguageString";
  en?: Maybe<Scalars["String"]["output"]>;
  fr?: Maybe<Scalars["String"]["output"]>;
  nl?: Maybe<Scalars["String"]["output"]>;
};

/** MUTATIONS */
export type Mutation = {
  __typename?: "Mutation";
  acceptInvite?: Maybe<Client>;
  answerQuestion?: Maybe<Scalars["Boolean"]["output"]>;
  /** Initialises the track for the currently signed in user. */
  beginTrack: Track;
  /** Cancels the specified consultation and notififies relevant parties */
  cancelConsultation: Consultation;
  /** Sets a new password for an existing user based off email and token, returns true when password was set successfully */
  choosePasswordV2: Scalars["Boolean"]["output"];
  /**
   * Registers more info for a new Human and returns the new entity. For this to happen,
   * The user should have logged in (so a token is present)
   */
  completeHumanRegistration?: Maybe<Human>;
  confirmConsultation?: Maybe<Consultation>;
  copyCalendarEvents?: Maybe<Array<Maybe<CalendarFormats>>>;
  createCalendarEvent?: Maybe<CalendarFormats>;
  createConsultation?: Maybe<Consultation>;
  createPermanentHuman?: Maybe<LoginResponse>;
  deleteCalendarEvents?: Maybe<Array<Maybe<Scalars["Int"]["output"]>>>;
  /** Mutation to enable 2FA. Input should be a secret and a token. The response will be a boolean indicating whether te enabling succeeded. */
  enable2FA: Scalars["Boolean"]["output"];
  inviteClient?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * Adds the currently logged in user to the organization specified by the input email address, or code.
   * Uses the same matching rules as the `organizationByEmailOrCode` query
   */
  joinOrganizationByEmailOrCode?: Maybe<User>;
  joinOrganizationByEmailOrCodeV2?: Maybe<Scalars["Boolean"]["output"]>;
  /** Query to handle a login. Input should be a user's email and password, the response will be a JSON Web Token */
  login: LoginResponse;
  /** Mutation to handle a login. Input should be a user's email, password and 2fa token, the response will either be a jwt token or a failure code */
  loginV2: LoginV2Response;
  /** Mutation to log a user in using an SSO authorization code */
  loginWithSSO: LoginV2Response;
  notifyOtherParty?: Maybe<Scalars["Boolean"]["output"]>;
  payForConsultation?: Maybe<Scalars["String"]["output"]>;
  /** Query to refresh a user's accessToken. Input should be the user's refreshToken. Response will be a new accessToken */
  refreshAccessToken: Scalars["String"]["output"];
  /** Query to refresh a user's tokens. Input should be the user's refreshToken. Response will be a new accessToken, new refreshToken and new mindLabToken */
  refreshAccessTokenV2: TokenResponse;
  /** Creates a new Human and returns access tokens for the associated user. */
  registerHuman: LoginResponse;
  /** Creates a new Human and returns access tokens for the associated user. */
  registerHumanV2: TokenResponse;
  /**
   * Registers more info for a new Human and returns the new entity. For this to happen,
   * the user needs to be invited by a professional, and should have an invite token present in the
   * url.
   */
  registerHumanWithInvite?: Maybe<HumanWithTokens>;
  /** Registers a Professional */
  registerProfessional?: Maybe<Scalars["Boolean"]["output"]>;
  /** Mutation to reauest the secrets to set up 2FA. Input should be a secret and a qrcode. The response are the secrets needed to setup 2fa. */
  request2FASecrets: Secrets2Fa;
  /** Creates reimbursement information */
  requestReimbursement?: Maybe<Reimbursement>;
  rescheduleConsultation: Consultation;
  /** Mutation to reset a user's password. Input should be a user's email. The response is a boolean indicating whether the reset process has been started. */
  resetPassword: Scalars["Boolean"]["output"];
  /** Sends an email with links to the app stores, and returns true on success. */
  sendAppInvite: Scalars["Boolean"]["output"];
  /** Updates the language for the currently logged in user. Will only work when authenticated. */
  setCurrentUserLanguage: User;
  startMatching: StartMatchingResponse;
  startMatchingV2: SubmitMatchingStepResponse;
  startVideoCall?: Maybe<VideoCall>;
  submitLocationStep: SubmitMatchingStepResponse;
  submitMutualityStep: SubmitMatchingStepResponse;
  /** Submits the current node of the track, and returns the NodeContent for the next node. */
  submitNodeV2: TypedNodeContent;
  updateCalendarEvent?: Maybe<CalendarFormats>;
  updateConsultation?: Maybe<Consultation>;
  /** Updates the current user */
  updateCurrentUser: User;
  /** Updates a Human and returns the updated entity. */
  updateHuman?: Maybe<Human>;
  updateMatchedHuman?: Maybe<MatchedHuman>;
  updateMatchedHumanWorries?: Maybe<MatchedHuman>;
  /** Updates a Professional and returns the updated entity */
  updateProfessional?: Maybe<Professional>;
  /** Updates the specialties of a Professional and returns the adjusted professional. */
  updateProfessionalSpecialties?: Maybe<Professional>;
  userJoinedConsultation: Consultation;
  /** Sets a Professionals status to verified and returns the updated entity */
  verifyProfessional?: Maybe<Scalars["Boolean"]["output"]>;
};

/** MUTATIONS */
export type MutationAcceptInviteArgs = {
  input: AcceptInviteInput;
};

/** MUTATIONS */
export type MutationAnswerQuestionArgs = {
  input: AnswerInput;
};

/** MUTATIONS */
export type MutationBeginTrackArgs = {
  trackId: Scalars["String"]["input"];
};

/** MUTATIONS */
export type MutationCancelConsultationArgs = {
  input: CancelConsultationInput;
};

/** MUTATIONS */
export type MutationChoosePasswordV2Args = {
  input: ChoosePasswordV2Input;
};

/** MUTATIONS */
export type MutationCompleteHumanRegistrationArgs = {
  input: HumanInput;
};

/** MUTATIONS */
export type MutationConfirmConsultationArgs = {
  input: ConfirmConsultationInput;
};

/** MUTATIONS */
export type MutationCopyCalendarEventsArgs = {
  input: CopyCalendarEventsInput;
};

/** MUTATIONS */
export type MutationCreateCalendarEventArgs = {
  input: CalendarEventInput;
};

/** MUTATIONS */
export type MutationCreateConsultationArgs = {
  input: CreateConsultationInput;
};

/** MUTATIONS */
export type MutationCreatePermanentHumanArgs = {
  input: PermanentHumanInput;
};

/** MUTATIONS */
export type MutationDeleteCalendarEventsArgs = {
  input: DeleteCalendarEventsInput;
};

/** MUTATIONS */
export type MutationEnable2FaArgs = {
  input: Enable2FaInput;
};

/** MUTATIONS */
export type MutationInviteClientArgs = {
  input: AddClientInput;
};

/** MUTATIONS */
export type MutationJoinOrganizationByEmailOrCodeArgs = {
  emailOrCode: Scalars["String"]["input"];
};

/** MUTATIONS */
export type MutationJoinOrganizationByEmailOrCodeV2Args = {
  emailOrCode: Scalars["String"]["input"];
};

/** MUTATIONS */
export type MutationLoginArgs = {
  credentials: LoginInput;
};

/** MUTATIONS */
export type MutationLoginV2Args = {
  input: LoginV2Input;
};

/** MUTATIONS */
export type MutationLoginWithSsoArgs = {
  input: LoginWithSsoInput;
};

/** MUTATIONS */
export type MutationNotifyOtherPartyArgs = {
  consultationId: Scalars["String"]["input"];
  isProfessional: Scalars["Boolean"]["input"];
};

/** MUTATIONS */
export type MutationPayForConsultationArgs = {
  input: PaymentInput;
};

/** MUTATIONS */
export type MutationRefreshAccessTokenArgs = {
  refreshToken: Scalars["String"]["input"];
};

/** MUTATIONS */
export type MutationRefreshAccessTokenV2Args = {
  refreshToken: Scalars["String"]["input"];
};

/** MUTATIONS */
export type MutationRegisterHumanArgs = {
  input: RegisterHumanInput;
};

/** MUTATIONS */
export type MutationRegisterHumanV2Args = {
  input: RegisterHumanInput;
};

/** MUTATIONS */
export type MutationRegisterHumanWithInviteArgs = {
  input: HumanInput;
  inviteToken: Scalars["String"]["input"];
};

/** MUTATIONS */
export type MutationRegisterProfessionalArgs = {
  input: ProfessionalRegistrationInput;
};

/** MUTATIONS */
export type MutationRequest2FaSecretsArgs = {
  password: Scalars["String"]["input"];
};

/** MUTATIONS */
export type MutationRequestReimbursementArgs = {
  input: ReimbursementInput;
};

/** MUTATIONS */
export type MutationRescheduleConsultationArgs = {
  input: RescheduleConsultationInput;
};

/** MUTATIONS */
export type MutationResetPasswordArgs = {
  email: Scalars["String"]["input"];
  fromMindlab?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** MUTATIONS */
export type MutationSendAppInviteArgs = {
  humanId: Scalars["Int"]["input"];
};

/** MUTATIONS */
export type MutationSetCurrentUserLanguageArgs = {
  language: Language;
};

/** MUTATIONS */
export type MutationStartMatchingArgs = {
  input: StartMatchingInput;
};

/** MUTATIONS */
export type MutationStartMatchingV2Args = {
  input: StartMatchingV2Input;
};

/** MUTATIONS */
export type MutationStartVideoCallArgs = {
  consultationUuid: Scalars["String"]["input"];
};

/** MUTATIONS */
export type MutationSubmitLocationStepArgs = {
  input: SubmitLocationStepInput;
};

/** MUTATIONS */
export type MutationSubmitMutualityStepArgs = {
  mutualityId: Scalars["String"]["input"];
};

/** MUTATIONS */
export type MutationSubmitNodeV2Args = {
  input: ContinueTrackInput;
  trackId: Scalars["String"]["input"];
};

/** MUTATIONS */
export type MutationUpdateCalendarEventArgs = {
  input: UpdateCalendarEventInput;
};

/** MUTATIONS */
export type MutationUpdateConsultationArgs = {
  input: UpdateConsultationInput;
};

/** MUTATIONS */
export type MutationUpdateCurrentUserArgs = {
  input: UpdateCurrentUserInput;
};

/** MUTATIONS */
export type MutationUpdateHumanArgs = {
  input: HumanInput;
};

/** MUTATIONS */
export type MutationUpdateMatchedHumanArgs = {
  input: MatchedHumanInput;
};

/** MUTATIONS */
export type MutationUpdateMatchedHumanWorriesArgs = {
  input: MatchedHumanWorriesInput;
};

/** MUTATIONS */
export type MutationUpdateProfessionalArgs = {
  input: ProfessionalInput;
};

/** MUTATIONS */
export type MutationUpdateProfessionalSpecialtiesArgs = {
  input: ProfessionalSpecialtiesInput;
};

/** MUTATIONS */
export type MutationUserJoinedConsultationArgs = {
  consultationId: Scalars["Int"]["input"];
};

/** MUTATIONS */
export type MutationVerifyProfessionalArgs = {
  id: Scalars["Int"]["input"];
};

export type Mutuality = {
  __typename?: "Mutuality";
  id: Scalars["String"]["output"];
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  preferred: Scalars["Boolean"]["output"];
  translationKey: Scalars["String"]["output"];
};

export type NodeApplicableQuestion = {
  __typename?: "NodeApplicableQuestion";
  question: MultiLanguageString;
  slider: NodeSlider;
};

export type NodeButtonType = "continue" | "start";

export type NodeCategoryQuestion = {
  __typename?: "NodeCategoryQuestion";
  options: Array<NodeOption>;
  question: MultiLanguageString;
};

/**
 * The generic version of the content for a node.
 * New nodes should have their own types, removing the need for the `content` field here.
 */
export type NodeContent = {
  __typename?: "NodeContent";
  content: Scalars["JSON"]["output"];
  id: Scalars["String"]["output"];
  professional?: Maybe<ProfessionalForTrack>;
  type: Scalars["String"]["output"];
};

export type NodeOption = {
  __typename?: "NodeOption";
  id: Scalars["String"]["output"];
  text: MultiLanguageString;
};

export type NodeSituation = {
  __typename?: "NodeSituation";
  id: Scalars["String"]["output"];
  image: Scalars["String"]["output"];
};

export type NodeSlider = {
  __typename?: "NodeSlider";
  endLabel: MultiLanguageString;
  startLabel: MultiLanguageString;
  steps: Scalars["Int"]["output"];
};

export type Notification = {
  body: Scalars["String"]["input"];
  deep_link?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  renotify?: InputMaybe<Scalars["Boolean"]["input"]>;
  title: Scalars["String"]["input"];
};

export type OptionListNodeContent = {
  __typename?: "OptionListNodeContent";
  body: MultiLanguageString;
  buttonType: NodeButtonType;
  headerImage?: Maybe<MultiLanguageString>;
  id: Scalars["String"]["output"];
  maxSelected?: Maybe<Scalars["Int"]["output"]>;
  minSelected: Scalars["Int"]["output"];
  options: Array<NodeOption>;
  progress: Scalars["Float"]["output"];
};

export type Organization = {
  __typename?: "Organization";
  code?: Maybe<Scalars["String"]["output"]>;
  color?: Maybe<Scalars["String"]["output"]>;
  contractDashboardUrl?: Maybe<MultiLanguageString>;
  creditsPerHuman?: Maybe<Scalars["Int"]["output"]>;
  emailRegex?: Maybe<Scalars["String"]["output"]>;
  ggtDashboardUrl?: Maybe<MultiLanguageString>;
  ggtEnabled: Scalars["Boolean"]["output"];
  id: Scalars["Int"]["output"];
  interestDashboardUrl?: Maybe<MultiLanguageString>;
  logoUrl: MultiLanguageString;
  mentalWellbeingDashboardUrl?: Maybe<MultiLanguageString>;
  mindlabBusinessId?: Maybe<Scalars["String"]["output"]>;
  /** Whether this organization currently has access to mindlab */
  mindlabEnabled: Scalars["Boolean"]["output"];
  name: MultiLanguageString;
  /** Markdown formatted information about this organization to show professionals */
  profile: MultiLanguageString;
  slug: Scalars["String"]["output"];
  ssoEnabled: Scalars["Boolean"]["output"];
  usageDashboardUrl?: Maybe<MultiLanguageString>;
  videoEnabled: Scalars["Boolean"]["output"];
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage?: Maybe<Scalars["Boolean"]["output"]>;
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentInput = {
  cashTransactionId: Scalars["Int"]["input"];
};

export type PaywallNodeContent = {
  __typename?: "PaywallNodeContent";
  id: Scalars["String"]["output"];
};

export type PermanentHumanInput = {
  contactForFeedback: Scalars["Boolean"]["input"];
  email: Scalars["String"]["input"];
  healthInsurance?: InputMaybe<Scalars["String"]["input"]>;
  password: Scalars["String"]["input"];
  preferredLanguage?: InputMaybe<Scalars["String"]["input"]>;
  timezone: Scalars["String"]["input"];
};

export type Practice = WithDates &
  WithId & {
    __typename?: "Practice";
    address?: Maybe<Address>;
    chatTherapy?: Maybe<Scalars["Boolean"]["output"]>;
    createdAt: Scalars["DateTime"]["output"];
    id: Scalars["Int"]["output"];
    offlineTherapy?: Maybe<Scalars["Boolean"]["output"]>;
    onlineTherapy?: Maybe<Scalars["Boolean"]["output"]>;
    phoneTherapy?: Maybe<Scalars["Boolean"]["output"]>;
    updatedAt: Scalars["DateTime"]["output"];
  };

export type PracticeInput = {
  address?: InputMaybe<AddressInput>;
  chatTherapy?: InputMaybe<Scalars["Boolean"]["input"]>;
  offlineTherapy?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlineTherapy?: InputMaybe<Scalars["Boolean"]["input"]>;
  phoneTherapy?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The persona of an individual professional on the platform. Reference attached user for persona agnostic fields */
export type Professional = WithId & {
  __typename?: "Professional";
  /** @deprecated Prefer to access on user object */
  age?: Maybe<Scalars["Int"]["output"]>;
  availability?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Link to an avatar image */
  avatar?: Maybe<Avatar>;
  billingInformation?: Maybe<BillingInformation>;
  /** @deprecated Prefer to access on user object */
  birthDate?: Maybe<Scalars["DateTime"]["output"]>;
  clientStop?: Maybe<Scalars["Boolean"]["output"]>;
  /** Clients are humans that are linked to this professional */
  clients: ProfessionalHumanConnection;
  cmNumber?: Maybe<Scalars["String"]["output"]>;
  consultationLanguages: Array<Maybe<Scalars["String"]["output"]>>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  /** @deprecated Prefer to access on user object */
  email: Scalars["String"]["output"];
  /** Experience of this professional in years. */
  experienceSince?: Maybe<Scalars["DateTime"]["output"]>;
  /** @deprecated Prefer to access on user object */
  firstName?: Maybe<Scalars["String"]["output"]>;
  forBgt: Scalars["Boolean"]["output"];
  gender?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  /** Whether or not this professional is a 'firstline' professional. */
  isFirstLine?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Prefer to access on user object */
  lastName?: Maybe<Scalars["String"]["output"]>;
  /**
   * Files that are linked to this professional
   * #TODO: Add File type
   */
  licenseFiles?: Maybe<Array<Maybe<UserFile>>>;
  onboardedAt?: Maybe<Scalars["DateTime"]["output"]>;
  organizationId?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Prefer to access on user object */
  phone?: Maybe<Scalars["String"]["output"]>;
  practice?: Maybe<Practice>;
  /**
   * The preferred language for this user. He / she will see all text presented in this language.
   * This is also the language that will be the default language used in consultations.
   * @deprecated Prefer to access on user object
   */
  preferredLanguage: Language;
  /**
   * Short text in which the professional describes him/herself. JSON string that must be parsed.
   * @deprecated Replaced by profileDescription
   */
  profile?: Maybe<Scalars["String"]["output"]>;
  /** Short text in which the professional describes him/herself. */
  profileDescription: MultiLanguageString;
  roleType?: Maybe<Scalars["String"]["output"]>;
  /** An array containing a professional's specialities. */
  specialties: Array<Maybe<SpecialtiesThemeCombo>>;
  /** Duration of one consultation in minutes. */
  standardDuration?: Maybe<Scalars["Int"]["output"]>;
  /**
   * # QUESTION: Do we define this in $/h, or $/consultation?
   * Rate a professional charges per hour.
   */
  standardRate?: Maybe<Scalars["Float"]["output"]>;
  /** Whether or not this professional offers supervision. */
  supervision?: Maybe<Scalars["Boolean"]["output"]>;
  /** Types of therapy that are offered by this professional. */
  therapyTypes?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Prefer to access on user object */
  timezone: Scalars["String"]["output"];
  /** The training this professional has had. */
  training?: Maybe<Scalars["String"]["output"]>;
  /** The type of this professional. */
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  /** The user account for this professional */
  user: User;
  /** @deprecated Prefer to access on user object */
  uuid: Scalars["String"]["output"];
  /** Date and time at which this professional was verified by the BloomUp Team. */
  verifiedAt?: Maybe<Scalars["DateTime"]["output"]>;
  visumNumber?: Maybe<Scalars["String"]["output"]>;
  /** This professional's website. */
  website?: Maybe<Scalars["String"]["output"]>;
};

/** The persona of an individual professional on the platform. Reference attached user for persona agnostic fields */
export type ProfessionalAvailabilityArgs = {
  timeInMinutes?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProfessionalEdge = {
  __typename?: "ProfessionalEdge";
  cursor?: Maybe<Scalars["String"]["output"]>;
  node?: Maybe<Professional>;
};

export type ProfessionalForTrack = {
  __typename?: "ProfessionalForTrack";
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
};

/** The connection type between a human and a professional */
export type ProfessionalHumanConnection = {
  __typename?: "ProfessionalHumanConnection";
  edges: Array<ProfessionalHumanEdge>;
};

/** The connection edge between a human and a professional */
export type ProfessionalHumanEdge = {
  __typename?: "ProfessionalHumanEdge";
  node: Human;
};

export type ProfessionalInput = {
  acceptedConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  avatar?: InputMaybe<Scalars["Upload"]["input"]>;
  billingInformation?: InputMaybe<BillingInformationInput>;
  birthDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  clientStop?: InputMaybe<Scalars["Boolean"]["input"]>;
  cmNumber?: InputMaybe<Scalars["String"]["input"]>;
  consultationLanguages?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  deletedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  experienceSince?: InputMaybe<Scalars["DateTime"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  isFirstLine?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  licenseFiles?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  onboardedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  practice?: InputMaybe<PracticeInput>;
  preferredLanguage?: InputMaybe<Scalars["String"]["input"]>;
  profile?: InputMaybe<Scalars["String"]["input"]>;
  specialties?: InputMaybe<Scalars["String"]["input"]>;
  standardDuration?: InputMaybe<Scalars["Int"]["input"]>;
  standardRate?: InputMaybe<Scalars["Float"]["input"]>;
  supervision?: InputMaybe<Scalars["Boolean"]["input"]>;
  therapyTypes?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  training?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  visumNumber?: InputMaybe<Scalars["String"]["input"]>;
  website?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProfessionalRegistrationInput = {
  acceptedConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  consultationLanguages?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  licenseFiles?: InputMaybe<Array<Scalars["Upload"]["input"]>>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  training?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  website?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProfessionalSpecialtiesInput = {
  /** Specialties should be an array of uuids of SpecialtiesThemeCombo's */
  specialtiesThemeComboIds: Array<Scalars["String"]["input"]>;
};

export type ProfessionalsConnection = {
  __typename?: "ProfessionalsConnection";
  edges?: Maybe<Array<Maybe<ProfessionalEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars["Int"]["output"]>;
};

export type ProfessionalsForCurrentHumanInput = {
  withAvailability?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ProfessionalsInput = {
  adminsIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  adminsOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  verifiedIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  verifiedOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  allConsultationsForHuman?: Maybe<ConsultationsConnection>;
  allConsultationsForProfessional?: Maybe<ConsultationsConnection>;
  /**
   * Returns the tracks available in the provided language with the chosen type.
   * If no language is provided it will return based on the currently logged in user.
   * If no type is provided it will return all the available tracks disregarding type.
   * Track order will depend on the currently logged in user
   */
  availableTracks: Array<Track>;
  calendarEvents?: Maybe<Array<Maybe<CalendarFormats>>>;
  cashTransactionsByConsultationId?: Maybe<Array<Maybe<CashTransaction>>>;
  checkIfHumanIsAClient?: Maybe<Scalars["Boolean"]["output"]>;
  clientsForProfessional?: Maybe<ClientsConnection>;
  consultation?: Maybe<Consultation>;
  /** Returns the list of consultations that the logged in professional has with the specified human */
  consultationsForHumanAndProfessional?: Maybe<ConsultationsConnection>;
  /** Returns the list of consultations that the logged in human has with the specified professional */
  consultationsForProfessionalAndHuman?: Maybe<ConsultationsConnection>;
  countries: Array<Country>;
  currentMatchedHuman?: Maybe<MatchedHuman>;
  /** Returns the node content for the logged in user and specified track. */
  currentNodeContentV2: TypedNodeContent;
  /** Returns the currently logged in user. If tokens are invalid or not provided this will return null */
  currentUser?: Maybe<User>;
  findMatches?: Maybe<Matches>;
  getQuestionById?: Maybe<Question>;
  getSSOUrl: Scalars["String"]["output"];
  /** Returns a patient with the specified Id */
  human?: Maybe<Human>;
  /**
   * This query returns the slug of a given org id.
   * If the org id is missing, global data will be returned.
   */
  landingPageUniqueVisitors: Array<LineGraphItem>;
  lastCashTransactionByConsultationId?: Maybe<CashTransaction>;
  mindlabUser?: Maybe<User>;
  mindlabUsers?: Maybe<Array<Maybe<User>>>;
  /** Gets the status of a given mobile app version. */
  mobileVersionStatus: MobileStatus;
  mutualities: MutualitiesResponse;
  /** Returns a list of consultations for the logged in professionals with optional pagination and filtering */
  myProfessionalConsultations: ConsultationsConnection;
  /** Verifies if the provided code is linked to an organisation, or if the arg is an email, if it's domain matches an organisation */
  organizationByEmailOrCode?: Maybe<Organization>;
  organizationById?: Maybe<Organization>;
  organizationBySlug?: Maybe<Organization>;
  organizations?: Maybe<Array<Maybe<Organization>>>;
  /**
   * Returns a professional with the specified Id, if no id is specified, returns the currently logged in
   * professional
   */
  professional?: Maybe<Professional>;
  professionalExists?: Maybe<Scalars["Boolean"]["output"]>;
  /** Returns all professionals */
  professionals?: Maybe<ProfessionalsConnection>;
  professionalsForBgt?: Maybe<Array<Maybe<Professional>>>;
  /** Returns all professionals for a logged in human */
  professionalsForCurrentHuman?: Maybe<ProfessionalsConnection>;
  questionForUserAtEntryPoint?: Maybe<Question>;
  sendNotification?: Maybe<Scalars["String"]["output"]>;
  specialties?: Maybe<Array<Maybe<SpecialtiesThemeCombo>>>;
  /** Returns a specific track by id */
  track: Track;
  /** Returns a specific track artifact by id */
  trackArtifact: TrackArtifact;
  /** Returns all tracks for which the currently logged in user has at least one trackArtifact */
  tracksWithArtifacts: Array<Track>;
  /**
   * This query returns the total of employees with an n amount of consults.
   * e.g.: 10 employees with 1 consult, 5 employees with 2 consults, etc.
   * If the org id is missing, global data will be returned.
   */
  videoConsultBarchartEmployeesWithAnAmountOfConsults: Array<BarchartItem>;
  /**
   * This query returns the average consult per employee.
   * If the org id is missing, global data will be returned.
   */
  videoConsultCardAverageConsultPerEmployee: Scalars["Float"]["output"];
  /**
   * This query returns consults that have been canceled.
   * If the org id is missing, global data will be returned.
   */
  videoConsultCardCancelledConsults: Scalars["Int"]["output"];
  /**
   * This query returns consults that have been completed.
   * If the org id is missing, global data will be returned.
   */
  videoConsultCardCompletedConsults: Scalars["Int"]["output"];
  /**
   * This query returns consults that have been canceled.
   * If the org id is missing, global data will be returned.
   */
  videoConsultCardNoShowConsults: Scalars["Int"]["output"];
  /**
   * This query returns a name and a value for the funnel chart.
   * If the org id is missing, global data will be returned.
   */
  videoConsultFunnel: Array<FunnelItem>;
  /**
   * This query returns the wellbeing challenges list
   * ordered from most chosen challenge to least chosen challenge, limited to 10.
   * If the org id is missing, global data will be returned.
   */
  wellbeingChallenges: Array<WellbeingChallengesResult>;
  /**
   * This query returns the wellbeing value(s) for the energy gauge chart.
   * If the org id is missing, global data will be returned.
   */
  wellbeingEnergyGauge: DifferenceGaugeResult;
  /**
   * This query returns the wellbeing value(s) for the productivity gauge chart.
   * If the org id is missing, global data will be returned.
   */
  wellbeingProductivityGauge: DifferenceGaugeResult;
  /**
   * This query returns the wellbeing value(s) for the stress gauge chart.
   * If the org id is missing, global data will be returned.
   */
  wellbeingStressGauge: DifferenceGaugeResult;
};

export type QueryAvailableTracksArgs = {
  language?: InputMaybe<Language>;
  type?: InputMaybe<TrackType>;
};

export type QueryCalendarEventsArgs = {
  asFullCalendar?: InputMaybe<Scalars["Boolean"]["input"]>;
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type QueryCashTransactionsByConsultationIdArgs = {
  consultationId: Scalars["Int"]["input"];
};

export type QueryCheckIfHumanIsAClientArgs = {
  humanId: Scalars["Int"]["input"];
};

export type QueryConsultationArgs = {
  uuid: Scalars["String"]["input"];
};

export type QueryConsultationsForHumanAndProfessionalArgs = {
  professionalId: Scalars["Int"]["input"];
};

export type QueryConsultationsForProfessionalAndHumanArgs = {
  humanId: Scalars["Int"]["input"];
};

export type QueryCurrentNodeContentV2Args = {
  trackId: Scalars["String"]["input"];
};

export type QueryGetQuestionByIdArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryGetSsoUrlArgs = {
  mindlabBusinessId?: InputMaybe<Scalars["String"]["input"]>;
  redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryHumanArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLandingPageUniqueVisitorsArgs = {
  endDate: Scalars["Date"]["input"];
  organizationID: Scalars["Int"]["input"];
  startDate: Scalars["Date"]["input"];
};

export type QueryLastCashTransactionByConsultationIdArgs = {
  consultationId: Scalars["Int"]["input"];
};

export type QueryMindlabUserArgs = {
  mindlabUserId: Scalars["String"]["input"];
};

export type QueryMindlabUsersArgs = {
  mindlabUserIds: Array<Scalars["String"]["input"]>;
};

export type QueryMobileVersionStatusArgs = {
  version: Scalars["SemVer"]["input"];
};

export type QueryMyProfessionalConsultationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ConsultationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<ConsultationOrder>;
};

export type QueryOrganizationByEmailOrCodeArgs = {
  emailOrCode: Scalars["String"]["input"];
};

export type QueryOrganizationByIdArgs = {
  id: Scalars["Int"]["input"];
};

export type QueryOrganizationBySlugArgs = {
  slug: Scalars["String"]["input"];
};

export type QueryProfessionalArgs = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryProfessionalExistsArgs = {
  email: Scalars["String"]["input"];
};

export type QueryProfessionalsArgs = {
  input: ProfessionalsInput;
};

export type QueryProfessionalsForCurrentHumanArgs = {
  input?: InputMaybe<ProfessionalsForCurrentHumanInput>;
};

export type QueryQuestionForUserAtEntryPointArgs = {
  input: EntryPointsInput;
};

export type QuerySendNotificationArgs = {
  beamName: Scalars["String"]["input"];
  message: Notification;
};

export type QueryTrackArgs = {
  id: Scalars["String"]["input"];
};

export type QueryTrackArtifactArgs = {
  id: Scalars["String"]["input"];
};

export type QueryVideoConsultBarchartEmployeesWithAnAmountOfConsultsArgs = {
  organizationID?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryVideoConsultCardAverageConsultPerEmployeeArgs = {
  organizationID?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryVideoConsultCardCancelledConsultsArgs = {
  organizationID?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryVideoConsultCardCompletedConsultsArgs = {
  organizationID?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryVideoConsultCardNoShowConsultsArgs = {
  organizationID?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryVideoConsultFunnelArgs = {
  organizationID?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryWellbeingChallengesArgs = {
  organizationID?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryWellbeingEnergyGaugeArgs = {
  organizationID?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryWellbeingProductivityGaugeArgs = {
  organizationID?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryWellbeingStressGaugeArgs = {
  organizationID?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Question = {
  __typename?: "Question";
  category: QuestionsCategory;
  component?: Maybe<QuestionsComponent>;
  id: Scalars["Int"]["output"];
  nextQuestionId?: Maybe<Scalars["Int"]["output"]>;
  options?: Maybe<Scalars["JSON"]["output"]>;
  questionType: QuestionsType;
  title: Scalars["String"]["output"];
  translationKey?: Maybe<Scalars["String"]["output"]>;
};

export type QuestionsCategory =
  | "b2b"
  | "call_quality"
  | "end_of_treatment"
  | "match_quality"
  | "nps"
  | "professional_quality"
  | "share_with_bloomup";

export type QuestionsComponent = "checkbox" | "dropdown" | "radio" | "toggle";

export type QuestionsType =
  | "likert"
  | "multi_select"
  | "open"
  | "single_select"
  | "slider"
  | "yes_no"
  | "yes_no_maybe";

export type Region = {
  __typename?: "Region";
  id: Scalars["String"]["output"];
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  translationKey: Scalars["String"]["output"];
};

export type RegisterHumanInput = {
  birthDate?: InputMaybe<Scalars["Date"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  fromMindlab?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  mindlabNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationCode?: InputMaybe<Scalars["String"]["input"]>;
  password: Scalars["String"]["input"];
  preferredLanguage: Language;
};

export type Reimbursement = WithDates &
  WithId & {
    __typename?: "Reimbursement";
    createdAt: Scalars["DateTime"]["output"];
    id: Scalars["Int"]["output"];
    nationalId?: Maybe<Scalars["String"]["output"]>;
    reimbursementSubmission?: Maybe<ReimbursementSubmission>;
    updatedAt: Scalars["DateTime"]["output"];
  };

export type ReimbursementInput = {
  consultationUuid: Scalars["String"]["input"];
  nationalId: Scalars["String"]["input"];
};

export type ReimbursementSubmission = WithDates &
  WithId & {
    __typename?: "ReimbursementSubmission";
    createdAt: Scalars["DateTime"]["output"];
    id: Scalars["Int"]["output"];
    submittedAt?: Maybe<Scalars["DateTime"]["output"]>;
    updatedAt: Scalars["DateTime"]["output"];
  };

export type RequestedByType = "HUMAN" | "PROFESSIONAL";

/** Input object for rescheduling a consultation. */
export type RescheduleConsultationInput = {
  id: Scalars["Int"]["input"];
  message: Scalars["String"]["input"];
  price?: InputMaybe<Scalars["Float"]["input"]>;
  scheduledFrom: Scalars["DateTime"]["input"];
  scheduledTo: Scalars["DateTime"]["input"];
};

export type RestrictedClient = {
  __typename?: "RestrictedClient";
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  human?: Maybe<Human>;
  humanId?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  professionalId?: Maybe<Scalars["Int"]["output"]>;
  status?: Maybe<ClientStatus>;
};

export type Secrets2Fa = {
  __typename?: "Secrets2FA";
  qrCode: Scalars["String"]["output"];
  secret: Scalars["String"]["output"];
};

export type SituationApplicationNodeContent = {
  __typename?: "SituationApplicationNodeContent";
  applicableQuestion: NodeApplicableQuestion;
  buttonType: NodeButtonType;
  categoryQuestion: NodeCategoryQuestion;
  id: Scalars["String"]["output"];
  situations: Array<NodeSituation>;
};

export type SortDirection = "asc" | "desc";

export type SpecialtiesThemeCombo = {
  __typename?: "SpecialtiesThemeCombo";
  id: Scalars["String"]["output"];
  primaryColor: Scalars["String"]["output"];
  secondaryColor: Scalars["String"]["output"];
  subcategories: Array<Maybe<SubCategory>>;
  theme: Scalars["String"]["output"];
  tooltip?: Maybe<Scalars["String"]["output"]>;
  translationKey: Scalars["String"]["output"];
};

export type StartMatchingInput = {
  extraInfo?: InputMaybe<Scalars["String"]["input"]>;
  language: Scalars["String"]["input"];
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type StartMatchingResponse = {
  __typename?: "StartMatchingResponse";
  matchedHumanId: Scalars["Int"]["output"];
  matchingToken: Scalars["String"]["output"];
};

export type StartMatchingV2Input = {
  age: Scalars["Int"]["input"];
  orgCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubCategory = {
  __typename?: "SubCategory";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  tooltip?: Maybe<Scalars["String"]["output"]>;
  translationKey: Scalars["String"]["output"];
};

export type SubmitLocationStepInput = {
  countryId: Scalars["String"]["input"];
  regionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubmitMatchingStepResponse = {
  __typename?: "SubmitMatchingStepResponse";
  matchingToken: Scalars["String"]["output"];
  nextStep: Scalars["String"]["output"];
};

export type TokenResponse = {
  __typename?: "TokenResponse";
  /**
   * the accessToken is a JWT that contains the role_type and user_id (UUID) of the user, if login succeeds.
   * The user_id can be used to fetch the associated Professional / Patient.
   */
  accessToken?: Maybe<Scalars["String"]["output"]>;
  /** the mindlabToken is a JWT that is used for the mindlab rest-api. The response can be found at https://github.com/we-are-coding/mindlab-backend/blob/main/src/api/AuthEndpoint.ts . */
  mindlabToken?: Maybe<Scalars["String"]["output"]>;
  /**
   * the refreshToken is a JWT that can be used to refresh the accessToken if it expires
   * without having to login again.
   */
  refreshToken?: Maybe<Scalars["String"]["output"]>;
};

export type Track = {
  __typename?: "Track";
  artifacts: Array<TrackArtifact>;
  beta: MultiLanguageBoolean;
  currentUserCompleted: Scalars["Boolean"]["output"];
  currentUserProfessional?: Maybe<Professional>;
  currentUserStarted: Scalars["Boolean"]["output"];
  description: MultiLanguageString;
  detailLink: MultiLanguageString;
  duration: Scalars["Duration"]["output"];
  id: Scalars["String"]["output"];
  imageUrl: Scalars["String"]["output"];
  sections: Array<TrackSection>;
  title: MultiLanguageString;
};

export type TrackArtifact = {
  __typename?: "TrackArtifact";
  content: MultiLanguageString;
  headerImage: MultiLanguageString;
  id: Scalars["String"]["output"];
  title: MultiLanguageString;
};

export type TrackSection = {
  __typename?: "TrackSection";
  duration: Scalars["Duration"]["output"];
  id: Scalars["String"]["output"];
  imageUrl: Scalars["String"]["output"];
  steps: Array<GrowthStep>;
  title: MultiLanguageString;
  type: TrackSectionType;
};

export type TrackSectionType = "phase" | "videoCall";

export type TrackType = "assessment" | "exercise" | "growthTrack";

/** The content for a given node of the track */
export type TypedNodeContent =
  | DistributeListNodeContent
  | EndOfTrackNodeContent
  | NodeContent
  | OptionListNodeContent
  | PaywallNodeContent
  | SituationApplicationNodeContent
  | WaitNodeContent;

export type UpdateCalendarEventInput = {
  allDay?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["Int"]["input"];
  returnAsFullCalendar?: InputMaybe<Scalars["Boolean"]["input"]>;
  scheduledFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduledTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateConsultationInput = {
  fromMatching?: InputMaybe<Scalars["Boolean"]["input"]>;
  humanCallQuality?: InputMaybe<Scalars["Int"]["input"]>;
  humanNps?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<MessageInput>;
  price?: InputMaybe<Scalars["Float"]["input"]>;
  professionalCallQuality?: InputMaybe<Scalars["Int"]["input"]>;
  scheduledFrom?: InputMaybe<Scalars["DateTime"]["input"]>;
  scheduledTo?: InputMaybe<Scalars["DateTime"]["input"]>;
  status?: InputMaybe<ConsultationStatus>;
  therapistWithoutBloomup?: InputMaybe<Scalars["Boolean"]["input"]>;
  userRequested?: InputMaybe<Scalars["Boolean"]["input"]>;
  uuid: Scalars["String"]["input"];
};

export type UpdateCurrentUserInput = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  mindlabNewsletter?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Every account is a user, see attached human or professional for persona specifc details */
export type User = {
  __typename?: "User";
  /** The organization this user is a admin of. Will be null when this user is not a admin of an organization */
  adminOfOrganization?: Maybe<Organization>;
  /** User's age based on date of birth. */
  age?: Maybe<Scalars["Int"]["output"]>;
  /** User's date of birth. */
  birthDate?: Maybe<Scalars["DateTime"]["output"]>;
  /** Date at which the user was created */
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  /** User's first e-mail address. */
  email: Scalars["String"]["output"];
  /** User's first name. */
  firstName?: Maybe<Scalars["String"]["output"]>;
  /** The human persona for this user. Will be null when this user does not act as a human */
  human?: Maybe<Human>;
  id: Scalars["String"]["output"];
  /** User's last name. */
  lastName?: Maybe<Scalars["String"]["output"]>;
  /** Boolean that indicates whether the client wants to receive newsletters or not. */
  mindlabNewsletter?: Maybe<Scalars["Boolean"]["output"]>;
  /** Id that identifies the user within the mindlab database */
  mindlabUserId?: Maybe<Scalars["String"]["output"]>;
  /** Whether or not a user has completed (Professional / Human) onboarding. */
  onboardedAt?: Maybe<Scalars["DateTime"]["output"]>;
  /** User's phone number. */
  phone?: Maybe<Scalars["String"]["output"]>;
  /**
   * The preferred language for this user. He / she will see all text presented in this language.
   * This is also the language that will be the default language used in consultations.
   */
  preferredLanguage: Language;
  /** The professional persona for this user. Will be null when this user does not act as a professional */
  professional?: Maybe<Professional>;
  timezone: Scalars["String"]["output"];
  twoFactorAuthEnabled?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UserFile = {
  __typename?: "UserFile";
  collectionName: Scalars["String"]["output"];
  fileName: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  mimeType: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export type VideoCall = {
  __typename?: "VideoCall";
  attendeeInfo: Array<ChimeAttendeeInfo>;
  meetingInfo: ChimeMeetingInfo;
};

export type WaitNodeContent = {
  __typename?: "WaitNodeContent";
  amountOfMinutes: Scalars["Int"]["output"];
  forCheckIn: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
};

/** This file contains datastructures that are common in multiple types */
export type WithDates = {
  /** Time and date when this user was created. */
  createdAt: Scalars["DateTime"]["output"];
  /** Time and date of the last update to this user. */
  updatedAt: Scalars["DateTime"]["output"];
};

export type WithId = {
  /** The database ID of this entity */
  id: Scalars["Int"]["output"];
};

export type ConsultationOrder = {
  scheduledFrom: SortDirection;
};

export type MutualitiesResponse = {
  __typename?: "mutualitiesResponse";
  otherMutualities: Array<Mutuality>;
  regionMutualities: Array<Mutuality>;
};

/** This is the type for wellbeing challenges. */
export type WellbeingChallengesResult = {
  __typename?: "wellbeingChallengesResult";
  /** This is the count (times chosen by users) of the challenge. */
  count: Scalars["Int"]["output"];
  /** This is the id of the challenge. */
  id: Scalars["Int"]["output"];
  /** This is the name (translation key) of the challenge. */
  name: Scalars["String"]["output"];
};

export type AddressFragment = {
  __typename?: "Address";
  country?: string | null;
  createdAt: string;
  id: number;
  location?: string | null;
  postcode?: string | null;
  street?: string | null;
  updatedAt: string;
} & { " $fragmentName"?: "AddressFragment" };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword: boolean;
};

export type BillingInformationFragment = {
  __typename?: "BillingInformation";
  accountNumber: string;
  createdAt: string;
  email?: string | null;
  id: number;
  taxId?: string | null;
  updatedAt: string;
  address: { __typename?: "Address" } & {
    " $fragmentRefs"?: { AddressFragment: AddressFragment };
  };
} & { " $fragmentName"?: "BillingInformationFragment" };

export type FullCalendarEventFragment = {
  __typename?: "FullCalendarEvent";
  allDay: boolean;
  end: string;
  id: string;
  start: string;
  extendedProps: {
    __typename?: "ExtendedProps";
    status?: string | null;
    type: CalendarEventTypes;
  };
} & { " $fragmentName"?: "FullCalendarEventFragment" };

export type CreateFullCalendarEventMutationVariables = Exact<{
  input: CalendarEventInput;
}>;

export type CreateFullCalendarEventMutation = {
  __typename?: "Mutation";
  createCalendarEvent?:
    | { __typename?: "CalendarEvent" }
    | ({ __typename?: "FullCalendarEvent" } & {
        " $fragmentRefs"?: {
          FullCalendarEventFragment: FullCalendarEventFragment;
        };
      })
    | null;
};

export type UpdateCalendarEventMutationVariables = Exact<{
  input: UpdateCalendarEventInput;
}>;

export type UpdateCalendarEventMutation = {
  __typename?: "Mutation";
  updateCalendarEvent?:
    | { __typename?: "CalendarEvent" }
    | ({ __typename?: "FullCalendarEvent" } & {
        " $fragmentRefs"?: {
          FullCalendarEventFragment: FullCalendarEventFragment;
        };
      })
    | null;
};

export type CopyCalendarEventsMutationVariables = Exact<{
  input: CopyCalendarEventsInput;
}>;

export type CopyCalendarEventsMutation = {
  __typename?: "Mutation";
  copyCalendarEvents?: Array<
    | { __typename?: "CalendarEvent" }
    | ({ __typename?: "FullCalendarEvent" } & {
        " $fragmentRefs"?: {
          FullCalendarEventFragment: FullCalendarEventFragment;
        };
      })
    | null
  > | null;
};

export type DeleteCalendarEventsMutationVariables = Exact<{
  input: DeleteCalendarEventsInput;
}>;

export type DeleteCalendarEventsMutation = {
  __typename?: "Mutation";
  deleteCalendarEvents?: Array<number | null> | null;
};

export type GetFullCalendarEventsQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  startDate?: InputMaybe<Scalars["DateTime"]["input"]>;
}>;

export type GetFullCalendarEventsQuery = {
  __typename?: "Query";
  calendarEvents?: Array<
    | { __typename?: "CalendarEvent" }
    | ({ __typename?: "FullCalendarEvent" } & {
        " $fragmentRefs"?: {
          FullCalendarEventFragment: FullCalendarEventFragment;
        };
      })
    | null
  > | null;
};

export type CashTransactionFragment = {
  __typename?: "CashTransaction";
  amount: number;
  createdAt?: string | null;
  id?: number | null;
  status: CashTransactionStatusType;
  updatedAt?: string | null;
} & { " $fragmentName"?: "CashTransactionFragment" };

export type GetCashLastTransactionByConsultationIdQueryVariables = Exact<{
  consultationId: Scalars["Int"]["input"];
}>;

export type GetCashLastTransactionByConsultationIdQuery = {
  __typename?: "Query";
  lastCashTransactionByConsultationId?:
    | ({ __typename?: "CashTransaction" } & {
        " $fragmentRefs"?: { CashTransactionFragment: CashTransactionFragment };
      })
    | null;
};

export type GetCashTransactionsByConsultationIdQueryVariables = Exact<{
  consultationId: Scalars["Int"]["input"];
}>;

export type GetCashTransactionsByConsultationIdQuery = {
  __typename?: "Query";
  cashTransactionsByConsultationId?: Array<
    | ({ __typename?: "CashTransaction" } & {
        " $fragmentRefs"?: { CashTransactionFragment: CashTransactionFragment };
      })
    | null
  > | null;
};

export type ClientForProfessionalFragment = {
  __typename?: "Client";
  createdAt?: string | null;
  description?: string | null;
  email?: string | null;
  humanId?: number | null;
  id?: number | null;
  status?: ClientStatus | null;
  updatedAt?: string | null;
  human?:
    | ({ __typename?: "Human" } & {
        " $fragmentRefs"?: {
          HumanWithOrganizationFragment: HumanWithOrganizationFragment;
        };
      })
    | null;
} & { " $fragmentName"?: "ClientForProfessionalFragment" };

export type RestrictedClientForProfessionalFragment = {
  __typename?: "RestrictedClient";
  createdAt?: string | null;
  description?: string | null;
  email?: string | null;
  humanId?: number | null;
  id?: number | null;
  professionalId?: number | null;
  status?: ClientStatus | null;
  human?:
    | ({ __typename?: "Human" } & {
        " $fragmentRefs"?: {
          HumanWithOrganizationFragment: HumanWithOrganizationFragment;
        };
      })
    | null;
} & { " $fragmentName"?: "RestrictedClientForProfessionalFragment" };

export type ClientFragment = {
  __typename?: "Client";
  createdAt?: string | null;
  description?: string | null;
  email?: string | null;
  status?: ClientStatus | null;
  updatedAt?: string | null;
  human?: {
    __typename?: "Human";
    canPayWithCredits: boolean;
    creditsLeft?: number | null;
    id: number;
  } | null;
  professional?: {
    __typename?: "Professional";
    firstName?: string | null;
    id: number;
    lastName?: string | null;
  } | null;
} & { " $fragmentName"?: "ClientFragment" };

export type InviteClientMutationVariables = Exact<{
  input: AddClientInput;
}>;

export type InviteClientMutation = {
  __typename?: "Mutation";
  inviteClient?: boolean | null;
};

export type AcceptInviteMutationVariables = Exact<{
  input: AcceptInviteInput;
}>;

export type AcceptInviteMutation = {
  __typename?: "Mutation";
  acceptInvite?:
    | ({ __typename?: "Client"; id?: number | null } & {
        " $fragmentRefs"?: { ClientFragment: ClientFragment };
      })
    | null;
};

export type ClientsForProfessionalQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ClientsForProfessionalQuery = {
  __typename?: "Query";
  clientsForProfessional?: {
    __typename?: "ClientsConnection";
    edges?: Array<{
      __typename?: "ClientEdge";
      node?:
        | ({ __typename?: "Client" } & {
            " $fragmentRefs"?: {
              ClientForProfessionalFragment: ClientForProfessionalFragment;
            };
          })
        | ({ __typename?: "RestrictedClient" } & {
            " $fragmentRefs"?: {
              RestrictedClientForProfessionalFragment: RestrictedClientForProfessionalFragment;
            };
          })
        | null;
    } | null> | null;
  } | null;
};

export type CheckIfHumanIsAClientQueryVariables = Exact<{
  humanId: Scalars["Int"]["input"];
}>;

export type CheckIfHumanIsAClientQuery = {
  __typename?: "Query";
  checkIfHumanIsAClient?: boolean | null;
};

export type CommonConsultationFragment = {
  __typename?: "Consultation";
  acceptedByHuman?: boolean | null;
  acceptedByProfessional?: boolean | null;
  createdAt: string;
  expectedReimbursementDate?: string | null;
  id: number;
  requestedBy?: RequestedByType | null;
  scheduledFrom: string;
  scheduledTo: string;
  status: ConsultationStatus;
  type?: ConsultationType | null;
  updatedAt: string;
  uuid: string;
  cashTransaction?:
    | ({ __typename?: "CashTransaction" } & {
        " $fragmentRefs"?: { CashTransactionFragment: CashTransactionFragment };
      })
    | null;
  creditTransactions: Array<{
    __typename?: "CreditTransaction";
    amount: number;
    id: number;
  }>;
  message?:
    | ({ __typename?: "Message" } & {
        " $fragmentRefs"?: { MessageFragment: MessageFragment };
      })
    | null;
} & { " $fragmentName"?: "CommonConsultationFragment" };

export type ProfessionalForConsultationFragment = {
  __typename?: "Consultation";
  professionalId?: number | null;
  professional?: {
    __typename?: "Professional";
    age?: number | null;
    consultationLanguages: Array<string | null>;
    email: string;
    firstName?: string | null;
    id: number;
    lastName?: string | null;
    phone?: string | null;
    profile?: string | null;
    standardDuration?: number | null;
    standardRate?: number | null;
    avatar?: { __typename?: "Avatar"; url?: string | null } | null;
  } | null;
} & { " $fragmentName"?: "ProfessionalForConsultationFragment" };

export type HumanForConsultationFragment = {
  __typename?: "Consultation";
  humanId?: number | null;
  human?:
    | ({ __typename?: "Human" } & {
        " $fragmentRefs"?: {
          HumanWithOrganizationFragment: HumanWithOrganizationFragment;
        };
      })
    | null;
} & { " $fragmentName"?: "HumanForConsultationFragment" };

export type ConsultationForCallFragment = ({ __typename?: "Consultation" } & {
  " $fragmentRefs"?: {
    CommonConsultationFragment: CommonConsultationFragment;
    HumanForConsultationFragment: HumanForConsultationFragment;
    ProfessionalForConsultationFragment: ProfessionalForConsultationFragment;
  };
}) & { " $fragmentName"?: "ConsultationForCallFragment" };

export type ConsultationFragment = ({ __typename?: "Consultation" } & {
  " $fragmentRefs"?: {
    CommonConsultationFragment: CommonConsultationFragment;
    HumanForConsultationFragment: HumanForConsultationFragment;
    ProfessionalForConsultationFragment: ProfessionalForConsultationFragment;
  };
}) & { " $fragmentName"?: "ConsultationFragment" };

export type ConsultationWithPaymentFragment = ({
  __typename?: "Consultation";
  cashTransaction?:
    | ({ __typename?: "CashTransaction" } & {
        " $fragmentRefs"?: { CashTransactionFragment: CashTransactionFragment };
      })
    | null;
} & {
  " $fragmentRefs"?: {
    CommonConsultationFragment: CommonConsultationFragment;
    ProfessionalForConsultationFragment: ProfessionalForConsultationFragment;
  };
}) & { " $fragmentName"?: "ConsultationWithPaymentFragment" };

export type CreateConsultationMutationVariables = Exact<{
  input: CreateConsultationInput;
}>;

export type CreateConsultationMutation = {
  __typename?: "Mutation";
  createConsultation?:
    | ({ __typename?: "Consultation" } & {
        " $fragmentRefs"?: { ConsultationFragment: ConsultationFragment };
      })
    | null;
};

export type ConsultationUpdateMutationVariables = Exact<{
  input: UpdateConsultationInput;
}>;

export type ConsultationUpdateMutation = {
  __typename?: "Mutation";
  updateConsultation?:
    | ({ __typename?: "Consultation" } & {
        " $fragmentRefs"?: { ConsultationFragment: ConsultationFragment };
      })
    | null;
};

export type ConfirmConsultationMutationMutationVariables = Exact<{
  input: ConfirmConsultationInput;
}>;

export type ConfirmConsultationMutationMutation = {
  __typename?: "Mutation";
  confirmConsultation?: {
    __typename?: "Consultation";
    acceptedByHuman?: boolean | null;
    acceptedByProfessional?: boolean | null;
    id: number;
    status: ConsultationStatus;
    uuid: string;
  } | null;
};

export type AllHumanConsultationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllHumanConsultationsQuery = {
  __typename?: "Query";
  allConsultationsForHuman?: {
    __typename?: "ConsultationsConnection";
    edges: Array<{
      __typename?: "ConsultationEdge";
      node: { __typename?: "Consultation" } & {
        " $fragmentRefs"?: {
          ConsultationWithPaymentFragment: ConsultationWithPaymentFragment;
        };
      };
    }>;
  } | null;
};

export type AllProfessionalConsultationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllProfessionalConsultationsQuery = {
  __typename?: "Query";
  allConsultationsForProfessional?: {
    __typename?: "ConsultationsConnection";
    edges: Array<{
      __typename?: "ConsultationEdge";
      node: { __typename?: "Consultation" } & {
        " $fragmentRefs"?: { ConsultationFragment: ConsultationFragment };
      };
    }>;
  } | null;
};

export type ConsultationsForProfessionalAndHumanResolverQueryVariables = Exact<{
  humanId: Scalars["Int"]["input"];
}>;

export type ConsultationsForProfessionalAndHumanResolverQuery = {
  __typename?: "Query";
  consultationsForProfessionalAndHuman?: {
    __typename?: "ConsultationsConnection";
    edges: Array<{
      __typename?: "ConsultationEdge";
      node: { __typename?: "Consultation" } & {
        " $fragmentRefs"?: { ConsultationFragment: ConsultationFragment };
      };
    }>;
  } | null;
};

export type FetchConsultationForCallByUuidQueryVariables = Exact<{
  uuid: Scalars["String"]["input"];
}>;

export type FetchConsultationForCallByUuidQuery = {
  __typename?: "Query";
  consultation?:
    | ({ __typename?: "Consultation" } & {
        " $fragmentRefs"?: {
          ConsultationForCallFragment: ConsultationForCallFragment;
        };
      })
    | null;
};

export type FetchConsultationWithCashTransactionQueryVariables = Exact<{
  uuid: Scalars["String"]["input"];
}>;

export type FetchConsultationWithCashTransactionQuery = {
  __typename?: "Query";
  consultation?:
    | ({ __typename?: "Consultation"; id: number } & {
        " $fragmentRefs"?: {
          ConsultationWithPaymentFragment: ConsultationWithPaymentFragment;
        };
      })
    | null;
};

export type HumanFragment = {
  __typename?: "Human";
  age?: number | null;
  birthDate?: string | null;
  createdAt: string;
  email: string;
  firstName?: string | null;
  healthInsurance?: string | null;
  id: number;
  lastName?: string | null;
  onboardedAt?: string | null;
  organizationId?: number | null;
  phone?: string | null;
  preferredLanguage: Language;
  timezone: string;
  updatedAt?: string | null;
} & { " $fragmentName"?: "HumanFragment" };

export type HumanRegistrationFragment = {
  __typename?: "Human";
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
} & { " $fragmentName"?: "HumanRegistrationFragment" };

export type HumanWithOrganizationFragment = ({
  __typename?: "Human";
  canPayWithCredits: boolean;
  creditsLeft?: number | null;
  organizationId?: number | null;
  organization?:
    | ({ __typename?: "Organization" } & {
        " $fragmentRefs"?: { OrganizationFragment: OrganizationFragment };
      })
    | null;
} & { " $fragmentRefs"?: { HumanFragment: HumanFragment } }) & {
  " $fragmentName"?: "HumanWithOrganizationFragment";
};

export type CompleteHumanRegistrationMutationVariables = Exact<{
  input: HumanInput;
}>;

export type CompleteHumanRegistrationMutation = {
  __typename?: "Mutation";
  completeHumanRegistration?:
    | ({ __typename?: "Human"; id: number } & {
        " $fragmentRefs"?: {
          HumanRegistrationFragment: HumanRegistrationFragment;
        };
      })
    | null;
};

export type RegisterHumanWithInviteMutationVariables = Exact<{
  input: HumanInput;
  inviteToken: Scalars["String"]["input"];
}>;

export type RegisterHumanWithInviteMutation = {
  __typename?: "Mutation";
  registerHumanWithInvite?: {
    __typename?: "HumanWithTokens";
    accessToken: string;
    refreshToken: string;
    human?:
      | ({ __typename?: "Human"; id: number } & {
          " $fragmentRefs"?: {
            HumanRegistrationFragment: HumanRegistrationFragment;
          };
        })
      | null;
  } | null;
};

export type UpdateHumanMutationVariables = Exact<{
  input: HumanInput;
}>;

export type UpdateHumanMutation = {
  __typename?: "Mutation";
  updateHuman?:
    | ({ __typename?: "Human" } & {
        " $fragmentRefs"?: { HumanFragment: HumanFragment };
      })
    | null;
};

export type HumanQueryVariables = Exact<{ [key: string]: never }>;

export type HumanQuery = {
  __typename?: "Query";
  human?:
    | ({ __typename?: "Human" } & {
        " $fragmentRefs"?: { HumanFragment: HumanFragment };
      })
    | null;
};

export type UseSelectTranslationStringInputFragment = {
  __typename?: "MultiLanguageString";
  en?: string | null;
  fr?: string | null;
  nl?: string | null;
} & { " $fragmentName"?: "UseSelectTranslationStringInputFragment" };

export type UseSelectTranslationBooleanInputFragment = {
  __typename?: "MultiLanguageBoolean";
  en: boolean;
  fr: boolean;
  nl: boolean;
} & { " $fragmentName"?: "UseSelectTranslationBooleanInputFragment" };

export type MatchedHumanFragment = {
  __typename?: "MatchedHuman";
  age?: number | null;
  chosenProfessionalId?: number | null;
  createdAt: string;
  id: number;
  preferredProfessionalGender?: string | null;
  updatedAt: string;
  userUuid?: string | null;
  worries?: string | null;
} & { " $fragmentName"?: "MatchedHumanFragment" };

export type UpdateMatchedHumanMutationVariables = Exact<{
  input: MatchedHumanInput;
}>;

export type UpdateMatchedHumanMutation = {
  __typename?: "Mutation";
  updateMatchedHuman?:
    | ({ __typename?: "MatchedHuman" } & {
        " $fragmentRefs"?: { MatchedHumanFragment: MatchedHumanFragment };
      })
    | null;
};

export type UpdateMatchedHumanWorriesMutationVariables = Exact<{
  input: MatchedHumanWorriesInput;
}>;

export type UpdateMatchedHumanWorriesMutation = {
  __typename?: "Mutation";
  updateMatchedHumanWorries?:
    | ({ __typename?: "MatchedHuman" } & {
        " $fragmentRefs"?: { MatchedHumanFragment: MatchedHumanFragment };
      })
    | null;
};

export type CreatePermanentHumanMutationVariables = Exact<{
  input: PermanentHumanInput;
}>;

export type CreatePermanentHumanMutation = {
  __typename?: "Mutation";
  createPermanentHuman?: {
    __typename?: "LoginResponse";
    accessToken: string;
    refreshToken: string;
  } | null;
};

export type StartMatchingMutationVariables = Exact<{
  input: StartMatchingInput;
}>;

export type StartMatchingMutation = {
  __typename?: "Mutation";
  startMatching: {
    __typename?: "StartMatchingResponse";
    matchedHumanId: number;
    matchingToken: string;
  };
};

export type FindMatchesQueryVariables = Exact<{ [key: string]: never }>;

export type FindMatchesQuery = {
  __typename?: "Query";
  findMatches?: {
    __typename?: "Matches";
    matches?: Array<{
      __typename?: "Match";
      availability: Array<string | null>;
      isOnline: boolean;
      score: number;
      professional: { __typename?: "Professional" } & {
        " $fragmentRefs"?: {
          ProfessionalInfoForHumanFragment: ProfessionalInfoForHumanFragment;
        };
      };
    }> | null;
  } | null;
};

export type InitialDataQueryVariables = Exact<{ [key: string]: never }>;

export type InitialDataQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    email: string;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    onboardedAt?: string | null;
    phone?: string | null;
    preferredLanguage: Language;
    timezone: string;
    human?: {
      __typename?: "Human";
      canPayWithCredits: boolean;
      id: number;
    } | null;
    professional?: {
      __typename?: "Professional";
      id: number;
      avatar?: { __typename?: "Avatar"; url?: string | null } | null;
    } | null;
  } | null;
};

export type MessageFragment = {
  __typename?: "Message";
  body?: string | null;
  fromProfessional?: boolean | null;
  id?: number | null;
} & { " $fragmentName"?: "MessageFragment" };

export type OrganizationFragment = {
  __typename?: "Organization";
  code?: string | null;
  color?: string | null;
  creditsPerHuman?: number | null;
  emailRegex?: string | null;
  ggtEnabled: boolean;
  id: number;
  mindlabBusinessId?: string | null;
  mindlabEnabled: boolean;
  slug: string;
  ssoEnabled: boolean;
  videoEnabled: boolean;
  logoUrl: { __typename?: "MultiLanguageString" } & {
    " $fragmentRefs"?: {
      UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
    };
  };
  name: { __typename?: "MultiLanguageString" } & {
    " $fragmentRefs"?: {
      UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
    };
  };
} & { " $fragmentName"?: "OrganizationFragment" };

export type FetchOrganizationBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;

export type FetchOrganizationBySlugQuery = {
  __typename?: "Query";
  organizationBySlug?:
    | ({ __typename?: "Organization" } & {
        " $fragmentRefs"?: { OrganizationFragment: OrganizationFragment };
      })
    | null;
};

export type CreatePaymentMutationVariables = Exact<{
  input: PaymentInput;
}>;

export type CreatePaymentMutation = {
  __typename?: "Mutation";
  payForConsultation?: string | null;
};

export type PracticeFragment = {
  __typename?: "Practice";
  chatTherapy?: boolean | null;
  createdAt: string;
  id: number;
  offlineTherapy?: boolean | null;
  onlineTherapy?: boolean | null;
  phoneTherapy?: boolean | null;
  updatedAt: string;
  address?:
    | ({ __typename?: "Address" } & {
        " $fragmentRefs"?: { AddressFragment: AddressFragment };
      })
    | null;
} & { " $fragmentName"?: "PracticeFragment" };

export type ProfessionalExtraFragment = {
  __typename?: "Professional";
  cmNumber?: string | null;
  visumNumber?: string | null;
  billingInformation?:
    | ({ __typename?: "BillingInformation" } & {
        " $fragmentRefs"?: {
          BillingInformationFragment: BillingInformationFragment;
        };
      })
    | null;
  practice?:
    | ({ __typename?: "Practice" } & {
        " $fragmentRefs"?: { PracticeFragment: PracticeFragment };
      })
    | null;
} & { " $fragmentName"?: "ProfessionalExtraFragment" };

export type ProfessionalFragment = {
  __typename?: "Professional";
  birthDate?: string | null;
  clientStop?: boolean | null;
  consultationLanguages: Array<string | null>;
  createdAt: string;
  email: string;
  experienceSince?: string | null;
  firstName?: string | null;
  forBgt: boolean;
  gender?: string | null;
  id: number;
  isFirstLine?: boolean | null;
  lastName?: string | null;
  phone?: string | null;
  preferredLanguage: Language;
  profile?: string | null;
  roleType?: string | null;
  standardDuration?: number | null;
  standardRate?: number | null;
  supervision?: boolean | null;
  therapyTypes?: string | null;
  timezone: string;
  training?: string | null;
  type?: string | null;
  updatedAt?: string | null;
  verifiedAt?: string | null;
  website?: string | null;
  avatar?: { __typename?: "Avatar"; url?: string | null } | null;
  specialties: Array<
    | ({ __typename?: "SpecialtiesThemeCombo" } & {
        " $fragmentRefs"?: { SpecialtyFragment: SpecialtyFragment };
      })
    | null
  >;
} & { " $fragmentName"?: "ProfessionalFragment" };

export type ProfessionalFullFragment = ({
  __typename?: "Professional";
  licenseFiles?: Array<{
    __typename?: "UserFile";
    collectionName: string;
    fileName: string;
    id: number;
    mimeType: string;
    name: string;
    url?: string | null;
  } | null> | null;
} & {
  " $fragmentRefs"?: {
    ProfessionalFragment: ProfessionalFragment;
    ProfessionalExtraFragment: ProfessionalExtraFragment;
  };
}) & { " $fragmentName"?: "ProfessionalFullFragment" };

export type ProfessionalInfoForHumanFragment = {
  __typename?: "Professional";
  availability?: Array<string> | null;
  birthDate?: string | null;
  consultationLanguages: Array<string | null>;
  email: string;
  experienceSince?: string | null;
  firstName?: string | null;
  gender?: string | null;
  id: number;
  lastName?: string | null;
  onboardedAt?: string | null;
  phone?: string | null;
  profile?: string | null;
  standardDuration?: number | null;
  standardRate?: number | null;
  therapyTypes?: string | null;
  timezone: string;
  training?: string | null;
  type?: string | null;
  website?: string | null;
  avatar?: { __typename?: "Avatar"; url?: string | null } | null;
  practice?:
    | ({ __typename?: "Practice" } & {
        " $fragmentRefs"?: { PracticeFragment: PracticeFragment };
      })
    | null;
  specialties: Array<
    | ({ __typename?: "SpecialtiesThemeCombo" } & {
        " $fragmentRefs"?: { SpecialtyFragment: SpecialtyFragment };
      })
    | null
  >;
  user: { __typename?: "User"; id: string; preferredLanguage: Language };
} & { " $fragmentName"?: "ProfessionalInfoForHumanFragment" };

export type RegisterProfessionalMutationVariables = Exact<{
  input: ProfessionalRegistrationInput;
}>;

export type RegisterProfessionalMutation = {
  __typename?: "Mutation";
  registerProfessional?: boolean | null;
};

export type UpdateProfessionalMutationVariables = Exact<{
  input: ProfessionalInput;
}>;

export type UpdateProfessionalMutation = {
  __typename?: "Mutation";
  updateProfessional?:
    | ({ __typename?: "Professional" } & {
        " $fragmentRefs"?: {
          ProfessionalFullFragment: ProfessionalFullFragment;
        };
      })
    | null;
};

export type VerifyProfessionalMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type VerifyProfessionalMutation = {
  __typename?: "Mutation";
  verifyProfessional?: boolean | null;
};

export type ProfessionalsQueryVariables = Exact<{
  input: ProfessionalsInput;
}>;

export type ProfessionalsQuery = {
  __typename?: "Query";
  professionals?: {
    __typename?: "ProfessionalsConnection";
    totalCount?: number | null;
    edges?: Array<{
      __typename?: "ProfessionalEdge";
      cursor?: string | null;
      node?:
        | ({ __typename?: "Professional" } & {
            " $fragmentRefs"?: { ProfessionalFragment: ProfessionalFragment };
          })
        | null;
    } | null> | null;
  } | null;
};

export type FullProfessionalProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FullProfessionalProfileQuery = {
  __typename?: "Query";
  professional?:
    | ({ __typename?: "Professional" } & {
        " $fragmentRefs"?: {
          ProfessionalFragment: ProfessionalFragment;
          ProfessionalExtraFragment: ProfessionalExtraFragment;
        };
      })
    | null;
};

export type ProfessionalCurrentQueryVariables = Exact<{ [key: string]: never }>;

export type ProfessionalCurrentQuery = {
  __typename?: "Query";
  professional?:
    | ({ __typename?: "Professional" } & {
        " $fragmentRefs"?: { ProfessionalFragment: ProfessionalFragment };
      })
    | null;
};

export type ProfessionalsForHumanQueryVariables = Exact<{
  input?: InputMaybe<ProfessionalsForCurrentHumanInput>;
}>;

export type ProfessionalsForHumanQuery = {
  __typename?: "Query";
  professionalsForCurrentHuman?: {
    __typename?: "ProfessionalsConnection";
    edges?: Array<{
      __typename?: "ProfessionalEdge";
      node?:
        | ({ __typename?: "Professional" } & {
            " $fragmentRefs"?: {
              ProfessionalInfoForHumanFragment: ProfessionalInfoForHumanFragment;
            };
          })
        | null;
    } | null> | null;
  } | null;
};

export type ProfessionalsForAdminQueryVariables = Exact<{
  input: ProfessionalsInput;
}>;

export type ProfessionalsForAdminQuery = {
  __typename?: "Query";
  professionals?: {
    __typename?: "ProfessionalsConnection";
    totalCount?: number | null;
    edges?: Array<{
      __typename?: "ProfessionalEdge";
      cursor?: string | null;
      node?:
        | ({ __typename?: "Professional" } & {
            " $fragmentRefs"?: {
              ProfessionalFullFragment: ProfessionalFullFragment;
            };
          })
        | null;
    } | null> | null;
  } | null;
};

export type ProfessionalExistsQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type ProfessionalExistsQuery = {
  __typename?: "Query";
  professionalExists?: boolean | null;
};

export type QualityQuestionFragment = {
  __typename?: "Question";
  category: QuestionsCategory;
  component?: QuestionsComponent | null;
  id: number;
  nextQuestionId?: number | null;
  options?: any | null;
  questionType: QuestionsType;
  title: string;
  translationKey?: string | null;
} & { " $fragmentName"?: "QualityQuestionFragment" };

export type AnswerQuestionMutationVariables = Exact<{
  input: AnswerInput;
}>;

export type AnswerQuestionMutation = {
  __typename?: "Mutation";
  answerQuestion?: boolean | null;
};

export type GetQuestionByIdQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetQuestionByIdQuery = {
  __typename?: "Query";
  getQuestionById?:
    | ({ __typename?: "Question" } & {
        " $fragmentRefs"?: { QualityQuestionFragment: QualityQuestionFragment };
      })
    | null;
};

export type QuestionForUserAtEntryPointQueryVariables = Exact<{
  input: EntryPointsInput;
}>;

export type QuestionForUserAtEntryPointQuery = {
  __typename?: "Query";
  questionForUserAtEntryPoint?:
    | ({ __typename?: "Question" } & {
        " $fragmentRefs"?: { QualityQuestionFragment: QualityQuestionFragment };
      })
    | null;
};

export type RefreshAccessTokenMutationVariables = Exact<{
  refreshToken: Scalars["String"]["input"];
}>;

export type RefreshAccessTokenMutation = {
  __typename?: "Mutation";
  refreshAccessTokenV2: {
    __typename?: "TokenResponse";
    accessToken?: string | null;
    refreshToken?: string | null;
  };
};

export type ReimbursementSubmissionFragment = {
  __typename?: "ReimbursementSubmission";
  createdAt: string;
  id: number;
  submittedAt?: string | null;
  updatedAt: string;
} & { " $fragmentName"?: "ReimbursementSubmissionFragment" };

export type ReimbursementFragment = {
  __typename?: "Reimbursement";
  createdAt: string;
  id: number;
  nationalId?: string | null;
  updatedAt: string;
  reimbursementSubmission?:
    | ({ __typename?: "ReimbursementSubmission" } & {
        " $fragmentRefs"?: {
          ReimbursementSubmissionFragment: ReimbursementSubmissionFragment;
        };
      })
    | null;
} & { " $fragmentName"?: "ReimbursementFragment" };

export type RequestReimbursementMutationVariables = Exact<{
  input: ReimbursementInput;
}>;

export type RequestReimbursementMutation = {
  __typename?: "Mutation";
  requestReimbursement?:
    | ({ __typename?: "Reimbursement" } & {
        " $fragmentRefs"?: { ReimbursementFragment: ReimbursementFragment };
      })
    | null;
};

export type SubCategoryFragment = {
  __typename?: "SubCategory";
  id: string;
  name: string;
  tooltip?: string | null;
  translationKey: string;
} & { " $fragmentName"?: "SubCategoryFragment" };

export type SpecialtyFragment = {
  __typename?: "SpecialtiesThemeCombo";
  id: string;
  primaryColor: string;
  secondaryColor: string;
  theme: string;
  tooltip?: string | null;
  translationKey: string;
  subcategories: Array<
    | ({ __typename?: "SubCategory" } & {
        " $fragmentRefs"?: { SubCategoryFragment: SubCategoryFragment };
      })
    | null
  >;
} & { " $fragmentName"?: "SpecialtyFragment" };

export type UpdateSpecialtiesMutationVariables = Exact<{
  input: ProfessionalSpecialtiesInput;
}>;

export type UpdateSpecialtiesMutation = {
  __typename?: "Mutation";
  updateProfessionalSpecialties?:
    | ({ __typename?: "Professional" } & {
        " $fragmentRefs"?: { ProfessionalFragment: ProfessionalFragment };
      })
    | null;
};

export type SpecialtiesQueryVariables = Exact<{ [key: string]: never }>;

export type SpecialtiesQuery = {
  __typename?: "Query";
  specialties?: Array<
    | ({ __typename?: "SpecialtiesThemeCombo" } & {
        " $fragmentRefs"?: { SpecialtyFragment: SpecialtyFragment };
      })
    | null
  > | null;
};

export type VideoCallFragment = {
  __typename?: "VideoCall";
  attendeeInfo: Array<{
    __typename?: "ChimeAttendeeInfo";
    AttendeeId: string;
    ExternalUserId: string;
    JoinToken: string;
  }>;
  meetingInfo: {
    __typename?: "ChimeMeetingInfo";
    ExternalMeetingId?: string | null;
    MediaRegion: string;
    MeetingId: string;
    MediaPlacement: {
      __typename?: "ChimeMediaPlacement";
      AudioFallbackUrl: string;
      AudioHostUrl: string;
      ScreenDataUrl: string;
      ScreenSharingUrl: string;
      ScreenViewingUrl: string;
      SignalingUrl: string;
    };
  };
} & { " $fragmentName"?: "VideoCallFragment" };

export type StartVideoCallMutationVariables = Exact<{
  consultationUuid: Scalars["String"]["input"];
}>;

export type StartVideoCallMutation = {
  __typename?: "Mutation";
  startVideoCall?:
    | ({ __typename?: "VideoCall" } & {
        " $fragmentRefs"?: { VideoCallFragment: VideoCallFragment };
      })
    | null;
};

export type OrgProfileQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type OrgProfileQuery = {
  __typename?: "Query";
  organizationById?:
    | ({
        __typename?: "Organization";
        id: number;
        profile: { __typename?: "MultiLanguageString" } & {
          " $fragmentRefs"?: {
            UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
          };
        };
      } & {
        " $fragmentRefs"?: {
          OrgProfileDialogOrganizationFragment: OrgProfileDialogOrganizationFragment;
        };
      })
    | null;
};

export type ConsultationInfoQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type ConsultationInfoQuery = {
  __typename?: "Query";
  organizationById?:
    | ({
        __typename?: "Organization";
        id: number;
        name: { __typename?: "MultiLanguageString" } & {
          " $fragmentRefs"?: {
            UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
          };
        };
      } & {
        " $fragmentRefs"?: {
          OrgProfileDialogOrganizationFragment: OrgProfileDialogOrganizationFragment;
        };
      })
    | null;
};

export type OrgProfileDialogOrganizationFragment = {
  __typename?: "Organization";
  ggtEnabled: boolean;
  id: number;
  mindlabEnabled: boolean;
  videoEnabled: boolean;
  name: { __typename?: "MultiLanguageString" } & {
    " $fragmentRefs"?: {
      UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
    };
  };
  profile: { __typename?: "MultiLanguageString" } & {
    " $fragmentRefs"?: {
      UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
    };
  };
} & { " $fragmentName"?: "OrgProfileDialogOrganizationFragment" };

export type ChoosePasswordV2MutationVariables = Exact<{
  input: ChoosePasswordV2Input;
}>;

export type ChoosePasswordV2Mutation = {
  __typename?: "Mutation";
  choosePasswordV2: boolean;
};

export type LandingPageUniqueVisitorsQueryVariables = Exact<{
  endDate: Scalars["Date"]["input"];
  organizationId: Scalars["Int"]["input"];
  startDate: Scalars["Date"]["input"];
}>;

export type LandingPageUniqueVisitorsQuery = {
  __typename?: "Query";
  landingPageUniqueVisitors: Array<{
    __typename?: "LineGraphItem";
    x: string;
    y: number;
  }>;
  videoConsultFunnel: Array<{
    __typename?: "FunnelItem";
    name: string;
    value: number;
  }>;
};

export type VideoConsultPageQueryVariables = Exact<{ [key: string]: never }>;

export type VideoConsultPageQuery = {
  __typename?: "Query";
  videoConsultCardAverageConsultPerEmployee: number;
  videoConsultCardCancelledConsults: number;
  videoConsultCardCompletedConsults: number;
  videoConsultCardNoShowConsults: number;
  videoConsultBarchartEmployeesWithAnAmountOfConsults: Array<{
    __typename?: "BarchartItem";
    name: string;
    value: number;
  }>;
  videoConsultFunnel: Array<{
    __typename?: "FunnelItem";
    name: string;
    value: number;
  }>;
};

export type WellBeingPageQueryVariables = Exact<{ [key: string]: never }>;

export type WellBeingPageQuery = {
  __typename?: "Query";
  wellbeingChallenges: Array<{
    __typename?: "wellbeingChallengesResult";
    count: number;
    id: number;
    name: string;
  }>;
  wellbeingEnergyGauge: {
    __typename?: "DifferenceGaugeResult";
    averageFirstScore: number;
    averageImprovement: number;
    averageLatestScore: number;
  };
  wellbeingProductivityGauge: {
    __typename?: "DifferenceGaugeResult";
    averageFirstScore: number;
    averageImprovement: number;
    averageLatestScore: number;
  };
  wellbeingStressGauge: {
    __typename?: "DifferenceGaugeResult";
    averageFirstScore: number;
    averageImprovement: number;
    averageLatestScore: number;
  };
};

export type CreateIntroMutationVariables = Exact<{
  input: CreateConsultationInput;
}>;

export type CreateIntroMutation = {
  __typename?: "Mutation";
  createConsultation?: {
    __typename?: "Consultation";
    id: number;
    uuid: string;
  } | null;
};

export type HumanProfilePageQueryVariables = Exact<{ [key: string]: never }>;

export type HumanProfilePageQuery = {
  __typename?: "Query";
  human?: {
    __typename?: "Human";
    creditsLeft?: number | null;
    id: number;
    organization?: {
      __typename?: "Organization";
      id: number;
      name: { __typename?: "MultiLanguageString" } & {
        " $fragmentRefs"?: {
          UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
        };
      };
    } | null;
  } | null;
};

export type RequestConsultationFormQueryVariables = Exact<{
  professionalId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type RequestConsultationFormQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: string;
    human?:
      | ({ __typename?: "Human"; id: number } & {
          " $fragmentRefs"?: {
            ProfessionalRate_HumanFragment: ProfessionalRate_HumanFragment;
          };
        })
      | null;
  } | null;
  professional?:
    | ({ __typename?: "Professional"; id: number } & {
        " $fragmentRefs"?: {
          ProfessionalRate_ProfessionalFragment: ProfessionalRate_ProfessionalFragment;
        };
      })
    | null;
};

export type LoginMutationVariables = Exact<{
  input: LoginV2Input;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  loginV2:
    | { __typename?: "LoginV2FailureResponse"; reason: LoginFailureReason }
    | {
        __typename?: "TokenResponse";
        accessToken?: string | null;
        refreshToken?: string | null;
      };
};

export type SsoUrlQueryVariables = Exact<{
  redirectUrl?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type SsoUrlQuery = { __typename?: "Query"; getSSOUrl: string };

export type LoginWithSsoMutationVariables = Exact<{
  input: LoginWithSsoInput;
}>;

export type LoginWithSsoMutation = {
  __typename?: "Mutation";
  loginWithSSO:
    | { __typename?: "LoginV2FailureResponse" }
    | {
        __typename?: "TokenResponse";
        accessToken?: string | null;
        mindlabToken?: string | null;
        refreshToken?: string | null;
      };
};

export type DashboardsQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: string;
    adminOfOrganization?: {
      __typename?: "Organization";
      ggtEnabled: boolean;
      id: number;
      videoEnabled: boolean;
      contractDashboardUrl?:
        | ({ __typename?: "MultiLanguageString" } & {
            " $fragmentRefs"?: {
              UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
            };
          })
        | null;
      ggtDashboardUrl?:
        | ({ __typename?: "MultiLanguageString" } & {
            " $fragmentRefs"?: {
              UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
            };
          })
        | null;
      interestDashboardUrl?:
        | ({ __typename?: "MultiLanguageString" } & {
            " $fragmentRefs"?: {
              UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
            };
          })
        | null;
      mentalWellbeingDashboardUrl?:
        | ({ __typename?: "MultiLanguageString" } & {
            " $fragmentRefs"?: {
              UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
            };
          })
        | null;
      usageDashboardUrl?:
        | ({ __typename?: "MultiLanguageString" } & {
            " $fragmentRefs"?: {
              UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
            };
          })
        | null;
    } | null;
  } | null;
};

export type OrganisationLandingPageQueryVariables = Exact<{
  emailOrCode: Scalars["String"]["input"];
}>;

export type OrganisationLandingPageQuery = {
  __typename?: "Query";
  organizationByEmailOrCode?:
    | ({ __typename?: "Organization" } & {
        " $fragmentRefs"?: { OrganizationFragment: OrganizationFragment };
      })
    | null;
};

export type GgtLandingPageQueryVariables = Exact<{
  language?: InputMaybe<Language>;
}>;

export type GgtLandingPageQuery = {
  __typename?: "Query";
  assessments: Array<{
    __typename?: "Track";
    id: string;
    title: { __typename?: "MultiLanguageString" } & {
      " $fragmentRefs"?: {
        UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
      };
    };
  }>;
  exercises: Array<{
    __typename?: "Track";
    id: string;
    title: { __typename?: "MultiLanguageString" } & {
      " $fragmentRefs"?: {
        UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
      };
    };
  }>;
  growthTracks: Array<{
    __typename?: "Track";
    id: string;
    title: { __typename?: "MultiLanguageString" } & {
      " $fragmentRefs"?: {
        UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
      };
    };
  }>;
};

export type VerifyOrganizationPageQueryVariables = Exact<{
  emailOrCode: Scalars["String"]["input"];
}>;

export type VerifyOrganizationPageQuery = {
  __typename?: "Query";
  organizationByEmailOrCode?:
    | ({ __typename?: "Organization" } & {
        " $fragmentRefs"?: { OrganizationFragment: OrganizationFragment };
      })
    | null;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  __typename?: "Query";
  countries: Array<{
    __typename?: "Country";
    id: string;
    translationKey: string;
    regions?: Array<{
      __typename?: "Region";
      id: string;
      translationKey: string;
    }> | null;
  }>;
};

export type SubmitLocationStepMutationVariables = Exact<{
  input: SubmitLocationStepInput;
}>;

export type SubmitLocationStepMutation = {
  __typename?: "Mutation";
  submitLocationStep: {
    __typename?: "SubmitMatchingStepResponse";
    matchingToken: string;
    nextStep: string;
  };
};

export type MutualitiesQueryVariables = Exact<{ [key: string]: never }>;

export type MutualitiesQuery = {
  __typename?: "Query";
  mutualities: {
    __typename?: "mutualitiesResponse";
    otherMutualities: Array<{
      __typename?: "Mutuality";
      id: string;
      logoUrl?: string | null;
      preferred: boolean;
      translationKey: string;
    }>;
    regionMutualities: Array<{
      __typename?: "Mutuality";
      id: string;
      logoUrl?: string | null;
      preferred: boolean;
      translationKey: string;
    }>;
  };
};

export type SubmitMutualityStepMutationVariables = Exact<{
  mutualityId: Scalars["String"]["input"];
}>;

export type SubmitMutualityStepMutation = {
  __typename?: "Mutation";
  submitMutualityStep: {
    __typename?: "SubmitMatchingStepResponse";
    matchingToken: string;
    nextStep: string;
  };
};

export type StartMatchingV2MutationVariables = Exact<{
  input: StartMatchingV2Input;
}>;

export type StartMatchingV2Mutation = {
  __typename?: "Mutation";
  startMatchingV2: {
    __typename?: "SubmitMatchingStepResponse";
    matchingToken: string;
    nextStep: string;
  };
};

export type ProfCallSidepaneQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type ProfCallSidepaneQuery = {
  __typename?: "Query";
  organizationById?: {
    __typename?: "Organization";
    ggtEnabled: boolean;
    id: number;
  } | null;
};

export type SendAppInviteMutationVariables = Exact<{
  humanId: Scalars["Int"]["input"];
}>;

export type SendAppInviteMutation = {
  __typename?: "Mutation";
  sendAppInvite: boolean;
};

export type ColleagueQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type ColleagueQuery = {
  __typename?: "Query";
  professional?:
    | ({
        __typename?: "Professional";
        id: number;
        user: { __typename?: "User"; id: string } & {
          " $fragmentRefs"?: {
            ViewOnlyProfileInfoFragment: ViewOnlyProfileInfoFragment;
          };
        };
      } & {
        " $fragmentRefs"?: {
          ViewOnlyProfessionalProfileFragment: ViewOnlyProfessionalProfileFragment;
        };
      })
    | null;
};

export type ColleagueFragment = {
  __typename?: "Professional";
  id: number;
  therapyTypes?: string | null;
  specialties: Array<{
    __typename?: "SpecialtiesThemeCombo";
    id: string;
    subcategories: Array<{
      __typename?: "SubCategory";
      id: string;
      translationKey: string;
    } | null>;
  } | null>;
  user: {
    __typename?: "User";
    firstName?: string | null;
    id: string;
    lastName?: string | null;
  } & { " $fragmentRefs"?: { AvatarFragment: AvatarFragment } };
} & { " $fragmentName"?: "ColleagueFragment" };

export type ColleagueNodeFragment = ({
  __typename?: "Professional";
  firstName?: string | null;
  id: number;
  lastName?: string | null;
} & { " $fragmentRefs"?: { ColleagueFragment: ColleagueFragment } }) & {
  " $fragmentName"?: "ColleagueNodeFragment";
};

export type ColleaguesQueryVariables = Exact<{
  input: ProfessionalsInput;
}>;

export type ColleaguesQuery = {
  __typename?: "Query";
  professionals?: {
    __typename?: "ProfessionalsConnection";
    totalCount?: number | null;
    edges?: Array<{
      __typename?: "ProfessionalEdge";
      cursor?: string | null;
      node?:
        | ({ __typename?: "Professional" } & {
            " $fragmentRefs"?: { ColleagueNodeFragment: ColleagueNodeFragment };
          })
        | null;
    } | null> | null;
  } | null;
};

export type ViewOnlyProfessionalProfileFragment = {
  __typename?: "Professional";
  consultationLanguages: Array<string | null>;
  experienceSince?: string | null;
  id: number;
  standardDuration?: number | null;
  standardRate?: number | null;
  therapyTypes?: string | null;
  website?: string | null;
  practice?: {
    __typename?: "Practice";
    id: number;
    address?: {
      __typename?: "Address";
      id: number;
      postcode?: string | null;
      street?: string | null;
    } | null;
  } | null;
  profileDescription: { __typename?: "MultiLanguageString" } & {
    " $fragmentRefs"?: {
      UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
    };
  };
  specialties: Array<{
    __typename?: "SpecialtiesThemeCombo";
    id: string;
    secondaryColor: string;
    subcategories: Array<{
      __typename?: "SubCategory";
      id: string;
      tooltip?: string | null;
      translationKey: string;
    } | null>;
  } | null>;
} & { " $fragmentName"?: "ViewOnlyProfessionalProfileFragment" };

export type ClientConsultationDetailQueryVariables = Exact<{
  uuid: Scalars["String"]["input"];
}>;

export type ClientConsultationDetailQuery = {
  __typename?: "Query";
  consultation?: {
    __typename?: "Consultation";
    acceptedByHuman?: boolean | null;
    acceptedByProfessional?: boolean | null;
    bloomupFee?: number | null;
    duration: string;
    id: number;
    professionalFee?: number | null;
    scheduledFrom: string;
    scheduledTo: string;
    status: ConsultationStatus;
    type?: ConsultationType | null;
    uuid: string;
    cashTransaction?: {
      __typename?: "CashTransaction";
      amount: number;
      id?: number | null;
      status: CashTransactionStatusType;
    } | null;
    creditTransactions: Array<{
      __typename?: "CreditTransaction";
      amount: number;
      id: number;
    }>;
    human?: {
      __typename?: "Human";
      canPayWithCredits: boolean;
      creditsLeft?: number | null;
      id: number;
      organization?:
        | ({
            __typename?: "Organization";
            id: number;
            name: { __typename?: "MultiLanguageString" } & {
              " $fragmentRefs"?: {
                UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
              };
            };
          } & {
            " $fragmentRefs"?: {
              OrgProfileDialogOrganizationFragment: OrgProfileDialogOrganizationFragment;
            };
          })
        | null;
      user: {
        __typename?: "User";
        email: string;
        firstName?: string | null;
        id: string;
        lastName?: string | null;
        phone?: string | null;
      };
    } | null;
    message?: {
      __typename?: "Message";
      body?: string | null;
      id?: number | null;
    } | null;
  } | null;
};

export type ProfessionalCancelConsultationQueryVariables = Exact<{
  uuid: Scalars["String"]["input"];
}>;

export type ProfessionalCancelConsultationQuery = {
  __typename?: "Query";
  consultation?: { __typename?: "Consultation"; id: number } | null;
};

export type ProfessionalCancelConsultationMutationMutationVariables = Exact<{
  input: CancelConsultationInput;
}>;

export type ProfessionalCancelConsultationMutationMutation = {
  __typename?: "Mutation";
  cancelConsultation: {
    __typename?: "Consultation";
    id: number;
    message?: {
      __typename?: "Message";
      body?: string | null;
      id?: number | null;
    } | null;
  };
};

export type ConsultationDetailActionsQueryVariables = Exact<{
  uuid: Scalars["String"]["input"];
}>;

export type ConsultationDetailActionsQuery = {
  __typename?: "Query";
  consultation?: {
    __typename?: "Consultation";
    acceptedByHuman?: boolean | null;
    acceptedByProfessional?: boolean | null;
    id: number;
    status: ConsultationStatus;
    type?: ConsultationType | null;
    human?: {
      __typename?: "Human";
      id: number;
      organization?:
        | ({
            __typename?: "Organization";
            id: number;
            name: { __typename?: "MultiLanguageString" } & {
              " $fragmentRefs"?: {
                UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
              };
            };
            profile: { __typename?: "MultiLanguageString" } & {
              " $fragmentRefs"?: {
                UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
              };
            };
          } & {
            " $fragmentRefs"?: {
              OrgProfileDialogOrganizationFragment: OrgProfileDialogOrganizationFragment;
            };
          })
        | null;
    } | null;
  } | null;
  currentUser?: { __typename?: "User"; email: string; id: string } | null;
};

export type ConfirmConsultationMutationProfessionalMutationVariables = Exact<{
  input: ConfirmConsultationInput;
}>;

export type ConfirmConsultationMutationProfessionalMutation = {
  __typename?: "Mutation";
  confirmConsultation?: {
    __typename?: "Consultation";
    acceptedByHuman?: boolean | null;
    acceptedByProfessional?: boolean | null;
    id: number;
    status: ConsultationStatus;
  } | null;
};

export type ProfessionalConsultationListFragment = {
  __typename?: "ConsultationsConnection";
  edges: Array<{
    __typename?: "ConsultationEdge";
    node: {
      __typename?: "Consultation";
      id: number;
      requestedBy?: RequestedByType | null;
      scheduledFrom: string;
      status: ConsultationStatus;
      uuid: string;
      cashTransaction?: {
        __typename?: "CashTransaction";
        id?: number | null;
        status: CashTransactionStatusType;
      } | null;
      human?: {
        __typename?: "Human";
        id: number;
        user: {
          __typename?: "User";
          email: string;
          firstName?: string | null;
          id: string;
          lastName?: string | null;
        } & { " $fragmentRefs"?: { AvatarFragment: AvatarFragment } };
      } | null;
    };
  }>;
} & { " $fragmentName"?: "ProfessionalConsultationListFragment" };

export type RequestConsultationProfessionalQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type RequestConsultationProfessionalQuery = {
  __typename?: "Query";
  professional?: {
    __typename?: "Professional";
    id: number;
    standardDuration?: number | null;
    standardRate?: number | null;
    clients: {
      __typename?: "ProfessionalHumanConnection";
      edges: Array<{
        __typename?: "ProfessionalHumanEdge";
        node: {
          __typename?: "Human";
          canPayCoachingWithCredits: boolean;
          canPayConsultWithCredits: boolean;
          creditsLeft?: number | null;
          id: number;
          user: {
            __typename?: "User";
            email: string;
            firstName?: string | null;
            id: string;
            lastName?: string | null;
          };
        };
      }>;
    };
  } | null;
};

export type ProfessionalRequestConsultationMutationVariables = Exact<{
  input: CreateConsultationInput;
}>;

export type ProfessionalRequestConsultationMutation = {
  __typename?: "Mutation";
  createConsultation?: {
    __typename?: "Consultation";
    id: number;
    uuid: string;
  } | null;
};

export type RescheduleConsultationDetailQueryVariables = Exact<{
  uuid: Scalars["String"]["input"];
}>;

export type RescheduleConsultationDetailQuery = {
  __typename?: "Query";
  consultation?: {
    __typename?: "Consultation";
    duration: string;
    id: number;
    scheduledFrom: string;
    scheduledTo: string;
    type?: ConsultationType | null;
    cashTransaction?: {
      __typename?: "CashTransaction";
      amount: number;
      id?: number | null;
      status: CashTransactionStatusType;
    } | null;
    creditTransactions: Array<{ __typename?: "CreditTransaction"; id: number }>;
    human?: {
      __typename?: "Human";
      canPayWithCredits: boolean;
      creditsLeft?: number | null;
      id: number;
    } | null;
  } | null;
};

export type ProfessionalRescheduleConsultationMutationVariables = Exact<{
  input: RescheduleConsultationInput;
}>;

export type ProfessionalRescheduleConsultationMutation = {
  __typename?: "Mutation";
  rescheduleConsultation: {
    __typename?: "Consultation";
    acceptedByProfessional?: boolean | null;
    duration: string;
    id: number;
    scheduledFrom: string;
    scheduledTo: string;
    status: ConsultationStatus;
    cashTransaction?: {
      __typename?: "CashTransaction";
      amount: number;
      id?: number | null;
    } | null;
    message?: {
      __typename?: "Message";
      body?: string | null;
      id?: number | null;
    } | null;
  };
};

export type ProfessionalCompletedConsultationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ProfessionalCompletedConsultationsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: string;
    professional?: { __typename?: "Professional"; id: number } | null;
  } | null;
  myProfessionalConsultations: {
    __typename?: "ConsultationsConnection";
    edges: Array<{ __typename?: "ConsultationEdge"; cursor?: string | null }>;
    pageInfo?: {
      __typename?: "PageInfo";
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    } | null;
  } & {
    " $fragmentRefs"?: {
      ProfessionalConsultationListFragment: ProfessionalConsultationListFragment;
    };
  };
};

export type ProfessionalConsultationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ProfessionalConsultationsQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename?: "User";
    id: string;
    professional?: { __typename?: "Professional"; id: number } | null;
  } | null;
  PlannedConsultations: {
    __typename?: "ConsultationsConnection";
    edges: Array<{ __typename?: "ConsultationEdge"; cursor?: string | null }>;
    pageInfo?: {
      __typename?: "PageInfo";
      endCursor?: string | null;
      hasNextPage?: boolean | null;
    } | null;
  } & {
    " $fragmentRefs"?: {
      ProfessionalConsultationListFragment: ProfessionalConsultationListFragment;
    };
  };
  RequestedConsultations: { __typename?: "ConsultationsConnection" } & {
    " $fragmentRefs"?: {
      ProfessionalConsultationListFragment: ProfessionalConsultationListFragment;
    };
  };
};

export type ProfessionalRate_ProfessionalFragment = {
  __typename?: "Professional";
  id: number;
  standardDuration?: number | null;
  standardRate?: number | null;
} & { " $fragmentName"?: "ProfessionalRate_ProfessionalFragment" };

export type ProfessionalRate_HumanFragment = {
  __typename?: "Human";
  canPayWithCredits: boolean;
  healthInsurance?: string | null;
  id: number;
  organization?: {
    __typename?: "Organization";
    creditsPerHuman?: number | null;
    id: number;
    slug: string;
  } | null;
} & { " $fragmentName"?: "ProfessionalRate_HumanFragment" };

export type ViewOnlyProfileInfoFragment = ({
  __typename?: "User";
  birthDate?: string | null;
  email: string;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  phone?: string | null;
  preferredLanguage: Language;
  timezone: string;
} & { " $fragmentRefs"?: { AvatarFragment: AvatarFragment } }) & {
  " $fragmentName"?: "ViewOnlyProfileInfoFragment";
};

export type AvatarFragment = {
  __typename?: "User";
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  professional?: {
    __typename?: "Professional";
    gender?: string | null;
    id: number;
    avatar?: { __typename?: "Avatar"; url?: string | null } | null;
  } | null;
} & { " $fragmentName"?: "AvatarFragment" };

export type LanguageDropdownUpdateMutationVariables = Exact<{
  language: Language;
}>;

export type LanguageDropdownUpdateMutation = {
  __typename?: "Mutation";
  setCurrentUserLanguage: {
    __typename?: "User";
    id: string;
    preferredLanguage: Language;
  };
};

export type VideoCallPartnerBoxQueryVariables = Exact<{
  organizationId: Scalars["Int"]["input"];
}>;

export type VideoCallPartnerBoxQuery = {
  __typename?: "Query";
  organizationById?:
    | ({
        __typename?: "Organization";
        id: number;
        name: { __typename?: "MultiLanguageString" } & {
          " $fragmentRefs"?: {
            UseSelectTranslationStringInputFragment: UseSelectTranslationStringInputFragment;
          };
        };
      } & {
        " $fragmentRefs"?: {
          OrgProfileDialogOrganizationFragment: OrgProfileDialogOrganizationFragment;
        };
      })
    | null;
};

export type SelfConnectedToCallMutationVariables = Exact<{
  consultationId: Scalars["Int"]["input"];
}>;

export type SelfConnectedToCallMutation = {
  __typename?: "Mutation";
  userJoinedConsultation: {
    __typename?: "Consultation";
    id: number;
    status: ConsultationStatus;
  };
};

export const FullCalendarEventFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FullCalendarEvent" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FullCalendarEvent" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "allDay" } },
          { kind: "Field", name: { kind: "Name", value: "end" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "extendedProps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "start" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FullCalendarEventFragment, unknown>;
export const HumanFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HumanFragment, unknown>;
export const UseSelectTranslationStringInputFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseSelectTranslationStringInputFragment, unknown>;
export const OrganizationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrganizationFragment, unknown>;
export const HumanWithOrganizationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HumanWithOrganizationFragment, unknown>;
export const ClientForProfessionalFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClientForProfessional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Client" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientForProfessionalFragment, unknown>;
export const RestrictedClientForProfessionalFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RestrictedClientForProfessional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "RestrictedClient" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RestrictedClientForProfessionalFragment, unknown>;
export const ClientFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Client" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Client" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "canPayWithCredits" },
                },
                { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientFragment, unknown>;
export const CashTransactionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CashTransactionFragment, unknown>;
export const MessageFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageFragment, unknown>;
export const CommonConsultationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CommonConsultationFragment, unknown>;
export const HumanForConsultationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HumanForConsultationFragment, unknown>;
export const ProfessionalForConsultationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfessionalForConsultationFragment, unknown>;
export const ConsultationForCallFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ConsultationForCall" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CommonConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanForConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalForConsultation" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConsultationForCallFragment, unknown>;
export const ConsultationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Consultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CommonConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanForConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalForConsultation" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConsultationFragment, unknown>;
export const ConsultationWithPaymentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ConsultationWithPayment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CommonConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalForConsultation" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConsultationWithPaymentFragment, unknown>;
export const HumanRegistrationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanRegistration" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HumanRegistrationFragment, unknown>;
export const UseSelectTranslationBooleanInputFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationBooleanInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageBoolean" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UseSelectTranslationBooleanInputFragment, unknown>;
export const MatchedHumanFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MatchedHuman" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MatchedHuman" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chosenProfessionalId" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "preferredProfessionalGender" },
          },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "userUuid" } },
          { kind: "Field", name: { kind: "Name", value: "worries" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MatchedHumanFragment, unknown>;
export const SubCategoryFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubCategoryFragment, unknown>;
export const SpecialtyFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SpecialtyFragment, unknown>;
export const ProfessionalFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Professional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "clientStop" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "forBgt" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isFirstLine" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          { kind: "Field", name: { kind: "Name", value: "roleType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "supervision" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "verifiedAt" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfessionalFragment, unknown>;
export const AddressFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddressFragment, unknown>;
export const BillingInformationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BillingInformation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BillingInformation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accountNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "taxId" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BillingInformationFragment, unknown>;
export const PracticeFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Practice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Practice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "chatTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "onlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "phoneTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PracticeFragment, unknown>;
export const ProfessionalExtraFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalExtra" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "billingInformation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "BillingInformation" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "cmNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "practice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Practice" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "visumNumber" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BillingInformation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BillingInformation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accountNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "taxId" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Practice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Practice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "chatTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "onlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "phoneTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfessionalExtraFragment, unknown>;
export const ProfessionalFullFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalFull" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "licenseFiles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "collectionName" },
                },
                { kind: "Field", name: { kind: "Name", value: "fileName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "mimeType" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Professional" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalExtra" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BillingInformation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BillingInformation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accountNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "taxId" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Practice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Practice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "chatTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "onlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "phoneTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Professional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "clientStop" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "forBgt" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isFirstLine" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          { kind: "Field", name: { kind: "Name", value: "roleType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "supervision" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "verifiedAt" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalExtra" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "billingInformation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "BillingInformation" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "cmNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "practice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Practice" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "visumNumber" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfessionalFullFragment, unknown>;
export const ProfessionalInfoForHumanFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalInfoForHuman" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "availability" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "practice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Practice" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredLanguage" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Practice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Practice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "chatTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "onlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "phoneTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfessionalInfoForHumanFragment, unknown>;
export const QualityQuestionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QualityQuestion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Question" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "component" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "nextQuestionId" } },
          { kind: "Field", name: { kind: "Name", value: "options" } },
          { kind: "Field", name: { kind: "Name", value: "questionType" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QualityQuestionFragment, unknown>;
export const ReimbursementSubmissionFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReimbursementSubmission" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReimbursementSubmission" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "submittedAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReimbursementSubmissionFragment, unknown>;
export const ReimbursementFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Reimbursement" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Reimbursement" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "nationalId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reimbursementSubmission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ReimbursementSubmission" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReimbursementSubmission" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReimbursementSubmission" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "submittedAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ReimbursementFragment, unknown>;
export const VideoCallFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VideoCall" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "VideoCall" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "attendeeInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "AttendeeId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ExternalUserId" },
                },
                { kind: "Field", name: { kind: "Name", value: "JoinToken" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meetingInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ExternalMeetingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "MediaPlacement" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "AudioFallbackUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "AudioHostUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ScreenDataUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ScreenSharingUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ScreenViewingUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "SignalingUrl" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "MediaRegion" } },
                { kind: "Field", name: { kind: "Name", value: "MeetingId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VideoCallFragment, unknown>;
export const OrgProfileDialogOrganizationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrgProfileDialogOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrgProfileDialogOrganizationFragment, unknown>;
export const AvatarFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Avatar" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AvatarFragment, unknown>;
export const ColleagueFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Colleague" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subcategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "translationKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Avatar" },
                },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Avatar" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColleagueFragment, unknown>;
export const ColleagueNodeFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ColleagueNode" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Colleague" },
          },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Avatar" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Colleague" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subcategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "translationKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Avatar" },
                },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColleagueNodeFragment, unknown>;
export const ViewOnlyProfessionalProfileFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ViewOnlyProfessionalProfile" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "practice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "profileDescription" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "secondaryColor" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subcategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tooltip" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "translationKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewOnlyProfessionalProfileFragment, unknown>;
export const ProfessionalConsultationListFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalConsultationList" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ConsultationsConnection" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "edges" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "node" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cashTransaction" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "human" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "Avatar" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestedBy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledFrom" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Avatar" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfessionalConsultationListFragment, unknown>;
export const ProfessionalRate_ProfessionalFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalRate_professional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfessionalRate_ProfessionalFragment, unknown>;
export const ProfessionalRate_HumanFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalRate_human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditsPerHuman" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfessionalRate_HumanFragment, unknown>;
export const ViewOnlyProfileInfoFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ViewOnlyProfileInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "FragmentSpread", name: { kind: "Name", value: "Avatar" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Avatar" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ViewOnlyProfileInfoFragment, unknown>;
export const ResetPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "resetPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const CreateFullCalendarEventDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createFullCalendarEvent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CalendarEventInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCalendarEvent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FullCalendarEvent" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FullCalendarEvent" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FullCalendarEvent" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "allDay" } },
          { kind: "Field", name: { kind: "Name", value: "end" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "extendedProps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "start" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFullCalendarEventMutation,
  CreateFullCalendarEventMutationVariables
>;
export const UpdateCalendarEventDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateCalendarEvent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateCalendarEventInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCalendarEvent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FullCalendarEvent" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FullCalendarEvent" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FullCalendarEvent" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "allDay" } },
          { kind: "Field", name: { kind: "Name", value: "end" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "extendedProps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "start" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables
>;
export const CopyCalendarEventsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "copyCalendarEvents" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CopyCalendarEventsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "copyCalendarEvents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FullCalendarEvent" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FullCalendarEvent" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FullCalendarEvent" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "allDay" } },
          { kind: "Field", name: { kind: "Name", value: "end" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "extendedProps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "start" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CopyCalendarEventsMutation,
  CopyCalendarEventsMutationVariables
>;
export const DeleteCalendarEventsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteCalendarEvents" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteCalendarEventsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteCalendarEvents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCalendarEventsMutation,
  DeleteCalendarEventsMutationVariables
>;
export const GetFullCalendarEventsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getFullCalendarEvents" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DateTime" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DateTime" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "calendarEvents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "asFullCalendar" },
                value: { kind: "BooleanValue", value: true },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startDate" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FullCalendarEvent" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FullCalendarEvent" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FullCalendarEvent" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "allDay" } },
          { kind: "Field", name: { kind: "Name", value: "end" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "extendedProps" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "start" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetFullCalendarEventsQuery,
  GetFullCalendarEventsQueryVariables
>;
export const GetCashLastTransactionByConsultationIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCashLastTransactionByConsultationId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "consultationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "lastCashTransactionByConsultationId",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "consultationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "consultationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCashLastTransactionByConsultationIdQuery,
  GetCashLastTransactionByConsultationIdQueryVariables
>;
export const GetCashTransactionsByConsultationIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getCashTransactionsByConsultationId" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "consultationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransactionsByConsultationId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "consultationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "consultationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCashTransactionsByConsultationIdQuery,
  GetCashTransactionsByConsultationIdQueryVariables
>;
export const InviteClientDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "inviteClient" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AddClientInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "inviteClient" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InviteClientMutation,
  InviteClientMutationVariables
>;
export const AcceptInviteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "acceptInvite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AcceptInviteInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptInvite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Client" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Client" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Client" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "canPayWithCredits" },
                },
                { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>;
export const ClientsForProfessionalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "clientsForProfessional" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "clientsForProfessional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ClientForProfessional",
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "RestrictedClientForProfessional",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ClientForProfessional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Client" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RestrictedClientForProfessional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "RestrictedClient" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientsForProfessionalQuery,
  ClientsForProfessionalQueryVariables
>;
export const CheckIfHumanIsAClientDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "checkIfHumanIsAClient" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "humanId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkIfHumanIsAClient" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "humanId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "humanId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckIfHumanIsAClientQuery,
  CheckIfHumanIsAClientQueryVariables
>;
export const CreateConsultationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createConsultation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateConsultationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createConsultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Consultation" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Consultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CommonConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanForConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalForConsultation" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateConsultationMutation,
  CreateConsultationMutationVariables
>;
export const ConsultationUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "consultationUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateConsultationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateConsultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Consultation" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Consultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CommonConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanForConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalForConsultation" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsultationUpdateMutation,
  ConsultationUpdateMutationVariables
>;
export const ConfirmConsultationMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "confirmConsultationMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ConfirmConsultationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmConsultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "acceptedByHuman" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "acceptedByProfessional" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmConsultationMutationMutation,
  ConfirmConsultationMutationMutationVariables
>;
export const AllHumanConsultationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "allHumanConsultations" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "allConsultationsForHuman" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ConsultationWithPayment",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ConsultationWithPayment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CommonConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalForConsultation" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllHumanConsultationsQuery,
  AllHumanConsultationsQueryVariables
>;
export const AllProfessionalConsultationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "allProfessionalConsultations" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "allConsultationsForProfessional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "Consultation" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Consultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CommonConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanForConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalForConsultation" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AllProfessionalConsultationsQuery,
  AllProfessionalConsultationsQueryVariables
>;
export const ConsultationsForProfessionalAndHumanResolverDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "consultationsForProfessionalAndHumanResolver",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "humanId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "consultationsForProfessionalAndHuman",
            },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "humanId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "humanId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "Consultation" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Consultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CommonConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanForConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalForConsultation" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsultationsForProfessionalAndHumanResolverQuery,
  ConsultationsForProfessionalAndHumanResolverQueryVariables
>;
export const FetchConsultationForCallByUuidDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "fetchConsultationForCallByUuid" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "consultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uuid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uuid" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ConsultationForCall" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanWithOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
          { kind: "FragmentSpread", name: { kind: "Name", value: "Human" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanWithOrganization" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "humanId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ConsultationForCall" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CommonConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "HumanForConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalForConsultation" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchConsultationForCallByUuidQuery,
  FetchConsultationForCallByUuidQueryVariables
>;
export const FetchConsultationWithCashTransactionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "fetchConsultationWithCashTransaction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "consultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uuid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uuid" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ConsultationWithPayment" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CashTransaction" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "CashTransaction" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Message" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Message" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "body" } },
          { kind: "Field", name: { kind: "Name", value: "fromProfessional" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CommonConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "acceptedByHuman" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "acceptedByProfessional" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "creditTransactions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amount" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "expectedReimbursementDate" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "message" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Message" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "requestedBy" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledFrom" } },
          { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "uuid" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalForConsultation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "age" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "consultationLanguages" },
                },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "profile" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "professionalId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ConsultationWithPayment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Consultation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cashTransaction" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "CashTransaction" },
                },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "CommonConsultation" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalForConsultation" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchConsultationWithCashTransactionQuery,
  FetchConsultationWithCashTransactionQueryVariables
>;
export const CompleteHumanRegistrationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "completeHumanRegistration" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "HumanInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "completeHumanRegistration" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "HumanRegistration" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanRegistration" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompleteHumanRegistrationMutation,
  CompleteHumanRegistrationMutationVariables
>;
export const RegisterHumanWithInviteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "registerHumanWithInvite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "HumanInput" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inviteToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerHumanWithInvite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "inviteToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inviteToken" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "human" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "HumanRegistration" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "HumanRegistration" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterHumanWithInviteMutation,
  RegisterHumanWithInviteMutationVariables
>;
export const UpdateHumanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateHuman" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "HumanInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateHuman" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Human" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateHumanMutation, UpdateHumanMutationVariables>;
export const HumanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "human" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Human" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "organizationId" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HumanQuery, HumanQueryVariables>;
export const UpdateMatchedHumanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateMatchedHuman" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MatchedHumanInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMatchedHuman" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MatchedHuman" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MatchedHuman" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MatchedHuman" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chosenProfessionalId" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "preferredProfessionalGender" },
          },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "userUuid" } },
          { kind: "Field", name: { kind: "Name", value: "worries" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatchedHumanMutation,
  UpdateMatchedHumanMutationVariables
>;
export const UpdateMatchedHumanWorriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateMatchedHumanWorries" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MatchedHumanWorriesInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateMatchedHumanWorries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "MatchedHuman" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MatchedHuman" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MatchedHuman" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "age" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "chosenProfessionalId" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "preferredProfessionalGender" },
          },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "userUuid" } },
          { kind: "Field", name: { kind: "Name", value: "worries" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateMatchedHumanWorriesMutation,
  UpdateMatchedHumanWorriesMutationVariables
>;
export const CreatePermanentHumanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createPermanentHuman" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PermanentHumanInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createPermanentHuman" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePermanentHumanMutation,
  CreatePermanentHumanMutationVariables
>;
export const StartMatchingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "startMatching" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "StartMatchingInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startMatching" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "matchedHumanId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "matchingToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartMatchingMutation,
  StartMatchingMutationVariables
>;
export const FindMatchesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "findMatches" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "findMatches" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "matches" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "availability" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isOnline" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "professional" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ProfessionalInfoForHuman",
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "score" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Practice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Practice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "chatTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "onlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "phoneTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalInfoForHuman" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "availability" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "practice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Practice" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredLanguage" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindMatchesQuery, FindMatchesQueryVariables>;
export const InitialDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "InitialData" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "human" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canPayWithCredits" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredLanguage" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "professional" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatar" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "url" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "timezone" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InitialDataQuery, InitialDataQueryVariables>;
export const FetchOrganizationBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "fetchOrganizationBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationBySlug" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "slug" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "slug" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchOrganizationBySlugQuery,
  FetchOrganizationBySlugQueryVariables
>;
export const CreatePaymentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createPayment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PaymentInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "payForConsultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreatePaymentMutation,
  CreatePaymentMutationVariables
>;
export const RegisterProfessionalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RegisterProfessional" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ProfessionalRegistrationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerProfessional" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RegisterProfessionalMutation,
  RegisterProfessionalMutationVariables
>;
export const UpdateProfessionalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateProfessional" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ProfessionalInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProfessional" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ProfessionalFull" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Professional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "clientStop" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "forBgt" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isFirstLine" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          { kind: "Field", name: { kind: "Name", value: "roleType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "supervision" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "verifiedAt" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BillingInformation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BillingInformation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accountNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "taxId" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Practice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Practice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "chatTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "onlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "phoneTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalExtra" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "billingInformation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "BillingInformation" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "cmNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "practice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Practice" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "visumNumber" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalFull" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "licenseFiles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "collectionName" },
                },
                { kind: "Field", name: { kind: "Name", value: "fileName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "mimeType" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Professional" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalExtra" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfessionalMutation,
  UpdateProfessionalMutationVariables
>;
export const VerifyProfessionalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "verifyProfessional" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verifyProfessional" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyProfessionalMutation,
  VerifyProfessionalMutationVariables
>;
export const ProfessionalsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "professionals" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ProfessionalsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professionals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "Professional" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Professional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "clientStop" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "forBgt" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isFirstLine" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          { kind: "Field", name: { kind: "Name", value: "roleType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "supervision" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "verifiedAt" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfessionalsQuery, ProfessionalsQueryVariables>;
export const FullProfessionalProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "fullProfessionalProfile" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Professional" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ProfessionalExtra" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BillingInformation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BillingInformation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accountNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "taxId" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Practice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Practice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "chatTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "onlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "phoneTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Professional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "clientStop" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "forBgt" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isFirstLine" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          { kind: "Field", name: { kind: "Name", value: "roleType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "supervision" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "verifiedAt" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalExtra" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "billingInformation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "BillingInformation" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "cmNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "practice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Practice" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "visumNumber" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FullProfessionalProfileQuery,
  FullProfessionalProfileQueryVariables
>;
export const ProfessionalCurrentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "professionalCurrent" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Professional" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Professional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "clientStop" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "forBgt" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isFirstLine" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          { kind: "Field", name: { kind: "Name", value: "roleType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "supervision" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "verifiedAt" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfessionalCurrentQuery,
  ProfessionalCurrentQueryVariables
>;
export const ProfessionalsForHumanDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "professionalsForHuman" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ProfessionalsForCurrentHumanInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professionalsForCurrentHuman" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "ProfessionalInfoForHuman",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Practice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Practice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "chatTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "onlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "phoneTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalInfoForHuman" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "availability" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "onboardedAt" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "practice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Practice" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredLanguage" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfessionalsForHumanQuery,
  ProfessionalsForHumanQueryVariables
>;
export const ProfessionalsForAdminDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "professionalsForAdmin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ProfessionalsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professionals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ProfessionalFull" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Professional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "clientStop" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "forBgt" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isFirstLine" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          { kind: "Field", name: { kind: "Name", value: "roleType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "supervision" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "verifiedAt" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Address" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Address" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "location" } },
          { kind: "Field", name: { kind: "Name", value: "postcode" } },
          { kind: "Field", name: { kind: "Name", value: "street" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "BillingInformation" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BillingInformation" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "accountNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "taxId" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Practice" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Practice" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "address" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Address" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "chatTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "offlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "onlineTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "phoneTherapy" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalExtra" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "billingInformation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "BillingInformation" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "cmNumber" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "practice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Practice" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "visumNumber" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalFull" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "licenseFiles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "collectionName" },
                },
                { kind: "Field", name: { kind: "Name", value: "fileName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "mimeType" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Professional" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "ProfessionalExtra" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfessionalsForAdminQuery,
  ProfessionalsForAdminQueryVariables
>;
export const ProfessionalExistsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "professionalExists" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professionalExists" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfessionalExistsQuery,
  ProfessionalExistsQueryVariables
>;
export const AnswerQuestionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "answerQuestion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AnswerInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "answerQuestion" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AnswerQuestionMutation,
  AnswerQuestionMutationVariables
>;
export const GetQuestionByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getQuestionById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getQuestionById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "QualityQuestion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QualityQuestion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Question" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "component" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "nextQuestionId" } },
          { kind: "Field", name: { kind: "Name", value: "options" } },
          { kind: "Field", name: { kind: "Name", value: "questionType" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetQuestionByIdQuery,
  GetQuestionByIdQueryVariables
>;
export const QuestionForUserAtEntryPointDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "questionForUserAtEntryPoint" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "EntryPointsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "questionForUserAtEntryPoint" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "QualityQuestion" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QualityQuestion" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Question" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "component" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "nextQuestionId" } },
          { kind: "Field", name: { kind: "Name", value: "options" } },
          { kind: "Field", name: { kind: "Name", value: "questionType" } },
          { kind: "Field", name: { kind: "Name", value: "title" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  QuestionForUserAtEntryPointQuery,
  QuestionForUserAtEntryPointQueryVariables
>;
export const RefreshAccessTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RefreshAccessToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "refreshToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "refreshAccessTokenV2" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "refreshToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "refreshToken" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refreshToken" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>;
export const RequestReimbursementDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "requestReimbursement" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ReimbursementInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestReimbursement" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Reimbursement" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ReimbursementSubmission" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReimbursementSubmission" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "submittedAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Reimbursement" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Reimbursement" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "nationalId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "reimbursementSubmission" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ReimbursementSubmission" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestReimbursementMutation,
  RequestReimbursementMutationVariables
>;
export const UpdateSpecialtiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateSpecialties" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ProfessionalSpecialtiesInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProfessionalSpecialties" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Professional" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Professional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "url" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "clientStop" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "forBgt" } },
          { kind: "Field", name: { kind: "Name", value: "gender" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isFirstLine" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "profile" } },
          { kind: "Field", name: { kind: "Name", value: "roleType" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "supervision" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
          { kind: "Field", name: { kind: "Name", value: "training" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "verifiedAt" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSpecialtiesMutation,
  UpdateSpecialtiesMutationVariables
>;
export const SpecialtiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "specialties" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Specialty" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SubCategory" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SubCategory" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Specialty" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "SpecialtiesThemeCombo" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "primaryColor" } },
          { kind: "Field", name: { kind: "Name", value: "secondaryColor" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "subcategories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "SubCategory" },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "theme" } },
          { kind: "Field", name: { kind: "Name", value: "tooltip" } },
          { kind: "Field", name: { kind: "Name", value: "translationKey" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SpecialtiesQuery, SpecialtiesQueryVariables>;
export const StartVideoCallDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "startVideoCall" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "consultationUuid" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startVideoCall" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "consultationUuid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "consultationUuid" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "VideoCall" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "VideoCall" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "VideoCall" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "attendeeInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "AttendeeId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ExternalUserId" },
                },
                { kind: "Field", name: { kind: "Name", value: "JoinToken" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meetingInfo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ExternalMeetingId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "MediaPlacement" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "AudioFallbackUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "AudioHostUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ScreenDataUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ScreenSharingUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ScreenViewingUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "SignalingUrl" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "MediaRegion" } },
                { kind: "Field", name: { kind: "Name", value: "MeetingId" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartVideoCallMutation,
  StartVideoCallMutationVariables
>;
export const OrgProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrgProfile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "OrgProfileDialogOrganization" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "useSelectTranslationStringInput",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrgProfileDialogOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OrgProfileQuery, OrgProfileQueryVariables>;
export const ConsultationInfoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ConsultationInfo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "useSelectTranslationStringInput",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "OrgProfileDialogOrganization" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrgProfileDialogOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsultationInfoQuery,
  ConsultationInfoQueryVariables
>;
export const ChoosePasswordV2Document = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ChoosePasswordV2" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ChoosePasswordV2Input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "choosePasswordV2" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChoosePasswordV2Mutation,
  ChoosePasswordV2MutationVariables
>;
export const LandingPageUniqueVisitorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LandingPageUniqueVisitors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Date" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "landingPageUniqueVisitors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationID" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startDate" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "x" } },
                { kind: "Field", name: { kind: "Name", value: "y" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "videoConsultFunnel" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LandingPageUniqueVisitorsQuery,
  LandingPageUniqueVisitorsQueryVariables
>;
export const VideoConsultPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "VideoConsultPage" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "videoConsultBarchartEmployeesWithAnAmountOfConsults",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
              ],
            },
          },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "videoConsultCardAverageConsultPerEmployee",
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "videoConsultCardCancelledConsults" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "videoConsultCardCompletedConsults" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "videoConsultCardNoShowConsults" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "videoConsultFunnel" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VideoConsultPageQuery,
  VideoConsultPageQueryVariables
>;
export const WellBeingPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "WellBeingPage" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "wellbeingChallenges" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "count" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "wellbeingEnergyGauge" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageFirstScore" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageImprovement" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageLatestScore" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "wellbeingProductivityGauge" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageFirstScore" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageImprovement" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageLatestScore" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "wellbeingStressGauge" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageFirstScore" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageImprovement" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "averageLatestScore" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WellBeingPageQuery, WellBeingPageQueryVariables>;
export const CreateIntroDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createIntro" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateConsultationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createConsultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateIntroMutation, CreateIntroMutationVariables>;
export const HumanProfilePageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "humanProfilePage" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "human" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "creditsLeft" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "name" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "useSelectTranslationStringInput",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HumanProfilePageQuery,
  HumanProfilePageQueryVariables
>;
export const RequestConsultationFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RequestConsultationForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "professionalId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "human" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ProfessionalRate_human" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "professionalId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ProfessionalRate_professional",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalRate_human" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Human" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "canPayWithCredits" } },
          { kind: "Field", name: { kind: "Name", value: "healthInsurance" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditsPerHuman" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "slug" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalRate_professional" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestConsultationFormQuery,
  RequestConsultationFormQueryVariables
>;
export const LoginDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Login" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LoginV2Input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loginV2" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "TokenResponse" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessToken" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "refreshToken" },
                      },
                    ],
                  },
                },
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "LoginV2FailureResponse" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reason" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const SsoUrlDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SsoUrl" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "redirectUrl" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSSOUrl" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "redirectUrl" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "redirectUrl" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SsoUrlQuery, SsoUrlQueryVariables>;
export const LoginWithSsoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LoginWithSso" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LoginWithSSOInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "loginWithSSO" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "InlineFragment",
                  typeCondition: {
                    kind: "NamedType",
                    name: { kind: "Name", value: "TokenResponse" },
                  },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "accessToken" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mindlabToken" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "refreshToken" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginWithSsoMutation,
  LoginWithSsoMutationVariables
>;
export const DashboardsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Dashboards" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adminOfOrganization" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "contractDashboardUrl" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "useSelectTranslationStringInput",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ggtDashboardUrl" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "useSelectTranslationStringInput",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ggtEnabled" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "interestDashboardUrl" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "useSelectTranslationStringInput",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "mentalWellbeingDashboardUrl",
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "useSelectTranslationStringInput",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usageDashboardUrl" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "useSelectTranslationStringInput",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "videoEnabled" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DashboardsQuery, DashboardsQueryVariables>;
export const OrganisationLandingPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OrganisationLandingPage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "emailOrCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationByEmailOrCode" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "emailOrCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "emailOrCode" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OrganisationLandingPageQuery,
  OrganisationLandingPageQueryVariables
>;
export const GgtLandingPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GgtLandingPage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "language" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Language" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "assessments" },
            name: { kind: "Name", value: "availableTracks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "language" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "language" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "EnumValue", value: "assessment" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "title" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "useSelectTranslationStringInput",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "exercises" },
            name: { kind: "Name", value: "availableTracks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "language" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "language" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "EnumValue", value: "exercise" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "title" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "useSelectTranslationStringInput",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "growthTracks" },
            name: { kind: "Name", value: "availableTracks" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "language" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "language" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "type" },
                value: { kind: "EnumValue", value: "growthTrack" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "title" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "useSelectTranslationStringInput",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GgtLandingPageQuery, GgtLandingPageQueryVariables>;
export const VerifyOrganizationPageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "VerifyOrganizationPage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "emailOrCode" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationByEmailOrCode" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "emailOrCode" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "emailOrCode" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Organization" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Organization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "color" } },
          { kind: "Field", name: { kind: "Name", value: "creditsPerHuman" } },
          { kind: "Field", name: { kind: "Name", value: "emailRegex" } },
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "logoUrl" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "mindlabBusinessId" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          { kind: "Field", name: { kind: "Name", value: "ssoEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyOrganizationPageQuery,
  VerifyOrganizationPageQueryVariables
>;
export const CountriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Countries" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "regions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "translationKey" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "translationKey" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountriesQuery, CountriesQueryVariables>;
export const SubmitLocationStepDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitLocationStep" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "SubmitLocationStepInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitLocationStep" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "matchingToken" },
                },
                { kind: "Field", name: { kind: "Name", value: "nextStep" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitLocationStepMutation,
  SubmitLocationStepMutationVariables
>;
export const MutualitiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Mutualities" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "mutualities" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "otherMutualities" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "logoUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "preferred" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "translationKey" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "regionMutualities" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "logoUrl" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "preferred" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "translationKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MutualitiesQuery, MutualitiesQueryVariables>;
export const SubmitMutualityStepDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitMutualityStep" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "mutualityId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitMutualityStep" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "mutualityId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "mutualityId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "matchingToken" },
                },
                { kind: "Field", name: { kind: "Name", value: "nextStep" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitMutualityStepMutation,
  SubmitMutualityStepMutationVariables
>;
export const StartMatchingV2Document = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "StartMatchingV2" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "StartMatchingV2Input" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "startMatchingV2" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "matchingToken" },
                },
                { kind: "Field", name: { kind: "Name", value: "nextStep" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StartMatchingV2Mutation,
  StartMatchingV2MutationVariables
>;
export const ProfCallSidepaneDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfCallSidepane" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfCallSidepaneQuery,
  ProfCallSidepaneQueryVariables
>;
export const SendAppInviteDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SendAppInvite" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "humanId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendAppInvite" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "humanId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "humanId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendAppInviteMutation,
  SendAppInviteMutationVariables
>;
export const ColleagueDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "colleague" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "ViewOnlyProfileInfo" },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ViewOnlyProfessionalProfile" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Avatar" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ViewOnlyProfileInfo" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "FragmentSpread", name: { kind: "Name", value: "Avatar" } },
          { kind: "Field", name: { kind: "Name", value: "birthDate" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          { kind: "Field", name: { kind: "Name", value: "phone" } },
          { kind: "Field", name: { kind: "Name", value: "preferredLanguage" } },
          { kind: "Field", name: { kind: "Name", value: "timezone" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ViewOnlyProfessionalProfile" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "consultationLanguages" },
          },
          { kind: "Field", name: { kind: "Name", value: "experienceSince" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "practice" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "address" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "postcode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "street" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "profileDescription" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "secondaryColor" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subcategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "tooltip" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "translationKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "standardDuration" } },
          { kind: "Field", name: { kind: "Name", value: "standardRate" } },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          { kind: "Field", name: { kind: "Name", value: "website" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColleagueQuery, ColleagueQueryVariables>;
export const ColleaguesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "colleagues" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ProfessionalsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professionals" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ColleagueNode" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Avatar" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Colleague" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "specialties" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "subcategories" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "translationKey" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "therapyTypes" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "Avatar" },
                },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ColleagueNode" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Professional" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "Colleague" },
          },
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ColleaguesQuery, ColleaguesQueryVariables>;
export const ClientConsultationDetailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ClientConsultationDetail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "consultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uuid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uuid" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "acceptedByHuman" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "acceptedByProfessional" },
                },
                { kind: "Field", name: { kind: "Name", value: "bloomupFee" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cashTransaction" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditTransactions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "duration" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "human" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canPayWithCredits" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creditsLeft" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organization" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "useSelectTranslationStringInput",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "OrgProfileDialogOrganization",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "message" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "professionalFee" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledFrom" },
                },
                { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrgProfileDialogOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ClientConsultationDetailQuery,
  ClientConsultationDetailQueryVariables
>;
export const ProfessionalCancelConsultationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfessionalCancelConsultation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "consultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uuid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uuid" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfessionalCancelConsultationQuery,
  ProfessionalCancelConsultationQueryVariables
>;
export const ProfessionalCancelConsultationMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProfessionalCancelConsultationMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CancelConsultationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cancelConsultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "message" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfessionalCancelConsultationMutationMutation,
  ProfessionalCancelConsultationMutationMutationVariables
>;
export const ConsultationDetailActionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ConsultationDetailActions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "consultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uuid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uuid" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "acceptedByHuman" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "acceptedByProfessional" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "human" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organization" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "useSelectTranslationStringInput",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "OrgProfileDialogOrganization",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "profile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: {
                                      kind: "Name",
                                      value: "useSelectTranslationStringInput",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrgProfileDialogOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConsultationDetailActionsQuery,
  ConsultationDetailActionsQueryVariables
>;
export const ConfirmConsultationMutationProfessionalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ConfirmConsultationMutationProfessional" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "ConfirmConsultationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmConsultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "acceptedByHuman" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "acceptedByProfessional" },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ConfirmConsultationMutationProfessionalMutation,
  ConfirmConsultationMutationProfessionalMutationVariables
>;
export const RequestConsultationProfessionalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RequestConsultationProfessional" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clients" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canPayCoachingWithCredits",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "canPayConsultWithCredits",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "creditsLeft",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "firstName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "lastName",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardDuration" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "standardRate" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RequestConsultationProfessionalQuery,
  RequestConsultationProfessionalQueryVariables
>;
export const ProfessionalRequestConsultationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProfessionalRequestConsultation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateConsultationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createConsultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "uuid" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfessionalRequestConsultationMutation,
  ProfessionalRequestConsultationMutationVariables
>;
export const RescheduleConsultationDetailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RescheduleConsultationDetail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "uuid" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "consultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "uuid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "uuid" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cashTransaction" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creditTransactions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "duration" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "human" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "canPayWithCredits" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "creditsLeft" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledFrom" },
                },
                { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RescheduleConsultationDetailQuery,
  RescheduleConsultationDetailQueryVariables
>;
export const ProfessionalRescheduleConsultationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ProfessionalRescheduleConsultation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "RescheduleConsultationInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "rescheduleConsultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "acceptedByProfessional" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "cashTransaction" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "duration" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "message" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "body" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduledFrom" },
                },
                { kind: "Field", name: { kind: "Name", value: "scheduledTo" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfessionalRescheduleConsultationMutation,
  ProfessionalRescheduleConsultationMutationVariables
>;
export const ProfessionalCompletedConsultationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfessionalCompletedConsultations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "professional" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "myProfessionalConsultations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "DONE" },
                          { kind: "EnumValue", value: "CANCELLED" },
                          { kind: "EnumValue", value: "CANCELLED_BY_HUMAN" },
                          {
                            kind: "EnumValue",
                            value: "CANCELLED_BY_PROFESSIONAL",
                          },
                          { kind: "EnumValue", value: "NO_SHOW_HUMAN" },
                          { kind: "EnumValue", value: "NO_SHOW_PROFESSIONAL" },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "20" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endCursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ProfessionalConsultationList" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Avatar" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalConsultationList" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ConsultationsConnection" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "edges" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "node" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cashTransaction" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "human" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "Avatar" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestedBy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledFrom" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfessionalCompletedConsultationsQuery,
  ProfessionalCompletedConsultationsQueryVariables
>;
export const ProfessionalConsultationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ProfessionalConsultations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "currentUser" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "professional" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "PlannedConsultations" },
            name: { kind: "Name", value: "myProfessionalConsultations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "ACCEPTED" },
                          { kind: "EnumValue", value: "IN_PROGRESS" },
                          { kind: "EnumValue", value: "WAITING" },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "20" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scheduledFrom" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cursor" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endCursor" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hasNextPage" },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ProfessionalConsultationList" },
                },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "RequestedConsultations" },
            name: { kind: "Name", value: "myProfessionalConsultations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "REQUESTED" }],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "200" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "order" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scheduledFrom" },
                      value: { kind: "EnumValue", value: "asc" },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ProfessionalConsultationList" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Avatar" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "User" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "firstName" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "lastName" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "professional" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "url" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProfessionalConsultationList" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ConsultationsConnection" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "edges" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "node" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "cashTransaction" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "human" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "user" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "FragmentSpread",
                                    name: { kind: "Name", value: "Avatar" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "firstName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lastName" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestedBy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "scheduledFrom" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "uuid" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfessionalConsultationsQuery,
  ProfessionalConsultationsQueryVariables
>;
export const LanguageDropdownUpdateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LanguageDropdownUpdate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "language" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Language" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "setCurrentUserLanguage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "language" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "language" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "preferredLanguage" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LanguageDropdownUpdateMutation,
  LanguageDropdownUpdateMutationVariables
>;
export const VideoCallPartnerBoxDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "VideoCallPartnerBox" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "name" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "useSelectTranslationStringInput",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "OrgProfileDialogOrganization" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "useSelectTranslationStringInput" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "MultiLanguageString" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "en" } },
          { kind: "Field", name: { kind: "Name", value: "fr" } },
          { kind: "Field", name: { kind: "Name", value: "nl" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrgProfileDialogOrganization" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "ggtEnabled" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "mindlabEnabled" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "name" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "useSelectTranslationStringInput",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "videoEnabled" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VideoCallPartnerBoxQuery,
  VideoCallPartnerBoxQueryVariables
>;
export const SelfConnectedToCallDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SelfConnectedToCall" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "consultationId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userJoinedConsultation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "consultationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "consultationId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SelfConnectedToCallMutation,
  SelfConnectedToCallMutationVariables
>;
