/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import HumanMatchingContext from "../context/HumanMatchingContext";
import useTrackPageView from "../../../providers/matomo/useTrackPageView";
import useSetMatchingStep from "../hooks/useSetMatchingStep";
import {
  getStepByNumber,
  SEARCH_MATCH_STEP,
} from "../context/humanMatchingSteps";
import { I18Namespaces } from "../../../language/I18Namespaces";
import MatchingAnimation from "./matching/MatchingAnimation";

export default function SearchMatchStep() {
  const { step, totalSteps, next } = useContext(HumanMatchingContext);

  useTrackPageView("Human Onboarding > Search Match");
  useSetMatchingStep(SEARCH_MATCH_STEP);
  const { t: translate } = useTranslation<I18Namespaces>(["human"]);

  useEffect(() => {
    const timeout: NodeJS.Timeout = setTimeout(() => {
      const step = getStepByNumber(SEARCH_MATCH_STEP);

      if (step) {
        next(step);
      } else {
        next();
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <OnboardingContainer marginBottom={6}>
      <OnboardingStepContainer
        fullCopyright
        headline={translate("human:onboarding.title")}
        showLanguageSelector={false}
        showSpecialConditionsForTherapist={false}
        step={step.step}
        totalSteps={totalSteps}
      >
        <MatchingAnimation />
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
