import { Route, Routes } from "react-router-dom";
import HumanCallPageWithState from "../pages/human-call/HumanCallPageWithState";
import HumanWaitingRoomPageWithState from "../pages/human-waiting-room/HumanWaitingRoomPageWithState";
import HumanGuard from "./guards/HumanGuard";
import { RouteNames } from "./routeNames";

export default function HumanVideoCallRoutes() {
  return (
    <HumanGuard>
      <Routes>
        <Route
          element={<HumanCallPageWithState />}
          path={`${RouteNames.Call.Enter.name}/*`}
        />
        <Route
          element={<HumanWaitingRoomPageWithState />}
          path={RouteNames.Call.WaitingRoom.name}
        />
      </Routes>
    </HumanGuard>
  );
}
