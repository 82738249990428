import { useMutation } from "@apollo/client";
import { useLayoutEffect, useRef } from "react";
import { MUTATION_UPDATE_MATCHED_HUMAN } from "../mutations";
import { APIMatchedHuman } from "../matchedHumans";

const useUpdateMatchedHuman = () => {
  const [updateMatchedHumanFunction, { data, loading, error }] = useMutation<
    APIMatchedHuman.Update.Result,
    APIMatchedHuman.Update.APIInput
  >(MUTATION_UPDATE_MATCHED_HUMAN);

  const mounted = useRef(false);

  useLayoutEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const updateMatchedHuman = (input: APIMatchedHuman.Update.Input) => {
    if (mounted.current) {
      return updateMatchedHumanFunction({
        variables: { input },
      });
    }
  };

  return {
    error,
    loading,
    matchedHuman: data?.updateMatchedHuman,
    updateMatchedHuman,
  };
};

export default useUpdateMatchedHuman;
