/* eslint-disable react-hooks/exhaustive-deps */

import Box from "@mui/material/Box";
import moment from "moment";
import { ReactNode, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LoadingPage from "../../layout/LoadingPage";
import { IAuthContext } from "../../providers/auth/auth";
import AuthContext from "../../providers/auth/AuthContext";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { prepareOneLanguageForAPI } from "../../language/languagesUtil";
import InlineEditableAddress from "../../ui/edit/InlineEditableAddress";
import InlineEditableDate from "../../ui/edit/InlineEditableDate";
import InlineEditableField from "../../ui/edit/InlineEditableField";
import InlineEditableMultiGroupSelect from "../../ui/edit/InlineEditableMultiGroupSelect";
import InlineEditableMultiSelect from "../../ui/edit/InlineEditableMultiSelect";
import InlineEditableProfessionalOther from "../../ui/edit/InlineEditableProfessionalOther";
import InlineEditableRate from "../../ui/edit/InlineEditableRate";
// import { getTitleAndPlaceholderForDescriptionField } from "./util/profileHelpers";
import { APIAddress } from "../../../api/addresses/addresses";
import CMNumber from "../../../data-model/types/professional/CMNumber";
import ConsultationLanguages from "../../../data-model/types/professional/ConsultationLanguages";
import Description, {
  DESCRIPTION_PREFIX,
} from "../../../data-model/types/professional/Description";
import Experience from "../../../data-model/types/professional/Experience";
import Specialties from "../../../data-model/types/professional/Specialties";
import Therapies from "../../../data-model/types/professional/Therapies";
import VisumNumber from "../../../data-model/types/professional/VisumNumber";
import Professional from "../../../data-model/types/professional/Professional";
import Theme from "../../../data-model/types/professional/Theme";
import EditableDescriptionsMultipleLanguages from "./EditableDescriptionsMultipleLanguages";

interface IProfessionalProfile {
  forClient?: boolean;
  forColleague?: boolean;
  formState?: any;
  onSave: () => Promise<void>;
  onSaveSpecialties: () => Promise<void>;
  professional: Professional;
  //TODO: change this any
  updateProfessionalDescriptions: (input: any) => Promise<void>;
  updateProfessionalPractice: (address: APIAddress.Input) => Promise<void>;
}

export default function EditableProfessionalProfile({
  professional,
  formState,
  onSave,
  onSaveSpecialties,
  updateProfessionalPractice,
  updateProfessionalDescriptions,
  forClient = false,
  forColleague = false,
}: IProfessionalProfile) {
  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Glossary,
    BloomUpNamespacesEnum.Ui,
  ]);

  const getPreferredLanguageDescriptionField = useMemo((): ReactNode => {
    if (!professional.getBio()) return null;

    if (currentUser) {
      const prefLanguage = prepareOneLanguageForAPI(
        currentUser.getPreferredLanguage().getValue(),
      );

      let description: Description | null = professional
        .getBio()
        .getDescriptionByLanguage(prefLanguage);

      if (description === null) description = new Description("");

      /* TODO: Add ValueWithLabel or other ways of showing data that is not editable, as this component also gets used when showing colleagues, which should not be editable!*/
      return (
        <Box mt={3}>
          <InlineEditableField<Description>
            dataObject={description}
            formState={formState}
            label={t(`professional:profile.aboutme-${prefLanguage}`)}
            multiline
            onSave={onSave}
            pathOverride={`${DESCRIPTION_PREFIX}-${prefLanguage}`}
            placeholder={t("professional:profile.description.placeholder")}
          />
        </Box>
      );
    }
  }, [professional, onSave]);

  /* TODO: Add ValueWithLabel or other ways of showing data that is not editable, as this component also gets used when showing colleagues, which should not be editable!*/
  if (!professional) return <LoadingPage logText="Professional Profile" />;

  return (
    <Box pb={5}>
      <Box>
        {
          forClient ? (
            getPreferredLanguageDescriptionField
          ) : (
            <EditableDescriptionsMultipleLanguages
              onSave={updateProfessionalDescriptions}
              professional={professional}
            />
          ) /* getMultipleDescriptionFields */
        }
      </Box>
      <Box mt={3}>
        <InlineEditableMultiGroupSelect<Theme, Specialties>
          dataObject={professional.getSpecialties()}
          formState={formState}
          label={t("professional:profile.specialties")}
          menuWithTooltip={true}
          onSave={onSaveSpecialties}
          placeholderText={t("professional:onboarding.specialities.label")}
        />
      </Box>
      <Box mb={3} mt={3}>
        <InlineEditableMultiSelect<Therapies>
          dataObject={professional.getTherapies()}
          formState={formState}
          label={t("professional:profile.therapytypes")}
          onSave={onSave}
          placeholderText={t("professional:onboarding.therapytype.placeholder")}
        />
      </Box>
      <Box mt={3}>
        <InlineEditableMultiSelect<ConsultationLanguages>
          cancelBtnTestId="test_profile_consultation_language_select_cancel"
          chipListTestId="test_profile_consultation_language_chiplist"
          dataObject={professional.getConsultationLanguages()}
          editBtnTestId="test_profile_consultation_language_select_edit"
          formState={formState}
          label={t("professional:profile.consultation.languages")}
          labelTestId="test_profile_consultation_language_label"
          onSave={onSave}
          placeholderText={t(
            "professional:profile.consultation.languages.label",
          )}
          saveBtnTestId="test_profile_consultation_language_select_save"
          selectTestId="test_profile_consultation_language_select"
        />
      </Box>
      <Box mt={3}>
        <InlineEditableRate
          durationDataObject={professional.getConsultationDuration()}
          formState={formState}
          label={t("professional:profile.rate")}
          onSave={onSave}
          rateDataObject={professional.getConsultationRate()}
        />
      </Box>
      <Box mt={3}>
        <InlineEditableDate<Experience>
          dataObject={professional.getExperience()}
          formState={formState}
          format="MM/YYYY"
          fullWidth
          label={t("common:experience.label")}
          maxDate={moment()}
          minDate={moment().subtract(100, "year")}
          onSave={onSave}
          openTo="year"
          placeholder={t("ui:experience.placeholder") ?? undefined}
          views={["year", "month"]}
        />
      </Box>
      {!forColleague && !forClient && (
        <>
          <Box mt={3}>
            <InlineEditableField<CMNumber>
              dataObject={professional.getCMNumber()}
              formState={formState}
              label={t("ui:cmnumber.optional")}
              onSave={onSave}
              placeholder={t(
                "professional:onboarding.visumandcmnumber.cm.placeholder",
              )}
            />
          </Box>
          <Box mt={3}>
            <InlineEditableField<VisumNumber>
              dataObject={professional.getVisumNumber()}
              formState={formState}
              label={t("ui:visumnumber.optional")}
              onSave={onSave}
              placeholder={t(
                "professional:onboarding.visumandcmnumber.visumnumber.placeholder",
              )}
            />
          </Box>
        </>
      )}
      <Box mt={3}>
        <InlineEditableAddress
          formState={formState}
          initialAddress={professional.getPractice().getAddress()}
          label={t("professional:profile.proaddress")}
          onSave={updateProfessionalPractice}
        />
      </Box>
      <Box mt={3}>
        <InlineEditableField
          dataObject={professional.getWebsite()}
          formState={formState}
          label={t("ui:website.optional")}
          onSave={onSave}
        />
      </Box>
      {!forClient && (
        <Box mt={3}>
          <InlineEditableProfessionalOther
            formState={formState}
            label={t("professional:profile.other")}
            onSave={onSave}
            professional={professional}
          />
        </Box>
      )}
    </Box>
  );
}
