import { List, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";

import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { WaveBox } from "wave-box";
import { compact } from "lodash";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import LoadingPage from "../../../layout/LoadingPage";
import { theme } from "../../../providers/theme/ThemeProvider";
import { RouteNames } from "../../../routes/routeNames";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavigationBar from "../components/NavBar";
import Steps from "../components/content/Steps";
import useOrganizationForLandingPage from "../hooks/useOrganizationForLandingPage";
import { modules, skills, tests } from "./modules";
import ListItemWithIcon from "../../../ui/lists/ListItemWithIcon";
import Button from "../../../ui/buttons/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import environment from "../../../../environment/environment";
import { I18NexusLanguages } from "../../../language/languagesUtil";

interface ContentItemProps {
  imageAlt: string;
  imageSrc: string;
  text: string;
}

const mindlabLanguagesMap = new Map<I18NexusLanguages, string>([
  [I18NexusLanguages.FLEMISH, "nl"],
  [I18NexusLanguages.FRENCH, "fr"],
  [I18NexusLanguages.ENGLISH, "en"],
]);

const ContentItem = ({ imageAlt, imageSrc, text }: ContentItemProps) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        fontSize: "18px",
        margin: "24px",
        textAlign: "center",
        width: "150px",
      }}
    >
      <img
        alt={imageAlt}
        src={imageSrc}
        style={{ height: "auto", marginBottom: "24px", maxWidth: "80%" }}
      ></img>
      <Typography>{text}</Typography>
    </Box>
  );
};

const SelfCareLandingPage = () => {
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width: 500px)");
  const downloadLinksRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Organization,
  ]);

  const mindlabLanguage = mindlabLanguagesMap.get(
    i18n.language as I18NexusLanguages,
  );

  const selectTranslation = useSelectTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { pathname } = useLocation();
  const { trackPageView } = useMatomo();
  const scrollToLinks = useCallback(() => {
    downloadLinksRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [downloadLinksRef]);

  const organization = useOrganizationForLandingPage({
    emailOrCode: searchParams.get("email-or-code"),
  });

  useEffect(() => {
    if (organization)
      trackPageView({
        customDimensions: [
          {
            id: 2,
            value: organization.id.toString(),
          },
        ],
        documentTitle: "Organization landing selfcare",
      });
  }, [organization, selectTranslation, trackPageView]);

  useEffect(() => {
    if (!organization) return;

    if (!organization.mindlabEnabled)
      navigate("/employee/?" + searchParams.toString());
  }, [navigate, organization, searchParams]);

  useLayoutEffect(() => {
    if (!organization) return;

    if (searchParams.get("target") === "download_link") {
      scrollToLinks();
    } else window.scrollTo(0, 0);
  }, [organization, pathname, scrollToLinks, searchParams, setSearchParams]);

  if (!organization) return <LoadingPage full={false} />;

  const {
    color,
    logoUrl,
    ggtEnabled,
    mindlabEnabled,
    videoEnabled,
    ssoEnabled,
  } = organization;

  const baseUrl = "/employee";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavigationBar
        baseUrl={baseUrl}
        loginUrl={`${environment.MINDLAB_FRONTEND_URL}/${mindlabLanguage}/${
          ssoEnabled ? "login/sso/" + organization.mindlabBusinessId : "login"
        }`}
        navigation={compact([
          ggtEnabled && {
            label: t("organization:landing.navigation.ggt"),
            path: `${baseUrl}${
              RouteNames.NewB2BLandingPages.ggt.path
            }/?${searchParams.toString()}`,
          },
          mindlabEnabled && {
            active: true,
            label: t("organization:landing.navigation.selfcare"),
            path: `${baseUrl}${
              RouteNames.NewB2BLandingPages.selfCare.path
            }/?${searchParams.toString()}`,
          },
          videoEnabled && {
            label: t("organization:landing.navigation.video"),
            path: `${baseUrl}/video/?${searchParams.toString()}`,
          },
        ])}
        organizationColor={color ?? "#15262A"}
        organizationLogoUrl={selectTranslation(logoUrl)}
        organizationName={selectTranslation(organization.name)}
      />
      <Header
        backgroundColor="#DBEEFA"
        button={{
          label: t("organization:landingGgt.header.button"),
          onClick: scrollToLinks,
        }}
        headerImageUrl="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/eating+smiling+girl.jpg"
        text={t("organization:landingSelfCare.header.text")}
        title={t(
          "organization:landingSelfCare.header.title",
          "Mentaal weerbaar met BloomUp",
        )}
      />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "120px 48px 0",
          position: "relative",
          top: "-50px",
          width: "100%",
          [theme.breakpoints.down(500)]: {
            padding: "100px 18px 0px",
          },
        }}
      >
        <Typography
          sx={{ textAlign: "center" }}
          variant={smallScreen ? "h2" : "h1"}
        >
          {t("organization:landingGgt.workTogether.mainTitle")}
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          {t("organization:landingSelfCare.workTogether.mainText")}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gap: "24px",
            gridTemplateColumns: "1fr 1fr 1fr",
            justifyContent: "space-around",
            justifyItems: "center",
            maxWidth: "1300px",
            paddingTop: "36px",
            [theme.breakpoints.down(900)]: {
              width: "100%",
            },
            [theme.breakpoints.down(780)]: {
              gridTemplateColumns: "fit-content(100%)",
              justifyContent: "center",
              justifyItems: "start",
            },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              alt="plant with checklist"
              component="img"
              src="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/3plants_stage1.svg"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                paddingBottom: "24px",
                [theme.breakpoints.down(900)]: { width: 200 },
              }}
            />
            <Box sx={{ paddingLeft: "2rem" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {t("organization:landingGgt.assessmentsHeader")}
              </Typography>
              <List>
                {tests.map((test) => {
                  return (
                    <ListItemWithIcon
                      sx={{ paddingBottom: 0, paddingTop: 0 }}
                      text={selectTranslation(test.title as any) as string}
                    ></ListItemWithIcon>
                  );
                })}
              </List>
            </Box>
          </Box>
          <Box
            sx={{
              [theme.breakpoints.up(780)]: { marginTop: "30px" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              alt="plant with sticky notes"
              component="img"
              src="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/3plants_stage2.svg"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                paddingBottom: "24px",
                [theme.breakpoints.down(900)]: { width: 200 },
              }}
            />
            <Box sx={{ paddingLeft: "2rem" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {t("organization:landingSelfcare.modulesHeader")}
              </Typography>
              <List>
                {modules.map((module) => {
                  return (
                    <ListItemWithIcon
                      sx={{ paddingBottom: 0, paddingTop: 0 }}
                      text={selectTranslation(module.title as any) as string}
                    ></ListItemWithIcon>
                  );
                })}
              </List>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              alt="plant recolouring itself"
              component="img"
              src="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/3plants_stage3.svg"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                paddingBottom: "24px",
                [theme.breakpoints.down(900)]: { width: 200 },
              }}
            />
            <Box sx={{ paddingLeft: "2rem" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {t("organization:landingSelfCare.skillsHeader")}
              </Typography>
              <List>
                {skills.map((skill) => {
                  return (
                    <ListItemWithIcon
                      sx={{ paddingBottom: 0, paddingTop: 0 }}
                      text={selectTranslation(skill.title as any) as string}
                    ></ListItemWithIcon>
                  );
                })}
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
      <WaveBox
        background={theme.palette.green.main}
        fillBackground
        waveHeight="100px"
      >
        <Box
          ref={downloadLinksRef}
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            padding: "50px 96px",
            textAlign: "center",
            [theme.breakpoints.down(900)]: {
              padding: "0 6rem",
            },
            [theme.breakpoints.down(600)]: {
              padding: "0 3rem",
            },
            [theme.breakpoints.down(400)]: {
              padding: "0 2rem",
            },
            [theme.breakpoints.down(280)]: {
              padding: "0 1rem",
            },
          }}
        >
          <img
            alt="Bloomup icon"
            src="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/iPhone_App_60_3x_blue.svg"
          ></img>
          <Typography
            sx={{ marginBottom: "16px", marginTop: "24px" }}
            variant={"h1"}
          >
            {t("organization:landingSelfCare.getStarted.title")}
          </Typography>
          {organization.code && !ssoEnabled && (
            <>
              <Typography sx={{ marginBottom: "20px" }}>
                {t("organization:landingSelfCare.getStarted.text")}
              </Typography>
              <Box
                sx={{
                  alignItems: "center",
                  background: "#daeee1",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "center",
                  padding: "12px 48px",
                }}
              >
                <Typography sx={{ marginTop: "8px" }} variant={"h1"}>
                  {organization.code}
                </Typography>
              </Box>
            </>
          )}
          <Typography sx={{ marginBottom: "20px" }}>
            {organization.ssoEnabled
              ? t("organization:landingSelfCare.getStarted.subTextSso")
              : t("organization:landingSelfCare.getStarted.subText")}
          </Typography>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              justifyContent: "center",
              marginTop: "24px",
              maxWidth: "100%",
              width: "400px",
              [theme.breakpoints.down(890)]: {
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            }}
          >
            <Button
              bloomColor={"black"}
              endIcon={<ArrowForwardIosIcon />}
              fillColor={theme.palette.green.main}
              label={t("organization:landingSelfCare.getStarted.button")}
              onClick={() =>
                (window.location.href = `${
                  environment.MINDLAB_FRONTEND_URL
                }/${mindlabLanguage}/${
                  ssoEnabled
                    ? "login/sso/" + organization.mindlabBusinessId
                    : "register"
                }`)
              }
            />
            {
              <Button
                bloomColor={"black"}
                endIcon={<ArrowForwardIosIcon />}
                fillColor={theme.palette.green.main}
                label={t("organization:landing.navbar.login")}
                onClick={() =>
                  (window.location.href = `${
                    environment.MINDLAB_FRONTEND_URL
                  }/${mindlabLanguage}/${
                    ssoEnabled
                      ? "login/sso/" + organization.mindlabBusinessId
                      : "login"
                  }`)
                }
              />
            }
          </Box>
        </Box>
      </WaveBox>
      <Steps
        mainText={t("organization:landingSelfCare.steps.text")}
        mainTitle={t("organization:landingGgt.steps.title")}
        steps={[
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+notes.svg",
            text: t("organization:landingGgt.steps.steps.1.text"),
            title: t("organization:landingGgt.steps.step.1.title"),
          },
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+mirror+reverse.svg",
            text: t("organization:landingSelfCare.steps.step.3.text"),
            title: t("organization:landingSelfCare.steps.step.3.title"),
          },
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+steps.svg",
            text: t("organization:landingSelfCare.steps.step.4.text"),
            title: t("organization:landingGgt.steps.step.4.title"),
          },
        ]}
      />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "3rem",
          padding: "0 96px",
          textAlign: "center",
          [theme.breakpoints.down(900)]: {
            padding: "0 6rem",
          },
          [theme.breakpoints.down(600)]: {
            padding: "0 3rem",
          },
          [theme.breakpoints.down(400)]: {
            padding: "0 2rem",
          },
          [theme.breakpoints.down(280)]: {
            padding: "0 1rem",
          },
        }}
      >
        <Typography sx={{ marginBottom: "48px" }} variant="h1">
          {t("organization:landingGgt.resultsPromises.title")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            maxWidth: "1000px",
            width: "100%",
            [theme.breakpoints.down(745)]: {
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            },
          }}
        >
          <ContentItem
            imageAlt="Hourglass"
            imageSrc="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Hourglass.svg"
            text={t("organization:landingGgt.resultsPromises.fiveMinutes.v2")}
          />
          <ContentItem
            imageAlt="Bullseye"
            imageSrc="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Bullseye.svg"
            text={t(
              "organization:landingGgt.resultsPromises.problemSolving.v2",
            )}
          />
          <ContentItem
            imageAlt="Sitting behind a laptop"
            imageSrc="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Behind+laptop.svg"
            text={t("organization:landingSelfCare.resultsPromises.interactive")}
          />
        </Box>
      </Box>
      <Box height={"3rem"} />
      <Footer
        baseUrl={baseUrl}
        ggtEnabled={ggtEnabled}
        mindlabBusinessId={organization.mindlabBusinessId || undefined}
        mindlabEnabled={mindlabEnabled}
        ssoEnabled={ssoEnabled}
        videoEnabled={videoEnabled}
      />
    </Box>
  );
};

export default SelfCareLandingPage;
