import { graphql } from "../__generated__";

export const FIND_MATCHES = graphql(`
  query findMatches {
    findMatches {
      matches {
        availability
        isOnline
        professional {
          ...ProfessionalInfoForHuman
        }
        score
      }
    }
  }
`);
