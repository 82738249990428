import { graphql } from "../__generated__";

export const MUTATION_MATCHING_CREATE_PERMANENT_HUMAN = graphql(`
  mutation createPermanentHuman($input: PermanentHumanInput!) {
    createPermanentHuman(input: $input) {
      accessToken
      refreshToken
    }
  }
`);

export const MUTATION_MATCHING_START = graphql(`
  mutation startMatching($input: StartMatchingInput!) {
    startMatching(input: $input) {
      matchedHumanId
      matchingToken
    }
  }
`);
