import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useStorage from "../../../hooks/useStorage";
import LoadingPage from "../../../layout/LoadingPage";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import { I18Namespaces } from "../../../language/I18Namespaces";
import useTrackPageView from "../../../providers/matomo/useTrackPageView";
import { IHumanMatchingContext } from "../context/humanMatching";
import HumanMatchingContext from "../context/HumanMatchingContext";
import { SELECT_MATCH_STEP } from "../context/humanMatchingSteps";
import useSetMatchingStep from "../hooks/useSetMatchingStep";
import useUpdateHumanMutation from "../../../../api/humans/hooks/useUpdateHumanMutation";
import { IMatch } from "../../../../api/matching/hooks/useFindMatches";
import MatchedProfessionalList from "./matching/MatchedProfessionalList";
import HealthInsuranceBox from "./matching/HealthInsuranceBox";

const PREFIX = "SelectMatchStep";

const classes = {
  matchContainer: `${PREFIX}-matchContainer`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.matchContainer}`]: {
    margin: "20px 50px",
    [theme.breakpoints.down(500)]: {
      margin: "20px 0",
    },
  },
}));

export default function SelectMatchStep() {
  const {
    step,
    totalSteps,
    matches,
    isCreatingConsult,
    next,
    onMatchChosen,
    findMatches,
    scheduleCall,
    startCall,
    setSelectedTimeSlot,
    formState,
    organization,
  } = useContext<IHumanMatchingContext>(HumanMatchingContext);

  const { getAccessTokenFromStorage /*, getWithKeyFromStorage */ } =
    useStorage();

  const { updateHuman } = useUpdateHumanMutation();

  useTrackPageView("Human Onboarding > Select Match");
  useSetMatchingStep(SELECT_MATCH_STEP);
  const { t: translate } = useTranslation<I18Namespaces>(["human", "common"]);

  useEffect(() => {
    if (!matches) {
      findMatches();
    }
  }, [findMatches, matches]);

  const goToNextStep = async (match: IMatch, shouldStartCall = false) => {
    const accessToken = await getAccessTokenFromStorage();

    if (accessToken) {
      // If this user is logged in, he already has an account
      // so we can skip the account steps
      /* const [consultationUUID, previouslyChosenProfessional]: [
                number | null,
                ProfessionalModel | null
            ] = await Promise.all([
                getWithKeyFromStorage<number>(KEY_CONSULTATION_UUID),
                getWithKeyFromStorage<ProfessionalModel>(
                    KEY_CHOSEN_PROFESSIONAL
                ),
            ]);*/

      /*if (
                consultationUUID &&
                shouldStartCall &&
                previouslyChosenProfessional
            ) {
                // If a user had been in the waiting room, but went back and then selected the same
                // professional again, route this user to the old consultation.
                if (
                    match.professional.getID() ===
                    previouslyChosenProfessional.id
                ) {
                    return navigate(
                        createOnboardingWaitingRoomRouteWithId(consultationUUID)
                    );
                }
            }*/
      if (organization?.getID()) {
        updateHuman({ organizationId: organization.getID() });
      }

      return shouldStartCall ? startCall(match) : scheduleCall(match);
    } else {
      next();
    }
  };

  if (!matches || isCreatingConsult) return <LoadingPage full={false} />;

  return (
    <OnboardingContainer marginBottom={6}>
      <OnboardingStepContainer
        description={
          matches.length !== 0
            ? translate("human:onboarding.select.description.match_found")
            : translate("human:onboarding.select.description.no_match")
        }
        fullContent
        fullCopyright
        headline={translate("human:onboarding.title")}
        showLanguageSelector={false}
        showSpecialConditionsForTherapist={false}
        step={step.step}
        subhead={
          matches.length !== 0
            ? translate("human:onboarding.select.match_found")
            : translate("human:onboarding.select.match_not_found")
        }
        subheadicon={
          matches.length !== 0 ? (
            <EmojiEmotionsOutlinedIcon />
          ) : (
            <SentimentDissatisfiedIcon />
          )
        }
        totalSteps={totalSteps}
      >
        {matches.length !== 0 && (
          <StyledBox className={classes.matchContainer}>
            <HealthInsuranceBox formState={formState} translate={translate} />

            <MatchedProfessionalList
              matches={matches}
              next={goToNextStep}
              onMatchChosen={onMatchChosen}
              setSelectedTimeSlot={setSelectedTimeSlot}
            />
          </StyledBox>
        )}
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
