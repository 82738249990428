import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { MouseEvent } from "react";
import { BloomColor } from "../layout/wizard/StepFooter";
import StyledTooltip from "./Tooltip";

const PREFIX = "BloomUpSwitch";

const classes = {
  root: `${PREFIX}-root`,
  switch: `${PREFIX}-switch`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
  },

  [`& .${classes.switch}`]: {
    "& .Mui-checked": {
      color: theme.palette.green.main,
    },
    "& .MuiSwitch-switchBase": {
      color: theme.palette.red.light,
    },
    "& .MuiSwitch-track": {
      backgroundColor: theme.palette.red.light,
    },
  },
}));

interface BloomUpSwitchProps {
  label?: string;
  onChange: (checked: boolean, onClick?: MouseEvent<HTMLElement>) => void;
  switchColor?: BloomColor;
  toolTip?: string;
  value: boolean;
}

export default function BloomUpSwitch({
  onChange,
  label,
  toolTip,
  value,
}: BloomUpSwitchProps) {
  return (
    <StyledTooltip placement="bottom-start" text={toolTip ?? ""}>
      <StyledBox
        className={classes.root}
        onClick={(onClick) => onChange(!value, onClick)}
      >
        <Switch checked={value} className={classes.switch} color="primary" />
        {label && <Typography>{label}</Typography>}
      </StyledBox>
    </StyledTooltip>
  );
}
