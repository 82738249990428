/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Grid, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Consultation, {
  ConsultationTypes,
} from "../../data-model/types/consultation/Consultation";
import { IFormState } from "../hooks/useFormState";
import LoadingPage from "../layout/LoadingPage";
import AuthContext from "../providers/auth/AuthContext";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";
import TextField from "../ui/form/TextField";
import Label from "../ui/text/Label";
import SanitizedText from "../ui/text/SanitizedText";
import ValueWithLabel from "../ui/text/ValueWithLabel";
import environment from "../../environment/environment";

const PREFIX = "ConsultationPriceSelection";

const classes = {
  cost: `${PREFIX}-cost`,
  priceContainer: `${PREFIX}-priceContainer`,
  smallCost: `${PREFIX}-smallCost`,
  superSmallCost: `${PREFIX}-superSmallCost`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.priceContainer}`]: {
    fontSize: 14,
  },

  [`& .${classes.cost}`]: {
    color: theme.palette.gray.dark,
    lineHeight: "22px",
    padding: "60px 0 0 15px",
  },

  [`& .${classes.smallCost}`]: {
    paddingRight: 0,
  },

  [`& .${classes.superSmallCost}`]: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 15,
  },
}));

interface IConsultationPriceSelection {
  canPayWithCredits: boolean;
  consultation?: Consultation;
  creditsLeft?: number | null;
  formState: IFormState;
  isEditing: boolean;
  narrow?: boolean;
}

const ConsultationPriceSelection = ({
  isEditing,
  formState,
  narrow = false,
  creditsLeft,
  consultation,
  canPayWithCredits,
}: IConsultationPriceSelection) => {
  const sm = useMediaQuery("only screen and (max-width: 500px)");
  const ssm = useMediaQuery("only screen and (max-width: 430px)");

  const { currentUser } = useContext(AuthContext);

  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Professional,
  ]);

  const [priceSetManually, setPriceSetManually] = useState<boolean>(false);

  const cashTransaction = consultation?.getCashTransaction();

  useEffect(() => {
    const fct = (val: number) => {
      const pricePerMinute = formState.getValue("pricePerMinute");

      if (!priceSetManually && pricePerMinute !== "") {
        formState.setValue("price", Math.round(pricePerMinute * val));
      }
    };

    formState.onValueChange("duration", fct);

    return () => {
      formState.removeOnValueChange("duration", fct);
    };
  }, []);

  const consultationPrice = useMemo(() => {
    if (cashTransaction) {
      const price = cashTransaction.getAmount();

      formState.setValue("price", price);

      return price;
    }

    return 0;
  }, [cashTransaction]);

  if (
    [ConsultationTypes.INTRO, ConsultationTypes.CHECKIN].includes(
      formState.getValue("type")?.value,
    )
  )
    return null;

  if (!currentUser) return <LoadingPage />;

  return (
    <Root>
      {!isEditing &&
        (currentUser.isProfessional() ||
          consultation?.getCashTransaction()) && (
          <Box>
            <ValueWithLabel label={t("common:price.label", "Prijs")}>
              <SanitizedText
                input={
                  canPayWithCredits
                    ? creditsLeft
                      ? t("professional:consultation.pricing.info.credits", {
                          credits: creditsLeft,
                        })
                      : t(
                          "professional:consultation.pricing.info.credits.unlimited",
                        )
                    : t("professional:consultation.pricing.info", {
                        clientPrice: consultationPrice,
                        professionalNetto:
                          (consultationPrice ? consultationPrice : 0) -
                          environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_CONSULT,
                        serviceCost:
                          environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_CONSULT,
                      })
                }
              />
            </ValueWithLabel>
          </Box>
        )}
      {isEditing && (
        <Grid
          alignItems="flex-start"
          className={classes.priceContainer}
          container
          direction={ssm || narrow ? "column" : "row"}
          justifyContent="flex-start"
          wrap="nowrap"
        >
          {!canPayWithCredits && (
            <Box mt={3} py={1}>
              <Label
                tooltip={t("professional:consultation.pricing.tooltip")}
                value={t("common:price.label")}
              />
              <Box mt={1}>
                <TextField
                  {...formState.getInputProps("price")}
                  onChange={(price: string) => {
                    formState.setValue("price", price);
                    setPriceSetManually(true);
                  }}
                />
              </Box>
            </Box>
          )}
          <Box
            className={clsx(
              classes.cost,
              sm && classes.smallCost,
              (ssm || narrow) && classes.superSmallCost,
            )}
          >
            <SanitizedText
              input={t("professional:consultation.pricing.info", {
                clientPrice: formState.getValue("price"),
                context: !canPayWithCredits
                  ? ""
                  : creditsLeft !== null
                  ? "credits"
                  : "unlimited_credits",
                credits: creditsLeft,
                professionalNetto: formState.getValue("price") - 6,
                professionalNettoCredits: 60,
                serviceCost: 6,
              })}
            />
          </Box>
        </Grid>
      )}
    </Root>
  );
};

export default ConsultationPriceSelection;
