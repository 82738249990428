import Age from "../../../../data-model/types/profile/Age";
import Gender from "../../../../data-model/types/profile/Gender";
import Email from "../../../../data-model/types/profile/Email";
import Password from "../../../../data-model/types/profile/Password";
import Worries from "../../../../data-model/types/profile/Worries";
import { RouteNames } from "../../../routes/routeNames";
import { Step } from "./humanMatching";

export const WELCOME_STEP = 0;

export const CONSENT_STEP = 1;

export const AGE_STEP = 2;

export const GENDER_STEP = 3;

export const WORRIES_STEP = 4;

export const SEARCH_MATCH_STEP = 5;

export const SELECT_MATCH_STEP = 6;

export const ACCOUNT_STEP = 7;

export const CONSULTATION_BOOKED_STEP = 8;

// This list is the "normal" progression a new user will go through. There are other steps that
// are not in this list but are defined and used (in case an existing user goes through matching
// again but is not logged in)
export const STEPS: Step[] = [
  {
    includeInStepsCount: true,
    name: "welcome",
    route: RouteNames.Matching.Start.path,
    step: WELCOME_STEP,
  },
  {
    includeInStepsCount: true,
    name: "consent",
    route: RouteNames.Matching.Consent.path,
    step: CONSENT_STEP,
  },
  {
    includeInStepsCount: true,
    name: "age",
    route: RouteNames.Matching.Age.path,
    step: AGE_STEP,
    validationPath: Age.getPath(),
  },
  {
    includeInStepsCount: true,
    name: "gender",
    route: RouteNames.Matching.Gender.path,
    step: GENDER_STEP,
    validationPath: Gender.getPath(),
  },
  {
    includeInStepsCount: true,
    name: "worries",
    route: RouteNames.Matching.Worries.path,
    step: WORRIES_STEP,
    validationPath: Worries.getPath(),
  },
  {
    includeInStepsCount: true,
    name: "search_match",
    route: RouteNames.Matching.Search.path,
    step: SEARCH_MATCH_STEP,
  },
  {
    includeInStepsCount: true,
    name: "select_match",
    route: RouteNames.Matching.Select.path,
    step: SELECT_MATCH_STEP,
  },
  {
    includeInStepsCount: false,
    name: "email",
    route: RouteNames.Matching.Account.path,
    step: ACCOUNT_STEP,
    validationPath: [Email.getPath(), Password.getPath()],
  },
  {
    includeInStepsCount: false,
    name: "consultation_booked",
    route: RouteNames.Matching.Success.path,
    step: CONSULTATION_BOOKED_STEP,
  },
];

export const getStepByNumber = (stepNumber: number): Step | undefined => {
  return STEPS.find((s) => s.step === stepNumber);
};
