/* eslint-disable react-hooks/exhaustive-deps */

import FullCalendar from "@fullcalendar/react";
import { EventInput } from "@fullcalendar/core";
import moment from "moment";
import { useContext, useEffect, useRef, useState, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import useCopyCalendarEvents from "../../../../api/calendarEvents/hooks/useCopyCalendarEvents";
import ToastContext from "../../../providers/toast/ToastContext";
import useUpdateFullCalendarEvent from "../../../../api/calendarEvents/hooks/useUpdateFullCalendarEvent";
import useDeleteCalendarEvents from "../../../../api/calendarEvents/hooks/useDeleteCalendarEvent";
import useFetchFullCalendarEvents from "../../../../api/calendarEvents/hooks/useLazyFetchFullCalendarEvents";
import useCreateFullCalendarEvent from "../../../../api/calendarEvents/hooks/useCreateFullCalendarEvent";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import useAvailabilityFormState from "../hooks/useAvailabilityFormState";
import useFetchOneProfessional from "../../../../api/professionals/hooks/useFetchOneProfessional";
import useUpdateProfessionalMutation from "../../../../api/professionals/hooks/useUpdateProfessionalMutation";
import { ISelectObject } from "../../../ui/form/select/BaseSimpleSelect";
import { OnlineDurationOptions } from "../HeaderToolBarFullCalendar";
import { CalendarEventTypes } from "../../../../data-model/types/consultation/CalendarEvent";
import {
  CalendarEventInput,
  DeleteCalendarEventsInput,
  UpdateCalendarEventInput,
} from "../../../../api/__generated__/graphql";
import ProfessionalAvailabilityContext from "./ProfessionalAvailabilityContext";

export interface IDateRangeOfCalendar {
  end: Date;
  start: Date;
}

export default function ProfessionalAvailabilityContextProvider({ children }) {
  const { setToast } = useContext(ToastContext);
  const { t: translate, i18n } = useTranslation<I18Namespaces>(
    BloomUpNamespacesEnum.Errors,
  );

  const formState = useAvailabilityFormState();

  const [events, setEvents] = useState<EventInput[]>();

  const [popUpAnchorEl, setPopUpAnchorEl] = useState<null | HTMLElement>(null);

  const [dateRangeOfCalendar, setDateRangeOfCalendar] =
    useState<IDateRangeOfCalendar>({
      end: moment().endOf("week").toDate(),
      start: moment().startOf("week").toDate(),
    });

  const [onlineUntil, setOnlineUntil] = useState<Date | null>(null);

  const calendarRef = useRef<FullCalendar>(null);

  calendarRef.current?.getApi().setOption("locale", i18n.language);
  calendarRef.current?.getApi().setOption("aspectRatio", 1);
  calendarRef.current?.getApi().setOption("height", "100%");

  const { data: calendarEvents, refetch: refetchAllEvents } =
    useFetchFullCalendarEvents(
      dateRangeOfCalendar.start,
      dateRangeOfCalendar.end,
    );

  const {
    loading: isCreatingCalendarEvent,
    error: creatingCalendarEventError,
    createFullCalendarEvent,
  } = useCreateFullCalendarEvent();

  const {
    deleteCalendarEvents,
    error: deleteCalendarEventError,
    loading: isDeletingCalendarEvent,
  } = useDeleteCalendarEvents();

  const { updateFullCalendarEvent } = useUpdateFullCalendarEvent();

  const { copyCalendarEvents, loading: isCopyingCalendarEvents } =
    useCopyCalendarEvents();

  const { professional, loading: isLoadingProfessional } =
    useFetchOneProfessional();

  const { updateProfessional } = useUpdateProfessionalMutation();

  useEffect(() => {
    formState.setValue(
      "isAcceptingNewClients",
      professional ? professional.getClientStop() : false,
    );
  }, [professional]);

  useEffect(() => {
    events?.forEach((e) => {
      if (
        e.extendedProps &&
        e.extendedProps.type === CalendarEventTypes.Online &&
        new Date(e.start as string) <= new Date() &&
        new Date(e.end as string) >= new Date()
      ) {
        formState.setValue("isOnline", true);
        setOnlineUntil(new Date(e.end as string));
      }
    });
  }, [events]);

  useEffect(() => {
    if (refetchAllEvents && dateRangeOfCalendar) {
      refetchAllEvents({
        endDate: dateRangeOfCalendar.end,
        startDate: dateRangeOfCalendar.start,
      });
    }
  }, [dateRangeOfCalendar]);

  useEffect(() => {
    if (calendarEvents) {
      setEvents(
        calendarEvents.map((calendarEvent) => {
          return {
            ...calendarEvent,
            classNames:
              calendarEvent.extendedProps.type ===
              CalendarEventTypes.Availability
                ? "availability-event"
                : "consultation-event",
            editable:
              calendarEvent.extendedProps.type ===
              CalendarEventTypes.Availability,
            title:
              calendarEvent.extendedProps.type ===
              CalendarEventTypes.Availability
                ? ""
                : "Consultatie",
          };
        }),
      );
    }
  }, [calendarEvents]);

  const createCalendarEvent = async (input: CalendarEventInput) => {
    await createFullCalendarEvent(input);
    await reloadCalendar();
  };

  const deleteCalendarEvent = async (input: DeleteCalendarEventsInput) => {
    await deleteCalendarEvents(input);
    await reloadCalendar();
  };

  const reloadCalendar = async () =>
    refetchAllEvents({
      endDate: dateRangeOfCalendar.end,
      startDate: dateRangeOfCalendar.start,
    });

  const updateCalendarEvent = async (input: UpdateCalendarEventInput) => {
    await updateFullCalendarEvent(input);
    await reloadCalendar();
  };

  const copyAvailabilityToThisWeek = async () => {
    try {
      const diff = moment(dateRangeOfCalendar.end).diff(
        moment(dateRangeOfCalendar.start),
        "minutes",
      );

      await copyCalendarEvents({
        copyTo: dateRangeOfCalendar.start,
        from: moment(dateRangeOfCalendar.start)
          .subtract(diff, "minutes")
          .toDate(),
        onlyType: CalendarEventTypes.Availability,
        to: moment(dateRangeOfCalendar.end).subtract(diff, "minutes").toDate(),
      });
      await reloadCalendar();
    } catch (e: any) {
      setToast({
        message: translate(
          "could.not.copy.availability",
          "\n" +
            "Het is ons niet gelukt om je beschikbaarheid te kopiëren. Probeer het later opnieuw of contacteer ons via het blauwe tekstballonnetje onderaan.",
        ),
        severity: "warning",
      });
    }
  };

  const removeAvailabilityOfVisiblePeriod = async () => {
    const eventsToDelete = events?.filter(
      (e) =>
        e.extendedProps &&
        e.extendedProps.type === CalendarEventTypes.Availability &&
        new Date(e.start as string) >= dateRangeOfCalendar.start &&
        new Date(e.end as string) <= dateRangeOfCalendar.end,
    );

    if (eventsToDelete && eventsToDelete.length > 0) {
      deleteCalendarEvent({
        ids: eventsToDelete.map((e) => Number(e.id)),
      });
    }

    await reloadCalendar();
  };

  const updateIsAcceptingNewClients = async (value: boolean) => {
    await updateProfessional({
      clientStop: value,
    });
  };

  const updateIsOnline = async (
    isOnline: boolean,
    event: MouseEvent<HTMLElement> | undefined,
  ) => {
    if (isOnline) {
      if (event) setPopUpAnchorEl(event.currentTarget);
    } else {
      const currentEvent = events?.find((e) => {
        if (!e.start || !e.end) return false;

        return (
          e.extendedProps &&
          e.extendedProps.type === CalendarEventTypes.Online &&
          new Date(e.start as string) <= new Date() &&
          new Date(e.end as string) >= new Date()
        );
      });

      if (currentEvent) {
        const shortestHasPassedWhileOnline =
          moment(currentEvent.start).diff(moment(), "minutes") >=
          OnlineDurationOptions.shortest;

        if (!shortestHasPassedWhileOnline)
          deleteCalendarEvent({ ids: [Number(currentEvent.id)] });
        else {
          updateCalendarEvent({
            allDay: currentEvent.allDay,
            id: Number(currentEvent.id),
            scheduledFrom: currentEvent.start as string,
            scheduledTo: new Date(),
          });
        }

        setOnlineUntil(null);
      }
    }
  };

  const popUpMenuOnClose = async (value: ISelectObject | null) => {
    if (!value?.value) {
      formState.setValue("isOnline", false);
    }

    setPopUpAnchorEl(null);
    await reloadCalendar();
  };

  const popUpMenuOnSelectItem = async (option: ISelectObject<Date>) => {
    const now = new Date();

    await createCalendarEvent({
      allDay: false,
      scheduledFrom: now,
      scheduledTo: new Date(option.value.getTime()),
      type: CalendarEventTypes.Online,
    });
    await reloadCalendar();
  };

  return (
    <ProfessionalAvailabilityContext.Provider
      value={{
        calendarRef,
        copyAvailabilityToThisWeek,
        createCalendarEvent,
        creatingCalendarEventError,
        dateRangeOfCalendar,
        deleteCalendarEvent,
        deleteCalendarEventError,
        events,
        formState,
        isCopyingCalendarEvents,
        isCreatingCalendarEvent,
        isDeletingCalendarEvent,
        isLoadingProfessional,
        onlineUntil,
        popUpAnchorEl,
        popUpMenuOnClose,
        popUpMenuOnSelectItem,
        professional,
        removeAvailabilityOfVisiblePeriod,
        setDateRangeOfCalendar,
        updateCalendarEvent,
        updateIsAcceptingNewClients,
        updateIsOnline,
      }}
    >
      {children}
    </ProfessionalAvailabilityContext.Provider>
  );
}
