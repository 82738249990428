import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy,
} from "@apollo/client/cache";
export type AddressKeySpecifier = (
  | "country"
  | "createdAt"
  | "id"
  | "location"
  | "postcode"
  | "street"
  | "updatedAt"
  | AddressKeySpecifier
)[];
export type AddressFieldPolicy = {
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  location?: FieldPolicy<any> | FieldReadFunction<any>;
  postcode?: FieldPolicy<any> | FieldReadFunction<any>;
  street?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AvatarKeySpecifier = ("url" | AvatarKeySpecifier)[];
export type AvatarFieldPolicy = {
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BarchartItemKeySpecifier = (
  | "name"
  | "value"
  | BarchartItemKeySpecifier
)[];
export type BarchartItemFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BillingInformationKeySpecifier = (
  | "accountNumber"
  | "address"
  | "createdAt"
  | "email"
  | "id"
  | "taxId"
  | "updatedAt"
  | BillingInformationKeySpecifier
)[];
export type BillingInformationFieldPolicy = {
  accountNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  taxId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CalendarEventKeySpecifier = (
  | "allDay"
  | "id"
  | "scheduledFrom"
  | "scheduledTo"
  | "type"
  | CalendarEventKeySpecifier
)[];
export type CalendarEventFieldPolicy = {
  allDay?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduledFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduledTo?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CashTransactionKeySpecifier = (
  | "amount"
  | "createdAt"
  | "id"
  | "mollieTransaction"
  | "status"
  | "updatedAt"
  | CashTransactionKeySpecifier
)[];
export type CashTransactionFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mollieTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChimeAttendeeInfoKeySpecifier = (
  | "AttendeeId"
  | "ExternalUserId"
  | "JoinToken"
  | ChimeAttendeeInfoKeySpecifier
)[];
export type ChimeAttendeeInfoFieldPolicy = {
  AttendeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  ExternalUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  JoinToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChimeMediaPlacementKeySpecifier = (
  | "AudioFallbackUrl"
  | "AudioHostUrl"
  | "ScreenDataUrl"
  | "ScreenSharingUrl"
  | "ScreenViewingUrl"
  | "SignalingUrl"
  | ChimeMediaPlacementKeySpecifier
)[];
export type ChimeMediaPlacementFieldPolicy = {
  AudioFallbackUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  AudioHostUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  ScreenDataUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  ScreenSharingUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  ScreenViewingUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  SignalingUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChimeMeetingInfoKeySpecifier = (
  | "ExternalMeetingId"
  | "MediaPlacement"
  | "MediaRegion"
  | "MeetingId"
  | ChimeMeetingInfoKeySpecifier
)[];
export type ChimeMeetingInfoFieldPolicy = {
  ExternalMeetingId?: FieldPolicy<any> | FieldReadFunction<any>;
  MediaPlacement?: FieldPolicy<any> | FieldReadFunction<any>;
  MediaRegion?: FieldPolicy<any> | FieldReadFunction<any>;
  MeetingId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientKeySpecifier = (
  | "createdAt"
  | "description"
  | "email"
  | "human"
  | "humanId"
  | "id"
  | "inviteToken"
  | "professional"
  | "professionalId"
  | "status"
  | "updatedAt"
  | ClientKeySpecifier
)[];
export type ClientFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  human?: FieldPolicy<any> | FieldReadFunction<any>;
  humanId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  inviteToken?: FieldPolicy<any> | FieldReadFunction<any>;
  professional?: FieldPolicy<any> | FieldReadFunction<any>;
  professionalId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | ClientEdgeKeySpecifier
)[];
export type ClientEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientsConnectionKeySpecifier = (
  | "edges"
  | "pageInfo"
  | "totalCount"
  | ClientsConnectionKeySpecifier
)[];
export type ClientsConnectionFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConsultationKeySpecifier = (
  | "acceptedByHuman"
  | "acceptedByProfessional"
  | "bloomupFee"
  | "cashTransaction"
  | "createdAt"
  | "creditTransactions"
  | "duration"
  | "expectedReimbursementDate"
  | "fromMatching"
  | "human"
  | "humanId"
  | "id"
  | "message"
  | "professional"
  | "professionalFee"
  | "professionalId"
  | "requestedBy"
  | "scheduledFrom"
  | "scheduledTo"
  | "status"
  | "type"
  | "updatedAt"
  | "userRequested"
  | "uuid"
  | ConsultationKeySpecifier
)[];
export type ConsultationFieldPolicy = {
  acceptedByHuman?: FieldPolicy<any> | FieldReadFunction<any>;
  acceptedByProfessional?: FieldPolicy<any> | FieldReadFunction<any>;
  bloomupFee?: FieldPolicy<any> | FieldReadFunction<any>;
  cashTransaction?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  creditTransactions?: FieldPolicy<any> | FieldReadFunction<any>;
  duration?: FieldPolicy<any> | FieldReadFunction<any>;
  expectedReimbursementDate?: FieldPolicy<any> | FieldReadFunction<any>;
  fromMatching?: FieldPolicy<any> | FieldReadFunction<any>;
  human?: FieldPolicy<any> | FieldReadFunction<any>;
  humanId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  professional?: FieldPolicy<any> | FieldReadFunction<any>;
  professionalFee?: FieldPolicy<any> | FieldReadFunction<any>;
  professionalId?: FieldPolicy<any> | FieldReadFunction<any>;
  requestedBy?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduledFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduledTo?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userRequested?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConsultationEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | ConsultationEdgeKeySpecifier
)[];
export type ConsultationEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConsultationsConnectionKeySpecifier = (
  | "edges"
  | "pageInfo"
  | "totalCount"
  | ConsultationsConnectionKeySpecifier
)[];
export type ConsultationsConnectionFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountryKeySpecifier = (
  | "id"
  | "logoUrl"
  | "regions"
  | "translationKey"
  | CountryKeySpecifier
)[];
export type CountryFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  logoUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  regions?: FieldPolicy<any> | FieldReadFunction<any>;
  translationKey?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreditTransactionKeySpecifier = (
  | "amount"
  | "id"
  | CreditTransactionKeySpecifier
)[];
export type CreditTransactionFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DifferenceGaugeResultKeySpecifier = (
  | "averageFirstScore"
  | "averageImprovement"
  | "averageLatestScore"
  | DifferenceGaugeResultKeySpecifier
)[];
export type DifferenceGaugeResultFieldPolicy = {
  averageFirstScore?: FieldPolicy<any> | FieldReadFunction<any>;
  averageImprovement?: FieldPolicy<any> | FieldReadFunction<any>;
  averageLatestScore?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DistributeListNodeContentKeySpecifier = (
  | "amountToDistribute"
  | "body"
  | "buttonType"
  | "headerImage"
  | "id"
  | "options"
  | "progress"
  | DistributeListNodeContentKeySpecifier
)[];
export type DistributeListNodeContentFieldPolicy = {
  amountToDistribute?: FieldPolicy<any> | FieldReadFunction<any>;
  body?: FieldPolicy<any> | FieldReadFunction<any>;
  buttonType?: FieldPolicy<any> | FieldReadFunction<any>;
  headerImage?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  options?: FieldPolicy<any> | FieldReadFunction<any>;
  progress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EndOfTrackNodeContentKeySpecifier = (
  | "id"
  | EndOfTrackNodeContentKeySpecifier
)[];
export type EndOfTrackNodeContentFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExtendedPropsKeySpecifier = (
  | "status"
  | "type"
  | ExtendedPropsKeySpecifier
)[];
export type ExtendedPropsFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FullCalendarEventKeySpecifier = (
  | "allDay"
  | "end"
  | "extendedProps"
  | "id"
  | "start"
  | "url"
  | FullCalendarEventKeySpecifier
)[];
export type FullCalendarEventFieldPolicy = {
  allDay?: FieldPolicy<any> | FieldReadFunction<any>;
  end?: FieldPolicy<any> | FieldReadFunction<any>;
  extendedProps?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FunnelItemKeySpecifier = (
  | "name"
  | "value"
  | FunnelItemKeySpecifier
)[];
export type FunnelItemFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GrowthStepKeySpecifier = (
  | "id"
  | "title"
  | GrowthStepKeySpecifier
)[];
export type GrowthStepFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HumanKeySpecifier = (
  | "address"
  | "age"
  | "birthDate"
  | "canPayCoachingWithCredits"
  | "canPayConsultWithCredits"
  | "canPayWithCredits"
  | "contactForFeedback"
  | "createdAt"
  | "creditsLeft"
  | "deletedAt"
  | "email"
  | "firstName"
  | "healthInsurance"
  | "id"
  | "lastName"
  | "onboardedAt"
  | "organization"
  | "organizationId"
  | "password"
  | "phone"
  | "preferredLanguage"
  | "preferredProfessionalGender"
  | "timezone"
  | "updatedAt"
  | "user"
  | "uuid"
  | HumanKeySpecifier
)[];
export type HumanFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  age?: FieldPolicy<any> | FieldReadFunction<any>;
  birthDate?: FieldPolicy<any> | FieldReadFunction<any>;
  canPayCoachingWithCredits?: FieldPolicy<any> | FieldReadFunction<any>;
  canPayConsultWithCredits?: FieldPolicy<any> | FieldReadFunction<any>;
  canPayWithCredits?: FieldPolicy<any> | FieldReadFunction<any>;
  contactForFeedback?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  creditsLeft?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  healthInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  onboardedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  organization?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  password?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredLanguage?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredProfessionalGender?: FieldPolicy<any> | FieldReadFunction<any>;
  timezone?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HumanWithTokensKeySpecifier = (
  | "accessToken"
  | "human"
  | "refreshToken"
  | HumanWithTokensKeySpecifier
)[];
export type HumanWithTokensFieldPolicy = {
  accessToken?: FieldPolicy<any> | FieldReadFunction<any>;
  human?: FieldPolicy<any> | FieldReadFunction<any>;
  refreshToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LineGraphItemKeySpecifier = (
  | "x"
  | "y"
  | LineGraphItemKeySpecifier
)[];
export type LineGraphItemFieldPolicy = {
  x?: FieldPolicy<any> | FieldReadFunction<any>;
  y?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LoginResponseKeySpecifier = (
  | "accessToken"
  | "refreshToken"
  | LoginResponseKeySpecifier
)[];
export type LoginResponseFieldPolicy = {
  accessToken?: FieldPolicy<any> | FieldReadFunction<any>;
  refreshToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LoginV2FailureResponseKeySpecifier = (
  | "reason"
  | LoginV2FailureResponseKeySpecifier
)[];
export type LoginV2FailureResponseFieldPolicy = {
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MatchKeySpecifier = (
  | "availability"
  | "isOnline"
  | "professional"
  | "score"
  | MatchKeySpecifier
)[];
export type MatchFieldPolicy = {
  availability?: FieldPolicy<any> | FieldReadFunction<any>;
  isOnline?: FieldPolicy<any> | FieldReadFunction<any>;
  professional?: FieldPolicy<any> | FieldReadFunction<any>;
  score?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MatchedHumanKeySpecifier = (
  | "age"
  | "chosenProfessionalId"
  | "createdAt"
  | "id"
  | "organization"
  | "preferredProfessionalGender"
  | "updatedAt"
  | "userUuid"
  | "worries"
  | MatchedHumanKeySpecifier
)[];
export type MatchedHumanFieldPolicy = {
  age?: FieldPolicy<any> | FieldReadFunction<any>;
  chosenProfessionalId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  organization?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredProfessionalGender?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  userUuid?: FieldPolicy<any> | FieldReadFunction<any>;
  worries?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MatchesKeySpecifier = ("matches" | MatchesKeySpecifier)[];
export type MatchesFieldPolicy = {
  matches?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MessageKeySpecifier = (
  | "body"
  | "consultationId"
  | "createdAt"
  | "fromProfessional"
  | "id"
  | "updatedAt"
  | MessageKeySpecifier
)[];
export type MessageFieldPolicy = {
  body?: FieldPolicy<any> | FieldReadFunction<any>;
  consultationId?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  fromProfessional?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MultiLanguageBooleanKeySpecifier = (
  | "en"
  | "fr"
  | "nl"
  | MultiLanguageBooleanKeySpecifier
)[];
export type MultiLanguageBooleanFieldPolicy = {
  en?: FieldPolicy<any> | FieldReadFunction<any>;
  fr?: FieldPolicy<any> | FieldReadFunction<any>;
  nl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MultiLanguageStringKeySpecifier = (
  | "en"
  | "fr"
  | "nl"
  | MultiLanguageStringKeySpecifier
)[];
export type MultiLanguageStringFieldPolicy = {
  en?: FieldPolicy<any> | FieldReadFunction<any>;
  fr?: FieldPolicy<any> | FieldReadFunction<any>;
  nl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | "acceptInvite"
  | "answerQuestion"
  | "beginTrack"
  | "cancelConsultation"
  | "choosePasswordV2"
  | "completeHumanRegistration"
  | "confirmConsultation"
  | "copyCalendarEvents"
  | "createCalendarEvent"
  | "createConsultation"
  | "createPermanentHuman"
  | "deleteCalendarEvents"
  | "enable2FA"
  | "inviteClient"
  | "joinOrganizationByEmailOrCode"
  | "joinOrganizationByEmailOrCodeV2"
  | "login"
  | "loginV2"
  | "loginWithSSO"
  | "notifyOtherParty"
  | "payForConsultation"
  | "refreshAccessToken"
  | "refreshAccessTokenV2"
  | "registerHuman"
  | "registerHumanV2"
  | "registerHumanWithInvite"
  | "registerProfessional"
  | "request2FASecrets"
  | "requestReimbursement"
  | "rescheduleConsultation"
  | "resetPassword"
  | "sendAppInvite"
  | "setCurrentUserLanguage"
  | "startMatching"
  | "startMatchingV2"
  | "startVideoCall"
  | "submitLocationStep"
  | "submitMutualityStep"
  | "submitNodeV2"
  | "updateCalendarEvent"
  | "updateConsultation"
  | "updateCurrentUser"
  | "updateHuman"
  | "updateMatchedHuman"
  | "updateMatchedHumanWorries"
  | "updateProfessional"
  | "updateProfessionalSpecialties"
  | "userJoinedConsultation"
  | "verifyProfessional"
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  acceptInvite?: FieldPolicy<any> | FieldReadFunction<any>;
  answerQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  beginTrack?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelConsultation?: FieldPolicy<any> | FieldReadFunction<any>;
  choosePasswordV2?: FieldPolicy<any> | FieldReadFunction<any>;
  completeHumanRegistration?: FieldPolicy<any> | FieldReadFunction<any>;
  confirmConsultation?: FieldPolicy<any> | FieldReadFunction<any>;
  copyCalendarEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  createCalendarEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  createConsultation?: FieldPolicy<any> | FieldReadFunction<any>;
  createPermanentHuman?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteCalendarEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  enable2FA?: FieldPolicy<any> | FieldReadFunction<any>;
  inviteClient?: FieldPolicy<any> | FieldReadFunction<any>;
  joinOrganizationByEmailOrCode?: FieldPolicy<any> | FieldReadFunction<any>;
  joinOrganizationByEmailOrCodeV2?: FieldPolicy<any> | FieldReadFunction<any>;
  login?: FieldPolicy<any> | FieldReadFunction<any>;
  loginV2?: FieldPolicy<any> | FieldReadFunction<any>;
  loginWithSSO?: FieldPolicy<any> | FieldReadFunction<any>;
  notifyOtherParty?: FieldPolicy<any> | FieldReadFunction<any>;
  payForConsultation?: FieldPolicy<any> | FieldReadFunction<any>;
  refreshAccessToken?: FieldPolicy<any> | FieldReadFunction<any>;
  refreshAccessTokenV2?: FieldPolicy<any> | FieldReadFunction<any>;
  registerHuman?: FieldPolicy<any> | FieldReadFunction<any>;
  registerHumanV2?: FieldPolicy<any> | FieldReadFunction<any>;
  registerHumanWithInvite?: FieldPolicy<any> | FieldReadFunction<any>;
  registerProfessional?: FieldPolicy<any> | FieldReadFunction<any>;
  request2FASecrets?: FieldPolicy<any> | FieldReadFunction<any>;
  requestReimbursement?: FieldPolicy<any> | FieldReadFunction<any>;
  rescheduleConsultation?: FieldPolicy<any> | FieldReadFunction<any>;
  resetPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  sendAppInvite?: FieldPolicy<any> | FieldReadFunction<any>;
  setCurrentUserLanguage?: FieldPolicy<any> | FieldReadFunction<any>;
  startMatching?: FieldPolicy<any> | FieldReadFunction<any>;
  startMatchingV2?: FieldPolicy<any> | FieldReadFunction<any>;
  startVideoCall?: FieldPolicy<any> | FieldReadFunction<any>;
  submitLocationStep?: FieldPolicy<any> | FieldReadFunction<any>;
  submitMutualityStep?: FieldPolicy<any> | FieldReadFunction<any>;
  submitNodeV2?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCalendarEvent?: FieldPolicy<any> | FieldReadFunction<any>;
  updateConsultation?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCurrentUser?: FieldPolicy<any> | FieldReadFunction<any>;
  updateHuman?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMatchedHuman?: FieldPolicy<any> | FieldReadFunction<any>;
  updateMatchedHumanWorries?: FieldPolicy<any> | FieldReadFunction<any>;
  updateProfessional?: FieldPolicy<any> | FieldReadFunction<any>;
  updateProfessionalSpecialties?: FieldPolicy<any> | FieldReadFunction<any>;
  userJoinedConsultation?: FieldPolicy<any> | FieldReadFunction<any>;
  verifyProfessional?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutualityKeySpecifier = (
  | "id"
  | "logoUrl"
  | "preferred"
  | "translationKey"
  | MutualityKeySpecifier
)[];
export type MutualityFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  logoUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  preferred?: FieldPolicy<any> | FieldReadFunction<any>;
  translationKey?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NodeApplicableQuestionKeySpecifier = (
  | "question"
  | "slider"
  | NodeApplicableQuestionKeySpecifier
)[];
export type NodeApplicableQuestionFieldPolicy = {
  question?: FieldPolicy<any> | FieldReadFunction<any>;
  slider?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NodeCategoryQuestionKeySpecifier = (
  | "options"
  | "question"
  | NodeCategoryQuestionKeySpecifier
)[];
export type NodeCategoryQuestionFieldPolicy = {
  options?: FieldPolicy<any> | FieldReadFunction<any>;
  question?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NodeContentKeySpecifier = (
  | "content"
  | "id"
  | "professional"
  | "type"
  | NodeContentKeySpecifier
)[];
export type NodeContentFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  professional?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NodeOptionKeySpecifier = ("id" | "text" | NodeOptionKeySpecifier)[];
export type NodeOptionFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  text?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NodeSituationKeySpecifier = (
  | "id"
  | "image"
  | NodeSituationKeySpecifier
)[];
export type NodeSituationFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  image?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NodeSliderKeySpecifier = (
  | "endLabel"
  | "startLabel"
  | "steps"
  | NodeSliderKeySpecifier
)[];
export type NodeSliderFieldPolicy = {
  endLabel?: FieldPolicy<any> | FieldReadFunction<any>;
  startLabel?: FieldPolicy<any> | FieldReadFunction<any>;
  steps?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OptionListNodeContentKeySpecifier = (
  | "body"
  | "buttonType"
  | "headerImage"
  | "id"
  | "maxSelected"
  | "minSelected"
  | "options"
  | "progress"
  | OptionListNodeContentKeySpecifier
)[];
export type OptionListNodeContentFieldPolicy = {
  body?: FieldPolicy<any> | FieldReadFunction<any>;
  buttonType?: FieldPolicy<any> | FieldReadFunction<any>;
  headerImage?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  maxSelected?: FieldPolicy<any> | FieldReadFunction<any>;
  minSelected?: FieldPolicy<any> | FieldReadFunction<any>;
  options?: FieldPolicy<any> | FieldReadFunction<any>;
  progress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationKeySpecifier = (
  | "code"
  | "color"
  | "contractDashboardUrl"
  | "creditsPerHuman"
  | "emailRegex"
  | "ggtDashboardUrl"
  | "ggtEnabled"
  | "id"
  | "interestDashboardUrl"
  | "logoUrl"
  | "mentalWellbeingDashboardUrl"
  | "mindlabBusinessId"
  | "mindlabEnabled"
  | "name"
  | "profile"
  | "slug"
  | "ssoEnabled"
  | "usageDashboardUrl"
  | "videoEnabled"
  | OrganizationKeySpecifier
)[];
export type OrganizationFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
  color?: FieldPolicy<any> | FieldReadFunction<any>;
  contractDashboardUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  creditsPerHuman?: FieldPolicy<any> | FieldReadFunction<any>;
  emailRegex?: FieldPolicy<any> | FieldReadFunction<any>;
  ggtDashboardUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  ggtEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  interestDashboardUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  logoUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  mentalWellbeingDashboardUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  mindlabBusinessId?: FieldPolicy<any> | FieldReadFunction<any>;
  mindlabEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  profile?: FieldPolicy<any> | FieldReadFunction<any>;
  slug?: FieldPolicy<any> | FieldReadFunction<any>;
  ssoEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  usageDashboardUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  videoEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PageInfoKeySpecifier = (
  | "endCursor"
  | "hasNextPage"
  | "startCursor"
  | PageInfoKeySpecifier
)[];
export type PageInfoFieldPolicy = {
  endCursor?: FieldPolicy<any> | FieldReadFunction<any>;
  hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>;
  startCursor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaywallNodeContentKeySpecifier = (
  | "id"
  | PaywallNodeContentKeySpecifier
)[];
export type PaywallNodeContentFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PracticeKeySpecifier = (
  | "address"
  | "chatTherapy"
  | "createdAt"
  | "id"
  | "offlineTherapy"
  | "onlineTherapy"
  | "phoneTherapy"
  | "updatedAt"
  | PracticeKeySpecifier
)[];
export type PracticeFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  chatTherapy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  offlineTherapy?: FieldPolicy<any> | FieldReadFunction<any>;
  onlineTherapy?: FieldPolicy<any> | FieldReadFunction<any>;
  phoneTherapy?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProfessionalKeySpecifier = (
  | "age"
  | "availability"
  | "avatar"
  | "billingInformation"
  | "birthDate"
  | "clientStop"
  | "clients"
  | "cmNumber"
  | "consultationLanguages"
  | "createdAt"
  | "deletedAt"
  | "email"
  | "experienceSince"
  | "firstName"
  | "forBgt"
  | "gender"
  | "id"
  | "isFirstLine"
  | "lastName"
  | "licenseFiles"
  | "onboardedAt"
  | "organizationId"
  | "phone"
  | "practice"
  | "preferredLanguage"
  | "profile"
  | "profileDescription"
  | "roleType"
  | "specialties"
  | "standardDuration"
  | "standardRate"
  | "supervision"
  | "therapyTypes"
  | "timezone"
  | "training"
  | "type"
  | "updatedAt"
  | "user"
  | "uuid"
  | "verifiedAt"
  | "visumNumber"
  | "website"
  | ProfessionalKeySpecifier
)[];
export type ProfessionalFieldPolicy = {
  age?: FieldPolicy<any> | FieldReadFunction<any>;
  availability?: FieldPolicy<any> | FieldReadFunction<any>;
  avatar?: FieldPolicy<any> | FieldReadFunction<any>;
  billingInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  birthDate?: FieldPolicy<any> | FieldReadFunction<any>;
  clientStop?: FieldPolicy<any> | FieldReadFunction<any>;
  clients?: FieldPolicy<any> | FieldReadFunction<any>;
  cmNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  consultationLanguages?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  experienceSince?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  forBgt?: FieldPolicy<any> | FieldReadFunction<any>;
  gender?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isFirstLine?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  licenseFiles?: FieldPolicy<any> | FieldReadFunction<any>;
  onboardedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  practice?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredLanguage?: FieldPolicy<any> | FieldReadFunction<any>;
  profile?: FieldPolicy<any> | FieldReadFunction<any>;
  profileDescription?: FieldPolicy<any> | FieldReadFunction<any>;
  roleType?: FieldPolicy<any> | FieldReadFunction<any>;
  specialties?: FieldPolicy<any> | FieldReadFunction<any>;
  standardDuration?: FieldPolicy<any> | FieldReadFunction<any>;
  standardRate?: FieldPolicy<any> | FieldReadFunction<any>;
  supervision?: FieldPolicy<any> | FieldReadFunction<any>;
  therapyTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  timezone?: FieldPolicy<any> | FieldReadFunction<any>;
  training?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  uuid?: FieldPolicy<any> | FieldReadFunction<any>;
  verifiedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  visumNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  website?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProfessionalEdgeKeySpecifier = (
  | "cursor"
  | "node"
  | ProfessionalEdgeKeySpecifier
)[];
export type ProfessionalEdgeFieldPolicy = {
  cursor?: FieldPolicy<any> | FieldReadFunction<any>;
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProfessionalForTrackKeySpecifier = (
  | "firstName"
  | "id"
  | "lastName"
  | ProfessionalForTrackKeySpecifier
)[];
export type ProfessionalForTrackFieldPolicy = {
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProfessionalHumanConnectionKeySpecifier = (
  | "edges"
  | ProfessionalHumanConnectionKeySpecifier
)[];
export type ProfessionalHumanConnectionFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProfessionalHumanEdgeKeySpecifier = (
  | "node"
  | ProfessionalHumanEdgeKeySpecifier
)[];
export type ProfessionalHumanEdgeFieldPolicy = {
  node?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProfessionalsConnectionKeySpecifier = (
  | "edges"
  | "pageInfo"
  | "totalCount"
  | ProfessionalsConnectionKeySpecifier
)[];
export type ProfessionalsConnectionFieldPolicy = {
  edges?: FieldPolicy<any> | FieldReadFunction<any>;
  pageInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | "allConsultationsForHuman"
  | "allConsultationsForProfessional"
  | "availableTracks"
  | "calendarEvents"
  | "cashTransactionsByConsultationId"
  | "checkIfHumanIsAClient"
  | "clientsForProfessional"
  | "consultation"
  | "consultationsForHumanAndProfessional"
  | "consultationsForProfessionalAndHuman"
  | "countries"
  | "currentMatchedHuman"
  | "currentNodeContentV2"
  | "currentUser"
  | "findMatches"
  | "getQuestionById"
  | "getSSOUrl"
  | "human"
  | "landingPageUniqueVisitors"
  | "lastCashTransactionByConsultationId"
  | "mindlabUser"
  | "mindlabUsers"
  | "mobileVersionStatus"
  | "mutualities"
  | "myProfessionalConsultations"
  | "organizationByEmailOrCode"
  | "organizationById"
  | "organizationBySlug"
  | "organizations"
  | "professional"
  | "professionalExists"
  | "professionals"
  | "professionalsForBgt"
  | "professionalsForCurrentHuman"
  | "questionForUserAtEntryPoint"
  | "sendNotification"
  | "specialties"
  | "track"
  | "trackArtifact"
  | "tracksWithArtifacts"
  | "videoConsultBarchartEmployeesWithAnAmountOfConsults"
  | "videoConsultCardAverageConsultPerEmployee"
  | "videoConsultCardCancelledConsults"
  | "videoConsultCardCompletedConsults"
  | "videoConsultCardNoShowConsults"
  | "videoConsultFunnel"
  | "wellbeingChallenges"
  | "wellbeingEnergyGauge"
  | "wellbeingProductivityGauge"
  | "wellbeingStressGauge"
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  allConsultationsForHuman?: FieldPolicy<any> | FieldReadFunction<any>;
  allConsultationsForProfessional?: FieldPolicy<any> | FieldReadFunction<any>;
  availableTracks?: FieldPolicy<any> | FieldReadFunction<any>;
  calendarEvents?: FieldPolicy<any> | FieldReadFunction<any>;
  cashTransactionsByConsultationId?: FieldPolicy<any> | FieldReadFunction<any>;
  checkIfHumanIsAClient?: FieldPolicy<any> | FieldReadFunction<any>;
  clientsForProfessional?: FieldPolicy<any> | FieldReadFunction<any>;
  consultation?: FieldPolicy<any> | FieldReadFunction<any>;
  consultationsForHumanAndProfessional?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  consultationsForProfessionalAndHuman?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  countries?: FieldPolicy<any> | FieldReadFunction<any>;
  currentMatchedHuman?: FieldPolicy<any> | FieldReadFunction<any>;
  currentNodeContentV2?: FieldPolicy<any> | FieldReadFunction<any>;
  currentUser?: FieldPolicy<any> | FieldReadFunction<any>;
  findMatches?: FieldPolicy<any> | FieldReadFunction<any>;
  getQuestionById?: FieldPolicy<any> | FieldReadFunction<any>;
  getSSOUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  human?: FieldPolicy<any> | FieldReadFunction<any>;
  landingPageUniqueVisitors?: FieldPolicy<any> | FieldReadFunction<any>;
  lastCashTransactionByConsultationId?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  mindlabUser?: FieldPolicy<any> | FieldReadFunction<any>;
  mindlabUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  mobileVersionStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  mutualities?: FieldPolicy<any> | FieldReadFunction<any>;
  myProfessionalConsultations?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationByEmailOrCode?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationById?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationBySlug?: FieldPolicy<any> | FieldReadFunction<any>;
  organizations?: FieldPolicy<any> | FieldReadFunction<any>;
  professional?: FieldPolicy<any> | FieldReadFunction<any>;
  professionalExists?: FieldPolicy<any> | FieldReadFunction<any>;
  professionals?: FieldPolicy<any> | FieldReadFunction<any>;
  professionalsForBgt?: FieldPolicy<any> | FieldReadFunction<any>;
  professionalsForCurrentHuman?: FieldPolicy<any> | FieldReadFunction<any>;
  questionForUserAtEntryPoint?: FieldPolicy<any> | FieldReadFunction<any>;
  sendNotification?: FieldPolicy<any> | FieldReadFunction<any>;
  specialties?: FieldPolicy<any> | FieldReadFunction<any>;
  track?: FieldPolicy<any> | FieldReadFunction<any>;
  trackArtifact?: FieldPolicy<any> | FieldReadFunction<any>;
  tracksWithArtifacts?: FieldPolicy<any> | FieldReadFunction<any>;
  videoConsultBarchartEmployeesWithAnAmountOfConsults?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  videoConsultCardAverageConsultPerEmployee?:
    | FieldPolicy<any>
    | FieldReadFunction<any>;
  videoConsultCardCancelledConsults?: FieldPolicy<any> | FieldReadFunction<any>;
  videoConsultCardCompletedConsults?: FieldPolicy<any> | FieldReadFunction<any>;
  videoConsultCardNoShowConsults?: FieldPolicy<any> | FieldReadFunction<any>;
  videoConsultFunnel?: FieldPolicy<any> | FieldReadFunction<any>;
  wellbeingChallenges?: FieldPolicy<any> | FieldReadFunction<any>;
  wellbeingEnergyGauge?: FieldPolicy<any> | FieldReadFunction<any>;
  wellbeingProductivityGauge?: FieldPolicy<any> | FieldReadFunction<any>;
  wellbeingStressGauge?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QuestionKeySpecifier = (
  | "category"
  | "component"
  | "id"
  | "nextQuestionId"
  | "options"
  | "questionType"
  | "title"
  | "translationKey"
  | QuestionKeySpecifier
)[];
export type QuestionFieldPolicy = {
  category?: FieldPolicy<any> | FieldReadFunction<any>;
  component?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nextQuestionId?: FieldPolicy<any> | FieldReadFunction<any>;
  options?: FieldPolicy<any> | FieldReadFunction<any>;
  questionType?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  translationKey?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RegionKeySpecifier = (
  | "id"
  | "logoUrl"
  | "translationKey"
  | RegionKeySpecifier
)[];
export type RegionFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  logoUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  translationKey?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReimbursementKeySpecifier = (
  | "createdAt"
  | "id"
  | "nationalId"
  | "reimbursementSubmission"
  | "updatedAt"
  | ReimbursementKeySpecifier
)[];
export type ReimbursementFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  nationalId?: FieldPolicy<any> | FieldReadFunction<any>;
  reimbursementSubmission?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReimbursementSubmissionKeySpecifier = (
  | "createdAt"
  | "id"
  | "submittedAt"
  | "updatedAt"
  | ReimbursementSubmissionKeySpecifier
)[];
export type ReimbursementSubmissionFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  submittedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RestrictedClientKeySpecifier = (
  | "createdAt"
  | "description"
  | "email"
  | "human"
  | "humanId"
  | "id"
  | "professionalId"
  | "status"
  | RestrictedClientKeySpecifier
)[];
export type RestrictedClientFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  human?: FieldPolicy<any> | FieldReadFunction<any>;
  humanId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  professionalId?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type Secrets2FAKeySpecifier = (
  | "qrCode"
  | "secret"
  | Secrets2FAKeySpecifier
)[];
export type Secrets2FAFieldPolicy = {
  qrCode?: FieldPolicy<any> | FieldReadFunction<any>;
  secret?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SituationApplicationNodeContentKeySpecifier = (
  | "applicableQuestion"
  | "buttonType"
  | "categoryQuestion"
  | "id"
  | "situations"
  | SituationApplicationNodeContentKeySpecifier
)[];
export type SituationApplicationNodeContentFieldPolicy = {
  applicableQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  buttonType?: FieldPolicy<any> | FieldReadFunction<any>;
  categoryQuestion?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  situations?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SpecialtiesThemeComboKeySpecifier = (
  | "id"
  | "primaryColor"
  | "secondaryColor"
  | "subcategories"
  | "theme"
  | "tooltip"
  | "translationKey"
  | SpecialtiesThemeComboKeySpecifier
)[];
export type SpecialtiesThemeComboFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  primaryColor?: FieldPolicy<any> | FieldReadFunction<any>;
  secondaryColor?: FieldPolicy<any> | FieldReadFunction<any>;
  subcategories?: FieldPolicy<any> | FieldReadFunction<any>;
  theme?: FieldPolicy<any> | FieldReadFunction<any>;
  tooltip?: FieldPolicy<any> | FieldReadFunction<any>;
  translationKey?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StartMatchingResponseKeySpecifier = (
  | "matchedHumanId"
  | "matchingToken"
  | StartMatchingResponseKeySpecifier
)[];
export type StartMatchingResponseFieldPolicy = {
  matchedHumanId?: FieldPolicy<any> | FieldReadFunction<any>;
  matchingToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubCategoryKeySpecifier = (
  | "id"
  | "name"
  | "tooltip"
  | "translationKey"
  | SubCategoryKeySpecifier
)[];
export type SubCategoryFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  tooltip?: FieldPolicy<any> | FieldReadFunction<any>;
  translationKey?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SubmitMatchingStepResponseKeySpecifier = (
  | "matchingToken"
  | "nextStep"
  | SubmitMatchingStepResponseKeySpecifier
)[];
export type SubmitMatchingStepResponseFieldPolicy = {
  matchingToken?: FieldPolicy<any> | FieldReadFunction<any>;
  nextStep?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TokenResponseKeySpecifier = (
  | "accessToken"
  | "mindlabToken"
  | "refreshToken"
  | TokenResponseKeySpecifier
)[];
export type TokenResponseFieldPolicy = {
  accessToken?: FieldPolicy<any> | FieldReadFunction<any>;
  mindlabToken?: FieldPolicy<any> | FieldReadFunction<any>;
  refreshToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TrackKeySpecifier = (
  | "artifacts"
  | "beta"
  | "currentUserCompleted"
  | "currentUserProfessional"
  | "currentUserStarted"
  | "description"
  | "detailLink"
  | "duration"
  | "id"
  | "imageUrl"
  | "sections"
  | "title"
  | TrackKeySpecifier
)[];
export type TrackFieldPolicy = {
  artifacts?: FieldPolicy<any> | FieldReadFunction<any>;
  beta?: FieldPolicy<any> | FieldReadFunction<any>;
  currentUserCompleted?: FieldPolicy<any> | FieldReadFunction<any>;
  currentUserProfessional?: FieldPolicy<any> | FieldReadFunction<any>;
  currentUserStarted?: FieldPolicy<any> | FieldReadFunction<any>;
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  detailLink?: FieldPolicy<any> | FieldReadFunction<any>;
  duration?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  sections?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TrackArtifactKeySpecifier = (
  | "content"
  | "headerImage"
  | "id"
  | "title"
  | TrackArtifactKeySpecifier
)[];
export type TrackArtifactFieldPolicy = {
  content?: FieldPolicy<any> | FieldReadFunction<any>;
  headerImage?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TrackSectionKeySpecifier = (
  | "duration"
  | "id"
  | "imageUrl"
  | "steps"
  | "title"
  | "type"
  | TrackSectionKeySpecifier
)[];
export type TrackSectionFieldPolicy = {
  duration?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  imageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  steps?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserKeySpecifier = (
  | "adminOfOrganization"
  | "age"
  | "birthDate"
  | "createdAt"
  | "email"
  | "firstName"
  | "human"
  | "id"
  | "lastName"
  | "mindlabNewsletter"
  | "mindlabUserId"
  | "onboardedAt"
  | "phone"
  | "preferredLanguage"
  | "professional"
  | "timezone"
  | "twoFactorAuthEnabled"
  | UserKeySpecifier
)[];
export type UserFieldPolicy = {
  adminOfOrganization?: FieldPolicy<any> | FieldReadFunction<any>;
  age?: FieldPolicy<any> | FieldReadFunction<any>;
  birthDate?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  human?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  mindlabNewsletter?: FieldPolicy<any> | FieldReadFunction<any>;
  mindlabUserId?: FieldPolicy<any> | FieldReadFunction<any>;
  onboardedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredLanguage?: FieldPolicy<any> | FieldReadFunction<any>;
  professional?: FieldPolicy<any> | FieldReadFunction<any>;
  timezone?: FieldPolicy<any> | FieldReadFunction<any>;
  twoFactorAuthEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserFileKeySpecifier = (
  | "collectionName"
  | "fileName"
  | "id"
  | "mimeType"
  | "name"
  | "url"
  | UserFileKeySpecifier
)[];
export type UserFileFieldPolicy = {
  collectionName?: FieldPolicy<any> | FieldReadFunction<any>;
  fileName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  mimeType?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VideoCallKeySpecifier = (
  | "attendeeInfo"
  | "meetingInfo"
  | VideoCallKeySpecifier
)[];
export type VideoCallFieldPolicy = {
  attendeeInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  meetingInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WaitNodeContentKeySpecifier = (
  | "amountOfMinutes"
  | "forCheckIn"
  | "id"
  | WaitNodeContentKeySpecifier
)[];
export type WaitNodeContentFieldPolicy = {
  amountOfMinutes?: FieldPolicy<any> | FieldReadFunction<any>;
  forCheckIn?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WithDatesKeySpecifier = (
  | "createdAt"
  | "updatedAt"
  | WithDatesKeySpecifier
)[];
export type WithDatesFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WithIdKeySpecifier = ("id" | WithIdKeySpecifier)[];
export type WithIdFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type mutualitiesResponseKeySpecifier = (
  | "otherMutualities"
  | "regionMutualities"
  | mutualitiesResponseKeySpecifier
)[];
export type mutualitiesResponseFieldPolicy = {
  otherMutualities?: FieldPolicy<any> | FieldReadFunction<any>;
  regionMutualities?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type wellbeingChallengesResultKeySpecifier = (
  | "count"
  | "id"
  | "name"
  | wellbeingChallengesResultKeySpecifier
)[];
export type wellbeingChallengesResultFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  Address?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | AddressKeySpecifier
      | (() => undefined | AddressKeySpecifier);
    fields?: AddressFieldPolicy;
  };
  Avatar?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | AvatarKeySpecifier
      | (() => undefined | AvatarKeySpecifier);
    fields?: AvatarFieldPolicy;
  };
  BarchartItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | BarchartItemKeySpecifier
      | (() => undefined | BarchartItemKeySpecifier);
    fields?: BarchartItemFieldPolicy;
  };
  BillingInformation?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | BillingInformationKeySpecifier
      | (() => undefined | BillingInformationKeySpecifier);
    fields?: BillingInformationFieldPolicy;
  };
  CalendarEvent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CalendarEventKeySpecifier
      | (() => undefined | CalendarEventKeySpecifier);
    fields?: CalendarEventFieldPolicy;
  };
  CashTransaction?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CashTransactionKeySpecifier
      | (() => undefined | CashTransactionKeySpecifier);
    fields?: CashTransactionFieldPolicy;
  };
  ChimeAttendeeInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ChimeAttendeeInfoKeySpecifier
      | (() => undefined | ChimeAttendeeInfoKeySpecifier);
    fields?: ChimeAttendeeInfoFieldPolicy;
  };
  ChimeMediaPlacement?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ChimeMediaPlacementKeySpecifier
      | (() => undefined | ChimeMediaPlacementKeySpecifier);
    fields?: ChimeMediaPlacementFieldPolicy;
  };
  ChimeMeetingInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ChimeMeetingInfoKeySpecifier
      | (() => undefined | ChimeMeetingInfoKeySpecifier);
    fields?: ChimeMeetingInfoFieldPolicy;
  };
  Client?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ClientKeySpecifier
      | (() => undefined | ClientKeySpecifier);
    fields?: ClientFieldPolicy;
  };
  ClientEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ClientEdgeKeySpecifier
      | (() => undefined | ClientEdgeKeySpecifier);
    fields?: ClientEdgeFieldPolicy;
  };
  ClientsConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ClientsConnectionKeySpecifier
      | (() => undefined | ClientsConnectionKeySpecifier);
    fields?: ClientsConnectionFieldPolicy;
  };
  Consultation?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ConsultationKeySpecifier
      | (() => undefined | ConsultationKeySpecifier);
    fields?: ConsultationFieldPolicy;
  };
  ConsultationEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ConsultationEdgeKeySpecifier
      | (() => undefined | ConsultationEdgeKeySpecifier);
    fields?: ConsultationEdgeFieldPolicy;
  };
  ConsultationsConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ConsultationsConnectionKeySpecifier
      | (() => undefined | ConsultationsConnectionKeySpecifier);
    fields?: ConsultationsConnectionFieldPolicy;
  };
  Country?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CountryKeySpecifier
      | (() => undefined | CountryKeySpecifier);
    fields?: CountryFieldPolicy;
  };
  CreditTransaction?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | CreditTransactionKeySpecifier
      | (() => undefined | CreditTransactionKeySpecifier);
    fields?: CreditTransactionFieldPolicy;
  };
  DifferenceGaugeResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | DifferenceGaugeResultKeySpecifier
      | (() => undefined | DifferenceGaugeResultKeySpecifier);
    fields?: DifferenceGaugeResultFieldPolicy;
  };
  DistributeListNodeContent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | DistributeListNodeContentKeySpecifier
      | (() => undefined | DistributeListNodeContentKeySpecifier);
    fields?: DistributeListNodeContentFieldPolicy;
  };
  EndOfTrackNodeContent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | EndOfTrackNodeContentKeySpecifier
      | (() => undefined | EndOfTrackNodeContentKeySpecifier);
    fields?: EndOfTrackNodeContentFieldPolicy;
  };
  ExtendedProps?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ExtendedPropsKeySpecifier
      | (() => undefined | ExtendedPropsKeySpecifier);
    fields?: ExtendedPropsFieldPolicy;
  };
  FullCalendarEvent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | FullCalendarEventKeySpecifier
      | (() => undefined | FullCalendarEventKeySpecifier);
    fields?: FullCalendarEventFieldPolicy;
  };
  FunnelItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | FunnelItemKeySpecifier
      | (() => undefined | FunnelItemKeySpecifier);
    fields?: FunnelItemFieldPolicy;
  };
  GrowthStep?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | GrowthStepKeySpecifier
      | (() => undefined | GrowthStepKeySpecifier);
    fields?: GrowthStepFieldPolicy;
  };
  Human?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | HumanKeySpecifier
      | (() => undefined | HumanKeySpecifier);
    fields?: HumanFieldPolicy;
  };
  HumanWithTokens?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | HumanWithTokensKeySpecifier
      | (() => undefined | HumanWithTokensKeySpecifier);
    fields?: HumanWithTokensFieldPolicy;
  };
  LineGraphItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LineGraphItemKeySpecifier
      | (() => undefined | LineGraphItemKeySpecifier);
    fields?: LineGraphItemFieldPolicy;
  };
  LoginResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LoginResponseKeySpecifier
      | (() => undefined | LoginResponseKeySpecifier);
    fields?: LoginResponseFieldPolicy;
  };
  LoginV2FailureResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | LoginV2FailureResponseKeySpecifier
      | (() => undefined | LoginV2FailureResponseKeySpecifier);
    fields?: LoginV2FailureResponseFieldPolicy;
  };
  Match?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MatchKeySpecifier
      | (() => undefined | MatchKeySpecifier);
    fields?: MatchFieldPolicy;
  };
  MatchedHuman?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MatchedHumanKeySpecifier
      | (() => undefined | MatchedHumanKeySpecifier);
    fields?: MatchedHumanFieldPolicy;
  };
  Matches?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MatchesKeySpecifier
      | (() => undefined | MatchesKeySpecifier);
    fields?: MatchesFieldPolicy;
  };
  Message?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MessageKeySpecifier
      | (() => undefined | MessageKeySpecifier);
    fields?: MessageFieldPolicy;
  };
  MultiLanguageBoolean?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MultiLanguageBooleanKeySpecifier
      | (() => undefined | MultiLanguageBooleanKeySpecifier);
    fields?: MultiLanguageBooleanFieldPolicy;
  };
  MultiLanguageString?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MultiLanguageStringKeySpecifier
      | (() => undefined | MultiLanguageStringKeySpecifier);
    fields?: MultiLanguageStringFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MutationKeySpecifier
      | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  Mutuality?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | MutualityKeySpecifier
      | (() => undefined | MutualityKeySpecifier);
    fields?: MutualityFieldPolicy;
  };
  NodeApplicableQuestion?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | NodeApplicableQuestionKeySpecifier
      | (() => undefined | NodeApplicableQuestionKeySpecifier);
    fields?: NodeApplicableQuestionFieldPolicy;
  };
  NodeCategoryQuestion?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | NodeCategoryQuestionKeySpecifier
      | (() => undefined | NodeCategoryQuestionKeySpecifier);
    fields?: NodeCategoryQuestionFieldPolicy;
  };
  NodeContent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | NodeContentKeySpecifier
      | (() => undefined | NodeContentKeySpecifier);
    fields?: NodeContentFieldPolicy;
  };
  NodeOption?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | NodeOptionKeySpecifier
      | (() => undefined | NodeOptionKeySpecifier);
    fields?: NodeOptionFieldPolicy;
  };
  NodeSituation?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | NodeSituationKeySpecifier
      | (() => undefined | NodeSituationKeySpecifier);
    fields?: NodeSituationFieldPolicy;
  };
  NodeSlider?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | NodeSliderKeySpecifier
      | (() => undefined | NodeSliderKeySpecifier);
    fields?: NodeSliderFieldPolicy;
  };
  OptionListNodeContent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | OptionListNodeContentKeySpecifier
      | (() => undefined | OptionListNodeContentKeySpecifier);
    fields?: OptionListNodeContentFieldPolicy;
  };
  Organization?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | OrganizationKeySpecifier
      | (() => undefined | OrganizationKeySpecifier);
    fields?: OrganizationFieldPolicy;
  };
  PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PageInfoKeySpecifier
      | (() => undefined | PageInfoKeySpecifier);
    fields?: PageInfoFieldPolicy;
  };
  PaywallNodeContent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PaywallNodeContentKeySpecifier
      | (() => undefined | PaywallNodeContentKeySpecifier);
    fields?: PaywallNodeContentFieldPolicy;
  };
  Practice?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | PracticeKeySpecifier
      | (() => undefined | PracticeKeySpecifier);
    fields?: PracticeFieldPolicy;
  };
  Professional?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ProfessionalKeySpecifier
      | (() => undefined | ProfessionalKeySpecifier);
    fields?: ProfessionalFieldPolicy;
  };
  ProfessionalEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ProfessionalEdgeKeySpecifier
      | (() => undefined | ProfessionalEdgeKeySpecifier);
    fields?: ProfessionalEdgeFieldPolicy;
  };
  ProfessionalForTrack?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ProfessionalForTrackKeySpecifier
      | (() => undefined | ProfessionalForTrackKeySpecifier);
    fields?: ProfessionalForTrackFieldPolicy;
  };
  ProfessionalHumanConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ProfessionalHumanConnectionKeySpecifier
      | (() => undefined | ProfessionalHumanConnectionKeySpecifier);
    fields?: ProfessionalHumanConnectionFieldPolicy;
  };
  ProfessionalHumanEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ProfessionalHumanEdgeKeySpecifier
      | (() => undefined | ProfessionalHumanEdgeKeySpecifier);
    fields?: ProfessionalHumanEdgeFieldPolicy;
  };
  ProfessionalsConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ProfessionalsConnectionKeySpecifier
      | (() => undefined | ProfessionalsConnectionKeySpecifier);
    fields?: ProfessionalsConnectionFieldPolicy;
  };
  Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | QueryKeySpecifier
      | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  Question?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | QuestionKeySpecifier
      | (() => undefined | QuestionKeySpecifier);
    fields?: QuestionFieldPolicy;
  };
  Region?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RegionKeySpecifier
      | (() => undefined | RegionKeySpecifier);
    fields?: RegionFieldPolicy;
  };
  Reimbursement?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ReimbursementKeySpecifier
      | (() => undefined | ReimbursementKeySpecifier);
    fields?: ReimbursementFieldPolicy;
  };
  ReimbursementSubmission?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | ReimbursementSubmissionKeySpecifier
      | (() => undefined | ReimbursementSubmissionKeySpecifier);
    fields?: ReimbursementSubmissionFieldPolicy;
  };
  RestrictedClient?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | RestrictedClientKeySpecifier
      | (() => undefined | RestrictedClientKeySpecifier);
    fields?: RestrictedClientFieldPolicy;
  };
  Secrets2FA?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | Secrets2FAKeySpecifier
      | (() => undefined | Secrets2FAKeySpecifier);
    fields?: Secrets2FAFieldPolicy;
  };
  SituationApplicationNodeContent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | SituationApplicationNodeContentKeySpecifier
      | (() => undefined | SituationApplicationNodeContentKeySpecifier);
    fields?: SituationApplicationNodeContentFieldPolicy;
  };
  SpecialtiesThemeCombo?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | SpecialtiesThemeComboKeySpecifier
      | (() => undefined | SpecialtiesThemeComboKeySpecifier);
    fields?: SpecialtiesThemeComboFieldPolicy;
  };
  StartMatchingResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | StartMatchingResponseKeySpecifier
      | (() => undefined | StartMatchingResponseKeySpecifier);
    fields?: StartMatchingResponseFieldPolicy;
  };
  SubCategory?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | SubCategoryKeySpecifier
      | (() => undefined | SubCategoryKeySpecifier);
    fields?: SubCategoryFieldPolicy;
  };
  SubmitMatchingStepResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | SubmitMatchingStepResponseKeySpecifier
      | (() => undefined | SubmitMatchingStepResponseKeySpecifier);
    fields?: SubmitMatchingStepResponseFieldPolicy;
  };
  TokenResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TokenResponseKeySpecifier
      | (() => undefined | TokenResponseKeySpecifier);
    fields?: TokenResponseFieldPolicy;
  };
  Track?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TrackKeySpecifier
      | (() => undefined | TrackKeySpecifier);
    fields?: TrackFieldPolicy;
  };
  TrackArtifact?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TrackArtifactKeySpecifier
      | (() => undefined | TrackArtifactKeySpecifier);
    fields?: TrackArtifactFieldPolicy;
  };
  TrackSection?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | TrackSectionKeySpecifier
      | (() => undefined | TrackSectionKeySpecifier);
    fields?: TrackSectionFieldPolicy;
  };
  User?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier);
    fields?: UserFieldPolicy;
  };
  UserFile?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | UserFileKeySpecifier
      | (() => undefined | UserFileKeySpecifier);
    fields?: UserFileFieldPolicy;
  };
  VideoCall?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | VideoCallKeySpecifier
      | (() => undefined | VideoCallKeySpecifier);
    fields?: VideoCallFieldPolicy;
  };
  WaitNodeContent?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WaitNodeContentKeySpecifier
      | (() => undefined | WaitNodeContentKeySpecifier);
    fields?: WaitNodeContentFieldPolicy;
  };
  WithDates?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WithDatesKeySpecifier
      | (() => undefined | WithDatesKeySpecifier);
    fields?: WithDatesFieldPolicy;
  };
  WithId?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | WithIdKeySpecifier
      | (() => undefined | WithIdKeySpecifier);
    fields?: WithIdFieldPolicy;
  };
  mutualitiesResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | mutualitiesResponseKeySpecifier
      | (() => undefined | mutualitiesResponseKeySpecifier);
    fields?: mutualitiesResponseFieldPolicy;
  };
  wellbeingChallengesResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
    keyFields?:
      | false
      | wellbeingChallengesResultKeySpecifier
      | (() => undefined | wellbeingChallengesResultKeySpecifier);
    fields?: wellbeingChallengesResultFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CalendarFormats: ["CalendarEvent", "FullCalendarEvent"],
    ClientFormats: ["Client", "RestrictedClient"],
    LoginV2Response: ["LoginV2FailureResponse", "TokenResponse"],
    TypedNodeContent: [
      "DistributeListNodeContent",
      "EndOfTrackNodeContent",
      "NodeContent",
      "OptionListNodeContent",
      "PaywallNodeContent",
      "SituationApplicationNodeContent",
      "WaitNodeContent",
    ],
    WithDates: [
      "Address",
      "BillingInformation",
      "Practice",
      "Reimbursement",
      "ReimbursementSubmission",
    ],
    WithId: [
      "Address",
      "BillingInformation",
      "Human",
      "Practice",
      "Professional",
      "Reimbursement",
      "ReimbursementSubmission",
    ],
  },
};
export default result;
