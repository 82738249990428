import { useMatomo } from "@jonkoops/matomo-tracker-react";

export default function useTrackLazyPageView() {
  const { trackPageView: _track } = useMatomo();

  const trackPageView = (pageTitle: string, customDimensions: any[]) =>
    _track({
      customDimensions,
      documentTitle: pageTitle,
    });

  return { trackPageView };
}
