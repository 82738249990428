import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Color from "color";
import SanitizedText from "../../../ui/text/SanitizedText";

const StyledImg = styled("img")<{ logoWidth: number }>(
  ({ theme, logoWidth }) => ({
    marginBottom: 40,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      marginBottom: 50,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 10,
      textAlign: "center",
      width: `calc(${logoWidth}px / 1.5)`,
    },
  }),
);

interface PartnerPageHeaderProps {
  color: string;
  description: string;
  image: string;
  logo: {
    height?: number;
    source: string;
    width: number;
  };
  title: string;
}

const PartnerPageHeader = ({
  image,
  logo,
  title,
  description,
  color,
}: PartnerPageHeaderProps) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundImage: `url(${image})`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        position: "relative",

        [theme.breakpoints.down("lg")]: {
          backgroundSize: "150%",
        },

        [theme.breakpoints.down("md")]: {
          backgroundSize: "220%",
        },

        [theme.breakpoints.down("sm")]: {
          backgroundPosition: "top center",
          backgroundSize: "650%",
        },
      })}
    >
      <Box
        sx={(theme) => ({
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1024px",
          minHeight: "400px",
          paddingBottom: "200px",
          paddingLeft: "115px",
          paddingRight: "115px",
          paddingTop: "100px",
          position: "relative",
          width: "auto",

          [theme.breakpoints.down("md")]: {
            paddingLeft: "70px",
            paddingRight: "70px",
          },
          [theme.breakpoints.down("sm")]: {
            left: 0,
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "10px",
            top: 0,
          },
        })}
      >
        <StyledImg
          height={logo.height}
          logoWidth={logo.width}
          src={logo.source}
          width={logo.width}
        />
        <Box>
          <Typography
            sx={(theme) => ({
              background: `${Color(color).alpha(0.6).string()}`,
              borderRadius: "15px",
              color: `${Color(color).alpha(0.6).isDark() ? "#fff" : "#000"}`,
              display: "inline-block",
              fontFamily: "Inter",
              fontSize: "31px",
              fontWeight: 600,
              letterSpacing: "0.4px",
              lineHeight: 1.4,
              marginBottom: "40px",
              padding: "25px",

              [theme.breakpoints.down("sm")]: {
                fontSize: "18px",
                marginBottom: "20px",
              },
            })}
          >
            <SanitizedText input={title} />
          </Typography>
          <Typography
            sx={(theme) => ({
              background: `${Color(color).alpha(0.6).string()}`,
              borderRadius: "15px",
              color: `${Color(color).alpha(0.6).isDark() ? "#FFF" : "#000"}`,
              fontFamily: "Inter",
              fontSize: 18,
              fontWeight: 400,
              letterSpacing: "0.4px",
              overflowWrap: "break-word",
              padding: "25px",

              [theme.breakpoints.down("sm")]: {
                fontSize: 12,
                lineHeight: "18px",
              },
            })}
          >
            <SanitizedText input={description} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PartnerPageHeader;
