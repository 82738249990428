import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isIE } from "react-device-detect";
import { useTranslation } from "react-i18next";
import React from "react";
import SanitizedText from "../../../../ui/text/SanitizedText";
import BloomUpLogo from "../../../../illustrations/BloomUpLogo";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import { PartnerContentBlockProps } from "./PartnerTextBlock";
import PartnerBlock from "./PartnerBlock";

const PREFIX = "PartnerMatchingBlock";

const classes = {
  startMatchingLogo: `${PREFIX}-startMatchingLogo`,
  title: `${PREFIX}-title`,
};

const StyledPartnerBlock = styled(PartnerBlock)(({ theme }) => ({
  [`& .${classes.startMatchingLogo}`]: {
    marginBottom: 20,
    textAlign: "center",
  },

  [`& .${classes.title}`]: {
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "35px",
    marginBottom: 20,
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      lineHeight: "24px",
    },
  },
}));

interface PartnerMatchingBlockProps extends PartnerContentBlockProps {
  matchingBlock: React.ReactNode;
}

export default function PartnerMatchingBlock({
  block,
  color,
  matchingBlock,
}: PartnerMatchingBlockProps) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Ui,
  ]);

  return (
    <StyledPartnerBlock color={color} title="">
      <Box className={classes.startMatchingLogo}>
        <BloomUpLogo width="100px" />
      </Box>
      {block.title && (
        <Typography className={classes.title}>
          <SanitizedText input={block.title} />
        </Typography>
      )}

      {!isIE && matchingBlock}
      {isIE && (
        <Box>
          <SanitizedText
            input={translate(
              "iecompatibility.error",
              "BloomUp en online consultaties met een psycholoog of psychotherapeut worden enkel ondersteund door een moderne browser:<ul><li>Google Chrome</li><li>Microsoft Edge</li><li>Apple Safari</li><li>Opera</li></ul> Gelieve deze pagina in één van de voorgestelde browsers te openen.",
            )}
          />
        </Box>
      )}
    </StyledPartnerBlock>
  );
}
