import { useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import Professional from "../../../data-model/types/professional/Professional";
import { FIND_MATCHES } from "../queries";
import { Match } from "../../__generated__/graphql";

export type IMatch = Omit<Match, "professional"> & {
  professional: Professional;
};

export default function useFindMatches() {
  const [execute, { loading, data, error }] = useLazyQuery(FIND_MATCHES);

  const convertedMatches = useMemo(() => {
    if (!loading && data && data.findMatches && data.findMatches.matches) {
      return data.findMatches.matches.reduce((matches, match) => {
        if (match !== undefined) {
          matches.push({
            ...match,
            professional: new Professional(match.professional as any, true),
          });
        }

        return matches;
      }, [] as IMatch[]);
    }
  }, [data, loading]);

  return {
    error,
    findMatches: execute,
    isMatching: loading,
    matches: convertedMatches,
  };
}
