import { graphql } from "../__generated__";

export const MUTATION_UPDATE_MATCHED_HUMAN = graphql(`
  mutation updateMatchedHuman($input: MatchedHumanInput!) {
    updateMatchedHuman(input: $input) {
      ...MatchedHuman
    }
  }
`);

export const MUTATION_UPDATE_MATCHED_HUMAN_WORRIES = graphql(`
  mutation updateMatchedHumanWorries($input: MatchedHumanWorriesInput!) {
    updateMatchedHumanWorries(input: $input) {
      ...MatchedHuman
    }
  }
`);
