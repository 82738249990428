import React from "react";

const HumanMatchStanding = () => (
  <svg
    height="201px"
    version="1.1"
    viewBox="0 0 201 201"
    width="201px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Human</title>
    <defs>
      <path
        d="M100.516484,200.035573 C146.294955,200.035573 197.297131,167.368668 200.406593,94.3032872 C202.602735,42.6989048 158.663447,0 103.495663,0 C48.3278786,0 4.46185899,38.4720178 0.626373626,91 C-2.07529037,128 27.0884888,168.110145 30.9603635,171.863984 C48.9454802,189.300817 73.4762615,200.035573 100.516484,200.035573 Z"
        id="path-1"
      ></path>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      id="Designs"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Searching-1" transform="translate(-87.000000, -249.000000)">
        <g id="Human" transform="translate(87.000000, 249.000000)">
          <ellipse
            cx="100.516484"
            cy="100.233202"
            fill="#AADDC7"
            id="Background"
            rx="99.8901099"
            ry="99.8023715"
          ></ellipse>
          <mask fill="white" id="mask-2">
            <use xlinkHref="#path-1"></use>
          </mask>
          <g id="Mask"></g>
          <g id="Group" mask="url(#mask-2)">
            <g
              id="A-Human/Standing"
              transform="translate(-50.000000, 30.000000)"
            >
              <g id="Group" transform="translate(82.000000, 0.000000)">
                <path
                  d="M61,77.7068588 C72.4289379,77.7068588 76.5854974,64.0240838 83.0786187,56.5153483 C87.7243138,51.143002 95.6530795,52.7449442 98,45.2237762 C103.901542,26.3111237 88.1061086,13 67.5,13 C46.8938914,13 33,24.6742524 30,42.2237762 C27,59.7733 40.3938914,77.7068588 61,77.7068588 Z"
                  fill="#191847"
                  id="Hair-Back"
                ></path>
                <g
                  fill="#B28B67"
                  id="Head"
                  transform="translate(54.000000, 31.000000)"
                >
                  <path d="M38.1433414,9.13393292 C43.9381521,19.9350547 43.249578,47.3329958 35.7603014,49.2634576 C32.773664,50.0333035 26.4113241,48.1475566 19.935865,44.2446031 L24,73 L0,73 L8.26160177,34.4893378 C3.65406109,29.0807313 0.535660132,22.452516 1.05677633,15.0254539 C2.55833022,-6.37502057 32.3485306,-1.66718886 38.1433414,9.13393292 Z"></path>
                </g>
                <path
                  d="M93.5831606,40.1945268 C88.9933309,41.0561444 79.749454,45.438095 72.3793005,51.7237673 C71.507977,51.2620835 70.5087281,51 69.4466684,51 C66.0464589,51 63.2900445,53.6862915 63.2900445,57 C63.2900445,58.352507 63.7492372,59.6004922 64.5239753,60.6039629 C63.065081,63.0086601 62.0695654,65.5348236 61.7432388,68.1317726 C56.6870351,68.1317726 38.2335803,49 56.6167901,31 C75,13 101.566014,38.6959598 93.5831606,40.1945268 Z"
                  fill="#191847"
                  id="Hair"
                ></path>
              </g>
              <g id="Group" transform="translate(0.000000, 187.000000)">
                <polygon
                  fill="#997659"
                  id="Leg"
                  points="134 0 183.081639 124.226909 216.000898 229.25029 229 229.25029 178.673688 5.090379e-13"
                ></polygon>
                <path
                  d="M124.116628,0 C123.593979,43.6541888 115.971833,102.427468 114.754071,105.148648 C113.942229,106.962768 85.9161573,130.032465 30.6758546,174.357739 L39.6290713,183.938736 C104.807511,145.935376 138.9048,124.399339 141.920937,119.330628 C146.445143,111.72756 161.1744,41.763137 173,0 L124.116628,0 Z"
                  fill="#B28B67"
                  id="Leg"
                ></path>
                <g
                  fill="#E4E4E4"
                  id="Left-Shoe"
                  transform="translate(21.000000, 169.000000)"
                >
                  <g transform="translate(30.500000, 20.000000) rotate(50.000000) translate(-30.500000, -20.000000) ">
                    <path
                      d="M2.67813181,25.4019242 C1.55937727,28.3884109 1,30.6229931 1,32.1056708 C1,33.908957 1.3004142,36.5404001 1.90124261,40 C3.99318117,40 22.7937852,40 58.3030548,40 C59.768738,35.1545073 58.9226607,32.5385816 55.7648228,32.1522232 C52.606985,31.7658647 49.9837155,31.4170139 47.8950143,31.1056708 L21.6799926,19.4188835 C21.1755635,19.1940064 20.584344,19.4206282 20.359467,19.9250573 C20.35562,19.9336867 20.3518954,19.9423702 20.3482945,19.9511052 L18.6632131,24.038695 L18.6632131,24.038695 C15.7398812,25.4026522 13.3643706,26.0846307 11.5366811,26.0846307 C10.0517269,26.0846307 8.00099246,25.4849054 5.38447792,24.2854549 L5.38448339,24.285443 C4.38038273,23.8251478 3.19325534,24.2659892 2.73296014,25.2700899 C2.71312074,25.3133681 2.69483298,25.3573409 2.67813181,25.4019242 Z"
                      id="shoe"
                    ></path>
                  </g>
                </g>
                <g
                  fill="#E4E4E4"
                  id="shoe"
                  transform="translate(208.000000, 199.000000)"
                >
                  <path d="M2.67813181,25.4019242 C1.55937727,28.3884109 1,30.6229931 1,32.1056708 C1,33.908957 1.3004142,36.5404001 1.90124261,40 C3.99318117,40 22.7937852,40 58.3030548,40 C59.768738,35.1545073 58.9226607,32.5385816 55.7648228,32.1522232 C52.606985,31.7658647 49.9837155,31.4170139 47.8950143,31.1056708 L21.6799926,19.4188835 C21.1755635,19.1940064 20.584344,19.4206282 20.359467,19.9250573 C20.35562,19.9336867 20.3518954,19.9423702 20.3482945,19.9511052 L18.6632131,24.038695 L18.6632131,24.038695 C15.7398812,25.4026522 13.3643706,26.0846307 11.5366811,26.0846307 C10.0517269,26.0846307 8.00099246,25.4849054 5.38447792,24.2854549 L5.38448339,24.285443 C4.38038273,23.8251478 3.19325534,24.2659892 2.73296014,25.2700899 C2.71312074,25.3133681 2.69483298,25.3573409 2.67813181,25.4019242 Z"></path>
                </g>
                <polygon
                  fill="#69A1AC"
                  id="Pant"
                  points="167 218.432889 228.804464 211.725327 183 2.84217094e-14 127.644073 2.84217094e-14"
                ></polygon>
                <path
                  d="M43.4599581,158.900479 L84,206.885961 C126.699953,160.249961 151.594137,129.819963 158.682552,115.595966 C165.770967,101.37197 172.143091,62.839981 177.798925,0 L115.478146,0 C112.388642,55.2838742 110.306102,84.2121259 109.230525,86.7847551 C108.154948,89.3573843 86.2314253,113.395959 43.4599581,158.900479 Z"
                  fill="#89C5CC"
                  id="Pant"
                ></path>
              </g>
              <g id="Group" transform="translate(22.000000, 82.000000)">
                <path
                  d="M68.3079281,93.0198914 L89.2053198,93.1020711 C78.4956844,127.674615 71.8763087,148.777448 69.3471926,156.410568 L69.088982,157.185464 C68.7122581,158.308737 68.4519068,159.048781 68.3079281,159.405597 C65.9939846,165.140133 70.8906002,173.873732 72.9845874,178.567103 C66.1645409,181.61887 66.8912241,170.316929 58.2879189,174.319492 C56.5428749,175.131349 54.8906295,176.089688 53.276551,177.027882 L52.7398634,177.339639 C52.5613888,177.443156 52.3833105,177.546197 52.2055535,177.648531 L51.6731714,177.953189 C46.6232753,180.822978 41.7734982,182.933781 35.3890352,178.994371 C34.2736052,178.306117 33.0512757,175.714714 36.0045784,173.68967 C38.2342008,172.160845 40.7618228,170.284476 43.2419631,168.330709 L43.9842353,167.742526 C49.4120517,163.416125 54.4428443,158.845865 55.3979297,156.908251 C57.3515741,152.944826 61.6549068,131.648706 68.3079281,93.0198914 L68.3079281,93.0198914 Z M194.04441,89.1225115 L222.372314,116.757535 C230.61469,120.273345 237.368143,124.155511 242.632673,128.40403 C243.816587,129.832102 245.10547,132.568362 239.535214,131.343925 C238.999612,131.22619 238.460831,131.110185 237.923503,130.998275 L237.279704,130.866101 C232.460889,129.891811 227.909884,129.325933 227.003896,130.893567 C225.956977,132.705055 229.041811,135.480899 227.106974,137.851671 C225.817082,139.432186 221.479076,134.404878 214.092956,122.769747 L184.635682,105.402467 L194.04441,89.1225115 Z"
                  fill="#B28B67"
                  id="Skin"
                ></path>
                <path
                  d="M122.01899,9.23199981 L130.503942,7.00082913 C148.576175,42.7520052 177.414525,74.1501034 217.01899,101.195124 L192.061793,137.56505 C151.33882,97.6857553 124.670571,55.1985909 122.01899,9.23199981 Z"
                  fill="#191847"
                  id="Coat-Back"
                  transform="translate(169.518990, 72.282940) rotate(5.000000) translate(-169.518990, -72.282940) "
                ></path>
                <path
                  d="M90,114 L167,114 C149.16087,70.6251468 140.241304,33.7787236 140.241304,3.4607303 L117.014208,0 C98.7598929,29.3584068 93.6048263,65.5045499 90,114 Z"
                  fill="#DDE3E9"
                  id="Shirt"
                ></path>
                <path
                  d="M118.047752,-4.26325641e-14 C119.86198,0.0624731357 122.625282,0.175030894 126.337658,0.337673275 L126.337658,0.337673275 L131.950158,16.3768801 C132.543836,35.9324229 136.010826,75.3421183 142.351127,134.605966 L142.351127,134.605966 L91.8760675,134.60553 C92.008386,138.698782 92.2265651,142.830272 92.5306047,147 L92.5306047,147 L55,147 C60.6139164,83.6639633 81.1152373,34.6639633 116.503963,1.42108547e-14 L116.503963,1.42108547e-14 L116.522,0.017 L116.531583,-4.26325641e-14 Z"
                  fill="#2F3676"
                  id="Coat-Front"
                ></path>
                <path
                  d="M106,135 L91.889057,134.999288 C91.2713571,116.643048 92.3783198,99.0548837 95.2099449,82.2347951 C96.6469231,107.748771 100.243477,125.3371 106,135 Z"
                  fill="#000000"
                  fillOpacity="0.1"
                  id="Shade"
                ></path>
                <polygon
                  fill="#FFFFFF"
                  fillOpacity="0.2"
                  id="Light"
                  points="103.110899 104 128 92.4292718 128 104"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HumanMatchStanding;
