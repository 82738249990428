import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import PartnerPageHeader from "./components/PartnerPageHeader";

const PREFIX = "UnknownOrgFallbackPage";

const classes = {
  content: `${PREFIX}-content`,
  root: `${PREFIX}-root`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: { maxWidth: "none", padding: 0 },

  [`& .${classes.content}`]: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: -150,
    maxWidth: 1024,
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: -180,
    },
  },
}));

const ORG_COLOR = "#364958";

export default function UnknownOrgFallbackPage() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Partners,
    BloomUpNamespacesEnum.Ui,
  ]);

  return (
    <StyledContainer className={classes.root} maxWidth="md">
      <PartnerPageHeader
        color={ORG_COLOR}
        description={translate(
          "partners:not.found.description",
          "Controleer of je het correcte adres hebt gebruikt. Als na controle het nog steeds niet lukt kan je via het blauwe textbalonnetje onderaan rechts contact opnemen met onze helpdienst.",
        )}
        image="https://bloomup-production-api-assets.s3-eu-central-1.amazonaws.com/PARTNERS/unknown/header.jpeg"
        logo={{
          source:
            "https://bloomup-production-api-assets.s3-eu-central-1.amazonaws.com/PARTNERS/unknown/logo.png",
          width: 150,
        }}
        title={translate(
          "partners:not.found.title",
          "Dit is geen partner van BloomUp!",
        )}
      />
    </StyledContainer>
  );
}
