import { Box } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import HealthInsurance from "../../../data-model/types/reimbursement/HealthInsurance";
import LeftSection from "../../layout/LeftSection";
import LoadingPage from "../../layout/LoadingPage";
import EditableProfile from "../../profile/EditableProfile";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import InlineEditableSingleSelect from "../../ui/edit/InlineEditableSingleSelect";
import { graphql } from "../../../api/__generated__";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import { IHumanProfileContext } from "./context/humanProfile";
import HumanProfileContext from "./context/HumanProfileContext";

const queryHumanProfilePage = graphql(`
  query humanProfilePage {
    human {
      creditsLeft
      id
      organization {
        id
        name {
          ...useSelectTranslationStringInput
        }
      }
    }
  }
`);

export default function HumanProfile() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Client,
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Glossary,
  ]);

  const {
    human,
    formState,
    onSave,
    updateTimezone,
    updatePreferredLanguage,
    updateHealthInsurance,
  } = useContext<IHumanProfileContext>(HumanProfileContext);

  const { data } = useQuery(queryHumanProfilePage);
  const selectTranslation = useSelectTranslation();

  if (!human) return <LoadingPage full={false} />;

  return (
    <LeftSection
      pageTitle={translate("common:myprofile", "Mijn Profiel")}
      short={false}
    >
      <EditableProfile
        creditsLeft={data?.human?.creditsLeft}
        formState={formState}
        onSave={onSave}
        organizationName={selectTranslation(data?.human?.organization?.name)}
        person={human}
        updatePreferredLanguage={updatePreferredLanguage}
        updateTimezone={updateTimezone}
      />
      <Box style={{ paddingLeft: "5px" }}>
        <InlineEditableSingleSelect<HealthInsurance>
          dataObject={human.getHealthInsurance()}
          formState={formState}
          label={translate(
            "common:preferences.health.insurance",
            "Mijn ziekenfonds",
          )}
          onSave={() =>
            updateHealthInsurance(
              formState.getInputProps(HealthInsurance.getPath()).value,
            )
          }
          placeholderText={translate(
            "common:preferences.health.insurance.placeholder",
            "Geef je ziekenfonds op.",
          )}
        />
      </Box>
    </LeftSection>
  );
}
