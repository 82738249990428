import { Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import Button from "../../../../../components/ui/buttons/Button";
import { RouteNames } from "../../../../routes/routeNames";
import SpeechPlant from "./SpeechPlant";

const PREFIX = "Approach";

const classes = {
  approachContent: `${PREFIX}-approachContent`,
  approachContentText: `${PREFIX}-approachContentText`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  content: `${PREFIX}-content`,
  contentItem: `${PREFIX}-contentItem`,
  image: `${PREFIX}-image`,
  list: `${PREFIX}-list`,
  root: `${PREFIX}-root`,
  subContent: `${PREFIX}-subContent`,
  subContentSingleItem: `${PREFIX}-subContentSingleItem`,
  text: `${PREFIX}-text`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)<{ multipleProducts: boolean }>(
  ({ theme, multipleProducts }) => ({
    [`&.${classes.root}`]: {
      display: "flex",
      justifyContent: "center",
      padding: "0 96px",
      [theme.breakpoints.down(900)]: {
        padding: "0 6rem",
      },
      [theme.breakpoints.down(600)]: {
        padding: "0 3rem",
      },
      [theme.breakpoints.down(400)]: {
        padding: "0 2rem",
      },
      [theme.breakpoints.down(280)]: {
        padding: "0 1rem",
      },
    },

    [`& .${classes.content}`]: {
      display: "flex",
      flexDirection: "column",
      maxWidth: multipleProducts ? "970px" : undefined,
      textAlign: "center",
      width: "100%",
    },

    [`& .${classes.subContentSingleItem}`]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      textAlign: "left",
    },

    [`& .${classes.subContent}`]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      textAlign: "center",
      [theme.breakpoints.up(1220)]: {
        justifyContent: "space-between",
        textAlign: "left",
      },
    },

    [`& .${classes.contentItem}`]: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "450px",
      padding: "2rem 1rem",
    },

    [`& .${classes.buttonContainer}`]: {
      marginTop: "auto",
    },

    [`& .${classes.approachContent}`]: {
      "& img": {
        "&:nth-of-type(even)": {
          gridColumn: "col2-start /col2-end",
        },
        display: "flex",
        gridColumn: "col1-start / col1-end",
        placeSelf: "center",
      },
      display: "grid",
      gridAutoFlow: "dense",
      gridTemplateColumns: "[col1-start col2-start] 1fr [col1-start col2-end]",
      height: "100%",
      [theme.breakpoints.up(1220)]: {
        gridTemplateColumns:
          "[col1-start] 1fr [col1-end col2-start] 1fr [col2-end]",
        textAlign: "left",
      },

      textAlign: "center",
    },

    [`& .${classes.list}`]: {
      fontSize: "18px",
      marginBottom: "2rem",
      minHeight: "5rem",
      paddingLeft: "30px",
      textAlign: "left",
    },

    [`& .${classes.title}`]: {
      minHeight: "3.5rem",
    },

    [`& .${classes.text}`]: {
      fontSize: "18px",
      minHeight: "2.5rem",
    },

    [`& .${classes.image}`]: {
      height: "100%",
      margin: multipleProducts ? undefined : "2rem 3rem",
      marginBottom: "1rem",
      maxHeight: "300px",
      maxWidth: "100%",
      objectFit: "cover",
      [theme.breakpoints.down(500)]: {
        margin: "1rem 0",
      },
    },

    [`& .${classes.approachContentText}`]: {
      display: "flex",
      flexDirection: "column",
      gridColumn: "col2-start /col2-end",
      justifyContent: "center",
      margin: multipleProducts ? "0rem 1rem" : "2rem 3rem",
      maxWidth: "450px",
      [theme.breakpoints.up(1220)]: {
        minWidth: "450px",
      },
      [theme.breakpoints.down(500)]: {
        margin: "1rem 0",
      },
    },
  }),
);

interface ApproachProps {
  baseUrl: string;
  creditsPerHuman?: number | null;
  ggtEnabled: boolean;
  mainTitle: string;
  mindlabEnabled: boolean;
  name: string;
  videoEnabled: boolean;
}

const Approach = ({
  ggtEnabled,
  mindlabEnabled,
  videoEnabled,
  mainTitle,
  baseUrl,
  name,
  creditsPerHuman,
}: ApproachProps) => {
  const smallScreen = useMediaQuery("(max-width: 400px)");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Organization,
  ]);

  return (
    <StyledBox
      className={classes.root}
      multipleProducts={(ggtEnabled || mindlabEnabled) && videoEnabled}
    >
      <Box className={classes.content}>
        {(ggtEnabled || mindlabEnabled) && videoEnabled && (
          <Box>
            <Typography
              className={classes.title}
              variant={smallScreen ? "h2" : "h1"}
            >
              {mainTitle}
            </Typography>
          </Box>
        )}
        <Box
          className={clsx(
            (ggtEnabled || mindlabEnabled) && videoEnabled
              ? classes.subContent
              : classes.subContentSingleItem,
          )}
        >
          {ggtEnabled && (
            <Box
              className={
                videoEnabled ? classes.contentItem : classes.approachContent
              }
            >
              <img
                alt="approach"
                className={classes.image}
                src={
                  "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+Illustration.svg"
                }
              />
              <Box className={classes.approachContentText}>
                <Typography
                  className={classes.title}
                  variant={smallScreen ? "h4" : "h2"}
                >
                  {t("organization:landing.approach.bgt.title")}
                </Typography>
                <Typography className={classes.text}>
                  {t("organization:landing.approach.bgt.text")}
                </Typography>
                <ul className={classes.list}>
                  <li>{t("organization:landing.approach.bgt.list.1")}</li>
                  <li>{t("organization:landing.approach.bgt.list.2")}</li>
                  <li>{t("organization:landing.approach.bgt.list.3")}</li>
                </ul>
              </Box>
              <Box className={classes.buttonContainer}>
                <Button
                  fullyColored
                  label={t("organization:landing.approach.bgt.buttonText.v2")}
                  onClick={() => {
                    navigate(
                      `${baseUrl}${
                        RouteNames.NewB2BLandingPages.ggt.path
                      }?${searchParams.toString()}`,
                    );
                  }}
                  size={smallScreen ? "small" : "large"}
                ></Button>
              </Box>
            </Box>
          )}
          {mindlabEnabled && (
            <Box
              className={
                videoEnabled ? classes.contentItem : classes.approachContent
              }
            >
              <img
                alt="approach"
                className={classes.image}
                src={
                  "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+Illustration.svg"
                }
              />
              <Box className={classes.approachContentText}>
                <Typography
                  className={classes.title}
                  variant={smallScreen ? "h4" : "h2"}
                >
                  {t("organization:landing.approach.bgt.title")}
                </Typography>
                <Typography className={classes.text}>
                  {t("organization:landing.approach.selfcare.text")}
                </Typography>
                <ul className={classes.list}>
                  <li>{t("organization:landing.approach.selfcare.list.1")}</li>
                  <li>{t("organization:landing.approach.selfcare.list.2")}</li>
                  <li>{t("organization:landing.approach.selfcare.list.3")}</li>
                </ul>
                <Typography
                  sx={{
                    fontSize: "18px",
                    marginBottom: "1rem",
                    minHeight: "2.5rem",
                  }}
                >
                  {t("organization:landing.approach.selfcare.list.footer")}
                </Typography>
              </Box>
              <Box className={classes.buttonContainer}>
                <Button
                  fullyColored
                  label={t("organization:landing.approach.selfcare.buttonText")}
                  onClick={() => {
                    navigate(
                      `${baseUrl}${
                        RouteNames.NewB2BLandingPages.selfCare.path
                      }?${searchParams.toString()}`,
                    );
                  }}
                  size={smallScreen ? "small" : "large"}
                ></Button>
              </Box>
            </Box>
          )}
          {videoEnabled && (
            <Box
              className={
                ggtEnabled || mindlabEnabled
                  ? classes.contentItem
                  : classes.approachContent
              }
            >
              <img
                alt="approach"
                className={classes.image}
                src={
                  "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/1on1.png"
                }
              />
              <Box className={classes.approachContentText}>
                <Typography
                  className={classes.title}
                  variant={smallScreen ? "h4" : "h2"}
                >
                  {t("organization:landing.approach.onlineTherapy.title")}
                </Typography>
                <Typography className={classes.text}>
                  {t("organization:landing.approach.onlineTherapy.text")}
                </Typography>
                <ul className={classes.list}>
                  <li>
                    {t("organization:landing.approach.onlineTherapy.list.1")}
                  </li>
                  <li>
                    {t("organization:landing.approach.onlineTherapy.list.2")}
                  </li>
                  {creditsPerHuman !== null && creditsPerHuman !== 0 ? (
                    <li>
                      {t(
                        "organization:landing.approach.onlineTherapy.list.3.1",
                      )}
                      <br />

                      {creditsPerHuman === 1
                        ? t(
                            "organization:landing.approach.onlineTherapy.list.3.2_one",
                            {
                              creditsPerHuman,
                              organizationName: name,
                            },
                          )
                        : t(
                            "organization:landing.approach.onlineTherapy.list.3.2_other",
                            {
                              creditsPerHuman,
                              organizationName: name,
                            },
                          )}
                    </li>
                  ) : null}
                </ul>

                <Box className={classes.buttonContainer}>
                  <Button
                    fullyColored
                    label={t(
                      "organization:landing.approach.onlineTherapy.buttonText",
                    )}
                    onClick={() => {
                      navigate(`${baseUrl}/video?${searchParams.toString()}`, {
                        preventScrollReset: false,
                      });
                    }}
                    size={smallScreen ? "small" : "large"}
                  ></Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {videoEnabled || ggtEnabled ? (
          <SpeechPlant text={t("organization:landing.approach.plant.text")} />
        ) : null}
      </Box>
    </StyledBox>
  );
};

export default Approach;
