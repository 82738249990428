/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../layout/wizard/OnboardingStepContainer";
import SittingHuman from "../../illustrations/human-sorry-sitting";
import LoadingPage from "../../layout/LoadingPage";
import useAcceptInvite from "../../../api/clients/hooks/useAcceptInvite";
import useQueryParams, { getParamAsString } from "../../hooks/useQueryParams";
import { IToastContext } from "../../providers/toast/toast";
import ToastContext from "../../providers/toast/ToastContext";
import ErrorPage from "../../ui/ErrorPage";
import { I18Namespaces } from "../../language/I18Namespaces";

const AcceptInvite = () => {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);
  const { setToast } = useContext<IToastContext>(ToastContext);

  const {
    email: emailParam,
    invite: inviteParam,
    id: idParam,
  } = useQueryParams();

  const email = getParamAsString(emailParam);
  const invite = getParamAsString(inviteParam);
  const id = getParamAsString(idParam);

  const { acceptInvite, loading, error } = useAcceptInvite();

  const executeAcceptInvite = async () => {
    try {
      await acceptInvite({
        email,
        id: Number(id),
        invite,
      });
    } catch (error) {
      setToast({
        message: translate(
          "common:invite.failure",
          "We konden je invite niet verwerken.",
        ),
        severity: "warning",
      });
    }
  };

  useEffect(() => {
    executeAcceptInvite();
  }, []);

  if (error) return <ErrorPage />;

  if (loading) return <LoadingPage full />;

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullCopyright
        headline={translate("common:invited")}
        showSpecialConditionsForTherapist={false}
      >
        <div className="content">
          <div className="content__container content__container__narrow">
            <Box className="illustration" mt={5}>
              <SittingHuman />
            </Box>
            <Box className="description" mt={5}>
              <Typography variant="subtitle2">
                {translate("common:professional.linked")}
              </Typography>
              <Typography variant="body1">
                {translate("common:can.close")}
              </Typography>
            </Box>
          </div>
        </div>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
};

export default AcceptInvite;
