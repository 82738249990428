import { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import HearingIcon from "@mui/icons-material/Hearing";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
import { useTranslation } from "react-i18next";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import useTrackPageView from "../../../providers/matomo/useTrackPageView";
import useSetMatchingStep from "../hooks/useSetMatchingStep";
import { CONSENT_STEP } from "../context/humanMatchingSteps";
import HumanMatchingContext from "../context/HumanMatchingContext";
import TermsAndConditions from "../../TermsAndConditions";
import { I18Namespaces } from "../../../language/I18Namespaces";

const PREFIX = "ConsentStep";

const classes = {
  avatar: `${PREFIX}-avatar`,
  avatarWrapper: `${PREFIX}-avatarWrapper`,
  list: `${PREFIX}-list`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.list}`]: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 450,
  },

  [`& .${classes.avatarWrapper}`]: {
    alignItems: "flex-start",
    marginTop: 10,
  },

  [`& .${classes.avatar}`]: {
    "& SVG": {
      color: theme.palette.bloom.dark,
    },
    background: theme.palette.white.main,
    border: "2px solid",
    borderColor: theme.palette.bloom.dark,

    color: theme.palette.bloom.dark,
  },
}));

export default function ConsentStep() {
  const [onCheckedConsent, setOnCheckedConsent] = useState(false);

  const { step, next, previous, totalSteps } = useContext(HumanMatchingContext);

  const { t: translate } = useTranslation<I18Namespaces>(["common", "human"]);

  useTrackPageView("Human Onboarding > Consent");
  useSetMatchingStep(CONSENT_STEP);

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate("human:onboarding.data_security")}
        disable={!onCheckedConsent}
        fullCopyright
        headline={translate("human:onboarding.title")}
        labelBack={translate("common:previous")}
        labelNext={translate("common:agree")}
        onClickBack={previous}
        onClickNext={() => next()}
        showInDangerNotification
        showSpecialConditionsForTherapist={false}
        step={step.step}
        subhead={translate("common:your_data")}
        totalSteps={totalSteps}
      >
        <StyledBox className="description">
          <List className={classes.list}>
            <ListItem>
              <ListItemAvatar className={classes.avatarWrapper}>
                <Avatar className={classes.avatar}>
                  <ContactMailIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={translate("human:onboarding.consent.email.title")}
                secondary={translate(
                  "human:onboarding.consent.email.description",
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar className={classes.avatarWrapper}>
                <Avatar className={classes.avatar}>
                  <HearingIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={translate(
                  "human:onboarding.consent.encrypted_conversation.title",
                )}
                secondary={translate(
                  "human:onboarding.consent.encrypted_conversation.description",
                )}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar className={classes.avatarWrapper}>
                <Avatar className={classes.avatar}>
                  <PhonelinkLockIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={translate(
                  "human:onboarding.consent.data_ownership.title",
                )}
                secondary={translate(
                  "human:onboarding.consent.data_ownership.description",
                )}
              />
            </ListItem>
          </List>

          <Box mt={5}>
            <TermsAndConditions onCheck={setOnCheckedConsent} />
          </Box>
        </StyledBox>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
