/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import LandingPageContext from "../../context/LandingPageContext";
import { ILandingPageContext } from "../../context/organization";

import createFormValidation from "../../../../../utils/forms/createFormValidation";
import useFormState, { IFormState } from "../../../../hooks/useFormState";

import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import LoadingPage from "../../../../layout/LoadingPage";
import Button from "../../../../ui/buttons/Button";
import { ISelectObject } from "../../../../ui/form/select/BaseSimpleSelect";
import SingleSelect from "../../../../ui/form/select/SingleSelect";

const PREFIX = "MatchingBlockOctaplus";

const classes = {
  center: `${PREFIX}-center`,
  content: `${PREFIX}-content`,
  infoText: `${PREFIX}-infoText`,
  root: `${PREFIX}-root`,
  startButton: `${PREFIX}-startButton`,
  startButtonContainer: `${PREFIX}-startButtonContainer`,
  startMatchingLogo: `${PREFIX}-startMatchingLogo`,
  title: `${PREFIX}-title`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: { position: "relative" },

  [`& .${classes.content}`]: {
    position: "relative",
    top: -130,
  },

  [`& .${classes.startMatchingLogo}`]: {
    marginBottom: 20,
  },

  [`& .${classes.center}`]: {
    textAlign: "center",
  },

  [`& .${classes.title}`]: {
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: "0.4px",
    marginBottom: 40,
  },

  [`& .${classes.infoText}`]: {
    color: "#4D4D4D",
    fontFamily: theme.typography.fontFamily,

    fontSize: 13,
    size: theme.typography.sizes.small,
  },

  [`& .${classes.startButton}`]: {
    minWidth: 120,
  },

  [`& .${classes.startButtonContainer}`]: {
    paddingTop: 50,
    width: "100%",
  },
}));

const MatchingBlockOctaplus = () => {
  const WORKAS = "workAs";
  const WORKLOCATION = "workLocation";

  const { handleSubmit, organization } =
    useContext<ILandingPageContext>(LandingPageContext);

  const { t: translate, ready } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Validation,
    BloomUpNamespacesEnum.Partners,
  ]);

  const validation = useMemo(
    () =>
      createFormValidation([
        {
          message:
            organization?.getTranslation(
              translate,
              "octaplus.matchingblock.work.as.validate",
            ) || "Gelieve een statuut te selecteren",
          path: WORKAS,
          validate: (workAs: ISelectObject<string> | undefined): boolean => {
            return workAs?.value !== undefined;
          },
        },
        {
          message:
            organization?.getTranslation(
              translate,
              "octaplus.matchingblock.work.location.validate",
            ) || "Gelieve een regio te selecteren.",
          path: WORKLOCATION,
          validate: (
            workLocation: ISelectObject<string> | undefined,
          ): boolean => {
            return workLocation?.value !== undefined;
          },
        },
      ]),
    [translate],
  );

  const formState: IFormState = useFormState(
    {
      [WORKAS]: "blue-collar/driver",
      [WORKLOCATION]: "Vilvoorde",
    },
    { validation },
  );

  const onSubmit = () => {
    formState.validate();

    if (!formState.testForErrors()) {
      const values = {
        [WORKAS]: formState.getValue(WORKAS).value,
        [WORKLOCATION]: formState.getValue(WORKLOCATION).value,
      };

      handleSubmit(values);
    }
  };

  if (!organization || !ready) return <LoadingPage />;

  return (
    <Root>
      <Box mb={4} mt={4}>
        <SingleSelect<string>
          options={[
            {
              label: organization.getTranslation(
                translate,
                `octaplus.matchingblock.work.as.answer.bluecollar`,
                "Arbeider/Chauffeur",
              ),
              value: "blue-collar/driver",
            },
            {
              label: organization.getTranslation(
                translate,
                `octaplus.matchingblock.work.as.answer.whitecollar`,
                "Bediende",
              ),
              value: "white-collar",
            },
            {
              label: organization.getTranslation(
                translate,
                `octaplus.matchingblock.work.as.answer.selfemployed`,
                "Zelfstandige",
              ),
              value: "self-employed",
            },
            {
              label: organization.getTranslation(
                translate,
                `octaplus.matchingblock.work.as.answer.dontwantsay`,
                "Zeg ik liever niet",
              ),
              value: "dont want to say",
            },
          ]}
          placeholder={organization.getTranslation(
            translate,
            "octaplus.matchingblock.work.as.question",
            "Ik werk als",
          )}
          {...formState.getInputProps(WORKAS)}
        />
      </Box>
      <Box mb={4} mt={4}>
        <SingleSelect<string>
          options={[
            {
              label: organization.getTranslation(
                translate,
                `octaplus.matchingblock.work.location.answer.vilvoorde`,
                "Vilvoorde (of hang af van Vilvoorde)",
              ),
              value: "Vilvoorde",
            },
            {
              label: organization.getTranslation(
                translate,
                `octaplus.matchingblock.work.location.answer.wallonie`,
                "Wallonië",
              ),
              value: "Wallonië",
            },
            {
              label: organization.getTranslation(
                translate,
                `octaplus.matchingblock.work.location.answer.dontwantsay`,
                "Zeg ik liever niet",
              ),
              value: "dont want to say",
            },
          ]}
          placeholder={organization.getTranslation(
            translate,
            "octaplus.matchingblock.work.location.question",
            "Ik werk voornamelijk vanuit",
          )}
          {...formState.getInputProps(WORKLOCATION)}
        />
      </Box>
      <Box
        className={classes.startButtonContainer}
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          className={classes.startButton}
          disabled={formState.testForErrors()}
          id="back-button"
          label={organization.getTranslation(
            translate,
            "blocks.matching.button",
          )}
          onClick={onSubmit}
        />
      </Box>
    </Root>
  );
};

export default MatchingBlockOctaplus;
