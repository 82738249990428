import {
  DateTimePicker,
  DateTimeValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";

import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/nl-be";

import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getDateFnsLocale } from "../../language/languagesUtil";

export const CustomDateTimePicker = ({
  onChange,
  value,
  error,
  fullWidth = false,
  disableFuture = false,
  disablePast = false,
}: {
  disableFuture?: boolean;
  disablePast?: boolean;
  error: string | undefined;
  fullWidth?: boolean;
  onChange:
    | ((
        value: Date | null,
        context: PickerChangeHandlerContext<DateTimeValidationError>,
      ) => void)
    | undefined;
  value: Date;
}) => {
  const { i18n } = useTranslation();
  const dateFnsLocale = getDateFnsLocale(i18n.language);

  return (
    <LocalizationProvider
      adapterLocale={dateFnsLocale}
      dateAdapter={AdapterDateFns}
    >
      <DateTimePicker<Date>
        disableFuture={disableFuture}
        disablePast={disablePast}
        minutesStep={5}
        onChange={onChange}
        slotProps={{
          popper: {
            sx: (theme) => ({
              "& .MuiPaper-root": {
                border: 3,
                borderColor: theme.palette.bloom.main,
              },
            }),
          },
          textField: {
            error: false,
            sx: (theme) => ({
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                border: 3,
                borderColor: error
                  ? theme.palette.error.main
                  : theme.palette.bloom.main,
                borderRadius: "5px",
              },
              backgroundColor: theme.palette.white.main,
              border: 3,
              borderColor: error
                ? theme.palette.error.main
                : theme.palette.black.light,
              borderRadius: "5px",
              cursor: "pointer",
              width: fullWidth ? "100%" : "inherit",
            }),
          },
        }}
        value={value}
      />
      {error && (
        <Typography
          sx={(theme) => ({
            color: theme.palette.error.main,
          })}
          variant={"caption"}
        >
          {/* wrapping is needed for linter not to complain => https://github.com/jaredpalmer/formik/issues/3683 */}
          <>{error}</>
        </Typography>
      )}
    </LocalizationProvider>
  );
};
