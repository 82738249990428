import { useMutation } from "@apollo/client";
import { useMediaQuery } from "@mui/material";
import * as Sentry from "@sentry/react";
import {
  DeviceLabels,
  useAudioVideo,
  useLocalVideo,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { graphql } from "../../../../api/__generated__";
import {
  ChimeAttendeeInfo,
  ChimeMeetingInfo,
} from "../../../../api/__generated__/graphql";
import useFetchConsultationByUuid from "../../../../api/consultations/hooks/useFetchConsultationByUuid";
import useStartVideoCall from "../../../../api/videoCall/hooks/useStartVideoCall";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import LoadingPage from "../../../layout/LoadingPage";
import AuthContext from "../../../providers/auth/AuthContext";
import ToastContext from "../../../providers/toast/ToastContext";
import { IToastContext } from "../../../providers/toast/toast";
import { RouteNames } from "../../../routes/routeNames";
import ErrorPage from "../../ErrorPage";
import { ChimeVideoCall } from "../ChimeVideoCall.types";
import { ICallContext } from "./Call";
import CallContext from "./CallContext";

export interface CallInfo {
  clientConnectedTime?: Date;
  clientLeftTime?: Date;
  duration?: number;
  endTime?: Date;
  isCallEnded?: boolean;
  isCallStarted?: boolean;
  isProfessionalConnected?: boolean;
  professionalConnectedTime?: Date;
  professionalLeftTime?: Date;
  someoneHasEntered?: boolean;
  startTime?: Date;
}

type CallInfoActions =
  | "PROFESSIONAL_CONNECTED"
  | "PROFESSIONAL_DISCONNECTED"
  | "CLIENT_CONNECTED"
  | "CLIENT_DISCONNECTED"
  | "CALL_STARTED"
  | "CALL_ENDED"
  | "OTHERPARTY_CONNECTED";

interface ReducerAction<A> {
  type: A;
}

const SELF_CONNECTED_TO_CALL_MUTATION = graphql(`
  mutation SelfConnectedToCall($consultationId: Int!) {
    userJoinedConsultation(consultationId: $consultationId) {
      id
      status
    }
  }
`);

const callInfoReducer = (
  state: CallInfo,
  action: ReducerAction<CallInfoActions>,
): CallInfo => {
  const now = new Date();

  switch (action.type) {
    case "PROFESSIONAL_CONNECTED":
      return {
        ...state,
        isProfessionalConnected: true,
        professionalConnectedTime: now,
      };

    case "PROFESSIONAL_DISCONNECTED":
      return {
        ...state,
        isProfessionalConnected: false,
        professionalLeftTime: now,
      };

    case "CLIENT_CONNECTED":
      return {
        ...state,
        clientConnectedTime: now,
      };

    case "CLIENT_DISCONNECTED":
      return { ...state, clientLeftTime: now };

    case "OTHERPARTY_CONNECTED":
      return { ...state, someoneHasEntered: true };

    case "CALL_STARTED":
      if (!state.startTime) {
        // Only set starttime if it wasn't set yet (case when someone loses connection)
        return { ...state, isCallStarted: true, startTime: now };
      }

      return state;

    case "CALL_ENDED":
      return {
        ...state,
        duration: state.startTime
          ? state.startTime.getTime() - now.getTime()
          : undefined,
        endTime: now,
        isCallEnded: true,
      };

    default:
      throw new Error(`Reducer received unknown action type: ${action.type}`);
  }
};

export default function CallStateProvider({ children }: ICallContext) {
  const { currentUser } = useContext(AuthContext);

  const { setToast } = useContext<IToastContext>(ToastContext);

  const [callInfo, dispatch] = useReducer(callInfoReducer, {
    clientConnectedTime: undefined,
    clientLeftTime: undefined,
    duration: undefined,
    endTime: undefined,
    isCallEnded: false,
    isCallStarted: false,
    isProfessionalConnected: false,
    professionalConnectedTime: undefined,
    professionalLeftTime: undefined,
    someoneHasEntered: false,
    startTime: undefined,
  });

  const [connectedToCallMutation] = useMutation(
    SELF_CONNECTED_TO_CALL_MUTATION,
  );

  const [paneStack, setPaneStack] = useState<
    Array<ChimeVideoCall.SidePaneProfessional>
  >([ChimeVideoCall.SidePaneProfessional.none]);

  const [isWaitingRoomReady, setIsWaitingRoomReady] = useState<boolean>(false);

  // const [isOtherPartyAvailable, setOtherPartyAvailable] =
  //     useState<boolean>(false);

  const [isJoinedMeeting, setIsJoinedMeeting] = useState<boolean>(false);

  const [startMeetingStatus, setStartMeetingStatus] =
    useState<ChimeVideoCall.StartMeetingStatus>(
      ChimeVideoCall.StartMeetingStatus.loading,
    );

  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Errors]);

  const isSmallerThanFirstBreakpoint = useMediaQuery(
    "only screen and (max-width: 465px)",
  );

  const isSmallerThanSecondBreakpoint = useMediaQuery(
    "only screen and (max-width: 390px)",
  );

  const isSmallerThanThirdBreakpoint = useMediaQuery(
    "only screen and (max-width: 315px)",
  );

  const audioVideo = useAudioVideo();

  const meetingManager = useMeetingManager();

  const { setIsVideoEnabled } = useLocalVideo();

  const { uuid } = useParams();

  const {
    execute: startVideoCall,
    response: startVideoCallResponse,
    loading: loadingStartVideoCallResponse,
    error: errorStartVideoCallResponse,
  } = useStartVideoCall();

  const {
    consultation,
    loading: loadingFetchingConsultation,
    error: errorFetchingConsultation,
  } = useFetchConsultationByUuid(uuid || "");

  const consultationId = consultation?.getID();
  const navigate = useNavigate();

  const consultationUuid = consultation?.getUUID();

  const isCancelled = consultation?.getStatus().isCancelled();

  useEffect(() => {
    if (consultationUuid) {
      if (isCancelled) {
        navigate(`${RouteNames.Home.Consultations.path}/${consultationUuid}`);
      } else {
        startVideoCall(consultationUuid);
      }
    }
  }, [consultationUuid, isCancelled, navigate, startVideoCall]);

  const joinVideoCall = useCallback(
    async (joinData: {
      attendeeInfo: ChimeAttendeeInfo;
      deviceLabels: DeviceLabels;
      meetingInfo: ChimeMeetingInfo;
    }) => {
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        joinData.meetingInfo,
        joinData.attendeeInfo,
      );

      const deviceLabels = joinData.deviceLabels;

      const options = { deviceLabels };

      await meetingManager.join(meetingSessionConfiguration, options);

      setIsJoinedMeeting(true);

      setStartMeetingStatus(ChimeVideoCall.StartMeetingStatus.success);

      setIsWaitingRoomReady(true);
    },
    [meetingManager],
  );

  useEffect(() => {
    if (
      !loadingStartVideoCallResponse &&
      !errorStartVideoCallResponse &&
      startVideoCallResponse &&
      meetingManager
    ) {
      const joinData = {
        attendeeInfo: startVideoCallResponse.attendeeInfo[0],
        deviceLabels: DeviceLabels.AudioAndVideo,
        meetingInfo: startVideoCallResponse.meetingInfo,
      };

      if (joinData) joinVideoCall(joinData);
    }
  }, [
    startVideoCallResponse,
    loadingStartVideoCallResponse,
    errorStartVideoCallResponse,
    meetingManager,
    joinVideoCall,
  ]);

  // Other party enters the room
  const onOtherPartyConnected = useCallback(() => {
    console.log("###onOtherPartyConnected");

    if (currentUser?.isProfessional()) {
      dispatch({ type: "CLIENT_CONNECTED" });
    } else {
      dispatch({ type: "PROFESSIONAL_CONNECTED" });
    }

    dispatch({ type: "OTHERPARTY_CONNECTED" });
  }, [currentUser, dispatch]);

  // Other party leaves the room
  const onOtherPartyDisconnected = useCallback(() => {
    console.log("###onOtherPartyDisconnected");

    if (currentUser?.isProfessional()) {
      dispatch({ type: "CLIENT_DISCONNECTED" });
    } else {
      dispatch({ type: "PROFESSIONAL_DISCONNECTED" });
    }
  }, [currentUser, dispatch]);

  // Logged in user enters the room
  const onSelfConnected = useCallback(() => {
    if (currentUser?.isProfessional()) {
      dispatch({ type: "PROFESSIONAL_CONNECTED" });
    } else {
      dispatch({ type: "CLIENT_CONNECTED" });
    }

    if (consultationId) {
      connectedToCallMutation({
        variables: { consultationId },
      });
    }
  }, [connectedToCallMutation, consultationId, currentUser]);

  // Logged in user leaves the room
  const onSelfDisconnected = useCallback(() => {
    if (currentUser?.isProfessional()) {
      dispatch({ type: "PROFESSIONAL_DISCONNECTED" });
    } else {
      dispatch({ type: "CLIENT_DISCONNECTED" });
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (audioVideo) {
      audioVideo.realtimeSubscribeToAttendeeIdPresence(
        (attendeeId: string, present: boolean, externalUserId?: string) => {
          // When screenshare is activated attendeeId will have #content at the end of id. This way onOtherPartyConnected and onOtherPartyDisconnected wil not trigger when screenshare.
          if (
            externalUserId &&
            externalUserId !== currentUser?.getUUID() &&
            !attendeeId.endsWith("#content")
          ) {
            if (present) {
              onOtherPartyConnected();
            } else {
              onOtherPartyDisconnected();
            }
          }
        },
      );
    }
  }, [
    audioVideo,
    onOtherPartyConnected,
    onOtherPartyDisconnected,
    currentUser,
  ]);

  useEffect(() => {
    meetingManager.meetingSession?.eventController.addObserver({
      eventDidReceive: (name, attributes) => {
        const { meetingHistory, ...otherAttributes } = attributes;

        switch (name) {
          case "videoInputFailed":
            setToast({
              message: t("error:videocall.videoinputfailed"),
              noHide: true,
              severity: "error",
            });
            break;

          case "audioInputFailed":
            setToast({
              message: t("error:videocall.audioinputfailed"),
              noHide: true,
              severity: "error",
            });
            break;

          case "meetingStartFailed":
          // falls through

          case "meetingFailed":
            setToast({
              message: t("error:videocall.meetingfailed"),
              severity: "error",
            });
            break;

          default:
            break;
        }

        if (
          name === "meetingFailed" ||
          name === "audioInputFailed" ||
          name === "videoInputFailed" ||
          name === "meetingStartFailed"
        ) {
          Sentry.captureMessage(
            JSON.stringify({
              attributes: {
                ...otherAttributes,
                meetingHistory: meetingHistory?.filter(({ timestampMs }) => {
                  return Date.now() - timestampMs < 5 * 60 * 1000;
                }),
              },
              name,
            }),
          );
        }
      },
    });
  }, [meetingManager, setToast, t]);

  const startMeeting = useCallback(async () => {
    await meetingManager.start();
    dispatch({ type: "CALL_STARTED" });
    setIsWaitingRoomReady(true);

    if (
      meetingManager.meetingSession &&
      meetingManager.meetingSession.audioVideo &&
      meetingManager.selectedVideoInputDevice
    ) {
      await meetingManager.meetingSession.audioVideo.startVideoInput(
        meetingManager.selectedVideoInputDevice,
      );
      meetingManager.meetingSession.audioVideo.startLocalVideoTile();
      setIsVideoEnabled(true);
    }

    onSelfConnected();
  }, [meetingManager, onSelfConnected, setIsVideoEnabled]);

  const exitMeeting = useCallback(async () => {
    if (meetingManager && meetingManager.audioVideo) {
      onSelfDisconnected();

      meetingManager.audioVideo.stopVideoInput();

      meetingManager.audioVideo.stopLocalVideoTile();

      meetingManager.audioVideo.stop();

      await meetingManager.leave();

      dispatch({ type: "CALL_ENDED" });
    }
  }, [meetingManager, onSelfDisconnected]);

  const setPaneToPresent = (pane: ChimeVideoCall.SidePaneProfessional) => {
    setPaneStack((oldArray) => [...oldArray, pane]);
  };

  const returnToPreviousPane = useCallback(() => {
    setPaneStack((oldArray) => {
      oldArray.pop();

      return [...oldArray];
    });
  }, []);

  if (loadingFetchingConsultation || !uuid) return <LoadingPage full />;

  if (errorFetchingConsultation || consultation === null) return <ErrorPage />;

  if (!consultation) return <LoadingPage full />;

  return (
    <CallContext.Provider
      value={{
        callInfo,
        consultation,
        exitMeeting,
        isJoinedMeeting,
        isSmallerThanFirstBreakpoint,
        isSmallerThanSecondBreakpoint,
        isSmallerThanThirdBreakpoint,
        isWaitingRoomReady,
        paneToPresent: paneStack[paneStack.length - 1],
        returnToPreviousPane,
        setPaneToPresent,
        startMeeting,
        startMeetingStatus,
      }}
    >
      {children}
    </CallContext.Provider>
  );
}
