/* eslint-disable react-hooks/exhaustive-deps */

import { styled } from "@mui/material/styles";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress } from "@mui/material";
import TextField from "../../../../ui/form/TextField";
import Button from "../../../../ui/buttons/Button";
import SingleSelect from "../../../../ui/form/select/SingleSelect";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import { ILandingPageContext } from "../../context/organization";
import LandingPageContext from "../../context/LandingPageContext";
import createFormValidation from "../../../../../utils/forms/createFormValidation";
import useFormState, { IFormState } from "../../../../hooks/useFormState";

const PREFIX = "MatchingBlockStadGent";

const classes = {
  input: `${PREFIX}-input`,
  options: `${PREFIX}-options`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.options}`]: {
    "& .select__control": {
      backgroundColor: theme.palette.white.light,
    },
  },

  [`& .${classes.input}`]: {
    "& .MuiOutlinedInput-root": {
      borderWidth: 3,
    },

    backgroundColor: theme.palette.white.light,
  },
}));

const POST_CODES: Array<string> = [
  "9000",
  "9030",
  "9031",
  "9032",
  "9040",
  "9041",
  "9042",
  "9050",
  "9051",
  "9052",
];

const MatchingBlockStadGent = () => {
  const { handleSubmit, organization } =
    useContext<ILandingPageContext>(LandingPageContext);

  const { t: translate, ready } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Validation,
    BloomUpNamespacesEnum.Partners,
  ]);

  const POST_CODE_TYPE = "postcode";
  const CHANNEL = "channel";

  const validation = useMemo(
    () =>
      createFormValidation([
        {
          message:
            organization?.getTranslation(
              translate,
              "validation.channel.empty",
            ) || "Geef een optie op, hoe je hier bent gekomen.",
          path: CHANNEL,
          validate: (channel: string): boolean => {
            return channel !== "";
          },
        },
        {
          message:
            organization?.getTranslation(translate, "validation.zip.empty") ||
            "Vul een postcode in.",
          path: POST_CODE_TYPE,
          validate: (postcode: string): boolean => {
            return postcode !== "";
          },
        },
        {
          message:
            organization?.getTranslation(translate, "validation.zip.invalid") ||
            "Deze postcode heeft jammergenoeg geen recht op 3 gratis consults voor mantelzorgers.",
          path: POST_CODE_TYPE,
          validate: (postcode: string): boolean => {
            return POST_CODES.indexOf(postcode) !== -1;
          },
        },
      ]),
    [translate],
  );

  const formState: IFormState = useFormState(
    {
      [CHANNEL]: "",
      [POST_CODE_TYPE]: "",
    },
    { validation },
  );

  const onSubmit = () => {
    formState.validate();

    if (!formState.testForErrors()) {
      handleSubmit(formState.values);
    }
  };

  if (!ready) return <CircularProgress />;

  return (
    <Root>
      <Box className={classes.options} mb={4} mt={4}>
        <SingleSelect<string>
          options={[
            {
              label: "Gemeente",
              value: "gemeente",
            },
            {
              label: "Huisarts",
              value: "huisarts",
            },
            {
              label: "Krant",
              value: "krant",
            },
            {
              label: "Mantelzorgvereniging",
              value: "mantelzorgvereniging",
            },

            {
              label: "Onderwijsinstelling",
              value: "onderwijsinstelling",
            },
            {
              label: "Sociale media",
              value: "socialemedia",
            },
            {
              label: "Thuishulp",
              value: "thuishulp",
            },
            {
              label: "Via een vriend of familie",
              value: "vriendoffamilie",
            },
            {
              label: "Ziekenfonds",
              value: "ziekenfonds",
            },
            {
              label: "Diensten centrum",
              value: "dienstencentrum",
            },
            {
              label: "Andere",
              value: "andere",
            },
          ]}
          placeholder="Hoe kwam je hier terecht?"
          {...formState.getInputProps(CHANNEL)}
        />
      </Box>
      <TextField
        label="Postcode"
        style={{
          marginBottom: 25,
          marginRight: 15,
          width: 160,
        }}
        {...formState.getInputProps(POST_CODE_TYPE)}
        className={classes.input}
        inputProps={{ maxLength: 4 }}
      />
      <Button
        disabled={formState.testForErrors()}
        label="Ga verder"
        onClick={onSubmit}
        style={{
          marginBottom: 25,
        }}
      />
    </Root>
  );
};

export default MatchingBlockStadGent;
