import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import moment from "moment";
import type { TFunction } from "i18next";
import CheckBox from "../../../../ui/form/CheckBox";
import HealthInsurance, {
  HealthInsuranceCompanies,
} from "../../../../../data-model/types/reimbursement/HealthInsurance";
import { IFormState } from "../../../../hooks/useFormState";
import { I18Namespaces } from "../../../../language/I18Namespaces";

const PREFIX = "HealthInsuranceBox";

const classes = {
  checkCM: `${PREFIX}-checkCM`,
  root: `${PREFIX}-root`,
  timeZoneText: `${PREFIX}-timeZoneText`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: "center",
    backgroundColor: theme.palette.white.main,
    borderRadius: 10,
    display: "flex",
    height: "55px",
    justifyContent: "space-between",
    marginBottom: "18px",
    marginTop: "18px",
    [theme.breakpoints.down(500)]: {
      margin: "0 10px",
    },
  },

  [`& .${classes.checkCM}`]: { paddingLeft: "20px" },
  [`& .${classes.timeZoneText}`]: { marginRight: "20px" },
}));

interface IHealthInsuranceContainerWithCheckbox {
  formState: IFormState;
  translate: TFunction<I18Namespaces, undefined>;
}

function HealthInsuranceBox({
  formState,
  translate,
}: IHealthInsuranceContainerWithCheckbox) {
  return (
    <StyledBox className={classes.root}>
      <Box className={classes.checkCM}>
        <CheckBox
          handleNativeChange={(_, checked) => {
            if (formState) {
              formState.setValue(
                HealthInsurance.getPath(),
                checked ? HealthInsuranceCompanies.CM : null,
              );
            }
          }}
          helperStyle={{ fontWeight: "bold" }}
          label={translate(
            "human:onboarding.matching.checkbox.health.insurance",
            "Ik ben CM-lid",
          )}
          style={{ paddingTop: 0 }}
        />
      </Box>
      {/*Check if UTC offset is not 60 minutes (like +1 UTC) then show*/}
      {/*timezone*/}
      {moment.tz.guess() !== "Europe/Brussels" && (
        <Typography className={classes.timeZoneText}>
          {translate("human:onboarding.matching.timezone", {
            defaultValue: "Tijdzone: {{timeZone}}",
            timeZone: moment.tz.guess(),
          })}
        </Typography>
      )}
    </StyledBox>
  );
}

export default HealthInsuranceBox;
