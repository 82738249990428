import { useMutation } from "@apollo/client";
import { MUTATION_MATCHING_CREATE_PERMANENT_HUMAN } from "../../matching/mutations";
import { APIHuman } from "../humans";

const useCreatePermanentHuman = () => {
  const [createPermanentHumanFunction, { data, loading, error }] = useMutation<
    APIHuman.CreatePermanent.Result,
    APIHuman.CreatePermanent.APIInput
  >(MUTATION_MATCHING_CREATE_PERMANENT_HUMAN);

  const createPermanentHuman = (input: APIHuman.CreatePermanent.Input) =>
    createPermanentHumanFunction({ variables: { input } });

  return {
    createPermanentHuman,
    error,
    loading,
    loginTokens: data?.createPermanentHuman,
  };
};

export default useCreatePermanentHuman;
