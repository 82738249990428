/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import moment from "moment";
import "moment/locale/nl-be";
import "moment/locale/fr";
import "moment/locale/en-gb";

import { useTranslation } from "react-i18next";
import LoadingPage from "../../../../layout/LoadingPage";

import HumanMatchingContext from "../../context/HumanMatchingContext";
import { IHumanMatchingContext } from "../../context/humanMatching";
import TimeSlot from "../../../../../data-model/types/consultation/TimeSlot";
import Button from "../../../../ui/buttons/Button";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import { getMomentLocale } from "../../../../language/languagesUtil";
import { IMatch } from "../../../../../api/matching/hooks/useFindMatches";
import MatchedProfessionalListItem from "./MatchedProfessionalListItem";

const PREFIX = "MatchedProfessionalList";

const classes = {
  moreButton: `${PREFIX}-moreButton`,
  moreButtonText: `${PREFIX}-moreButtonText`,
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexFlow: "row wrap",
  },

  [`& .${classes.moreButton}`]: {
    margin: "50px auto 0px",
  },

  [`& .${classes.moreButtonText}`]: {
    [theme.breakpoints.down(350)]: {
      maxWidth: "230px",
      whiteSpace: "nowrap",
    },
  },
}));

interface IMatchedProfessionalsList {
  matches: Array<IMatch>;
  next: (match: IMatch, startCall?: boolean) => void;
  onMatchChosen: (match: IMatch) => void;
  setSelectedTimeSlot: (timeSlot: TimeSlot) => void;
}

function MatchedProfessionalList({
  matches,
  next,
  onMatchChosen,
  setSelectedTimeSlot,
}: IMatchedProfessionalsList) {
  const { t: translate, i18n } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Human,
  ]);

  const { clearTimeSlot } =
    useContext<IHumanMatchingContext>(HumanMatchingContext);

  moment.locale(getMomentLocale(i18n.language));

  const [show3More, setShow3More] = useState<boolean>(false);

  const showProfessional = (match: IMatch) => {
    return (
      <MatchedProfessionalListItem
        key={match.professional.getID()}
        match={match}
        onScheduleCall={() => next(match)}
        onSelectTime={(timeslot: TimeSlot) => {
          setSelectedTimeSlot(timeslot);
          onMatchChosen(match);
        }}
        onStartCall={() => {
          clearTimeSlot();
          onMatchChosen(match);

          next(match, true);
        }}
      />
    );
  };

  if (!matches || matches.length === 0) return <LoadingPage full={false} />;

  return (
    <StyledBox className={classes.root}>
      {matches.slice(0, 3).map((match) => {
        return showProfessional(match);
      })}
      {!show3More && matches.length > 3 && (
        <Box className={classes.moreButton}>
          <Button
            className={classes.moreButtonText}
            label={translate(
              "onboarding.show.more.professionals",
              "Toon meer professionals",
            )}
            onClick={() => setShow3More(true)}
          />
        </Box>
      )}
      {show3More && (
        <>
          {matches.slice(3).map((match) => {
            return showProfessional(match);
          })}
        </>
      )}
    </StyledBox>
  );
}

export default MatchedProfessionalList;
