/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from "react";
import HumanMatchingContext from "../context/HumanMatchingContext";
import { getStepByNumber } from "../context/humanMatchingSteps";

export default function useSetMatchingStep(stepNumber: number) {
  const { setCurrentStep } = useContext(HumanMatchingContext);

  useEffect(() => {
    //setting the stepnumber here, makes sure we maintain the right order of progression through
    // onboarding, even when the browsers forward an back buttons are used instead of our buttons.
    const step = getStepByNumber(stepNumber);

    if (step) setCurrentStep(step);
  }, []);
}
