import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import SanitizedText from "../../../../ui/text/SanitizedText";
import { PartnerPageContentBlock } from "../PartnerPageContentBlocks";
import AppleAppStoreIcon from "../../../../../assets/static/appStore/apple-app-store.svg";
import PartnerBlock from "./PartnerBlock";

const PREFIX = "PartnerGGTBlock";

const classes = {
  description: `${PREFIX}-description`,
};

const StyledPartnerBlock = styled(PartnerBlock)(({ theme }) => ({
  [`& .${classes.description}`]: {
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 16,

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "18xpx",
    },
  },
}));

interface PartnerContentBlockProps {
  block: PartnerPageContentBlock;
  color: string;
}

export default function PartnerTextBlock({
  block,
  color,
}: PartnerContentBlockProps) {
  return (
    <StyledPartnerBlock color={color} title={block.title ? block.title : ""}>
      {block.description && (
        <Typography className={classes.description}>
          <SanitizedText input={block.description.toString()} />
        </Typography>
      )}
      <Box>
        <Box marginLeft="-12px">
          <a href="https://play.google.com/store/apps/details?id=org.bloomup.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              width="200px"
            />
          </a>
        </Box>

        <a href="https://apps.apple.com/app/id1639537792">
          <img
            alt="Get it in the Apple app store"
            src={AppleAppStoreIcon}
            width="178px"
          />
        </a>
      </Box>
    </StyledPartnerBlock>
  );
}
