import { styled } from "@mui/material/styles";
import {
  List,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import FiberManualRecordIconOutlined from "@mui/icons-material/FiberManualRecordOutlined";
import SanitizedText from "../../../../ui/text/SanitizedText";
import { PartnerContentBlockProps } from "./PartnerTextBlock";
import PartnerBlock from "./PartnerBlock";

const PREFIX = "PartnerListBlock";

const classes = {
  description: `${PREFIX}-description`,
};

const StyledPartnerBlock = styled(PartnerBlock)(({ theme }) => ({
  [`& .${classes.description}`]: {
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 16,
    lineHeight: "24px",

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "18px",
    },
  },
}));

export default function PartnerListBlock({
  block,
  color,
}: PartnerContentBlockProps) {
  const descriptions = Array.isArray(block.description)
    ? block.description
    : [block.description];

  return (
    <StyledPartnerBlock color={color} title={block.title ? block.title : ""}>
      <List className={classes.description}>
        {descriptions.map((description?: string) => {
          return (
            <ListItem
              key={description}
              style={{
                padding: 0,
                paddingBottom: 10,
              }}
            >
              <ListItemIcon
                style={{
                  alignSelf: "flex-start",
                  marginTop: 5,
                  minWidth: 30,
                }}
              >
                <FiberManualRecordIconOutlined
                  style={{
                    color,
                    width: 15,
                  }}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography className={classes.description}>
                  <SanitizedText input={description ?? ""} />
                </Typography>
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </StyledPartnerBlock>
  );
}
