import { useMutation } from "@apollo/client";
import { IMutationInput } from "../../api";
import { APIMatchedHuman } from "../matchedHumans";
import { MUTATION_UPDATE_MATCHED_HUMAN_WORRIES } from "../mutations";

export interface IUpdateMatchedHumanWorriesInput {
  specialtiesThemeComboIds: Array<string>;
}

interface IMutationResult<T> {
  updateMatchedHuman: T;
}

const useUpdateMatchedHumanWorries = () => {
  const [updateMatchedHumanWorriesFunction, { data, loading, error }] =
    useMutation<
      IMutationResult<APIMatchedHuman.Result>,
      IMutationInput<IUpdateMatchedHumanWorriesInput>
    >(MUTATION_UPDATE_MATCHED_HUMAN_WORRIES);

  const updateMatchedHumanWorries = (input: IUpdateMatchedHumanWorriesInput) =>
    updateMatchedHumanWorriesFunction({
      variables: { input },
    });

  return {
    error,
    loading,
    matchedHuman: data?.updateMatchedHuman,
    updateMatchedHumanWorries,
  };
};

export default useUpdateMatchedHumanWorries;
