import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Footer from "../../../ui/Footer";
import SanitizedText from "../../../ui/text/SanitizedText";

const PREFIX = "PartnerPageFooter";

const classes = {
  contentBlock: `${PREFIX}-contentBlock`,
  description: `${PREFIX}-description`,
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)<{
  color: string;
}>(({ theme, color }) => ({
  [`&.${classes.root}`]: { position: "relative" },

  [`& .${classes.contentBlock}`]: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 50,
    maxWidth: 1024,
    paddingLeft: 125,
    paddingRight: 125,
    wordWrap: "break-word",

    [theme.breakpoints.down("md")]: {
      paddingLeft: 70,
      paddingRight: 70,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },

  [`& .${classes.title}`]: {
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: "0.4px",
    marginBottom: 20,

    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      lineHeight: "24px",

      marginBottom: 10,
    },
  },

  [`& .${classes.description}`]: {
    "& A": {
      ...theme.controls.link,
      color: `${color}`,
    },
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 15,
    fontWeight: 200,

    letterSpacing: "0.4px",

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "18px",
    },
  },
}));

const PartnerPageFooter = ({ title, description, color }) => {
  return (
    <StyledBox className={classes.root} color={color}>
      <Box className={classes.contentBlock}>
        <Typography className={classes.title}>
          <SanitizedText input={title} />
        </Typography>
        <Typography className={classes.description}>
          <SanitizedText input={description} />
        </Typography>
      </Box>

      <Footer
        showLanguageSelector={true}
        showSpecialConditionsForTherapist={false}
        style={{
          width: "100%",
        }}
      />
    </StyledBox>
  );
};

export default PartnerPageFooter;
