import Color from "color";
import { styled } from "@mui/material/styles";
import { Box, BoxProps, Typography } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import SanitizedText from "../../../../ui/text/SanitizedText";
import Button from "../../../../ui/buttons/Button";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";

const PREFIX = "PartnerBlock";

const classes = {
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  contentBlock: `${PREFIX}-contentBlock`,
  title: `${PREFIX}-title`,
  wrapperBlock: `${PREFIX}-wrapperBlock`,
};

const StyledBox = styled(Box)<{
  color: string;
}>(({ theme, color }) => ({
  [`&.${classes.wrapperBlock}`]: {
    background: "white",
    borderRadius: 14,
    margin: 30,
    maxWidth: 362,
    minWidth: 320,

    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginTop: 15,
      minWidth: 0,
    },
  },

  [`& .${classes.contentBlock}`]: {
    background: `${Color(color).alpha(0.01).string()}`,
    border: `solid 2px ${Color(color).alpha(0.2).string()}`,
    borderRadius: 14,
    paddingBottom: 50,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 50,
    position: "relative",
  },

  [`& .${classes.buttonWrapper}`]: {
    "& .MuiButton-root": {
      background: color,
    },
    bottom: 25,
    position: "absolute",

    right: 25,
  },

  [`& .${classes.title}`]: {
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 20,

    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      lineHeight: "24px",
    },
  },
}));

interface IPartnerBlock extends BoxProps {
  children: ReactNode;
  color: string;
  showButton?: boolean;
  title: string;
}

const PartnerBlock = ({
  title,
  children,
  color,
  showButton = false,
  ...other
}: IPartnerBlock) => {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Partners,
  ]);

  return (
    <StyledBox
      color={color}
      {...other}
      className={clsx(classes.wrapperBlock, other.className)}
      key={title}
    >
      <Box className={classes.contentBlock}>
        {title && (
          <Typography className={classes.title}>
            <SanitizedText input={title} />
          </Typography>
        )}
        {children}
        {showButton && (
          <Box className={classes.buttonWrapper}>
            <Button
              label={translate("customlanding.blocks.askfor", "Vraag aan")}
              onClick={() => {}}
            />
          </Box>
        )}
      </Box>
    </StyledBox>
  );
};

export default PartnerBlock;
