import { Box, Link, SvgIconTypeMap, Typography } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import React from "react";
import Label from "./Label";

const PREFIX = "ValueWithLabel";

const classes = {
  clickable: `${PREFIX}-clickable`,
  itemTitle: `${PREFIX}-itemTitle`,
  itemValue: `${PREFIX}-itemValue`,
  largeItem: `${PREFIX}-largeItem`,
  root: `${PREFIX}-root`,
  row: `${PREFIX}-row`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.root}`]: {
    paddingBottom: "10px",
    width: "100%",
  },

  [`& .${classes.row}`]: {},

  [`& .${classes.itemTitle}`]: {
    color: theme.palette.gray.dark,
    fontSize: theme.typography.sizes.small,
    fontWeight: 600,
    width: "100%",
  },

  [`& .${classes.itemValue}`]: {
    alignItems: "center",
    display: "flex",
    fontSize: theme.typography.sizes.normal,
    paddingBottom: 10,
    whiteSpace: "pre-line",
  },

  [`& .${classes.largeItem}`]: {
    fontSize: 25,
    padding: "5px 0 10px",
  },

  [`& .${classes.clickable}`]: {
    cursor: "pointer",
  },
}));

interface IValueWithLabel {
  buttons?: {
    color: string;
    href?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
      muiName: string;
    };
    text: string;
  }[];
  children: React.ReactNode;
  className?: string;
  label: string;
  large?: boolean;
  onClick?: () => void | undefined;
  variant?: string;
}

export default function ValueWithLabel({
  label,
  variant = "normal",
  className: extraClass,
  onClick,
  large = false,
  children,
  buttons,
}: IValueWithLabel) {
  return (
    <StyledBox className={`${classes.root} ${extraClass}`}>
      {label && (
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Label value={label} variant={variant} />
          </Box>
          {buttons && (
            <Box display={"flex"} mr={"17px"}>
              {buttons.map((button) => {
                return (
                  <Link href={button.href} underline="none">
                    <Box
                      alignItems={"center"}
                      color={button.color}
                      display={"flex"}
                      justifyContent={"space-between"}
                      ml={"10px"}
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      <button.Icon
                        style={{ color: button.color, marginRight: "4px" }}
                      />
                      <Typography
                        pb={"2px"}
                        style={{ color: button.color }}
                        variant="body2"
                      >
                        {button.text}
                      </Typography>
                    </Box>
                  </Link>
                );
              })}
            </Box>
          )}
        </Box>
      )}
      <Box className={clsx(classes.row, large && classes.largeItem)}>
        <div
          className={clsx(onClick && classes.clickable, classes.itemValue)}
          onClick={onClick ? onClick : undefined}
        >
          {children}
        </div>
      </Box>
    </StyledBox>
  );
}
