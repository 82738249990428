import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "../../../api/__generated__";
import { ConsultationTypes } from "../../../data-model/types/consultation/Consultation";
import Professional from "../../../data-model/types/professional/Professional";
import { IFormState } from "../../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import ProfessionalRate from "../../professionals/professionalRate";
import Button from "../../ui/buttons/Button";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";
import SingleSelect from "../../ui/form/select/SingleSelect";
import Label from "../../ui/text/Label";
import TimeSlotBoxes from "../human-matching/steps/matching/TimeSlotBoxes";
import TimeSlot from "../../../data-model/types/consultation/TimeSlot";
import { IRequestConsultationContext } from "./context/RequestConsultation";
import RequestConsultationContext from "./context/RequestConsultationContext";
import { IRequestConsultationFormStateValues } from "./useRequestConsultationFormState";

const graphQuery = graphql(`
  query RequestConsultationForm($professionalId: Int) {
    currentUser {
      human {
        id
        ...ProfessionalRate_human
      }
      id
    }
    professional(id: $professionalId) {
      id
      ...ProfessionalRate_professional
    }
  }
`);

interface IRequestConsultationForm {
  formState: IFormState<IRequestConsultationFormStateValues>;
  onSubmit: () => void;
  professionals: Array<Professional>;
}

const RequestConsultationForm = ({
  professionals,
  formState,
  onSubmit,
}: IRequestConsultationForm) => {
  const { t: translate, i18n } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Human,
  ]);

  const { submitting } = useContext<IRequestConsultationContext>(
    RequestConsultationContext,
  );

  const [disabled, setDisabled] = useState(true);

  const [selectedProfessional, setSelectedProfessional] =
    useState<Professional | null>(null);

  const { data } = useQuery(graphQuery, {
    variables: { professionalId: selectedProfessional?.getID() },
  });

  useEffect(
    () => setDisabled(formState.testForErrors()),
    [formState, formState.testForErrors],
  );

  const professionalOptions: Array<ISelectObject<Professional>> = useMemo(
    () =>
      professionals.map((c) => ({
        label: c.getScreenName(),
        value: c,
      })),
    [professionals],
  );

  const timeSlotOptions: Array<ISelectObject<TimeSlot>> = useMemo(() => {
    if (!selectedProfessional) return [];

    return selectedProfessional.getAvailability().map((ts) => ({
      label: ts.getStartTime().locale(i18n.language).format("dd DD MMM HH:mm"),
      value: ts,
    }));
  }, [i18n.language, selectedProfessional]);

  return (
    <>
      <Box mb={4} mt={2}>
        <Box mb={3} py={1}>
          <Label
            value={translate(
              "human:request.consultation.select.type.label",
              "Wil je een eerste kennismakingsgesprek of een betalende consultatie aanvragen?",
            )}
          />
          <Box mt={1}>
            <SingleSelect<ConsultationTypes>
              options={[
                {
                  label: translate(
                    "common:consultation_type." + ConsultationTypes.APPOINTMENT,
                  ),
                  value: ConsultationTypes.APPOINTMENT,
                },

                {
                  label: translate(
                    "common:consultation_type." + ConsultationTypes.INTRO,
                  ),
                  value: ConsultationTypes.INTRO,
                },
              ]}
              {...formState.getInputProps("type")}
              placeholder={translate(
                "human:request.consultation.select.type.placeholder",
                "Kies type consultatie",
              )}
            />
          </Box>
        </Box>
        <Box mb={3} py={1}>
          <Label value={translate("common:professional")} />
          <Box mt={1}>
            <SingleSelect<Professional>
              options={professionalOptions}
              {...formState.getInputProps("professional")}
              onSelected={(option) => {
                const found = professionals.find(
                  (c) => option.value.getID() === c.getID(),
                );

                if (found) setSelectedProfessional(found);
              }}
              placeholder={translate(
                "human:consultations.select.user",
                "Kies een professional",
              )}
            />
          </Box>
        </Box>
        {selectedProfessional && (
          <Box mb={3} py={1}>
            <Label
              value={translate(
                "human:request.consultation.timeslot.label",
                "Wanneer had je graag een afspraak?",
              )}
            />
            <Box mt={1}>
              <TimeSlotBoxes
                label={translate(
                  "human:request.consultation.timeslot.placeholder",
                  "Kies een tijdslot",
                )}
                onSelectTime={(timeSlot) =>
                  formState.setValue("timeslot", timeSlot)
                }
                timeslotItems={timeSlotOptions}
              />
            </Box>
          </Box>
        )}
        {data?.professional &&
          selectedProfessional &&
          data.currentUser?.human && (
            <Box mb={3} py={1}>
              <Label value={translate("common:rate", "Tarief")} />
              <ProfessionalRate
                human={data.currentUser.human}
                professional={data.professional}
              />
              <Box mb={0} py={2}>
                <Label
                  value={translate(
                    "human:request.consultation.quick.response.label",
                    "Je professional zal je aanvraag zo snel mogelijk bevestigen of je een nieuw voorstel sturen.",
                  )}
                />
              </Box>
            </Box>
          )}
      </Box>
      <Box textAlign="left">
        <Button
          disabled={disabled}
          label={translate("common:book", "Boek consultatie")}
          loading={submitting}
          onClick={onSubmit}
        />
      </Box>
    </>
  );
};

export default RequestConsultationForm;
