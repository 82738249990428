/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFetchConsultationByUuid from "../../../api/consultations/hooks/useFetchConsultationByUuid";
import SpecificWaitingRoomPresence from "../../../utils/pusher/channels/components/SpecificWaitingRoomPresence";
import WaitingRoomPresence from "../../../utils/pusher/channels/components/WaitingRoomPresence";
import { getGenericErrorMessage } from "../../../constants";
import ToastContext from "../../providers/toast/ToastContext";
import useUpdateConsultation from "../../../api/consultations/hooks/useUpdateConsultation";
import LoadingPage from "../../layout/LoadingPage";
import useStorage from "../../hooks/useStorage";
import { KEY_CHOSEN_PROFESSIONAL } from "../human-matching/context/HumanMatchingStateProvider";
import Professional from "../../../data-model/types/professional/Professional";
import { RouteNames } from "../../routes/routeNames";
import { BloomUpNamespaces } from "../../language/I18Namespaces";
import HumanWaitingRoomPage from "./HumanWaitingRoomPage";

export default function HumanWaitingRoomPageWithState() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { getWithKeyFromStorage } = useStorage();
  const { t: translate } = useTranslation<BloomUpNamespaces>("errors");
  const { setToast } = useContext(ToastContext);
  const { consultation } = useFetchConsultationByUuid(uuid || "");
  const { updateConsultation } = useUpdateConsultation();
  const [chosenProfessional, setChosenProfessional] = useState<
    Professional | undefined
  >(undefined);

  const getChosenProfessional = async () => {
    /**
     * getWithKeyFromStorage returns a parsed object.
     */
    const professionalFromLocalStorage = await getWithKeyFromStorage(
      KEY_CHOSEN_PROFESSIONAL,
    );

    //'Specialties' would have to be treated in the same way as availability
    //  but since we don't use it in this function, it just gets deleted.
    delete professionalFromLocalStorage.specialties;
    setChosenProfessional(new Professional(professionalFromLocalStorage, true));
  };

  useEffect(() => {
    getChosenProfessional();
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  useEffect(() => {
    getChosenProfessional();

    if (consultation) reopenConsultation();

    if (consultation && consultation.getStatus().isInProgress()) {
      handlePushToStartCall();
    }
  }, [consultation]);

  const cancelConsultation = async () => {
    try {
      if (uuid) {
        await updateConsultation({
          status: "CANCELLED_BY_HUMAN",
          uuid,
        });
      }
    } catch {
      setToast({
        message: getGenericErrorMessage(translate),
        severity: "error",
      });
    }
  };

  const reopenConsultation = async () => {
    if (
      consultation?.getStatus().getValue() === "REQUESTED" &&
      consultation.requestedBy === "HUMAN" &&
      uuid
    ) {
      await updateConsultation({
        status: "REQUESTED",
        uuid,
      });
    }
  };

  const handleUnload = (e: Event) => {
    e.preventDefault();

    return cancelConsultation();
  };

  const handlePushToStartCall = () =>
    navigate(RouteNames.Call.Enter.path.replace(":uuid", uuid || ""));

  if (!consultation || !chosenProfessional) return <LoadingPage full />;

  return (
    <WaitingRoomPresence>
      <SpecificWaitingRoomPresence
        userAddedCallback={handlePushToStartCall}
        uuid={uuid || ""}
      >
        <HumanWaitingRoomPage
          chosenProfessional={chosenProfessional}
          consultation={consultation}
        />
      </SpecificWaitingRoomPresence>
    </WaitingRoomPresence>
  );
}
