import { useContext } from "react";
import { IWithChildren } from "../../../baseInterfaces";
import { IAuthContext } from "../../providers/auth/auth";
import AuthContext from "../../providers/auth/AuthContext";
import { RouteNames } from "../routeNames";
import LoadingPage from "../../layout/LoadingPage";
import { UserRoles } from "../../../data-model/types/User";
import Guard from "./Guard";

const OnboardedGuard = ({ children }: IWithChildren) => {
  const { currentUser, loading } = useContext<IAuthContext>(AuthContext);

  const path =
    currentUser?.getRole() === UserRoles.PROFESSIONAL
      ? RouteNames.Onboarding.Professional.path
      : RouteNames.Matching.Consent.path;

  if (loading) return <LoadingPage full />;

  return (
    <Guard
      allowAccessToAdmin
      condition={
        currentUser ? currentUser.isOnboardedAt() !== undefined : false
      }
      redirectPath={path}
    >
      {children}
    </Guard>
  );
};

export default OnboardedGuard;
