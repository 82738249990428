/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetchAllSpecialties from "../../../../api/specialities/hooks/useFetchAllSpecialties";
import Worries from "../../../../data-model/types/profile/Worries";
import useStorage from "../../../hooks/useStorage";
import LoadingPage from "../../../layout/LoadingPage";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import useTrackPageView from "../../../providers/matomo/useTrackPageView";
import SpecialtiesSelect from "../../professional-profile/components/SpecialtiesSelect";
import HumanOnboardingContext from "../context/HumanMatchingContext";
import { WORRIES_STEP } from "../context/humanMatchingSteps";
import useSetMatchingStep from "../hooks/useSetMatchingStep";

const PREFIX = "WorriesStep";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.root}`]: {
    "& .select__value-container": {
      paddingBottom: "10px !important",
      paddingTop: "10px !important",
    },
  },
}));

export {};

const path = Worries.getPath();
const WorriesStep = () => {
  const {
    step,
    next,
    totalSteps,
    previous,
    formState,
    specialties,
    updateMatchedHumanWorriesData,
    loadStoredWorriesInFormState,
  } = useContext(HumanOnboardingContext);

  useFetchAllSpecialties();

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Human,
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Glossary,
  ]);

  const { persistWorriesToStorage } = useStorage();

  useTrackPageView("Human Onboarding > Worries");
  useSetMatchingStep(WORRIES_STEP);

  useLayoutEffect(() => {
    // If not in form state, load from storage.
    const worriesFromFS = formState.getValue(path);

    if (Array.isArray(worriesFromFS)) {
      if (worriesFromFS.length === 0) loadStoredWorriesInFormState();
    }
  }, []);

  const goToNextStep = async () => {
    const worries = formState.getValue(path);

    if (worries && Array.isArray(worries)) {
      await persistWorriesToStorage(worries);

      updateMatchedHumanWorriesData({
        specialtiesThemeComboIds: worries.map((worry) => worry.value),
      });
    }

    next();
  };

  const error = formState.testForErrors(path);

  if (!specialties) return <LoadingPage full />;

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate("human:onboarding.worries.description")}
        disable={error}
        fullCopyright
        headline={translate("human:onboarding.title")}
        labelBack={translate("common:previous")}
        labelNext={translate("human:onboarding.worries.find_match")}
        onClickBack={previous}
        onClickNext={goToNextStep}
        step={step.step}
        subhead={translate("human:onboarding.worries.title")}
        totalSteps={totalSteps}
      >
        <StyledBox className={classes.root}>
          <SpecialtiesSelect
            formState={formState}
            pathOverride={path}
            specialtiesObject={specialties}
          />
        </StyledBox>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
};

export default WorriesStep;
