import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { FunctionComponent, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Email from "../../../../data-model/types/profile/Email";
import Password, {
  passwordValidationMessage,
} from "../../../../data-model/types/profile/Password";
import { I18Namespaces } from "../../../language/I18Namespaces";
import LoadingPage from "../../../layout/LoadingPage";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import useTrackPageView from "../../../providers/matomo/useTrackPageView";
import CheckBox from "../../../ui/form/CheckBox";
import PasswordField from "../../../ui/form/PasswordField";
import TextField from "../../../ui/form/TextField";
import HumanMatchingContext from "../context/HumanMatchingContext";
import { IHumanMatchingContext } from "../context/humanMatching";
import { ACCOUNT_STEP } from "../context/humanMatchingSteps";
import useSetMatchingStep from "../hooks/useSetMatchingStep";
import LoginForm from "../../login/LoginForm";

const PasswordAidText = styled(Typography)(({ theme }) => ({
  color: theme.palette.gray.dark,
  fontSize: theme.typography.sizes.normal,
  margin: "5px 0 10px",
  textAlign: "left",
}));

const AccountStep: FunctionComponent = () => {
  const { formState, createFullUser, finishMatchingForExistingUser } =
    useContext<IHumanMatchingContext>(HumanMatchingContext);

  const [asLogin, setAsLogin] = useState(false);
  const emailInput = useRef<HTMLInputElement>();
  const { t: translate, ready } = useTranslation<I18Namespaces>([
    "human",
    "common",
    "validation",
  ]);

  useTrackPageView("Human Onboarding > Acount");
  useSetMatchingStep(ACCOUNT_STEP);

  if (!ready) return <LoadingPage full />;

  const disable =
    formState.testForErrors(Email.getPath()) ||
    formState.testForErrors(Password.getPath());

  const onClickBack = () => {
    setAsLogin(!asLogin);

    if (emailInput.current) emailInput.current.focus();
  };

  return asLogin ? (
    <LoginForm
      labelBack={translate("human:onboarding.account.new")}
      onClickBack={onClickBack}
      replaceRedirect={finishMatchingForExistingUser}
    />
  ) : (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={disable}
        fullCopyright
        headline={translate("human:onboarding.title")}
        labelBack={translate("human:onboarding.account.with_account")}
        labelNext={translate("common:register")}
        onClickBack={onClickBack}
        onClickNext={createFullUser}
        showLanguageSelector={false}
        showSpecialConditionsForTherapist={false}
        subhead={translate("common:new_user")}
        subheadicon={<LockOutlinedIcon />}
      >
        <Box
          sx={(theme) => ({
            margin: "0 auto",
            width: "70%",

            [theme.breakpoints.between("xss", "sm")]: {
              width: "100%",
            },
          })}
        >
          <TextField
            autoFocus
            id="email"
            inputRef={emailInput}
            label={translate("common:email")}
            {...formState.getInputProps(Email.getPath())}
            style={{
              margin: "15px auto 5px",
            }}
          />
          <PasswordAidText>
            {translate("human:onboarding.account.email.description")}
          </PasswordAidText>
          <Box
            style={{
              margin: "40px auto 5px",
            }}
          >
            <PasswordField
              autoComplete="password"
              id="password"
              label={translate("common:password")}
              type="password"
              {...formState.getInputProps(Password.getPath())}
            />
          </Box>
          <PasswordAidText id="password-helper-text">
            {translate(
              "human:onboarding.account.password.instructions",
              passwordValidationMessage,
            )}
          </PasswordAidText>
          <Box
            sx={(theme) => ({
              "& .MuiTypography-body2": {
                fontSize: theme.typography.sizes.large,
              },

              margin: "30px 0 0 0",
            })}
          >
            <CheckBox
              {...formState.getInputProps("contactForFeedback")}
              handleNativeChange={(_, checked) =>
                formState.handleChange("contactForFeedback", checked)
              }
              label={translate("human:onboarding.account.feedback.agree")}
            />
          </Box>
        </Box>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
};

export default AccountStep;
