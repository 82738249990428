import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { prepareOneLanguageForAPI } from "../../../components/language/languagesUtil";
import { MUTATION_MATCHING_START } from "../mutations";
import { APIMatching } from "../matching";

const useStartMatching = () => {
  const { i18n } = useTranslation();

  const [startMatchingFunction, { data, loading, error }] = useMutation(
    MUTATION_MATCHING_START,
  );

  const startMatching = async ({
    slug,
    extraInfo,
  }: APIMatching.Start.Input) => {
    if (!slug) slug = "";

    const { data } = await startMatchingFunction({
      variables: {
        input: {
          extraInfo,
          language: prepareOneLanguageForAPI(i18n.language),
          slug: slug ? slug.toLowerCase() : undefined,
        },
      },
    });

    return data?.startMatching;
  };

  return {
    data,
    error,
    loading,
    startMatching,
  };
};

export default useStartMatching;
