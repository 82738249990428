import { Box, Typography } from "@mui/material";
import { graphql } from "../../../api/__generated__";
import { useQuery, useMutation } from "@apollo/client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../ui/buttons/Button";

const mutualitiesQuery = graphql(`
  query Mutualities {
    mutualities {
      otherMutualities {
        id
        logoUrl
        preferred
        translationKey
      }
      regionMutualities {
        id
        logoUrl
        preferred
        translationKey
      }
    }
  }
`);

const submitMutualityStepMutation = graphql(`
  mutation SubmitMutualityStep($mutualityId: String!) {
    submitMutualityStep(mutualityId: $mutualityId) {
      matchingToken
      nextStep
    }
  }
`);

interface MutualityStepProps {
  setNextStep: (nextStep: string) => void;
}

const MutualityStep = ({ setNextStep }: MutualityStepProps) => {
  const { t } = useTranslation();
  const { data: mutualitiesData } = useQuery(mutualitiesQuery);
  const [choice, setChoice] = useState<string>("");
  const [submitMutualityStep] = useMutation(submitMutualityStepMutation);

  const preferredMutualities =
    mutualitiesData?.mutualities.regionMutualities.filter((e) => e.preferred);

  const regularMutualities =
    mutualitiesData?.mutualities.regionMutualities.filter((e) => !e.preferred);

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> Mutuality </Typography>
      {preferredMutualities && (
        <Box>
          {preferredMutualities.map((mutuality) => (
            <Button
              label={t(mutuality.translationKey)}
              onClick={() => {
                setChoice(mutuality.id);
              }}
            />
          ))}
        </Box>
      )}
      {regularMutualities && (
        <Box>
          {regularMutualities.map((mutuality) => (
            <Button
              label={t(mutuality.translationKey)}
              onClick={() => {
                setChoice(mutuality.id);
              }}
            />
          ))}
        </Box>
      )}
      {mutualitiesData?.mutualities.otherMutualities && (
        <div>
          <label htmlFor="others"></label>
          <select
            id="others"
            name="others"
            onChange={(e) => {
              setChoice(e.target.value);
            }}
          >
            <option disabled selected>
              -- select an option --
            </option>
            {mutualitiesData.mutualities.otherMutualities.map((mutuality) => {
              return (
                <option value={mutuality.id}>
                  {t(mutuality.translationKey)}
                </option>
              );
            })}
          </select>
        </div>
      )}
      <br /> <br />
      {choice && (
        <Button
          label="next"
          onClick={async () => {
            const { data: submitData } = await submitMutualityStep({
              variables: { mutualityId: choice },
            });

            submitData?.submitMutualityStep.nextStep &&
              setNextStep(submitData.submitMutualityStep.nextStep);

            submitData?.submitMutualityStep.matchingToken &&
              localStorage.setItem(
                "matchingToken",
                submitData.submitMutualityStep.matchingToken,
              );
          }}
          type="submit"
        />
      )}
    </Box>
  );
};

export default MutualityStep;
