import { useTranslation } from "react-i18next";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../api/__generated__";
import { OrganizationSlugs } from "../../data-model/types/Organization";
import SanitizedText from "../ui/text/SanitizedText";

export const ProfessionalRate_professional = graphql(`
  fragment ProfessionalRate_professional on Professional {
    id
    standardDuration
    standardRate
  }
`);

export const ProfessionalRate_human = graphql(`
  fragment ProfessionalRate_human on Human {
    canPayWithCredits
    healthInsurance
    id
    organization {
      creditsPerHuman
      id
      slug
    }
  }
`);

function ProfessionalRate({
  professional: profData,
  human: humanData,
}: {
  human: FragmentType<typeof ProfessionalRate_human>;
  professional: FragmentType<typeof ProfessionalRate_professional>;
}) {
  const professional = getFragmentData(ProfessionalRate_professional, profData);

  const human = getFragmentData(ProfessionalRate_human, humanData);

  const { t } = useTranslation();

  const reimbursementRate = 10;

  if (!professional.standardRate) {
    throw new Error("Rate is not defined");
  }

  const text = (() => {
    if (human.canPayWithCredits && human.organization?.creditsPerHuman !== 0) {
      const pricePerConsult =
        human.organization?.slug === OrganizationSlugs.MANTELZORG_STAD_ANTWERPEN
          ? Math.min(professional.standardRate, 60)
          : professional.standardRate;

      if (human.organization?.creditsPerHuman === null) {
        return t(`human:rateline.credits.unlimited`);
      } else if (human.healthInsurance) {
        return t(`human:rateline.credits.limited.reimbursement`, {
          duration: professional.standardDuration,
          numberOfFreeConsults: human.organization?.creditsPerHuman,
          pricePerConsult,
          reimbursementPricePerConsult: pricePerConsult - reimbursementRate,
        });
      } else {
        return t(`human:rateline.credits.limited.noreimbursement`, {
          duration: professional.standardDuration,
          numberOfFreeConsults: human.organization?.creditsPerHuman,
          pricePerConsult,
        });
      }
    } else if (human.healthInsurance) {
      return t(`human:rateline.nocredits.reimbursement`, {
        duration: professional.standardDuration,
        rate: professional.standardRate,
        reimbursementPricePerConsult:
          professional.standardRate - reimbursementRate,
      });
    } else {
      return t(`human:rateline.nocredits.noreimbursement`, {
        duration: professional.standardDuration,
        rate: professional.standardRate,
      });
    }
  })();

  return <SanitizedText input={text} />;
}

export default ProfessionalRate;
