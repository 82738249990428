import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import HumanMatchStanding from "../../../../illustrations/human-match-standing";
import "../../../human-waiting-room/Pulsing.scss";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";

const PREFIX = "MatchingAnimation";

const classes = {
  bullet: `${PREFIX}-bullet`,
  close: `${PREFIX}-close`,
  container: `${PREFIX}-container`,
  h3: `${PREFIX}-h3`,
  illu: `${PREFIX}-illu`,
  pulsing: `${PREFIX}-pulsing`,
  waitingInfoBox: `${PREFIX}-waitingInfoBox`,
};

const Root = styled("section")(({ theme }) => ({
  [`& .${classes.h3}`]: {
    color: theme.palette.bloom.dark,
    lineHeight: 1.3,
    marginBottom: 16,
  },

  [`& .${classes.illu}`]: {
    position: "relative",
  },

  [`& .${classes.pulsing}`]: {
    background: theme.palette.secondary.main,
    borderRadius: "50%",
    bottom: 5,
    height: 199,
    left: 1,
    opacity: 0.5,
    position: "absolute",
    width: 199,
  },

  [`& .${classes.close}`]: {
    "& svg": {
      position: "relative",
      top: 1,
    },
    "&:hover": {
      "& svg": {
        stroke: theme.palette.primary.light,
      },
      background: theme.palette.primary.main,
    },
    background: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    cursor: "pointer",
    height: 44,
    padding: 0,
    position: "absolute",
    right: 24,
    top: -16,

    transition: "background .15s ease-in-out",

    width: 44,
  },

  [`& .${classes.waitingInfoBox}`]: {
    background: "rgba(242, 242, 242, 0.4)",
    borderRadius: 5,
    display: "flex",
    left: "24px",
    maxWidth: 270,
    padding: theme.spacing(2, 3),
    position: "absolute",
    top: "24px",
    zIndex: 2,
  },

  [`& .${classes.bullet}`]: {
    background: "red",
    borderRadius: "50%",
    display: "block",
    height: 5,
    width: 5,
  },

  [`&.${classes.container}`]: {
    border: "none",
    borderRadius: 0,
    overflow: "visible",
  },
}));

export default function MatchingAnimation() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Human,
  ]);

  return (
    <Root
      className={`onboarding__step onboarding__step__single ${classes.container}`}
    >
      <div className="content">
        <div className="content__container content__container__narrow">
          <div className={`illustration ${classes.illu}`}>
            <div className={`pulsing ${classes.pulsing}`} />
            <HumanMatchStanding />
          </div>
          <div className="description">
            <Typography className={classes.h3} variant="h2">
              {translate(
                "human:onboarding.search.matching_animation.part_1",
                "We zoeken een psycholoog die bij je past...",
              )}
            </Typography>
            <Typography paragraph variant="body1">
              {translate(
                "human:onboarding.search.matching_animation.part_2",
                "Gelieve deze pagina niet te sluiten. Dit duurt meestal maar een paar seconden.",
              )}
            </Typography>
          </div>
        </div>
      </div>
    </Root>
  );
}
