import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";
import Organization from "../../../data-model/types/Organization";
import { QUERY_FETCH_ORGANIZATION_BY_SLUG } from "../queries";
import { APIOrganization } from "../organizations";

export default function useFetchOrganizationBySlug() {
  const [executeFunction, { data, error, loading }] = useLazyQuery<
    APIOrganization.Fetch.BySlug.Result,
    APIOrganization.Fetch.BySlug.Input
  >(QUERY_FETCH_ORGANIZATION_BY_SLUG);

  const fetchOrganizationBySlug = (slug: string) =>
    executeFunction({ variables: { slug } });

  const organizationToReturn = useMemo(() => {
    if (!loading && data) {
      const { organizationBySlug } = data;

      return new Organization(organizationBySlug);
    }
  }, [loading, data]);

  return {
    error,
    fetchOrganizationBySlug,
    loading,
    organization: organizationToReturn,
  };
}
