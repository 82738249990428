import { Box, Typography } from "@mui/material";

const TopicStep = () => {
  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> Topic </Typography>
    </Box>
  );
};

export default TopicStep;
