import { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SittingHuman from "../../../illustrations/human-sorry-sitting";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import useTrackPageView from "../../../providers/matomo/useTrackPageView";
import LoadingPage from "../../../layout/LoadingPage";
import { CONSULTATION_BOOKED_STEP } from "../context/humanMatchingSteps";
import useSetMatchingStep from "../hooks/useSetMatchingStep";
import HumanMatchingContext from "../context/HumanMatchingContext";
import { IHumanMatchingContext } from "../context/humanMatching";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import { RouteNames } from "../../../routes/routeNames";

export default function ConsultationBookedStep() {
  const { loading } = useContext<IHumanMatchingContext>(HumanMatchingContext);

  const navigate = useNavigate();

  useTrackPageView("Human Onboarding > Consult Booked");
  useSetMatchingStep(CONSULTATION_BOOKED_STEP);

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Human,
    BloomUpNamespacesEnum.Common,
  ]);

  if (loading) return <LoadingPage />;

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullCopyright
        headline={translate("human:onboarding.title")}
        labelNext={translate("common:to.my.consultation")}
        onClickNext={() => {
          navigate(RouteNames.Home.path);
        }}
        showLanguageSelector={false}
        showSpecialConditionsForTherapist={false}
      >
        <div className="content">
          <div className="content__container content__container__narrow">
            <Box className="illustration" mt={5}>
              <SittingHuman />
            </Box>
            <Box className="description" mt={5}>
              <Typography variant="subtitle2">
                {translate("human:onboarding.booked.title")}
              </Typography>
              <Typography variant="body1">
                {translate("human:onboarding.booked.description.part_1")}
              </Typography>
            </Box>
          </div>
        </div>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
