import { useContext } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import HumanMatchStanding from "../../illustrations/human-match-standing";
import useTrackPageView from "../../providers/matomo/useTrackPageView";
import Consultation from "../../../data-model/types/consultation/Consultation";
import LoadingPage from "../../layout/LoadingPage";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import TimeSlotBoxes from "../human-matching/steps/matching/TimeSlotBoxes";
import HumanMatchingContext from "../human-matching/context/HumanMatchingContext";
import { IHumanMatchingContext } from "../human-matching/context/humanMatching";
import "./HumanWaiting.scss";
import "./Pulsing.scss";
import TimeSlot from "../../../data-model/types/consultation/TimeSlot";
import Professional from "../../../data-model/types/professional/Professional";
import useUpdateConsultation from "../../../api/consultations/hooks/useUpdateConsultation";
import { I18Namespaces } from "../../language/I18Namespaces";
import { IMatch } from "../../../api/matching/hooks/useFindMatches";

const PREFIX = "HumanWaitingRoomPage";

const classes = {
  bullet: `${PREFIX}-bullet`,
  close: `${PREFIX}-close`,
  h3: `${PREFIX}-h3`,
  illu: `${PREFIX}-illu`,
  pulsing: `${PREFIX}-pulsing`,
  timeSlots: `${PREFIX}-timeSlots`,
  waitingInfoBox: `${PREFIX}-waitingInfoBox`,
};

const StyledSection = styled("section")(({ theme }) => ({
  [`& .${classes.h3}`]: {
    lineHeight: 1.3,
    marginBottom: 16,
  },

  [`& .${classes.illu}`]: {
    position: "relative",
  },

  [`& .${classes.pulsing}`]: {
    background: theme.palette.secondary.main,
    borderRadius: "50%",
    bottom: 5,
    height: 199,
    left: 1,
    opacity: 0.5,
    position: "absolute",
    width: 199,
  },

  [`& .${classes.close}`]: {
    "& svg": {
      position: "relative",
      top: 1,
    },
    "&:hover": {
      "& svg": {
        stroke: theme.palette.white.main,
      },
      background: theme.palette.primary.main,
    },
    background: theme.palette.white.main,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    cursor: "pointer",
    height: 44,
    padding: 0,
    position: "absolute",
    right: 24,
    top: -16,
    transition: "background .15s ease-in-out",
    width: 44,
  },

  [`& .${classes.waitingInfoBox}`]: {
    background: "rgba(242, 242, 242, 0.4)",
    borderRadius: 5,
    display: "flex",
    left: "24px",
    maxWidth: 270,
    padding: theme.spacing(2, 3),
    position: "absolute",
    top: "24px",
    zIndex: 2,
  },

  [`& .${classes.bullet}`]: {
    background: "red",
    borderRadius: "50%",
    display: "block",
    height: 5,
    width: 5,
  },

  [`& .${classes.timeSlots}`]: {
    "& .timeslot": {
      background: theme.palette.porple.light,
      color: theme.palette.porple.main,
    },
    marginBottom: 65,
    marginTop: 115,

    width: 500,
  },
}));

interface IHumanWaitingRoomPage {
  chosenProfessional: Professional;
  consultation: Consultation;
}

export default function HumanWaitingRoomPage({
  consultation,
  chosenProfessional,
}: IHumanWaitingRoomPage) {
  useTrackPageView("Human Onboarding > Waiting room");

  const { t: translate } = useTranslation<I18Namespaces>(["human"]);
  const { scheduleCall, setSelectedTimeSlot, loading, matches } =
    useContext<IHumanMatchingContext>(HumanMatchingContext);

  const { updateConsultation } = useUpdateConsultation();
  let match: IMatch | undefined;

  if (matches) {
    match = matches.find(
      (m) => m.professional.getID() === chosenProfessional.getID(),
    );
  }

  if (!consultation || !match || loading) return <LoadingPage full />;

  return (
    <OnboardingContainer>
      <StyledSection className="onboarding__step onboarding__step__single">
        <div className="content">
          <div className="content__container content__container__narrow">
            <header className="header">
              <Typography variant="h1">Blooming...</Typography>
            </header>
            <div className={`illustration ${classes.illu}`}>
              <div className={`pulsing ${classes.pulsing}`} />
              <HumanMatchStanding />
            </div>
            <div className="description">
              <Typography className={classes.h3} variant="h3">
                {translate(
                  "human:waitingroom.title",
                  "Wij laten je psycholoog weten dat je klaar zit.",
                )}
              </Typography>
              <Typography paragraph variant="body1">
                {translate(
                  "human:waitingroom.body_1",
                  "Gelieve deze pagina niet te sluiten. We sturen je een browser melding als je professional er is.",
                )}
              </Typography>
              <Typography paragraph variant="body1">
                {translate(
                  "human:waitingroom.body_2",
                  "Dit kan tot 15 minuten duren. Gelieve onze professional de tijd te geven om in het gesprek te komen.",
                )}
              </Typography>
            </div>
            <Box className={classes.timeSlots}>
              {match.availability ? (
                <TimeSlotBoxes
                  label={translate(
                    "human:waitingroom.dropdown.placeholder",
                    "Wacht je liever niet? Plan een gesprek in...",
                  )}
                  onScheduleCall={() => {
                    updateConsultation({
                      status: "NO_SHOW_PROFESSIONAL",
                      uuid: consultation.getUUID(),
                    });
                    scheduleCall({
                      availability: [],

                      //Next two don't matter
                      isOnline: true,
                      professional: chosenProfessional,
                      score: 100,
                    });
                  }}
                  onSelectTime={(timeslot: TimeSlot) =>
                    setSelectedTimeSlot(timeslot)
                  }
                  timeslotItems={match.availability.map(
                    (slot: string | null) => {
                      const timeSlot: TimeSlot = new TimeSlot({
                        date: slot as string,
                        durationInMinutes: 15,
                        startTime: slot as string,
                      });

                      return {
                        label: timeSlot.calendar(),
                        value: timeSlot,
                      };
                    },
                  )}
                />
              ) : (
                <LoadingPage full={false} />
              )}
            </Box>
          </div>
        </div>
      </StyledSection>
    </OnboardingContainer>
  );
}
