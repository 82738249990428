import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import SanitizedText from "../../../../ui/text/SanitizedText";
import { PartnerPageContentBlock } from "../PartnerPageContentBlocks";
import PartnerBlock from "./PartnerBlock";

const PREFIX = "PartnerTextBlock";

const classes = {
  description: `${PREFIX}-description`,
};

const StyledPartnerBlock = styled(PartnerBlock)(({ theme }) => ({
  [`& .${classes.description}`]: {
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 16,

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "18px",
    },
  },
}));

export interface PartnerContentBlockProps {
  block: PartnerPageContentBlock;
  color: string;
}

export default function PartnerTextBlock({
  block,
  color,
}: PartnerContentBlockProps) {
  return (
    <StyledPartnerBlock color={color} title={block.title ? block.title : ""}>
      {block.description && (
        <Typography className={classes.description}>
          <SanitizedText input={block.description.toString()} />
        </Typography>
      )}
    </StyledPartnerBlock>
  );
}
