/**
 * Worries of a user in mathcing.
 */

import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class Worries extends DataType<
  Array<string>,
  DataTypePaths.Profile.Worries
> {
  protected static path = "worries";
  protected static defaultValue = [];

  protected fromGraphQL = (value: string): Array<string> => JSON.parse(value);
  protected forGraphQL = (value: Array<string>): string =>
    JSON.stringify(value);

  protected type: any = Worries;

  constructor(value?: any, fromGQL = false) {
    super();
    this.setValue(value || Worries.defaultValue, fromGQL);
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "worries.toomany",
          "Geef maximum 10 onderwerpen op.",
        ),
        path: this.getPath(),
        validate: (worries: Array<string>): boolean => worries.length <= 10,
      },
    ];
  }

  getAsFormStateValue(): Partial<
    Record<DataTypePaths.Profile.Worries, string[]>
  > {
    return { worries: this.value };
  }

  //TODO :
  /**
   * Return an array of objects usable for a select-type of control.
   * This function only returns the values set to this object.
   *
   * @param  {any} translate The i18next translate hook with the glossary namespace.
   * @return {Array<Object>}
   */
  /* public getSelectedOptions(translate: TFunction): Array<Object> {
        const options: Array<Object> = [];
        if (this.getValue() === null) return [];
        for (const value of this.getValue()) {
            options.push({
                value,
                label: translate(`glossary:specialties.${value}`),
            });
        }

        return _.orderBy(options, ["label"], ["asc"]);
    }

    /**
     * Return an array of translated values.
     * This function only returns the values set to this object.
     *
     * @param  {Function} translate The i18next translate hook with the glossary namespace.
     * @return {Array<String>}
     */
  /*public getTranslatedValues(translate: TFunction): Array<string> {
        const options: Array<string> = [];
        if (this.getValue() === null) return [];
        for (const value of this.getValue()) {
            options.push(translate(`glossary:specialties.${value}`));
        }

        return _.orderBy(options, ["label"], ["asc"]);
    }

    /**
     * Get the humanreadible string for this data type's value.
     * Ex: Burn-out, Angsstoornis
     *
     * @param  {any} translate The i18next translate hook with the glossary namespace.
     */
  /*public prettyPrint(translate: TFunction): string {
        return this.getTranslatedValues(translate).filter(Boolean).join(", ");
    }*/
}
