import { useQuery } from "@apollo/client";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { memo, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoOutlined } from "@mui/icons-material";
import { graphql } from "../../../api/__generated__";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import { UserRoles } from "../../../data-model/types/User";
import Consultation, {
  ConsultationTypes,
} from "../../../data-model/types/consultation/Consultation";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import LoadingPage from "../../layout/LoadingPage";
import AuthContext from "../../providers/auth/AuthContext";
import { IAuthContext } from "../../providers/auth/auth";
import Label from "../../ui/form/FormLabel";
import TextField from "../../ui/form/TextField";
import ValueWithLabel from "../../ui/text/ValueWithLabel";
import ConsultationDateSelection from "../ConsultationDateSelection";
import ConsultationPriceSelection from "../ConsultationPriceSelection";
import ConsultationStatus from "../../../data-model/types/consultation/ConsultationStatus";
import { OrgProfileDialog } from "../../organizations/OrgProfileDialog";
import ConsultationCancellation from "./ConsultationCancellation";
import ReimbursementInfo from "./ReimbursementInfo";

const PREFIX = "ConsultationInfo";

const classes = {
  infoItem: `${PREFIX}-infoItem`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(() => ({
  [`& .${classes.infoItem}`]: {
    marginBottom: 15,
  },
}));

//This is a temporary solution to rely less on the model.
//This should eventually become a fragment.
const consultationInfo = graphql(`
  query ConsultationInfo($organizationId: Int!) {
    organizationById(id: $organizationId) {
      id
      name {
        ...useSelectTranslationStringInput
      }
      ...OrgProfileDialogOrganization
    }
  }
`);

const ConsultationInfo = ({
  consultation,
  isGoingToCancel,
  isEditing,
  formState,
}: {
  consultation: Consultation;
  formState: any;
  isEditing: boolean;
  isGoingToCancel: boolean;
  updateConsultationStatus: (uuid: string, status: ConsultationStatus) => void;
}) => {
  const selectTranslation = useSelectTranslation();
  const organizationId = consultation.getClient().getOrganization().getID();

  const { data } = useQuery(consultationInfo, {
    skip: !organizationId,
    variables: {
      organizationId,
    },
  });

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Partners,
    BloomUpNamespacesEnum.Payments,
  ]);

  const [orgDialogOpen, setOrgDialogOpen] = useState(false);

  const { currentUser } = useContext<IAuthContext>(AuthContext);

  const client = consultation.getClient();

  const cashTransaction = consultation.getCashTransaction();
  const creditTransactions = consultation.creditTransactions;

  const getClientName = () => {
    const fullName = client.getFullName();

    return fullName || translate("common:-", "-");
  };

  const msg = consultation.getMessage().getValue();
  const hasMessage =
    msg && msg !== null && msg.body !== "" && msg.body !== null;

  const profEmail = consultation.getProfessional().getEmail().getValue();
  const clientEmail = client.getEmail().prettyPrint();

  const clientPhone = client.getPhone().prettyPrint();

  const creditsLeft = client.getCreditsLeft();

  const showPriceInfo: boolean =
    consultation.getConsultationType() !== ConsultationTypes.INTRO &&
    (!!cashTransaction || creditTransactions.length > 0);

  if (!currentUser || !consultation) return <LoadingPage />;

  const organization = data?.organizationById;

  const organizationName = selectTranslation(organization?.name);

  const handleCloseOrgDialog = () => {
    setOrgDialogOpen(false);
  };

  return (
    <Root>
      {currentUser.isProfessional() && organizationName && (
        <Box className={classes.infoItem}>
          <ValueWithLabel
            label={translate("common:partner")}
            onClick={() => setOrgDialogOpen(true)}
          >
            {organizationName}
            <InfoOutlined color="action" sx={{ marginLeft: "4px" }} />
          </ValueWithLabel>
          {organization && (
            <OrgProfileDialog
              onClose={handleCloseOrgDialog}
              open={orgDialogOpen}
              organization={organization}
            />
          )}
        </Box>
      )}
      {currentUser.isProfessional() && (
        <Box className={classes.infoItem}>
          <ValueWithLabel
            label={translate("common:consultation.with", "Cliënt")}
          >
            {getClientName()} -{" "}
            <a href={`mailto:${clientEmail}`}>{clientEmail}</a> -{" "}
            <a href={`tel:${clientPhone}`}>{clientPhone}</a>
          </ValueWithLabel>
        </Box>
      )}
      {currentUser && currentUser.getRole() !== UserRoles.PROFESSIONAL && (
        <Box className={classes.infoItem}>
          <ValueWithLabel
            label={translate(
              "common:consultation.professional",
              "Professional",
            )}
          >
            {consultation.getProfessional().getFullName()} -{" "}
            <a href={`mailto:${profEmail}`}>{profEmail}</a>
          </ValueWithLabel>
        </Box>
      )}
      <Box className={classes.infoItem}>
        <ValueWithLabel
          label={translate("common:consultation_type.label", "Type")}
        >
          {translate(
            `common:consultation_type.${consultation.getConsultationType()}`,
          )}
        </ValueWithLabel>
      </Box>
      <Box className={classes.infoItem}>
        <ValueWithLabel
          label={translate("common:status.label", "Status van je sessie")}
        >
          {translate(
            `common:consultation_status.${consultation.getStatus().getValue()}`,
            {
              context: currentUser.isProfessional() ? "FOR_PROFESSIONAL" : "",
            },
          )}
        </ValueWithLabel>
      </Box>
      {!isEditing && hasMessage && (
        <Box className={classes.infoItem}>
          <ValueWithLabel
            label={translate("common:consultation.message", "Laatste bericht")}
          >
            {consultation.getMessage().getValue().body}
          </ValueWithLabel>
        </Box>
      )}
      <Box className={classes.infoItem}>
        <ConsultationDateSelection
          consultation={consultation}
          formState={formState}
          isEditing={isEditing}
        />
      </Box>
      {showPriceInfo && (
        <>
          {" "}
          <Box className={classes.infoItem}>
            <ConsultationPriceSelection
              canPayWithCredits={consultation.getClient().canPayWithCredits}
              consultation={consultation}
              creditsLeft={creditsLeft}
              formState={formState}
              isEditing={isEditing && currentUser.isProfessional()}
            />
          </Box>
          {cashTransaction && (
            <Box className={classes.infoItem}>
              <ValueWithLabel
                label={translate("payments:payment.status.text", {
                  context: currentUser.isProfessional() ? "professional" : "",
                  defaultValue: "Status van je betaling",
                })}
              >
                {cashTransaction
                  ? translate(
                      `payments:payment.status.${cashTransaction
                        .getStatus()
                        .toLowerCase()}`,
                      "Unknown",
                    )
                  : translate(
                      "payments:payment.none",
                      "Er is geen betaling aan deze consultatie gekoppeld.",
                    )}
              </ValueWithLabel>
            </Box>
          )}
          {!consultation.getStatus().isCancelled() &&
            consultation.getConsultationType() ===
              ConsultationTypes.APPOINTMENT && (
              <Box className={classes.infoItem}>
                <ReimbursementInfo
                  consultation={consultation}
                  forProfessional={currentUser.isProfessional()}
                />
              </Box>
            )}
        </>
      )}
      <Box>
        {isEditing && (
          <Box>
            <Label
              label={translate(
                "common:consultation.cancel.comment",
                "Opmerking (zichtbaar voor beide)",
              )}
            />
            <TextField
              onChange={() => {}}
              {...formState.getInputProps("message")}
              autoFocus
              required={true}
              value={
                formState.getValue("message")
                  ? formState.getValue("message").body
                  : ""
              }
            />
          </Box>
        )}

        <ConsultationCancellation
          editingMessage={isGoingToCancel}
          formState={formState}
        />
      </Box>
    </Root>
  );
};

export default memo(ConsultationInfo);
