import { Box, Typography } from "@mui/material";
import Button from "../../ui/buttons/Button";
import React, { useState } from "react";
import { graphql } from "../../../api/__generated__";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

const countriesQuery = graphql(`
  query Countries {
    countries {
      id
      regions {
        id
        translationKey
      }
      translationKey
    }
  }
`);

type Choice = {
  countryId: string;
  regionId?: string;
};

const submitLocationStepMutation = graphql(`
  mutation SubmitLocationStep($input: SubmitLocationStepInput!) {
    submitLocationStep(input: $input) {
      matchingToken
      nextStep
    }
  }
`);

interface LocationStepProps {
  setNextStep: (nextStep: string) => void;
}

const LocationStep = ({ setNextStep }: LocationStepProps) => {
  const { t } = useTranslation();
  const { data: countriesData } = useQuery(countriesQuery);
  const [submitLocationStep] = useMutation(submitLocationStepMutation);
  const [choice, setChoice] = useState<Choice>();

  return (
    <Box sx={{ height: "100%", textAlign: "center", width: "100%" }}>
      <Typography variant="h1"> LOCATION </Typography>
      {countriesData?.countries.map((country) => (
        <React.Fragment key={country.id}>
          {country.regions && country.regions[0] ? (
            country.regions.map((region) => (
              <Button
                key={region.id}
                label={t(region.translationKey)}
                onClick={() => {
                  setChoice({ countryId: country.id, regionId: region.id });
                }}
              />
            ))
          ) : (
            <Button
              label={t(country.translationKey)}
              onClick={() => {
                setChoice({ countryId: country.id, regionId: undefined });
              }}
            />
          )}
        </React.Fragment>
      ))}
      <br /> <br />
      {choice && (
        <Button
          label="next"
          onClick={async () => {
            const { data: submitData } = await submitLocationStep({
              variables: {
                input: {
                  countryId: choice.countryId,
                  regionId: choice.regionId,
                },
              },
            });

            submitData?.submitLocationStep.nextStep &&
              setNextStep(submitData.submitLocationStep.nextStep);

            submitData?.submitLocationStep.matchingToken &&
              localStorage.setItem(
                "matchingToken",
                submitData.submitLocationStep.matchingToken,
              );
          }}
          type="submit"
        />
      )}
    </Box>
  );
};

export default LocationStep;
