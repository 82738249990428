/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import HumanMatchingContext from "../context/HumanMatchingContext";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import useTrackPageView from "../../../providers/matomo/useTrackPageView";
import useSetMatchingStep from "../hooks/useSetMatchingStep";
import { WELCOME_STEP } from "../context/humanMatchingSteps";
import useStorage from "../../../hooks/useStorage";
import { getTokenPayload, hasTokenExpired } from "../../../../utils/auth";
import { I18Namespaces } from "../../../language/I18Namespaces";
import { KEY_POSTCODE } from "../../organization-landing/custom/mantelzorgstadantwerpen/MatchingBlockStadAntwerpen";
import { RouteNames } from "../../../routes/routeNames";

function HumanOnboardingStart() {
  const {
    next,
    totalSteps,
    step,
    fromPartnerPage,
    setFromPartnerPage,
    fetchAndStoreMatchingToken,
  } = useContext(HumanMatchingContext);

  const {
    getAccessTokenFromStorage,
    removeAuthTokensFromStorage,
    getMatchingTokenFromStorage,
  } = useStorage();

  const navigate = useNavigate();
  const { getWithKeyFromStorage } = useStorage();
  const { t: translate } = useTranslation<I18Namespaces>(["human", "common"]);
  const checkFromPartnerPage = async () => {
    const postcode = await getWithKeyFromStorage(KEY_POSTCODE);

    if (postcode) setFromPartnerPage(true);
  };

  useLayoutEffect(() => {
    checkFromPartnerPage();
  }, []);

  useTrackPageView("Human Onboarding > Start");
  useSetMatchingStep(WELCOME_STEP);

  //TODO: we should have these steps extend an interface, that forces it to override this function.
  const goToNextStep = async () => {
    const [accessToken, matchingToken] = await Promise.all([
      getAccessTokenFromStorage(),
      getMatchingTokenFromStorage(),
    ]);

    if (accessToken) {
      const tokenPayload = getTokenPayload(accessToken);

      // If this person is logged in as a professional, remove his / her token and
      // continue as a normal user.
      if (tokenPayload.scope === "PROFESSIONAL") {
        await removeAuthTokensFromStorage();
      }
    }

    let matchingTokenHasExpired = false;

    if (matchingToken) {
      /* If this user has a matching token, two things can be happening:
                1. he is completing the matching flow for the second time with the same token --> skip the age and consent steps, we know this already
                2. he started from an organization page where a token was created (= 'fromPartnerPage' url param);
                   in that case, we just progress through the flow as a 'normal' user
            */
      matchingTokenHasExpired = hasTokenExpired(matchingToken);

      if (!matchingTokenHasExpired && !fromPartnerPage) {
        return navigate(RouteNames.Matching.Gender.path);
      }
    }

    if (!matchingToken || matchingTokenHasExpired) {
      // If we don't have a matching token here, create one without a slug.
      // If an accesstoken is present at this moment, the backend will use that to set a
      // possible organization
      fetchAndStoreMatchingToken();
    }

    next();
  };

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate("human:onboarding.description")}
        fullCopyright={true}
        headline={translate("human:onboarding.title")}
        labelNext={translate("common:start")}
        onClickNext={goToNextStep}
        showSpecialConditionsForTherapist={false}
        step={step.step}
        subhead={translate("common:slogan")}
        totalSteps={totalSteps}
      />
    </OnboardingContainer>
  );
}

export default HumanOnboardingStart;
