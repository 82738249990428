import { Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { WaveBox } from "wave-box";
import { UseSelectTranslationStringInputFragment } from "../../../../api/languages/fragments";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import { makeFragmentData } from "../../../../api/__generated__";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import { RouteNames } from "../../../routes/routeNames";
import LanguageDropdown from "../../../ui/LanguageDropdown";
import BloomUpLogoWithTag from "../../../illustrations/logos/BloomUpLogoWithTag";
import environment from "../../../../environment/environment";

const PREFIX = "Footer";

const classes = {
  bloomupInfoAndLanguage: `${PREFIX}-bloomupInfoAndLanguage`,
  bloomupLogo: `${PREFIX}-bloomupLogo`,
  content: `${PREFIX}-content`,
  groupTitle: `${PREFIX}-groupTitle`,
  language: `${PREFIX}-language`,
  linkSection: `${PREFIX}-linkSection`,
  linkUrl: `${PREFIX}-linkUrl`,
  links: `${PREFIX}-links`,
  logoAndInfo: `${PREFIX}-logoAndInfo`,
  root: `${PREFIX}-root`,
  selectControl: `${PREFIX}-selectControl`,
  termLinks: `${PREFIX}-termLinks`,
  terms: `${PREFIX}-terms`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    justifyContent: "center",
    padding: "0 10rem 3rem",
    [theme.breakpoints.down(900)]: {
      padding: "0 6rem 3rem",
    },
    [theme.breakpoints.down(600)]: {
      padding: "0 3rem 3rem",
    },
    [theme.breakpoints.down(400)]: {
      padding: "0 2rem 3rem",
    },
    [theme.breakpoints.down(280)]: {
      padding: "0 1rem 3rem",
    },
  },

  [`& .${classes.content}`]: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1100px",
    width: "100%",
  },

  [`& .${classes.links}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "3rem",
    marginTop: "1rem",
  },

  [`& .${classes.linkSection}`]: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
    marginRight: "3rem",
    marginTop: "1rem",
  },

  [`& .${classes.groupTitle}`]: {
    marginBottom: "1rem",
  },

  [`& .${classes.linkUrl}`]: {
    "&:hover": {
      cursor: "pointer",
    },
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "150%",
    marginBottom: "0.5rem",
  },

  [`& .${classes.bloomupLogo}`]: {
    marginBottom: "1rem",
  },

  [`& .${classes.terms}`]: {
    marginRight: "1rem",
  },

  [`& .${classes.bloomupInfoAndLanguage}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  [`& .${classes.logoAndInfo}`]: {
    marginRight: "2rem",
  },

  [`& .${classes.language}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    marginTop: "2rem",
  },

  [`& .${classes.selectControl}`]: {
    display: "flex",
    flexDirection: "row",
  },

  [`& .${classes.termLinks}`]: {
    "&:hover": {
      cursor: "pointer",
    },
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "150%",
    marginBottom: "0.5rem",
    textDecoration: "underline",
  },
}));

const missionUrls = makeFragmentData(
  {
    en: "https://www.bloomup.org/en/team",
    fr: "https://www.bloomup.org/fr/notre-equipe",
    nl: "https://www.bloomup.org/team",
  },
  UseSelectTranslationStringInputFragment,
);

const privacyUrls = makeFragmentData(
  {
    en: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/DOCS/Privacyverklaring.pdf",
    fr: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/DOCS/Politique_de_confidentialit.pdf",
    nl: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/DOCS/Privacyverklaring.pdf",
  },
  UseSelectTranslationStringInputFragment,
);

const termsUrls = makeFragmentData(
  {
    en: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/DOCS/Algemene+Voorwaarden+BloomUp.pdf",
    fr: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/DOCS/Algemene+Voorwaarden+BloomUp.pdf",
    nl: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/DOCS/Algemene+Voorwaarden+BloomUp.pdf",
  },
  UseSelectTranslationStringInputFragment,
);

const Footer = ({
  baseUrl,
  ggtEnabled,
  videoEnabled,
  mindlabEnabled,
  ssoEnabled,
  mindlabBusinessId,
}: {
  baseUrl?: string;
  ggtEnabled: boolean;
  mindlabBusinessId?: string;
  mindlabEnabled: boolean;
  ssoEnabled: boolean;
  videoEnabled: boolean;
}) => {
  const selectTranslation = useSelectTranslation();
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Organization,
  ]);

  const [searchParams] = useSearchParams();

  return (
    <WaveBox background="#ECF8FF" bottomWave={false} fillBackground>
      <StyledBox className={classes.root}>
        <Box className={classes.content}>
          <Box className={classes.links}>
            {videoEnabled && (
              <Box className={classes.linkSection}>
                <Typography className={classes.groupTitle} variant="h4">
                  {t(
                    "organization:landing.footer.doormat.groupTitle.videoCall",
                  )}
                </Typography>
                <Link
                  className={classes.linkUrl}
                  href={`${environment.REACT_APP_URL}${
                    ssoEnabled
                      ? `${
                          RouteNames.Login.path + RouteNames.Login.Sso.path
                        }?redirectUrl=${encodeURI(
                          environment.REACT_APP_URL + "/matching/start",
                        )}`
                      : "/matching/start"
                  }`}
                  underline="hover"
                >
                  {t(
                    "organization:landing.footer.doormat.content.videoCall.linkText.startMatching",
                  )}
                </Link>
                <Link
                  className={classes.linkUrl}
                  href={`${baseUrl}/video?${searchParams.toString()}`}
                  underline="hover"
                >
                  {t(
                    "organization:landing.footer.doormat.content.videoCall.linkText.offer",
                  )}
                </Link>
              </Box>
            )}
            {ggtEnabled && (
              <Box className={classes.linkSection}>
                <Typography className={classes.groupTitle} variant="h4">
                  {t("organization:landing.footer.doormat.groupTitle.ggt")}
                </Typography>
                <Link
                  className={classes.linkUrl}
                  href={`${baseUrl}${
                    RouteNames.NewB2BLandingPages.ggt.path
                  }?target=download_link&${searchParams.toString()}`}
                  underline="hover"
                >
                  {t(
                    "organization:landing.footer.doormat.content.ggt.linkText.download",
                  )}
                </Link>
                <Link
                  className={classes.linkUrl}
                  href={`${baseUrl}${
                    RouteNames.NewB2BLandingPages.ggt.path
                  }?${searchParams.toString()}`}
                  underline="hover"
                >
                  {t(
                    "organization:landing.footer.doormat.content.ggt.linkText.offer",
                  )}
                </Link>
              </Box>
            )}
            {mindlabEnabled && (
              <Box className={classes.linkSection}>
                <Typography className={classes.groupTitle} variant="h4">
                  {t("organization:landing.footer.doormat.groupTitle.selfCare")}
                </Typography>
                <Link
                  className={classes.linkUrl}
                  href={`${environment.MINDLAB_FRONTEND_URL}/${
                    ssoEnabled ? "login/sso/" + mindlabBusinessId : "login"
                  }`}
                  underline="hover"
                >
                  {t(
                    "organization:landing.footer.doormat.content.selfCare.linkText.signup",
                  )}
                </Link>
                <Link
                  className={classes.linkUrl}
                  href={`${baseUrl}${
                    RouteNames.NewB2BLandingPages.selfCare.path
                  }?${searchParams.toString()}`}
                  underline="hover"
                >
                  {t(
                    "organization:landing.footer.doormat.content.ggt.linkText.offer",
                  )}
                </Link>
              </Box>
            )}
            <Box className={classes.linkSection}>
              <Typography className={classes.groupTitle} variant="h4">
                {t(
                  "organization:landing.footer.doormat.groupTitle.bloomup",
                  "BloomUp",
                )}
              </Typography>
              <Link
                className={classes.linkUrl}
                href={
                  selectTranslation(missionUrls) ??
                  "https://www.bloomup.org/mission"
                }
                underline="hover"
              >
                {t(
                  "organization:landing.footer.doormat.content.bloomup.linkText.ourMission",
                )}
              </Link>
              <Link
                className={classes.linkUrl}
                href={
                  selectTranslation(privacyUrls) ??
                  "https://bloomup.notion.site/Privacyverklaring-5ee558b79b824f24b0550e9730dc138f"
                }
                underline="hover"
              >
                {t(
                  "organization:landing.footer.doormat.content.bloomup.linkText.privacy",
                )}
              </Link>
              <Link
                className={classes.linkUrl}
                href="mailto:support@bloomup.org"
                underline="hover"
              >
                {t("landing.footer.doormat.content.bloomup.linkText.contactus")}
              </Link>
            </Box>
          </Box>
          <Box className={classes.bloomupInfoAndLanguage}>
            <Box className={classes.logoAndInfo}>
              <BloomUpLogoWithTag />
              <Box>
                <Typography>
                  {t("landing.footer.address") + "  "}
                  <Link
                    className={classes.termLinks}
                    href="mailto:support@bloomup.org"
                    underline="hover"
                  >
                    {"support@bloomup.org"}
                  </Link>
                </Typography>
                <Box>
                  <Link
                    className={clsx(classes.terms, classes.termLinks)}
                    href={
                      selectTranslation(termsUrls) ??
                      "https://docs.google.com/document/d/1ZlGkF5ms9YilqQhgd91HrLay9BtcHKWiCejGG-3eehE/edit"
                    }
                    underline="hover"
                  >
                    {t("organization:landing.footer.terms", "Voorwaarden")}
                  </Link>
                </Box>
              </Box>
            </Box>
            <Box className={classes.language}>
              <LanguageDropdown />
            </Box>
          </Box>
        </Box>
      </StyledBox>
    </WaveBox>
  );
};

export default Footer;
