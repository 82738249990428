import { Box } from "@mui/material";
import PrivacyAgeStep from "./PrivacyAgeStep";
import { useState } from "react";
import ErrorPage from "../../ui/ErrorPage";
import LocationStep from "./LocationStep";
import MutualityStep from "./MutualityStep";
import TopicStep from "./TopicStep";

const Orientation = () => {
  const [currentstep, setCurrentStep] = useState<string>("privacy");

  const getCurrentMatchingStep = (step: string) => {
    switch (step) {
      case "privacy":
        return <PrivacyAgeStep setNextStep={setCurrentStep} />;

      case "location":
        return <LocationStep setNextStep={setCurrentStep} />;

      case "mutuality":
        return <MutualityStep setNextStep={setCurrentStep} />;

      case "topic":
        return <TopicStep />;

      default:
        return <ErrorPage />;
    }
  };

  return <Box>{getCurrentMatchingStep(currentstep)}</Box>;
};

export default Orientation;
