import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import BloomUpSwitch from "../../ui/BloomUpSwitch";
import PopUpMenu from "../../ui/PopUpMenu";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";
import Button from "../../ui/buttons/Button";
import ProfessionalAvailabilityContext from "./context/ProfessionalAvailabilityContext";
import { IProfessionalAvailabilityContext } from "./context/ProfessionalAvailability";

const PREFIX = "HeaderToolBarFullCalendar";

const classes = {
  agendaButton: `${PREFIX}-agendaButton`,
  navigationBox: `${PREFIX}-navigationBox`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.navigationBox}`]: {
    display: "flex",
    flexWrap: "wrap",
    p: 1,
  },

  [`& .${classes.agendaButton}`]: {
    flexGrow: 1,
    marginRight: "15px",
  },
}));

interface IHeaderToolBarFullCalendar {
  handleAgendaButton: () => void;
  handleDisplayOptionButton: () => void;
  handleNextButton: () => void;
  handlePreviousButton: () => void;
  headerTitle: string;
}

export enum OnlineDurationOptions {
  thirtyMinutes = 30,
  fourtyFiveMinutes = 45,
  oneHour = 60,
  twoHours = 120,
  threeHours = 180,
  shortest = thirtyMinutes,
}

const HeaderToolBarFullCalendar = ({
  headerTitle,
  handlePreviousButton,
  handleNextButton,
}: IHeaderToolBarFullCalendar) => {
  const { t: translate } = useTranslation<I18Namespaces>(
    BloomUpNamespacesEnum.Professional,
  );

  const {
    formState,
    updateIsAcceptingNewClients,
    updateIsOnline,
    popUpAnchorEl,
    popUpMenuOnClose,
    popUpMenuOnSelectItem,
    onlineUntil,
  } = useContext<IProfessionalAvailabilityContext>(
    ProfessionalAvailabilityContext,
  );

  const getDate = (onlineDurationOptions: OnlineDurationOptions): Date => {
    return moment().add(onlineDurationOptions, "minutes").toDate();
  };

  const options: Array<ISelectObject<Date>> = [
    {
      label: "30 minuten",
      value: getDate(OnlineDurationOptions.thirtyMinutes),
    },
    {
      label: "45 minuten",
      value: getDate(OnlineDurationOptions.fourtyFiveMinutes),
    },
    {
      label: "1 hour",
      value: getDate(OnlineDurationOptions.oneHour),
    },
    {
      label: "2 hours",
      value: getDate(OnlineDurationOptions.twoHours),
    },
    {
      label: "3 hours",
      value: getDate(OnlineDurationOptions.threeHours),
    },
  ];

  return (
    <StyledBox marginBottom={"20px"} width={"100%"}>
      <Box className={classes.navigationBox}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            p: 1,
          }}
        >
          <Box marginRight={"15px"}>
            <Button
              circular={true}
              icon={<KeyboardArrowLeftIcon />}
              noLabel={true}
              onClick={handlePreviousButton}
            />
          </Box>
          <Box marginRight={"15px"}>
            <Button
              circular={true}
              icon={<KeyboardArrowRightIcon />}
              noLabel={true}
              onClick={handleNextButton}
            />
          </Box>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            p: 1,
          }}
        >
          <Box>
            <Typography variant="h3">{headerTitle}</Typography>
          </Box>
        </Box>
        <Box
          marginRight={"5px"}
          sx={{
            alignItems: "center",
            display: "flex",
            flexShrink: 1,
            p: 1,
          }}
        >
          <BloomUpSwitch
            {...formState.getInputProps("isAcceptingNewClients")}
            label={translate(
              "professional:availability.new.clients.switch.label",
              "Cliëntenstop",
            )}
            onChange={(value) => {
              formState.getInputProps("isAcceptingNewClients").onChange(value);

              updateIsAcceptingNewClients(value);
            }}
            toolTip={translate(
              "professional:availability.new.clients.switch.tooltip",
              "Zet de knop cliëntenstop aan om geen nieuwe clienten meer te ontvangen via de matching.",
            )}
          />
          <BloomUpSwitch
            {...formState.getInputProps("isOnline")}
            label={translate(
              "professional:availability.is.online.switch.label",
              {
                context: onlineUntil ? "online" : "offline",
                defaultValue: "Online tot {{onlineUntil}}",
                onlineUntil: moment(onlineUntil).format("HH:MM"),
              },
            )}
            onChange={(value, onClick) => {
              formState.getInputProps("isOnline").onChange(value);

              updateIsOnline(value, onClick);
            }}
            toolTip={translate(
              "professional:availability.is.online.switch.tooltip",
              "Zet deze knop aan om jezelf voor een bepaalde tijd online te zetten in de matching.",
            )}
          />
          <PopUpMenu
            anchorEl={popUpAnchorEl}
            onClose={popUpMenuOnClose}
            options={options}
            {...formState.getInputProps("isOnlineOption")}
            handleMenuItemClick={popUpMenuOnSelectItem}
          />
        </Box>
      </Box>
    </StyledBox>
  );
};

export default HeaderToolBarFullCalendar;
