import { useContext } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import LandingPageContext from "../../context/LandingPageContext";
import { ILandingPageContext } from "../../context/organization";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import Button from "../../../../ui/buttons/Button";
import LoadingPage from "../../../../layout/LoadingPage";
import SingleSelect from "../../../../ui/form/select/SingleSelect";
import {
  getCrispLanguage,
  I18NexusLanguages,
} from "../../../../language/languagesUtil";
import Label from "../../../../ui/text/Label";

const PREFIX = "MatchingBlockEmpty";

const classes = {
  center: `${PREFIX}-center`,
  content: `${PREFIX}-content`,
  infoText: `${PREFIX}-infoText`,
  root: `${PREFIX}-root`,
  startButton: `${PREFIX}-startButton`,
  startButtonContainer: `${PREFIX}-startButtonContainer`,
  startMatchingLogo: `${PREFIX}-startMatchingLogo`,
  title: `${PREFIX}-title`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: { position: "relative" },

  [`& .${classes.content}`]: {
    position: "relative",
    top: -130,
  },

  [`& .${classes.startMatchingLogo}`]: {
    marginBottom: 20,
  },

  [`& .${classes.center}`]: {
    textAlign: "center",
  },

  [`& .${classes.title}`]: {
    color: "#4D4D4D",
    fontFamily: "Inter",
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: "0.4px",
    marginBottom: 40,
  },

  [`& .${classes.infoText}`]: {
    color: "#4D4D4D",
    fontFamily: theme.typography.fontFamily,

    fontSize: 13,
    size: theme.typography.sizes.small,
  },

  [`& .${classes.startButton}`]: {
    minWidth: 120,
  },

  [`& .${classes.startButtonContainer}`]: {
    paddingTop: 50,
    width: "100%",
  },
}));

const MatchingBlockEmpty = () => {
  const { handleSubmit, organization } =
    useContext<ILandingPageContext>(LandingPageContext);

  const { t: translate, i18n } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Validation,
    BloomUpNamespacesEnum.Partners,
  ]);

  if (!organization) return <LoadingPage />;

  const selectLanguage = (language) => {
    i18n.changeLanguage(language.value || "en");
  };

  return (
    <Root>
      <Box mb={4} mt={4}>
        <Label
          value={organization.getTranslation(
            translate,
            "languages.question",
            "Ik spreek...",
          )}
        />
        <SingleSelect<string>
          onChange={selectLanguage}
          options={[
            {
              label: organization.getTranslation(
                translate,
                `languages.nl`,
                "Nederlands",
              ),
              value: I18NexusLanguages.FLEMISH,
            },
            {
              label: organization.getTranslation(
                translate,
                `languages.fr`,
                "Frans",
              ),
              value: I18NexusLanguages.FRENCH,
            },
            {
              label: organization.getTranslation(
                translate,
                `languages.en`,
                "Engels",
              ),
              value: I18NexusLanguages.ENGLISH,
            },
          ]}
          value={{
            label: organization.getTranslation(
              translate,
              `languages.${getCrispLanguage(i18n.language)}`,
            ),
            value: i18n.language || I18NexusLanguages.ENGLISH,
          }}
        />
      </Box>
      <Box
        className={classes.startButtonContainer}
        display="flex"
        justifyContent="center"
      >
        <Button
          className={classes.startButton}
          id="back-button"
          label={organization.getTranslation(
            translate,
            "blocks.matching.button",
          )}
          onClick={() => handleSubmit({})}
        />
      </Box>
    </Root>
  );
};

export default MatchingBlockEmpty;
