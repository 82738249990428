// import { defaultFormStateValues } from "./../../../hooks/useFormState";
import { createContext } from "react";
import { ILandingPageContext } from "./organization";

const defaultLandingPageValues: ILandingPageContext = {
  handleSubmit: () => {},
  isSubmitting: false,
  setSlug: () => {},
  slug: undefined,
};

const LandingPageContext = createContext<ILandingPageContext>(
  defaultLandingPageValues,
);

export default LandingPageContext;
