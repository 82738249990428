import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useFormState from "../../../hooks/useFormState";
import createFormValidation from "../../../../utils/forms/createFormValidation";
import Email from "../../../../data-model/types/profile/Email";
import PreferredGender from "../../../../data-model/types/human/PreferredGender";
import Age from "../../../../data-model/types/profile/Age";
import Password from "../../../../data-model/types/profile/Password";
import Specialties from "../../../../data-model/types/professional/Specialties";
import Worries from "../../../../data-model/types/profile/Worries";
import { I18Namespaces } from "../../../language/I18Namespaces";
import HealthInsurance from "../../../../data-model/types/reimbursement/HealthInsurance";

const INITIAL_VALUES = {
  ...Email.getInitialValue(),
  ...PreferredGender.getInitialValue(),
  ...Age.getInitialValue(),
  ...Password.getInitialValue(),
  ...Worries.getInitialValue(),
  ...PreferredGender.getInitialValue(),
  ...Specialties.getInitialValue(),
  ...HealthInsurance.getInitialValue(),
  contactForFeedback: false,
  match: false,
  timeslot: false,
};

export default function useHumanMatchingFormState(
  initialValues?: any,
  options?: any,
) {
  const { t: translate } = useTranslation<I18Namespaces>(["validation"]);

  const validation = useMemo(
    () =>
      createFormValidation([
        ...Email.getFormValidation(translate),
        ...PreferredGender.getFormValidation(translate),
        ...Age.getFormValidation(translate),
        ...Worries.getFormValidation(translate),
        ...PreferredGender.getFormValidation(translate),
        Password.getFormValidation(translate)[0],
        ...Specialties.getFormValidation(translate),
        ...HealthInsurance.getFormValidation(translate),
      ]),
    [translate],
  );

  return useFormState(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
