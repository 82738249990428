/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useRef, useState, MouseEvent } from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FiberManualRecordIconOutlined from "@mui/icons-material/FiberManualRecordOutlined";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Button from "../../../../ui/buttons/Button";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import PartnerPageFooter from "../../components/PartnerPageFooter";
import ReviewBox from "../../../../ui/ReviewBox";
import testimony1 from "../../../../illustrations/testimony1.png";
import testimony2 from "../../../../illustrations/testimony2.png";
import BloomUpLogoWithTag from "../../../../illustrations/logos/BloomUpLogoWithTag";
import LoadingPage from "../../../../layout/LoadingPage";
import LandingPageContext from "../../context/LandingPageContext";
import { ILandingPageContext } from "../../context/organization";
import MatchingBlockStadAntwerpen from "./MatchingBlockStadAntwerpen";

const PREFIX = "MantelzorgPage";

const classes = {
  buttons: `${PREFIX}-buttons`,
  contentContainer: `${PREFIX}-contentContainer`,
  description: `${PREFIX}-description`,
  header: `${PREFIX}-header`,
  headerCenter: `${PREFIX}-headerCenter`,
  ikziejezorgen: `${PREFIX}-ikziejezorgen`,
  leftButton: `${PREFIX}-leftButton`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  listItemIconWrapper: `${PREFIX}-listItemIconWrapper`,
  logo: `${PREFIX}-logo`,
  reviewContainer: `${PREFIX}-reviewContainer`,
  rightButton: `${PREFIX}-rightButton`,
  root: `${PREFIX}-root`,
  shareModal: `${PREFIX}-shareModal`,
  submitRow: `${PREFIX}-submitRow`,
  submitTitle: `${PREFIX}-submitTitle`,
  title: `${PREFIX}-title`,
};

const PARTNER_SLUG = "mantelzorgstadantwerpen";

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: theme.palette.white.light,
    height: "100%",
  },

  [`& .${classes.header}`]: {
    alignContent: "center",
    alignItems: "center",
    backgroundImage: `url(https://bloomup-production-api-assets.s3-eu-central-1.amazonaws.com/PARTNERS/${PARTNER_SLUG}/header.jpg)`,
    backgroundPosition: "center -750px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    display: "flex",
    flexDirection: "column",
    height: 400,
    marginBottom: 150,
    position: "relative",

    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "center -580px",
    },

    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center -390px",
    },

    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "center center",
    },
  },

  [`& .${classes.logo}`]: {
    marginLeft: -25,
    marginTop: 60,

    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
      marginTop: 30,
    },
  },

  [`& .${classes.contentContainer}`]: {
    marginBottom: 50,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 50,
    maxWidth: 750,
  },

  [`& .${classes.reviewContainer}`]: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 20px",
    },
  },

  [`& .${classes.headerCenter}`]: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 750,
    position: "relative",
  },

  [`& .${classes.title}`]: {
    ...theme.typography.h4,
    background: theme.palette.white.main,

    borderRadius: 30,
    fontWeight: "bold",
    marginTop: 190,
    padding: 30,

    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },

  [`& .${classes.ikziejezorgen}`]: {
    background: theme.palette.bloom.main,
    borderRadius: 40,
    fontSize: 20,
    padding: 15,
    position: "absolute",
    right: -15,
    transform: "rotate(-15deg)",

    [theme.breakpoints.down("sm")]: {
      right: 5,
    },
  },

  [`& .${classes.description}`]: {
    fontSize: 16,
    margin: "0 50px 50px 50px",
  },

  [`& .${classes.submitTitle}`]: {
    fontSize: 20,
    fontWeight: "bold",
    margin: "0 50px 15px 50px",
    paddingTop: 25,
  },

  [`& .${classes.buttons}`]: {
    textAlign: "center",
  },

  [`& .${classes.leftButton}`]: {
    marginBottom: 20,
    marginRight: 15,
  },

  [`& .${classes.rightButton}`]: {
    marginBottom: 20,
  },

  [`& .${classes.shareModal}`]: {
    background: theme.palette.bloom.main,
  },

  [`& .${classes.submitRow}`]: {
    background: theme.palette.white.main,
    padding: "25px 0",
  },

  [`& .${classes.list}`]: {
    fontSize: 16,
    margin: "0 50px 25px 0",
  },

  [`& .${classes.listItem}`]: {
    color: "#4D4D4D",
    lineHeight: 24,
    padding: 0,
  },

  [`& .${classes.listItemIconWrapper}`]: {
    alignSelf: "flex-start",
    marginTop: 5,
    minWidth: 30,
  },

  [`& .${classes.listItemIcon}`]: {
    width: 15,
  },
}));

const MantelzorgPage = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const formRef = useRef<null | HTMLDivElement>(null);

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Partners,
    BloomUpNamespacesEnum.Common,
  ]);

  const scrollToElement = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView();
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { setSlug, organization } =
    useContext<ILandingPageContext>(LandingPageContext);

  useEffect(() => {
    setSlug(PARTNER_SLUG);
  }, []);

  if (!organization) return <LoadingPage full />;

  const orgColor: string =
    organization.getTranslation(translate, "color") ?? "#000000";

  const urlText =
    "Hey!\n\nIk zag dit passeren en moest meteen aan jou denken. Omdat jij altijd druk in de " +
    "weer bent voor een ander, schenk ik jou vijf gratis gesprekken met een erkend therapeut. Zo kan jij ook je hart eens luchten - en wordt er eens een keertje voor jou" +
    " gezorgd. Wat denk je ervan?\n\n" +
    "Meer info vind je op: https://talk.bloomup.org/mantelzorg";

  const url = "https://talk.bloomup.org/mantelzorg";

  return (
    <StyledBox className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.headerCenter}>
          <Box className={classes.logo}>
            <BloomUpLogoWithTag />
          </Box>
          <Box className={classes.title}>
            Schenk vijf gratis gesprekken met een erkend therapeut.
            <Box className={classes.ikziejezorgen}>#ikziejezorgen</Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.description}>
          Ken jij iemand die voor een vriend, kennis of familielid zorgt omwille
          van ziekte, ouderdom, handicap of psychische kwetsbaarheid?
          <br />
          <br />
          Stad Antwerpen en BloomUp ondersteunen zij die altijd klaarstaan voor
          een ander. Daarom kan jij hen vijf gratis gesprekken met een erkend
          therapeut schenken. Zo wordt er ook voor hen gezorgd - en krijgen ze
          opnieuw ruimte in hun hoofd om te zorgen voor diegenen die hen nodig
          hebben.
        </Box>
        <Box className={classes.buttons}>
          <Button
            bloomColor="green"
            className={classes.leftButton}
            label="Schenk vijf sessies"
            onClick={handleClick}
          />
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: "center",
              vertical: "center",
            }}
            id={id}
            onClose={handleClose}
            open={open}
            transformOrigin={{
              horizontal: "center",
              vertical: "center",
            }}
          >
            <Box className={classes.shareModal}>
              <EmailShareButton body={urlText} subject={""} url={""}>
                <EmailIcon />
              </EmailShareButton>
              <FacebookShareButton url={url}>
                <FacebookIcon />
              </FacebookShareButton>
              <LinkedinShareButton url={url}>
                <LinkedinIcon />
              </LinkedinShareButton>
              <WhatsappShareButton url={urlText}>
                <WhatsappIcon />
              </WhatsappShareButton>
            </Box>
          </Popover>
          <Button
            bloomColor="bloom"
            className={classes.rightButton}
            icon={<ArrowDownwardIcon />}
            label="Kom je zelf in aanmerking?"
            onClick={scrollToElement}
          />
        </Box>
      </Box>
      <Box className={classes.submitRow}>
        <div className={classes.contentContainer} ref={formRef}>
          <Box className={classes.submitTitle}>
            Mantelzorgers verdienen ondersteuning
          </Box>
          <Box className={classes.description}>
            Wij zien je zorgen. En daarvoor willen wij jou bedanken. Woon jij in
            Stad Antwerpen (Antwerpen, Berchem, Berendrecht-Zandvliet-Lillo,
            Borgerhout, Deurne, Ekeren, Hoboken, Merksem en Wilrijk) en neem jij
            de zorg voor een vriend, familielid of kennis op? <br />
            <br />
            Dan is het nu tijd om voor jezelf te zorgen. Met BloomUp vind jij
            een erkend therapeut met wie het écht klikt. Match en krijg vijf
            gratis sessies. Want dat verdien je!
            <MatchingBlockStadAntwerpen />
          </Box>
        </div>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.submitTitle}>
          {organization.getTranslation(
            translate,
            "blocks.howto.title",
            "Hoe werkt het?",
          )}
        </Box>
        <Box className={classes.description}>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIconWrapper}>
                <FiberManualRecordIconOutlined
                  className={classes.listItemIcon}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  Op basis van je voorkeuren stellen we 3 professionals voor die
                  bij je passen. Voel je toch niet comfortabel bij het doorlopen
                  van het proces online? Contacteer ons op het nr. 0471 08 47 99
                  voor telefonische begeleiding.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIconWrapper}>
                <FiberManualRecordIconOutlined
                  className={classes.listItemIcon}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  Kies een moment om online kort kennis te maken met een
                  professional naar keuze.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIconWrapper}>
                <FiberManualRecordIconOutlined
                  className={classes.listItemIcon}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography className={classes.listItemIconWrapper}>
                  Klikt het? Start je traject richting een gelukkiger en
                  gezonder leven.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          Wat na de vijf gratis gesprekken?
          <br />
          <br />
          Na de 5 gratis sessies kan je jouw traject verder zetten met je
          begeleidend professional aan een tarief van gemiddeld 60 euro per
          sessie.
        </Box>
      </Box>
      <Box className={clsx(classes.contentContainer, classes.reviewContainer)}>
        <ReviewBox
          description={
            "Tijdens het vrijblijvende kennismakingsgesprek voelde ik meteen de klik. De psycholoog stelde mij direct op mijn gemak dat ik mijn verhaal kon doen, hierdoor besliste ik om gebruik te maken van de 3 gratis consultaties."
          }
          imgSrc={testimony2}
          name={"Ilse Jacobs: Mantelzorger"}
        />
        <ReviewBox
          description={
            "In mijn werk als seniorenconsulente heb ik al verschillende mantelzorgers doorverwezen naar BloomUp. Ik ben blij om te horen dat ze nieuwe moed vinden bij een luisterend oor en leren hun grenzen te bewaren."
          }
          imgSrc={testimony1}
          name={"Sonja Verbustel: Seniorenconsulente"}
        />
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.submitTitle}>
          {organization.getTranslation(translate, "blocks.privacy.title")}
        </Box>
        <Box className={classes.description}>
          {organization.getTranslation(translate, "blocks.privacy.description")}
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.submitTitle}>In samenwerking met</Box>
        <Box className={classes.description}>
          <img
            alt="Mantelzorg Antwerpen icon"
            src={`https://bloomup-production-api-assets.s3-eu-central-1.amazonaws.com/PARTNERS/mantelzorgstadantwerpen/logo.png`}
            width={100}
          />
        </Box>
      </Box>
      <PartnerPageFooter
        color={orgColor}
        description={organization.getTranslation(
          translate,
          "footer.description",
        )}
        title={organization.getTranslation(translate, "footer.title", "")}
      />
    </StyledBox>
  );
};

export default MantelzorgPage;
