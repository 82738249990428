/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useRef } from "react";
import FiberManualRecordIconOutlined from "@mui/icons-material/FiberManualRecordOutlined";
import { useTranslation } from "react-i18next";
import { ILandingPageContext } from "../../context/organization";
import LandingPageContext from "../../context/LandingPageContext";
import LoadingPage from "../../../../layout/LoadingPage";
import BloomUpLogoWithTag from "../../../../illustrations/logos/BloomUpLogoWithTag";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import PartnerPageFooter from "../../components/PartnerPageFooter";
import MatchingBlockStadGent from "./MatchingBlockStadGent";

const PREFIX = "MantelzorgPage";

const classes = {
  buttons: `${PREFIX}-buttons`,
  contentContainer: `${PREFIX}-contentContainer`,
  description: `${PREFIX}-description`,
  header: `${PREFIX}-header`,
  headerCenter: `${PREFIX}-headerCenter`,
  ikziejezorgen: `${PREFIX}-ikziejezorgen`,
  leftButton: `${PREFIX}-leftButton`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  listItemIconWrapper: `${PREFIX}-listItemIconWrapper`,
  logo: `${PREFIX}-logo`,
  reviewContainer: `${PREFIX}-reviewContainer`,
  rightButton: `${PREFIX}-rightButton`,
  root: `${PREFIX}-root`,
  shareModal: `${PREFIX}-shareModal`,
  submitRow: `${PREFIX}-submitRow`,
  submitTitle: `${PREFIX}-submitTitle`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    background: theme.palette.white.light,
    height: "100%",
  },

  [`& .${classes.header}`]: {
    alignContent: "center",
    alignItems: "center",
    backgroundImage:
      "url(https://bloomup-production-api-assets.s3-eu-central-1.amazonaws.com/PARTNERS/mantelzorgstadantwerpen/header.jpg)",
    backgroundPosition: "center -750px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    display: "flex",
    flexDirection: "column",
    height: 400,
    position: "relative",

    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "center -580px",
    },

    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center -390px",
    },

    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "center center",
    },
  },

  [`& .${classes.logo}`]: {
    marginLeft: -25,
    marginTop: 60,

    [theme.breakpoints.down("sm")]: {
      marginLeft: 30,
      marginTop: 30,
    },
  },

  [`& .${classes.contentContainer}`]: {
    marginBottom: 50,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 50,
    maxWidth: 750,
  },

  [`& .${classes.reviewContainer}`]: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 20px",
    },
  },

  [`& .${classes.headerCenter}`]: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 750,
    position: "relative",
  },

  [`& .${classes.title}`]: {
    ...theme.typography.h4,
    background: theme.palette.white.light,

    borderRadius: 30,
    fontWeight: "bold",
    marginTop: 190,
    padding: 30,

    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },

  [`& .${classes.ikziejezorgen}`]: {
    background: theme.palette.bloom.main,
    borderRadius: 40,
    fontSize: 20,
    padding: 15,
    position: "absolute",
    right: -15,
    transform: "rotate(-15deg) translate(20px,20px)",

    [theme.breakpoints.down("sm")]: {
      right: 5,
    },
  },

  [`& .${classes.description}`]: {
    fontSize: 16,
    margin: "0 50px 50px 50px",
  },

  [`& .${classes.submitTitle}`]: {
    fontSize: 20,
    fontWeight: "bold",
    margin: "0 50px 15px 50px",
    paddingTop: 25,
  },

  [`& .${classes.buttons}`]: {
    textAlign: "center",
  },

  [`& .${classes.leftButton}`]: {
    marginBottom: 20,
    marginRight: 15,
  },

  [`& .${classes.rightButton}`]: {
    marginBottom: 20,
  },

  [`& .${classes.shareModal}`]: {
    background: theme.palette.bloom.main,
  },

  [`& .${classes.submitRow}`]: {
    background: theme.palette.white.main,
    padding: "25px 0",
  },

  [`& .${classes.list}`]: {
    fontSize: 16,
    margin: "0 50px 25px 0",
  },

  [`& .${classes.listItem}`]: {
    color: "#4D4D4D",
    lineHeight: 24,
    padding: 0,
  },

  [`& .${classes.listItemIconWrapper}`]: {
    alignSelf: "flex-start",
    marginTop: 5,
    minWidth: 30,
  },

  [`& .${classes.listItemIcon}`]: {
    width: 15,
  },
}));

const PARTNER_SLUG = "mantelzorgstadgent";

const MantelzorgPage = () => {
  const formRef = useRef<null | HTMLDivElement>(null);

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Partners,
    BloomUpNamespacesEnum.Common,
  ]);

  const { setSlug, organization } =
    useContext<ILandingPageContext>(LandingPageContext);

  useEffect(() => {
    setSlug(PARTNER_SLUG);
  }, []);

  if (!organization) return <LoadingPage full />;

  const orgColor: string =
    organization.getTranslation(translate, "color") ?? "#000000";

  return (
    <StyledBox className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.headerCenter}>
          <Box className={classes.logo}>
            <BloomUpLogoWithTag />
          </Box>
          <Box className={classes.title}>
            Mantelzorgers verdienen ondersteuning.
            <Box className={classes.ikziejezorgen}>#ikziejezorgen</Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.submitRow}>
        <div className={classes.contentContainer} ref={formRef}>
          <Box className={classes.submitTitle}>
            Vraag drie gratis gesprekken aan!
          </Box>
          <Box className={classes.description}>
            Woon je in Gent en zorg je vaak voor een vriend, kennis of
            familielid omwille van ziekte, ouderdom, handicap of een psychische
            kwetsbaarheid?
            <br />
            <br />
            Dan is het belangrijk om ook voor jezelf te zorgen. Via Stad Gent en
            BloomUp vind jij een psycholoog of therapeut met wie het écht klikt.
            Match en plan drie gratis video- of telefoon gesprekken in. Want dat
            verdien je!”
            <MatchingBlockStadGent />
          </Box>
        </div>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.submitTitle}>
          {organization.getTranslation(
            translate,
            "blocks.howto.title",
            "Hoe werkt het?",
          )}
        </Box>
        <Box className={classes.description}>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIconWrapper}>
                <FiberManualRecordIconOutlined
                  className={classes.listItemIcon}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  Op basis van je voorkeuren stellen we 3 psychologen of
                  therapeuten voor die bij je passen.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIconWrapper}>
                <FiberManualRecordIconOutlined
                  className={classes.listItemIcon}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography>
                  Kies een moment om online kort kennis te maken met een
                  psycholoog of therapeut naar keuze.
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listItemIconWrapper}>
                <FiberManualRecordIconOutlined
                  className={classes.listItemIcon}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography className={classes.listItemIconWrapper}>
                  Klikt het? Start je traject richting een gelukkiger en
                  gezonder leven.
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          Wil je liever een telefonisch gesprek met een psycholoog inplannen?
          Geen probleem, bel naar 0498/29 00 42 (elke woensdag tussen 8 uur en 9
          uur) en maak een afspraak. Je kan ook een sms sturen naar dit nummer.
          We contacteren je daarna zo snel mogelijk om een afspraak in te
          plannen.
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.submitTitle}>
          Wat na de drie gratis gesprekken?
        </Box>
        <Box className={classes.description}>
          Na de 3 gratis sessies kan je jouw traject verder zetten met je
          begeleidend psycholoog of therapeut aan een tarief van gemiddeld 60
          euro per sessie.
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.submitTitle}>Heb je vragen?</Box>
        <Box className={classes.description}>
          Dienst Regie Gezondheid en Zorg <br />
          09 266 35 19 <br />
          <a href="mailto:regiegezondheidenzorg@stad.gent">
            regiegezondheidenzorg@stad.gent
          </a>
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.submitTitle}>
          {organization.getTranslation(translate, "blocks.privacy.title")}
        </Box>
        <Box className={classes.description}>
          Via je account op BloomUp kan je veilig in gesprek gaan met een
          externe erkende psycholoog of therapeut. Jouw gegevens worden niet
          gedeeld.
        </Box>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={classes.submitTitle}>In samenwerking met</Box>
        <Box className={classes.description}>
          <img
            alt="Mantelzorg Gent icon"
            src={`https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_LOGOS/gent.png`}
            width={100}
          />
        </Box>
      </Box>
      <PartnerPageFooter
        color={orgColor}
        description={organization.getTranslation(
          translate,
          "footer.description",
        )}
        title={organization.getTranslation(translate, "footer.title", "")}
      />
    </StyledBox>
  );
};

export default MantelzorgPage;
