/* eslint-disable react-hooks/exhaustive-deps */

import { Box, CircularProgress, Typography } from "@mui/material";
import { styled, lighten } from "@mui/material/styles";
import VideocamIcon from "@mui/icons-material/Videocam";
import clsx from "clsx";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { makeFragmentData } from "../../../../../api/__generated__";
import TimeSlot from "../../../../../data-model/types/consultation/TimeSlot";
import Bio from "../../../../../data-model/types/professional/Bio";
import Worries from "../../../../../data-model/types/profile/Worries";
import { FemaleIcon, MaleIcon } from "../../../../icons/emoticons";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import { prepareOneLanguageForAPI } from "../../../../language/languagesUtil";
import ProfessionalRate, {
  ProfessionalRate_human,
  ProfessionalRate_professional,
} from "../../../../professionals/professionalRate";
import Professional from "../../../../../data-model/types/professional/Professional";
import DeprecatedAvatar from "../../../../ui/DeprecatedAvatar";
import Button from "../../../../ui/buttons/Button";
import StyledTooltip from "../../../../ui/Tooltip";
import { IHumanMatchingContext } from "../../context/humanMatching";
import HumanMatchingContext from "../../context/HumanMatchingContext";
import { IMatch } from "../../../../../api/matching/hooks/useFindMatches";
import Experience from "../../../../../data-model/types/professional/Experience";
import MatchedProfessionalSpecialties from "./MatchedProfessionalSpecialties";
import TimeSlotBoxes from "./TimeSlotBoxes";

const PREFIX = "MatchedProfessionalListItem";

const classes = {
  actionsBody: `${PREFIX}-actionsBody`,
  actionsTitle: `${PREFIX}-actionsTitle`,
  avatar: `${PREFIX}-avatar`,
  body: `${PREFIX}-body`,
  divider: `${PREFIX}-divider`,
  firstLastName: `${PREFIX}-firstLastName`,
  genderIcon: `${PREFIX}-genderIcon`,
  info: `${PREFIX}-info`,
  name: `${PREFIX}-name`,
  online: `${PREFIX}-online`,
  profInfo: `${PREFIX}-profInfo`,
  professional: `${PREFIX}-professional`,
  professionalExpand: `${PREFIX}-professionalExpand`,
  titleBox: `${PREFIX}-titleBox`,
  wordwrapper: `${PREFIX}-wordwrapper`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.professional}`]: {
    "& .MuiAvatar-root": {
      backgroundColor: theme.palette.white.main,
      borderColor: theme.palette.bloom.main,
      borderWidth: 3,
      color: theme.palette.bloom.dark,
    },
    "&.online": {
      "& .MuiAvatar-root": {
        borderColor: theme.palette.green.main,
        color: theme.palette.green.dark,
      },

      borderLeftColor: theme.palette.green.main,
    },
    "&:last-child": {
      marginBottom: 10,
    },
    borderLeft: `3px solid ${lighten(theme.palette.bloom.main, 0.5)}`,
    display: "flex",
    margin: "10px 0 30px 0",

    paddingLeft: 15,

    paddingRight: 15,

    width: "100%",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "10px 0 0 0",
      padding: 0,
    },
  },

  [`& .${classes.professionalExpand}`]: {
    "& .select__control": {
      backgroundColor: theme.palette.white.light,
    },
    background: theme.palette.white.main,
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
    maxWidth: 210,
    minWidth: 230,
    padding: "15px 15px 15px",

    [theme.breakpoints.down("sm")]: {
      background: "white",
      maxWidth: "100%",
      width: "100%",
    },

    textAlign: "center",
  },

  [`& .${classes.profInfo}`]: {
    width: "70%",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  [`& .${classes.name}`]: {
    fontSize: theme.typography.sizes.xxl,
    margin: "10px 0 0 0",
    textAlign: "left",
    [theme.breakpoints.down(500)]: {
      margin: "0 0 0 0px",
    },
  },

  [`& .${classes.firstLastName}`]: {
    gridColumnStart: 2,
    gridRowStart: 1,
    paddingTop: 10,
    position: "relative",
    width: "100%",
    [theme.breakpoints.down(500)]: {
      display: "flex",
      gap: "5px",
      margin: "0 0 0 15px",
      paddingTop: 0,
    },
  },

  [`& .${classes.online}`]: {
    alignSelf: "center",
    backgroundColor: theme.palette.green.dark,
    borderRadius: 15,
    color: theme.palette.white.main,
    display: "inline-block",
    padding: "5px 15px",
    position: "absolute",
    [theme.breakpoints.down(400)]: {
      marginTop: "10px",
      right: 10,
    },
    right: 25,
  },

  [`& .${classes.titleBox}`]: {
    display: "flex",
    position: "relative",
    width: "100%",
    [theme.breakpoints.down(500)]: {
      flexDirection: "column",
    },
  },

  [`& .${classes.avatar}`]: {
    margin: "15px",
    position: "relative",
  },

  [`& .${classes.genderIcon}`]: {
    "& SVG": {
      fill: "white",
      height: 30,
      width: 30,
    },
    position: "absolute",

    top: 40,
  },

  [`& .${classes.info}`]: {
    padding: "0 10px 15px 15px",
    textAlign: "left",
  },

  [`& .${classes.body}`]: {
    marginTop: 10,
    textAlign: "left",
  },

  [`& .${classes.actionsTitle}`]: {
    fontSize: 12,
    marginBottom: 15,
    padding: "5px 0",
    textAlign: "left",
  },

  [`& .${classes.actionsBody}`]: {
    padding: "5px 0",
    textAlign: "left",
  },

  [`& .${classes.wordwrapper}`]: {
    wordWrap: "break-word",
  },

  [`& .${classes.divider}`]: {},
}));

interface MatchedProfessionalProps {
  match: IMatch;
  onScheduleCall: () => void;
  onSelectTime: (slot: TimeSlot) => void;
  onStartCall: () => void;
}

function MatchedProfessionalListItem({
  match,
  onStartCall,
  onScheduleCall,
  onSelectTime,
}: MatchedProfessionalProps) {
  const { professional, isOnline } = match;

  const {
    t: translate,
    i18n,
    ready,
  } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Human,
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Glossary,
  ]);

  const {
    organization,
    formState,
    loadStoredWorriesInFormState,
    healthInsurance,
  } = useContext<IHumanMatchingContext>(HumanMatchingContext);

  const gender: string = professional.getGender().getValue();

  useEffect(() => {
    // If not in form state, load from storage.
    const worriesFromFS = formState.getValue(Worries.getPath());

    if (worriesFromFS.length === 0) loadStoredWorriesInFormState();
  }, []);

  const profBio = useMemo((): string => {
    let result = translate(
      "human:onboarding.select.no_description",
      "Geen beschrijving",
    );

    const bio: Bio = professional.getBio();

    if (bio) {
      const descriptionInLanguage = bio.getDescriptionByLanguage(
        prepareOneLanguageForAPI(i18n.language),
      );

      if (descriptionInLanguage) {
        result = descriptionInLanguage.getValue();
      }
    }

    return result;
  }, [professional, i18n.language]);

  const professionalLabelStrings = {
    experience: (professional.getExperience() as Experience).getInYears(),
    name: professional.getFirstName().getValue(),
    type: translate(`common:${professional.getProfessionalType()}`),
  };

  if (!ready) return <CircularProgress />;

  return (
    <StyledBox className={`${classes.professional} ${isOnline && "online"}`}>
      <Box className={classes.profInfo}>
        <Box className={classes.titleBox}>
          <Box className={classes.avatar}>
            <DeprecatedAvatar size={70} user={professional} />
            {(gender === "male" || gender === "female") && (
              <Box className={classes.genderIcon}>
                {gender === "female" && (
                  <FemaleIcon fill={undefined} size={undefined} />
                )}
                {gender === "male" && (
                  <MaleIcon fill={undefined} size={undefined} />
                )}
              </Box>
            )}
          </Box>
          <Box className={classes.firstLastName}>
            <Typography className={classes.name}>
              {professional.getFirstName().getValue()}
            </Typography>
            <Typography className={classes.name}>
              {professional.getLastName().getValue()}
            </Typography>
          </Box>
          {isOnline && (
            <Box className={classes.online}>{translate("common:online")}</Box>
          )}
        </Box>
        <Box className={classes.info}>
          <Box className={clsx(classes.body, classes.wordwrapper)}>
            {profBio}
          </Box>
          <MatchedProfessionalSpecialties
            formState={formState}
            professional={professional}
          />
          <StyledTooltip
            placement="top-start"
            text={translate(
              "human:onboarding.select.professionaltype.tooltip",
              {
                context: (professional as Professional).getProfessionalType(),
              },
            )}
          >
            <Box className={classes.body}>
              {professional.getExperience().getInYears() <= 1
                ? translate(
                    "human:onboarding.select.professionaltype.label.noexperience",
                    professionalLabelStrings,
                  )
                : translate(
                    "human:onboarding.select.professionaltype.label",
                    professionalLabelStrings,
                  )}
            </Box>
          </StyledTooltip>
          <Box className={classes.body}>
            <ProfessionalRate
              human={makeFragmentData(
                {
                  // we have no human yet, so faking an id
                  canPayWithCredits: !!organization,
                  healthInsurance,
                  id: 999999999999999,
                  organization: organization && {
                    creditsPerHuman: organization.getCreditsPerHuman(),
                    id: organization.getID(),
                    slug: organization.getSlug(),
                  },
                },
                ProfessionalRate_human,
              )}
              professional={makeFragmentData(
                {
                  id: professional.getID(),
                  standardDuration: professional
                    .getConsultationDuration()
                    .getValue(),
                  standardRate: professional.getConsultationRate().getValue(),
                },
                ProfessionalRate_professional,
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.professionalExpand}>
        <Box className={classes.actionsTitle}>
          {translate(
            "human:onboarding.select.question",
            "Wens je een gratis en vrijblijvend kennismakingsgesprek van 15 min?",
          )}
        </Box>
        {isOnline && (
          <Button
            bloomColor="green"
            icon={<VideocamIcon />}
            label={translate(
              "human:onboarding.select.start_conversation",
              "Start nu",
            )}
            onClick={onStartCall}
            style={{
              marginBottom: 20,
              width: "-webkit-fill-available",
            }}
          />
        )}
        <TimeSlotBoxes
          label={translate("human:onboarding.select.plan_conversation", {
            context: isOnline ? "online" : "offline",
          })}
          onScheduleCall={onScheduleCall}
          onSelectTime={onSelectTime}
          timeslotItems={match.availability.map((slot: string | null) => {
            const timeSlot: TimeSlot = new TimeSlot({
              date: slot as string,
              durationInMinutes: 15,
              startTime: slot as string,
            });

            return {
              label: timeSlot.calendar(),
              value: timeSlot,
            };
          })}
        />
      </Box>
    </StyledBox>
  );
}

export default MatchedProfessionalListItem;
