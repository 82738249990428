import { Route, Routes } from "react-router-dom";
import CrispChat from "../../utils/crisp-chat/CrispChat";
import LandingPageContextProvider from "../pages/organization-landing/context/LandingPageContextProvider";
import GenericOrganizationPage from "../pages/organization-landing/GenericOrganizationPage";
import UnknownOrgFallbackPage from "../pages/organization-landing/UnknownOrgFallbackPage";
import MantelzorgPageAntwerpen from "../pages/organization-landing/custom/mantelzorgstadantwerpen/MantelzorgPageAntwerpen";
import MantelzorgPageGent from "../pages/organization-landing/custom/mantelzorgstadgent/MantelzorgPageGent";
import { RouteNames } from "./routeNames";

export default function OrganizationRoutes() {
  return (
    <LandingPageContextProvider>
      <CrispChat />

      <Routes>
        <Route
          element={<MantelzorgPageAntwerpen />}
          path={RouteNames.B2BLandingPages.MantelzorgLanding.path}
        />
        <Route
          element={<MantelzorgPageGent />}
          path={RouteNames.B2BLandingPages.MantelzorgGentLanding.path}
        />
        <Route
          element={<GenericOrganizationPage />}
          path={RouteNames.B2BLandingPages.Organization.path}
        />
        <Route
          element={<UnknownOrgFallbackPage />}
          path={`${RouteNames.B2BLandingPages.Unknown.path}/*`}
        />
        <Route
          element={<UnknownOrgFallbackPage />}
          path={RouteNames.B2BLandingPages.path}
        />
      </Routes>
    </LandingPageContextProvider>
  );
}
