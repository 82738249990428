import { useContext } from "react";
import { useTranslation } from "react-i18next";
import LoadingPage from "../../layout/LoadingPage";
import { IAuthContext } from "../../providers/auth/auth";
import AuthContext from "../../providers/auth/AuthContext";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { RouteNames } from "../../routes/routeNames";
import EmptyList from "../../ui/EmptyList";
import Human from "../../../data-model/types/human/Human";
import { IRequestConsultationContext } from "./context/RequestConsultation";
import RequestConsultationContext from "./context/RequestConsultationContext";
import RequestConsultationForm from "./RequestConsultationForm";

export default function RequestConsultationPage() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Human,
  ]);

  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const { professionals, onSubmit, formState } =
    useContext<IRequestConsultationContext>(RequestConsultationContext);

  if (!currentUser || !professionals || !formState) return <LoadingPage />;

  return professionals.length && Human.isHuman(currentUser) ? (
    <RequestConsultationForm
      formState={formState}
      onSubmit={onSubmit}
      professionals={professionals}
    />
  ) : (
    <EmptyList
      description={translate(
        "human:request.consultation.no.professional.description",
        "Kom via onze matching snel in contact met een professional die bij jou past!",
      )}
      navigationRoute={RouteNames.Matching.Start.path}
      submitLabel={translate(
        "human:request.consultation.no.professional.button.label",
        "Start de matching",
      )}
      title={translate(
        "human:request.consultation.no.professional.title",
        "Nog geen professional",
      )}
    />
  );
}
